import React, { Component } from 'react';
import './login.css';
import { Link } from 'react-router-dom';
import axios from 'axios';
import HelpBtn from '../../Components/Actions/HelpBtn';

class Login extends Component {
  constructor() {
    super();
    this.state = {
      email: '',
      password: '',
      loading: false,
      errors: {},
    };

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  async handleSubmit(event) {
    event.preventDefault();
    this.setState({
      loading: true,
    });
    const loginData = {
      email: this.state.email,
      password: this.state.password,
    };

    try {
      const response = await axios.post('/login', loginData);
      // Assuming the token is part of the response data.
      const token = response.data.idToken;

      localStorage.setItem('IdToken', `Bearer ${token}`);
      console.log(response.data);

      this.setState({
        loading: false,
      });

      // Redirect to the '/profile' route after the token is set.
      this.props.history.push('/profile');
      window.location.reload();
    } catch (error) {
      if (!error.response) {
        this.setState({
          errors: { general: 'No internet connection, try again later.' },
          loading: false,
        });
      } else {
        this.setState({
          errors: error.response.data,
          loading: false,
        });
      }
    }
  }

  onEmailChange = (event) => {
    this.setState({
      email: event.target.value,
    });
  };
  onPasswordChange = (event) => {
    this.setState({
      password: event.target.value,
    });
  };
  render() {
    return (
      <div className='login-page'>
        <div className='row justify-content-center'>
          <a href='/' className='text-center'>
            <img
              src='../Images/successrocketlogo.png'
              className='login-logo'
              alt='Landing'
            />
          </a>
        </div>
        <div className='row justify-content-center'>
          <div className='form-row'>
            <form>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  paddingLeft: '-1%',
                  paddingRight: '2%',
                }}
              >
                <div style={{ paddingTop: '2%' }}>
                  <h1 className='login-header'>User Login</h1>
                </div>
                <div>
                  <HelpBtn
                    source='https://firebasestorage.googleapis.com/v0/b/sr-server-e8ea5.appspot.com/o/SR%20Audio%20-%20Welcome%20Message.mp3?alt=media&token=9fd7a607-85a9-44b9-ab86-16f30595798e'
                    section='Success Rocket'
                  />
                </div>
              </div>

              <div className='form-group'>
                <label htmlFor='exampleInputEmail1' className='success-label'>
                  Email
                </label>
                <input
                  value={this.state.email}
                  onChange={this.onEmailChange}
                  type='email'
                  className='form-control success-input'
                  id='exampleInputEmail1'
                  aria-describedby='emailHelp'
                  placeholder='example@email.com'
                />
              </div>
              <p className='errors-p'>{this.state.errors.email}</p>
              <div className='form-group'>
                <label
                  htmlFor='exampleInputPassword1'
                  className='success-label'
                >
                  Password
                </label>
                <input
                  value={this.state.password}
                  onChange={this.onPasswordChange}
                  type='password'
                  className='form-control success-input'
                  id='exampleInputPassword1'
                  placeholder=''
                />
              </div>
              <p className='errors-p'>{this.state.errors.password}</p>
              <p className='errors-p'>{this.state.errors.general}</p>
              <div className='row justify-content-center'>
                <div className='login-btn-row'>
                  <button onClick={this.handleSubmit} className='login-btn'>
                    Login
                  </button>
                </div>
              </div>
              <div className='row justify-content-center'>
                <button className='forgot-btn'>
                  <Link to='/forgot' className='forgot-link'>
                    Forgot Password?
                  </Link>
                </button>
              </div>
            </form>
            <div className='row'>
              <div className='col-6 text-left'>
                <div className='row'>
                  <button className='login-signup-btn bottom'>
                    <Link to='/companylogin' className='login-signup-link'>
                      Company Login
                    </Link>
                  </button>
                </div>
              </div>
              <div className='col-6 '>
                <div className='row text-right'>
                  <button className='login-signup-btn bottom'>
                    <Link to='/signup' className='login-signup-link'>
                      Sign Up
                    </Link>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Login;
