import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import OnboardEdit from '../Actions/OnboardEdit';

function OnCard(props) {
  const [loading, setLoading] = useState(true);
  const [goal, setGoal] = useState('');
  const [errors, setErrors] = useState({});
  useEffect(() => {
    const ninety = props.ninety;
    if (ninety) {
      axios
        .get('/getOnboard')
        .then((res) => {
          const answer = res.data[0].answer;
          let theGoal;
          console.log(res.data);
          if (!answer) {
            theGoal = '';
          } else {
            theGoal = answer;
          }
          setGoal(theGoal);
          setLoading(false);
        })
        .catch((error) => {
          console.error('OnCard - Error fetching onboard:', error);

          setErrors('An error occurred while fetching onboard.');
          setLoading(false);
        });
    } else {
      const goaL = '';
      setGoal(goaL);
      setLoading(false);
    }
  }, []);
  if (loading) return null;
  return (
    <>
      <div className='col-md-5 shout-card text-light mb-3'>
        <div className='row'>
          <div className='col-10'>
            <h3 className='shout-header'>Onboarding Plan:</h3>
          </div>
          <div className='col-2'>
            <button
              id='onModal'
              className='onboard-modal'
              type='button'
              data-toggle='modal'
              data-target='#onmodal-edit'
            >
              <FontAwesomeIcon
                className='text-light'
                icon={['fas', 'pencil']}
              />
            </button>
            <OnboardEdit goal={goal} />
          </div>
        </div>
        <div className='shout-text'>{goal}</div>
      </div>
    </>
  );
}

export default OnCard;
