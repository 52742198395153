import axios from 'axios';
import React, { Component } from 'react';
import './actions.css';
import $ from 'jquery';
import ModalCloseBtn from '../../Constants/ModalCloseBtn';

class OnboardEdit extends Component {
  constructor() {
    super();
    this.state = {
      loading: false,
      goal: '',
      isNew: true,
      errors: {},
    };
  }

  componentDidMount() {
    if (this.props.goal === '') {
      this.setState({ isNew: true });
    } else {
      this.setState({ isNew: false });
      this.setState({ goal: this.props.goal });
    }
  }

  handleSubmit = (event) => {
    event.preventDefault();
    this.setState({
      loading: true,
    });
    const formData = {
      answer: this.state.goal,
    };

    axios
      .post('/createOnboardingGoal', formData)
      .then((res) => {
        this.setState({
          loading: false,
        });

        window.location.reload();
      })
      .catch((err) => {
        this.setState({
          errors: {
            general: 'Error: Please check information and try again.',
          },
          loading: false,
        });
        $(`#onmodal-edit`).trigger('click');
      });
  };

  onGoalChange = (event) => {
    this.setState({
      goal: event.target.value,
    });
  };

  render() {
    if (this.state.loading) return null;
    return (
      <>
        <div
          className='modal '
          id='onmodal-edit'
          tabIndex='-1'
          role='dialog'
          aria-labelledby='exampleModalCenterTitle'
          aria-hidden='true'
        >
          <div className='col-xs-2'>
            <div className='modal-arrow' id='top'></div>
          </div>
          <div className='modal-dialog modal-dialog-centered' role='document'>
            <div className='modal-content main-action text-dark'>
              <div className='modal-header'>
                <h5 className='modal-title' id='exampleModalLongTitle'>
                  90 Day Onboarding Plan
                </h5>
                <ModalCloseBtn />
              </div>
              <div className='modal-body'>
                <div className='row'>
                  <form>
                    <div className='form-group'>
                      <label
                        htmlFor='exampleFormControlInput4'
                        className='action-label'
                      >
                        Plan:
                      </label>
                      {/* <input
                    type="text"
                    className="form-control action-input"
                    
                    placeholder=""
                  /> */}
                      <textarea
                        onChange={this.onGoalChange}
                        value={this.state.goal}
                        type='text'
                        className='form-control shout-out-input'
                        id='q3'
                        placeholder='Type here'
                      />
                    </div>
                  </form>
                </div>
                <p className='errors-p p-3'>{this.state.errors.general}</p>
                <div className='modal-footer'>
                  <button
                    onClick={this.handleSubmit}
                    type='button'
                    className='btn btn-submit'
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default OnboardEdit;
