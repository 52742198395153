import axios from 'axios';
import React, { useEffect, useState } from 'react';
import Footer from './Footer';
import Header from './Header';
import SideNav from './SideNav';
import { useHistory } from 'react-router-dom';
import Spinner from '../../Components/Loading/Spinner';

const Page = (props) => {
  const [loading, setLoading] = useState(true);
  const [name, setName] = useState();
  const [rating, setRating] = useState();
  const [photo, setPhoto] = useState();
  const [isManager, setIsManager] = useState();
  const history = useHistory();

  useEffect(() => {
    axios
      .get('/user')
      .then((res) => {
        const info = res.data.credentials;
        const rating = info.rating;
        const mCode = info.managerCode;
        if (mCode !== '') {
          setIsManager(true);
        } else {
          setIsManager(false);
        }
        setName(`Hello ${info.firstName}, Have a nice day!`);
        let totalNum = 0;
        for (let j = 0; j < rating?.length; j++) {
          const rate = rating[j].rated;
          // const date = rating[j].date;
          totalNum = totalNum + rate;
        }
        const finalNum = totalNum / rating?.length;
        setPhoto(info.profileImg);
        if (finalNum <= 1) {
          setRating('☆ ☆ ☆ ☆ ☆');
        } else if (finalNum <= 2) {
          setRating('★ ☆ ☆ ☆ ☆');
        } else if (finalNum <= 3) {
          setRating('★ ★ ☆ ☆ ☆');
        } else if (finalNum <= 4) {
          setRating('★ ★ ★ ☆ ☆');
        } else if (finalNum < 5) {
          setRating('★ ★ ★ ★ ☆');
        } else {
          setRating('★ ★ ★ ★ ★');
        }
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        history.push('/landing');
      });
  }, []);

  if (loading) return <Spinner />;
  return (
    <div className='container-fluid flex-fill'>
      <div className='row'>
        <SideNav isManager={isManager} />
        <main className='col'>
          <Header name={name} rating={rating} photo={photo} />
          <div className='content-outside'>
            {props.content}
            {/* <div className='header-button'>
            <button className='fixed-btn'>AI</button>
          </div> */}
          </div>
        </main>
      </div>
      <Footer isManager={isManager} />
    </div>
  );
};

export default Page;
