import React, { Component } from 'react';
import './actions.css';
import ModalCloseBtn from '../../Constants/ModalCloseBtn';

class Policy extends Component {
  constructor() {
    super();
    this.state = {
      loading: false,
      errors: {},
    };
  }

  render() {
    return (
      <>
        <div
          className='modal '
          id='policyandterms'
          tabIndex='-1'
          role='dialog'
          aria-labelledby='exampleModalCenterTitle'
          aria-hidden='true'
        >
          <div className='col-xs-2'>
            <div className='modal-arrow' id='top'></div>
          </div>
          <div className='modal-dialog modal-dialog-centered' role='document'>
            <div className='modal-content main-action'>
              <div className='modal-header text-left'>
                <h5 className='modal-title-left' id='exampleModalLongTitle'>
                  Success Rocket <br></br> Privacy Policy & Terms of Use
                </h5>
                <ModalCloseBtn />
              </div>
              <div className='modal-body p-3 pt-1'>
                <div className='row policy-text p-3 pt-1'>
                  This page states the terms and conditions under which you may
                  use this internet site. Please read this page carefully. If
                  you do not accept the terms and conditions stated here, you
                  may not, and are not authorized to, use this site. Success
                  Rocket, Inc. may revise these terms and conditions at any time
                  by updating this posting. You should visit this page
                  periodically to review the terms and conditions because they
                  are binding on you when you use this site. Success Rocket,
                  Inc. hereby grants you a limited, non-exclusive,
                  non-assignable and non-transferable license to access and use
                  this web page and all public pages of the Success Rocket, Inc.
                  website (the “internet site”) operated by Success-Rocket,
                  Inc., and expressly conditioned upon, your agreement that all
                  such access and use shall be governed by all of the terms and
                  conditions set forth in this terms and conditions of use
                  agreement (this “agreement”). You hereby acknowledge and agree
                  as follows:
                  <ul className='policy-text'>
                    <li>
                      <strong className='strong'>The Internet Site.</strong>The
                      term internet site shall be deemed to include all areas,
                      pages and any subscription or any other software, product,
                      service, plan, data, or information provided by Success
                      Rocket, Inc. or third-parties (collectively referred to
                      herein as “information”) on the internet site. From time
                      to time, Success Rocket, Inc. may display or allow third
                      parties to display information on the internet site.
                      Success Rocket, Inc. does not guarantee the availability,
                      accuracy or reliability of any such information or endorse
                      the products or services of any third party. Success
                      Rocket, Inc. shall not be liable, directly or indirectly,
                      for any loss or damage arising out of your use or reliance
                      upon any of the information or the products featured on
                      the internet site, regardless of whether the information
                      was created by Success Rocket, Inc. or a third party.
                    </li>
                    <li>
                      <strong className='strong'>Ownership & Use.</strong>You
                      may not alter or obscure any copyright, legal or
                      proprietary notices in or on any portions of the
                      information. Success-Rocket, Inc.’s internet site and its
                      associated logos, and all page headers, marks, trade
                      names, custom graphics, buttons, and icons on the internet
                      site are service marks, trademarks, or copyrighted
                      materials of Success Rocket, Inc. or its licensors. You
                      may store in the memory of your computer and may
                      manipulate, analyze, reformat, print and/or display
                      information received or accessed through the internet
                      site. You agree to use the internet site and information
                      for lawful purposes only. You agree not to post or
                      transmit any information through the internet site which:
                      1) infringes the rights of others or violates their
                      privacy or publicity rights, 2) is unlawful, threatening,
                      abusive, vulgar, obscene, profane, indecent, or otherwise
                      objectionable, without the express permission of the owner
                      of such right. You shall be solely liable for any damages
                      resulting from any infringement of copyright, trademark or
                      other proprietary right, or any other harm resulting from
                      any uploading, posting or submission. You hereby grant to
                      Success Rocket, Inc. perpetual, irrevocable, non-exclusive
                      right and license to use, reproduce, modify, adapt,
                      publish, translate, create derivative works from,
                      distribute, perform and display any message posted on the
                      internet site or any e-mail sent by you to Success Rocket,
                      Inc. Or the internet site (in whole or in part) and to
                      incorporate it in other works in any form, media or
                      technology now known or later developed.
                    </li>
                    <li>
                      <strong className='strong'>Linking.</strong>You may not
                      link to any page within the internet site without the
                      prior written authorization of Success Rocket, Inc. You
                      may not use any of Success-Rocket, Inc.’s or its
                      licensor’s proprietary logos, marks, or other distinctive
                      graphics, video, or audio material in your links. You may
                      not link in any manner reasonably likely to:
                      <br></br>
                      1. imply affiliation with or endorsement or sponsorship by
                      Success-Rocket, Inc.;
                      <br></br>
                      2. cause confusion, mistake, or deception.
                      <br></br>
                      3. dilute Success Rocket, Inc. Or its licensor’s
                      trademarks, service marks or other intellectual property;
                      or
                      <br></br>
                      4. otherwise violate state or federal law.
                      <br></br>
                    </li>
                    <li>
                      <strong className='strong'>Delays In Services.</strong>
                      Neither Success Rocket, Inc. Nor any of its licensors
                      (including its and their partners, officers, directors,
                      employees, affiliates, agents, representatives or
                      subcontractors) shall be liable for any loss or liability
                      resulting, directly or indirectly, from delays or
                      interruptions due to electronic or mechanical equipment
                      failures, telephone interconnect problems, defects,
                      weather, strikes, walkouts, fire, acts of God, riots,
                      armed conflicts, acts of war, or other like causes.
                      Success Rocket, Inc. Shall have no responsibility to
                      provide you access to the internet site.
                    </li>
                    <li>
                      <strong className='strong'>Monitoring.</strong>
                      You acknowledge that Success Rocket, Inc. Reserves the
                      right to, and may from time to time, monitor any and all
                      information transmitted or received through the internet
                      site. Success-Rocket, Inc., in its sole discretion and
                      without further notice to you, may (but is not obligated
                      to) review, censor or prohibit the transmission or receipt
                      of any information which Success Rocket, Inc. deems
                      inappropriate (such as that specified in section 3) or
                      that violates any term or condition of this agreement.
                      During monitoring, information may be examined, recorded,
                      copied, and used for authorized purposes. Use of this
                      internet site, authorized or unauthorized, constitutes
                      consent to such monitoring. Unauthorized uses and
                      unauthorized users of the internet site will be prosecuted
                      to the full extent of the law.
                    </li>
                    <li>
                      <strong className='strong'>Equipment & Operation.</strong>
                      You shall provide and maintain all telephone and other
                      equipment necessary to access the internet site, and the
                      costs of any such equipment and/or telephone connections
                      or use, including any applicable taxes, shall be borne
                      solely by you. You shall reimburse Success Rocket, Inc.
                      for any such costs incurred by Success Rocket, Inc. due to
                      use of the internet site by you; such reimbursement shall
                      be billed to you and payable to Success Rocket, Inc.
                      within 10 days of receipt of the bill. You are responsible
                      for operating your own equipment and for familiarity with
                      the information used with or available through the
                      internet site. Success Rocket, Inc. reserves the right to
                      refuse assistance or to charge additional fees if you seek
                      assistance from Success Rocket, Inc. with respect to such
                      information or any other matters not directly relating to
                      the operation of the internet site.
                    </li>
                    <li>
                      <strong className='strong'>
                        Limited Warranty & Liability.
                      </strong>
                      You acknowledge that the information and links provided
                      through the internet site are compiled from sources, which
                      are beyond the control of Success-Rocket, Inc.. though
                      such information is recognized by the parties to be
                      generally reliable, the parties acknowledge that
                      inaccuracies may occur and Success Rocket, Inc. and its
                      licensors do not warrant the accuracy or suitability of
                      the information. For this reason, you acknowledge and
                      agree that the internet site and information is provided
                      to you on an “as is, with all faults” basis. Success
                      Rocket, Inc. And its licensors expressly disclaim any and
                      all warranties, whether express, oral, implied, statutory
                      or otherwise, including any implied warranty of fitness
                      for a particular purpose, any implied warranty of
                      merchantability, any warranties arising by virtue of
                      custom of trade or course of dealing and any implied
                      warranties of title or non-infringement. Further, Success
                      Rocket, Inc. and its licensors do not represent or warrant
                      that the internet site or the information will meet your
                      requirements or are suitable for your needs. Under this
                      agreement, you assume all risk of errors and/or omissions
                      in the information, including the transmission or
                      translation of information. You assume full responsibility
                      for implementing sufficient procedures and checks to
                      satisfy your requirements for the accuracy and suitability
                      of the information and for maintaining any means, which
                      you may require for the reconstruction of lost data or
                      subsequent manipulations, or analyses of the information
                      provided under this agreement. You agree that Success
                      Rocket, Inc. and its licensors (including its and their
                      partners, officers, directors, employees, affiliates,
                      agents, representatives or subcontractors) shall not in
                      any event be liable for any special, incidental or
                      consequential damages arising out of the use or inability
                      to use the internet site or the information for any
                      purpose whatsoever.
                    </li>
                    <li>
                      <strong className='strong'>Viruses.</strong>
                      You acknowledge and agree that Success Rocket, Inc. will
                      use reasonable efforts to assure that no viruses or
                      programs with similar functions operate on, or are passed
                      through the internet site or the information. However, you
                      hereby assume all responsibility (and thereby hold Success
                      Rocket, Inc. harmless), by whatever means you deem most
                      appropriate for your needs for detecting and eradicating
                      any virus or program with a similar function.
                    </li>
                    <li>
                      <strong className='strong'>FTC Notice.</strong>
                      Some states do not allow limitations on how long an
                      implied warranty lasts, so the above limitation may not
                      apply to you. Some states do not allow the exclusion or
                      limitation of incidental or consequential damages, so the
                      above limitation or exclusion may not apply to you. This
                      warranty gives you specific legal rights, and you may also
                      have other rights which vary from state to state.
                    </li>
                    <li>
                      <strong className='strong'>Indemnification.</strong>
                      You agree to and shall indemnify, defend, and hold
                      harmless Success Rocket, Inc. and its licensors (including
                      its and their partners, officers, directors, employees,
                      affiliates, agents, representatives or subcontractors)
                      from and against all claims brought by persons or entities
                      other than the parties to this agreement arising from or
                      relating to your access to and use of the internet site,
                      including the information obtained through the internet
                      site and any losses, costs, damages, personal injury or
                      other expenses arising out of your use of this internet
                      site, or your use of or reliance upon the information.
                    </li>
                    <li>
                      <strong className='strong'>Privacy.</strong>
                      Success Rocket, Inc. can use your personal identifying
                      information for editorial, promotional, or marketing
                      purposes, unless you request that your information not be
                      used in such manner. Success Rocket, Inc. may place a
                      “cookie” in the browser files of your computer. Such
                      cookie does not contain any personally identifying
                      information.
                    </li>
                    <li>
                      <strong className='strong'>No Conflicting Terms.</strong>
                      If there is any conflict between this agreement and any
                      help text, manuals or other documents, this agreement
                      shall govern, whether such other documents are prior to or
                      subsequent to this agreement, or are signed or
                      acknowledged by any partner, officer, director, employee,
                      affiliate, agent, representative or subcontractor of
                      SuccessRocket, Inc.
                    </li>
                    <li>
                      <strong className='strong'>Attorney's Fees.</strong>
                      If Success Rocket, Inc. Takes action (by itself or through
                      its representatives) to enforce any of the provisions of
                      this agreement, including collection of any amounts due
                      hereunder, Success Rocket, Inc. Shall be entitled to
                      recover from you (and you agree to pay), in addition to
                      all sums to which is entitled or any other relief, at law
                      or in equity, reasonable and necessary attorney’s fees and
                      any costs of any litigation.
                    </li>
                    <li>
                      <strong className='strong'>
                        Governing Law; Limitations; Venue.
                      </strong>
                      This agreement shall be governed by the laws of the state
                      of Georgia, excluding any rule or principle that would
                      refer to and apply the substantive law of another state or
                      jurisdiction. To the extent allowed by applicable law, any
                      claims or causes of action arising from or relating to
                      your access and use of the internet site or the
                      information contemplated by this agreement must be
                      instituted within two (2) years from the date upon which
                      such claim or cause arose or accrued. Further, any such
                      claim or cause of action may only be brought in the state
                      and federal courts located in Dawson County, Georgia, and
                      you agree to submit to the exclusive personal jurisdiction
                      of such courts and hereby appoint the secretary of state
                      of Georgia as your agent for service of process. You
                      hereby waive any objection and the right to raise any
                      future objection that the state or federal courts of
                      Dawson County, Georgia are an inconvenient forum for the
                      resolution of any dispute or claim arising under this
                      agreement.
                    </li>
                    <li>
                      <strong className='strong'>Severability.</strong>
                      If any provision of this agreement is found to be unlawful
                      or unenforceable in any respect, the court shall reform
                      such provision so as to render it enforceable (or, if it
                      is not possible to reform such provision so as to make it
                      enforceable, then delete such provision); and, as so
                      reformed or modified, fully enforce this agreement.
                    </li>
                    <li>
                      <strong className='strong'>Entire Agreement.</strong>
                      This agreement is complete and effective at the time you
                      agree to it by using this internet site, and this
                      agreement constitutes the entire agreement between you and
                      Success Rocket, Inc. concerning the subject matter hereof,
                      and no other agreement, written or oral, exists between
                      you and Success Rocket, Inc.
                    </li>
                  </ul>
                  Despite the efforts of Success Rocket, Inc. To provide
                  accurate information it is not possible to completely ensure
                  that all information is constantly correct. The materials
                  contained on this server could include technical inaccuracies
                  or typographical errors. As such, Success Rocket, Inc. does
                  not accept any responsibility or liability for any actions
                  taken as a result of using this server. Further, Success
                  Rocket, Inc. assumes no responsibility for errors or omissions
                  in the materials on this server. These are provided “as is”
                  without warranty of any kind, either express or implied
                  warranties of merchantability, fitness for a particular
                  purpose, or non-infringement. Success Rocket, Inc. further
                  does not warrant the accuracy or completeness of information,
                  text, graphics, links or other items contained within these
                  materials. In no event shall the Success Rocket, Inc. Be
                  liable for any special, indirect, incidental, or consequential
                  damages of any kind, or any damages whatsoever resulting from
                  loss of use, data or profits, whether or not advised of the
                  possibility of damage, and on any theory of liability, arising
                  out of or in connection with the use of the materials. The
                  materials are subject to change without notice. Success
                  Rocket, Inc. may add, withdraw or modify information within
                  the internet site or services provided through the internet
                  site at any time in its sole discretion. You agree to review
                  the terms and conditions of this agreement periodically to be
                  aware of such revisions.
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Policy;
