import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component } from 'react';
import { faCircle } from '@fortawesome/pro-solid-svg-icons';
import ResMain from './ResMain';
import ModalCloseBtn from '../../Constants/ModalCloseBtn';

class ArchivedMain extends Component {
  constructor() {
    super();
    this.state = {
      id: '',
      name: '',
      action: '',
      description: '',
      priority: '',
      dDate: '',
      comment: '',
      rating: '',
      loading: true,
      errors: {},
    };
  }

  componentDidMount() {
    this.setState({ id: this.props.id });
    this.setState({ name: this.props.name });
    this.setState({ action: this.props.action });
    this.setState({ dDate: this.props.dDate });
    this.setState({ description: this.props.description });
    this.setState({ priority: this.props.priority });
    this.setState({ comment: this.props.comment });
    this.setState({ rating: this.props.rating });

    this.setState({ loading: false });
  }

  render() {
    if (this.state.loading) return null;
    return (
      <>
        <div
          className='modal '
          id={`edit${this.state.id}`}
          tabIndex='-1'
          role='dialog'
          aria-labelledby='exampleModalCenterTitle'
          aria-hidden='true'
        >
          <div className='col-xs-2'>
            <div className='modal-arrow-sub' id='top'></div>
          </div>
          <div className='modal-dialog modal-dialog-centered' role='document'>
            <div className='modal-content sub-action'>
              <div className='modal-header'>
                <h5 className='modal-edit-title' id='exampleModalLongTitle'>
                  Archived Action
                </h5>
                <ModalCloseBtn />
              </div>
              <div className='modal-body'>
                <form>
                  <div className='form-group disabled-group'>
                    <label
                      htmlFor='exampleFormControlInput3'
                      className='action-label'
                    >
                      Action
                    </label>
                    <input
                      type='text'
                      className='form-control action-input disabled-input'
                      placeholder={this.state.action}
                      disabled
                    />
                  </div>
                  <div className='form-group disabled-group'>
                    <label
                      htmlFor='exampleFormControlInput4'
                      className='action-label'
                    >
                      Description
                    </label>
                    <input
                      type='text'
                      className='form-control action-input'
                      value={this.state.description}
                      disabled
                    />
                  </div>
                  <div className='form-group disabled-group'>
                    <label
                      htmlFor='exampleFormControlInput5'
                      className='action-label'
                    >
                      Priority
                    </label>
                    <div className='row'>
                      <div className='col-11 action-input-col'>
                        <p
                          type='text'
                          className='form-control action-input disabled-group'
                        >
                          <FontAwesomeIcon
                            className={`priority-icon-${this.state.priority}`}
                            icon={faCircle}
                          />{' '}
                          {this.state.priority}
                        </p>
                      </div>
                      <div className='col-1 action-icon-col'>
                        <span className='action-icon'>
                          <FontAwesomeIcon
                            className='action-i form-control-feedback'
                            icon={['fal', 'chevron-down']}
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className='form-group disabled-group'>
                    <label
                      htmlFor='exampleFormControlInput6'
                      className='action-label'
                    >
                      Due Date
                    </label>
                    <input
                      type='date'
                      className='form-control action-input'
                      value={this.state.dDate}
                      disabled
                    />
                  </div>
                  <div className='form-group disabled-group'>
                    <label
                      htmlFor='exampleFormControlInput7'
                      className='action-label'
                    >
                      Status
                    </label>
                    <select className='form-select action-select '>
                      <option>Complete</option>
                    </select>
                  </div>
                  <div className='form-group disabled-group'>
                    <label
                      htmlFor='exampleFormControlInput7'
                      className='action-label'
                    >
                      Rating
                    </label>
                    <select
                      className='form-select action-input star-select'
                      value={this.state.rating}
                      disabled
                    >
                      <option value='5'>★ ★ ★ ★ ★</option>
                      <option value='4'>★ ★ ★ ★ ☆</option>
                      <option value='3'>★ ★ ★ ☆ ☆</option>
                      <option value='2'>★ ★ ☆ ☆ ☆</option>
                      <option value='1'>★ ☆ ☆ ☆ ☆</option>
                      <option value='0'>☆ ☆ ☆ ☆ ☆</option>
                    </select>
                  </div>
                  <div className='form-group disabled-group'>
                    <label
                      htmlFor='exampleFormControlInput7'
                      className='action-label'
                    >
                      Comment
                    </label>
                    <input
                      type='text'
                      className='form-control action-input'
                      placeholder=''
                      value={this.state.comment}
                      disabled
                    />
                  </div>
                </form>
              </div>
              <div className='modal-footer'>
                <button
                  id={`resMainAction${this.state.id}btn`}
                  type='button'
                  className='btn btn-submit'
                  data-toggle='modal'
                  data-target={`#resMainAction${this.state.id}`}
                >
                  Resurrect
                </button>
                <ResMain
                  id={this.state.id}
                  name={this.state.name}
                  action={this.state.action}
                  description={this.state.description}
                  priority={this.state.priority}
                />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default ArchivedMain;
