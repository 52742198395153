import axios from 'axios';
import React, { Component } from 'react';
import BackBtn from '../../Components/Back/BackBtn';
import Page from '../../Components/NavBars/Page';
import ManagerNotes from '../../Components/Profile/ManagerNotes';
import ShoutCard from '../../Components/Profile/ShoutCard';
import ViewProfInfo from '../../Components/Profile/ViewProfInfo';
import UserSuccess from '../../Components/Profile/UserSuccess';
import './profile.css';
import { analytics } from '../../firebaseConfig.js';
import OnCardView from '../../Components/Onboarding/OnCardView';

class ViewProfile extends Component {
  constructor() {
    super();
    this.state = {
      id: '',
      name: '',
      first: '',
      phone: '',
      email: '',
      manager: '',
      overseer: '',
      momentum: '',
      rating: '',
      profileImg: '',
      ninety: '',
      se: '',
      sc: {
        a1: '',
        a2: '',
        a3: '',
      },
      shoutOuts: [],
      notes: [],
      loading: true,
      errors: {},
    };
  }

  componentDidMount() {
    analytics.logEvent('other_prof_visited');
    axios
      .get('/getUserByID/' + this.props.match.params.id)
      .then((res) => {
        const cred = res.data.userData[0];
        const days = cred.created;
        const newDate = new Date(days);
        let ninetyDay = new Date(newDate.setDate(newDate.getDate() + 90));
        let today = new Date();
        if (ninetyDay > today) {
          this.setState({ ninety: true });
        } else {
          this.setState({ ninety: true });
          // Use when 'created' is added ^
          // this.setState({ ninety: false})
        }
        const theShoutOuts = res.data.shoutouts;
        const name = cred.firstName + ' ' + cred.lastName;
        let credRating = cred.rating;
        if (!isNaN(credRating)) {
          credRating = [{ date: '', rated: '5' }];
        }
        const last90 = credRating.filter(
          (rate) =>
            new Date(rate.date).getTime() >=
            new Date().getTime() - 90 * 24 * 60 * 60 * 1000,
        );

        const prev90 = credRating.filter(
          (rate) =>
            new Date(rate.date).getTime() <=
              new Date().getTime() - 90 * 24 * 60 * 60 * 1000 &&
            new Date(rate.date).getTime() >=
              new Date().getTime() - 180 * 24 * 60 * 60 * 1000,
        );

        let last90sum = 0;
        let prev90sum = 0;

        last90.forEach((rating) => {
          last90sum += rating.rated;
        });

        prev90.forEach((rating) => {
          prev90sum += rating.rated;
        });

        let momentum = 0;
        if (prev90sum > 0) {
          momentum = last90sum / prev90sum;
        } else {
          momentum = last90sum;
        }
        this.setState({ momentum: momentum });

        let totalNum = 0;
        for (let j = 0; j < cred.rating?.length; j++) {
          const rating = cred.rating[j].rated;
          totalNum = totalNum + rating;
        }
        const finalNum = totalNum / cred.rating?.length;
        if (finalNum <= 1) {
          this.setState({ rating: '☆ ☆ ☆ ☆ ☆' });
        } else if (finalNum <= 2) {
          this.setState({ rating: '★ ☆ ☆ ☆ ☆' });
        } else if (finalNum <= 3) {
          this.setState({ rating: '★ ★ ☆ ☆ ☆' });
        } else if (finalNum <= 4) {
          this.setState({ rating: '★ ★ ★ ☆ ☆' });
        } else if (finalNum < 5) {
          this.setState({ rating: '★ ★ ★ ★ ☆' });
        } else {
          this.setState({ rating: '★ ★ ★ ★ ★' });
        }
        totalNum = 0;
        this.setState({ id: cred.userId });
        this.setState({ first: cred.firstName });
        this.setState({ name: name });
        this.setState({ phone: cred.phone });
        this.setState({ email: cred.email });
        this.setState({ se: cred.successEngagements });
        this.setState({ sc: { a1: cred.a1, a2: cred.a2, a3: cred.a3 } });
        this.setState({ profileImg: cred.profileImg });
        this.setState({ manager: cred.overseerName });
        this.setState({ shoutOuts: theShoutOuts });

        axios
          .get(`/getNotes/${cred.userId}`)
          .then((res) => {
            const theNotes = res.data;

            this.setState({ notes: theNotes.note });
          })
          .catch((error) => {
            console.error('ViewProfile - Error getting notes:', error);

            this.setState({
              loading: false,
              error: 'An error occurred while fetching notes.',
            });
          });
      })
      .catch((error) => {
        console.error('ViewProfile - Error fetching user by ID:', error);

        this.setState({
          loading: false,
          error: 'An error occurred while fetching user data.',
        });
      });
  }
  render() {
    if (this.state.loading) return null;
    return (
      <>
        <Page
          content={
            <div className='edit-profile'>
              <div className='row edit-col'>
                <div className='row mt-3'>
                  <BackBtn link='directory' className='back-check' />
                  {this.state.ninety ? (
                    <div className='row'>
                      <OnCardView name={this.state.first} id={this.state.id} />
                    </div>
                  ) : (
                    <div></div>
                  )}
                  <ViewProfInfo
                    id={this.state.id}
                    name={this.state.name}
                    phone={this.state.phone}
                    email={this.state.email}
                    manager={this.state.manager}
                    birthday={this.state.birthday}
                    momentum={this.state.momentum}
                    rating={this.state.rating}
                    se={this.state.se}
                    profileImg={this.state.profileImg}
                  />

                  <UserSuccess
                    first={this.state.first}
                    q1={this.state.sc.a1}
                    q2={this.state.sc.a2}
                    q3={this.state.sc.a3}
                  />
                </div>

                <div className='row p-0'>
                  <div className='col-lg-8'>
                    <ShoutCard shout={this.state.shoutOuts} />
                  </div>
                  <div className='col-lg-4 p-0 mt-1 mb-2'>
                    <ManagerNotes
                      notes={this.state.notes}
                      id={this.props.match.params.id}
                    />
                  </div>
                </div>
              </div>
            </div>
          }
        />
      </>
    );
  }
}

export default ViewProfile;
