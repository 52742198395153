import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import BackBtn from '../../Components/Back/BackBtn';
import Page from '../../Components/NavBars/Page';
import './edit.css';
import $ from 'jquery';
import { analytics } from '../../firebaseConfig.js';

class EditProfile extends Component {
  constructor() {
    super();
    this.state = {
      id: '',
      first: '',
      last: '',
      phone: '',
      first3: '',
      middle3: '',
      last4: '',
      email: '',
      manager: '',
      profileImg: '',
      loading: true,
      errors: {},
    };
  }

  componentDidMount() {
    analytics.logEvent(`edit_prof_visited`);
    axios.get('/user').then((res) => {
      const cred = res.data.credentials;
      this.setState({ id: cred.id });
      this.setState({ first: cred.firstName });
      this.setState({ last: cred.lastName });
      this.setState({ phone: cred.phone });
      const firstThree = cred.phone.split('-')[0];
      const middleThree = cred.phone.split('-')[1];
      const lastFour = cred.phone.split('-')[2];
      this.setState({ first3: firstThree });
      this.setState({ middle3: middleThree });
      this.setState({ last4: lastFour });
      this.setState({ email: cred.email });
      this.setState({ profileImg: cred.profileImg });
      this.setState({ manager: cred.overseerName });
    });

    //    axios.get('/userData/id')
    //     .then(res => {
    //         const data = res.data;
    //         this.setState({first: data.first})
    //         this.setState({last: data.last})
    //         this.setState({phone: data.phone})
    //         this.setState({email: data.email})
    //         this.setState( {manager: data.manager})
    //         this.setState({birthday: data.birthday})
    //     })
    this.setState({ loading: false });
  }
  handleSubmit = (event) => {
    event.preventDefault();
    this.setState({
      loading: true,
    });
    const editProfile = {
      firstName: this.state.first,
      lastName: this.state.last,
      phone: this.state.phone,
    };
    console.log(editProfile);
    axios
      .post('/updateProfileData', editProfile)
      .then((res) => {
        this.setState({
          loading: false,
        });
        this.props.history.push('/profile');
      })
      .catch((err) => {
        console.log(err.response.data);
        this.setState({
          errors: { general: 'Error: Please check information and try again.' },
          loading: false,
        });
      });
  };
  onFirstChange = (event) => {
    this.setState({
      first: event.target.value,
    });
  };
  onLastChange = (event) => {
    this.setState({
      last: event.target.value,
    });
  };
  onPhoneChange = (event) => {
    $('.numbers').keyup(function () {
      if (this.value.length === this.maxLength) {
        $(this).next('.numbers').focus();
      }
    });
    const val1 = $('#numbers1').val();
    const val2 = $('#numbers2').val();
    const val3 = $('#numbers3').val();
    this.setState({ first3: val1 });
    this.setState({ middle3: val2 });
    this.setState({ last4: val3 });
    this.setState({
      phone: val1 + '-' + val2 + '-' + val3,
    });
  };

  onImageChange = (event) => {
    this.setState({ loading: true });
    const image = event.target.files[0];
    const formData = new FormData();
    formData.append('image', image, image.name);
    axios.post('/user/image', formData).then((res) => {
      console.log(res);
      window.location.reload();
    });
    this.setState({ loading: false });
  };

  updatePassword = (event) => {
    event.preventDefault();
    this.setState({
      loading: true,
    });
    const passData = {
      email: this.state.email,
    };

    axios
      .post('/resetPasswordEmail', passData)
      .then((res) => {
        this.setState({
          errors: { reset: 'Check your email for a link to reset password.' },
          loading: false,
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          errors: {
            general:
              "That didn't work! Please enter the email associated with your account.",
          },
          loading: false,
        });
      });
  };

  imAManager = (event) => {
    event.preventDefault();
    this.setState({
      loading: true,
    });
    axios
      .post('/generateManagerCode')
      .then((res) => {
        this.setState({
          loading: false,
        });
        window.location.reload();
      })
      .catch((err) => {
        this.setState({
          loading: false,
        });
      });
  };

  render() {
    if (this.state.loading) return null;
    return (
      <>
        <Page
          content={
            <div className=''>
              <div className='row edit-col'>
                <div>
                  <BackBtn link='profile' className='space-below-2' />
                </div>
                <h1 className='edit-sc-header'>Edit Profile</h1>
                <div className='row my-3'>
                  <div className='d-flex flex-col align-items-end'>
                    <img
                      src={this.state.profileImg}
                      className='edit-profile-img'
                      alt='Example'
                    />
                    <label>
                      <FontAwesomeIcon
                        icon={['fal', 'pencil']}
                        className='cursor-hover'
                      />
                      <input
                        type='file'
                        onChange={this.onImageChange}
                        id='inputGroupFile01'
                      />
                    </label>
                  </div>
                </div>
                <div className='row mt-3'>
                  <form>
                    <div className='form-group'>
                      <label htmlFor='fName' className='sign-label'>
                        First Name
                      </label>
                      <input
                        type='text'
                        className='form-control sign-input'
                        id='fName'
                        aria-describedby='First Name'
                        value={this.state.first}
                        onChange={this.onFirstChange}
                      ></input>
                      <FontAwesomeIcon
                        className='manager-search-icon'
                        icon={['fal', 'pencil']}
                      />
                    </div>
                    <div className='form-group'>
                      <label htmlFor='lName' className='sign-label'>
                        Last Name
                      </label>
                      <input
                        type='text'
                        className='form-control sign-input'
                        id='lName'
                        aria-describedby='Last Name'
                        value={this.state.last}
                        onChange={this.onLastChange}
                      />
                      <FontAwesomeIcon
                        className='manager-search-icon'
                        icon={['fal', 'pencil']}
                      />
                    </div>
                    <div className='form-group'>
                      <label htmlFor='phone' className='sign-label'>
                        Phone Number
                      </label>
                      {/* <label>Phone Number</label> */}
                      <div className='row'></div>
                      <input
                        type='text'
                        id='numbers1'
                        className='numbers sign-input'
                        maxLength='3'
                        size='3'
                        value={this.state.first3}
                        onChange={this.onPhoneChange}
                      />{' '}
                      -{' '}
                      <input
                        type='text'
                        id='numbers2'
                        className='numbers sign-input'
                        maxLength='3'
                        size='3'
                        value={this.state.middle3}
                        onChange={this.onPhoneChange}
                      />{' '}
                      -{' '}
                      <input
                        type='text'
                        id='numbers3'
                        className='numbers sign-input'
                        maxLength='4'
                        size='5'
                        value={this.state.last4}
                        onChange={this.onPhoneChange}
                      />
                      {/* <input value={this.state.phone} onChange={this.onPhoneChange}type="number" className="form-control sign-input" id="phone" aria-describedby="Phone Number" placeholder=""/> */}
                    </div>
                    <div className='form-group'>
                      <label htmlFor='email' className='sign-label'>
                        Email
                      </label>
                      {/* <input
                        type='email'
                        className='form-control sign-input'
                        id='exampleInputEmail1'
                        aria-describedby='emailHelp'
                        value={this.state.email}
                      /> */}
                      <p className='form-control sign-input'>
                        {this.state.email}
                      </p>
                    </div>

                    <div className='form-group row'>
                      <div className='col-4'>
                        <label htmlFor='manager' className='sign-label'>
                          Manager
                        </label>
                        <input
                          type='text'
                          className='form-control sign-input disabled-input'
                          id='manager'
                          aria-describedby='First Name'
                          disabled
                          value={this.state.manager}
                        />
                      </div>
                      <div className='col-8 pt-3'>
                        <Link to='/newManager'>
                          <button className='btn btn-submit'>Change</button>
                        </Link>
                      </div>
                    </div>

                    <div className='form-group row'>
                      <label htmlFor='password' className='sign-label mb-1'>
                        My Role
                      </label>
                      <div className='col-7 '>
                        <button
                          className='new-password'
                          onClick={this.imAManager}
                        >
                          I've become a Manager
                        </button>{' '}
                      </div>
                    </div>
                    <div className='form-group row'>
                      <label htmlFor='password' className='sign-label mb-1'>
                        Password
                      </label>
                      <div className='col-7  '>
                        <button
                          className='new-password'
                          onClick={this.updatePassword}
                        >
                          Update Password
                        </button>{' '}
                      </div>
                    </div>
                    <p className='errors-p p-3'>{this.state.errors.reset}</p>
                    <p className='errors-p pl-3'>{this.state.errors.general}</p>
                    <div className='form-group mt-3'>
                      <button
                        onClick={this.handleSubmit}
                        className='btn sign-btn mt-3'
                      >
                        Update Info
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          }
        />
      </>
    );
  }
}

export default EditProfile;
