import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component } from 'react';
import './actions.css';

class ViewFeedback extends Component {
  constructor() {
    super();
    this.state = {
      responseId: '',
      responderName: '',
      description: '',
      anon: false,
      response1: '',
      response2: '',
      response3: '',
      rating: '',
      loading: true,
      errors: {},
    };
  }

  componentDidMount() {
    if (this.props.anon === true) {
      this.setState({ responderName: 'ANONYMOUS' });
    } else {
      this.setState({ responderName: this.props.responderName });
    }
    this.setState({
      responseId: this.props.responseId,
      anon: this.props.anon,
      description: this.props.description,
      rating: this.props.rating,
      response1: this.props.response1,
      response2: this.props.response2,
      response3: this.props.response3,
    });
    this.setState({ loading: false });
  }

  render() {
    return (
      <>
        <div
          key={this.props.id}
          className='modal '
          id={`viewFeedback${this.props.responseId}${this.props.feedbackId}`}
          tabIndex='-1'
          role='dialog'
          aria-labelledby='exampleModalCenterTitle'
          aria-hidden='true'
        >
          <div className='col-xs-2'>
            <div className='modal-arrow' id='top'></div>
          </div>
          <div className='modal-dialog modal-dialog-centered' role='document'>
            <div className='modal-content main-action'>
              <div className='modal-header'>
                <h3 className='modal-title' id='exampleModalLongTitle'>
                  <FontAwesomeIcon
                    className='icon-mobile'
                    icon={['fal', 'sync']}
                  />
                </h3>
                <button
                  type='button'
                  className='closeBtn mt-1'
                  data-dismiss='modal'
                  aria-label='Close'
                >
                  <span aria-hidden='true'>&times;</span>
                </button>
              </div>
              <div className='modal-header'>
                <h3 className='modal-title' id='exampleModalLongTitle'>
                  {`from ${this.state.responderName}`}
                </h3>
              </div>

              <div className='modal-body'>
                <form>
                  <div className='form-group'>
                    <label
                      htmlFor='exampleFormControlInput4'
                      className='action-label'
                    >
                      Description
                    </label>
                    <textarea
                      value={this.state.description}
                      type='text'
                      className='form-control action-input feed-textbox'
                      placeholder=''
                      disabled
                    />
                  </div>

                  <div className='form-group mt-3'>
                    <label
                      htmlFor='exampleFormControlInput4'
                      className='action-label'
                    >
                      How do you feel I'm doing in driving success for my
                      department?
                    </label>
                    <p className='form-control action-input feedback-res'>
                      {this.state.response1}
                    </p>
                  </div>

                  <div className='form-group mt-3'>
                    <label
                      htmlFor='exampleFormControlInput4'
                      className='action-label'
                    >
                      How do you feel I'm doing to contribute to the success of
                      our organization?
                    </label>
                    <p className='form-control action-input feedback-res'>
                      {this.state.response2}
                    </p>
                  </div>

                  <div className='form-group mt-3'>
                    <label
                      htmlFor='exampleFormControlInput4'
                      className='action-label'
                    >
                      How do you feel I'm doing in engaging with you and your
                      team (as applicable)?
                    </label>
                    <p className='form-control action-input feedback-res'>
                      {this.state.response3}
                    </p>
                  </div>

                  <div className={'form-group mt-3'}>
                    <label
                      htmlFor='exampleFormControlInput7'
                      className='action-label'
                    >
                      Feedback Rating
                    </label>
                    <select
                      value={this.state.rating}
                      className='form-select action-input star-select'
                      disabled
                    >
                      <option>{this.state.rating}</option>
                    </select>
                  </div>
                </form>
              </div>

              {/* <div className="modal-footer">
                <button
                  type="button"
                  onClick={this.handleSubmit}
                  className="btn btn-submit"
                >
                  Submit
                </button>
              </div> */}
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default ViewFeedback;
