import React from 'react';
import { Route, Redirect } from 'react-router-dom';

const AuthRoute = ({ component, auth, isManager, ...rest }) => {
  // const manager = store.user.credentials.managerCode;
  // const manager = "0899987890";
  const Component = component;

  return (
    <Route
      {...rest}
      render={(props) =>
        auth ? <Component {...props} /> : <Redirect to='/' />
      }
    />
  );
};

export default AuthRoute;
