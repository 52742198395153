import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import React, { Component } from 'react';
import CompanyPage from '../../Components/NavBars/CompanyPage';
import { analytics } from '../../firebaseConfig';

class AdminCompanyFocus extends Component {
  constructor() {
    super();
    this.state = {
      cv: '',
      kvo: '',
      cvI: false,
      kcoI: false,
      loading: true,
      errors: {},
    };
  }
  componentDidMount() {
    analytics.logEvent(`edit_cf_visited`);
    axios.get('/company').then((res) => {
      const data = res.data.credentials;
      this.setState({ cv: data.cv });
      this.setState({ kvo: data.kvo });
      this.setState({ loading: false }); // Move it here to set loading to false after data is fetched
    });
  }
  handleSubmit = (event) => {
    event.preventDefault();
    this.setState({
      loading: true,
    });
    let editSuccess = {
      id: this.state.id,
      cv: this.state.cv,
      kvo: this.state.kvo,
    };
    if (editSuccess.cv === '') {
      editSuccess.cv = '...';
    } else if (editSuccess.kvo === '') {
      editSuccess.kvo = '...';
    }
    console.log(editSuccess);
    axios
      .post('/updateCFAnswers', editSuccess)
      .then((res) => {
        this.setState({
          loading: false,
        });
      })
      .catch((err) => {
        console.log(err.response.data);
        this.setState({
          errors: { general: 'Error: Please check information and try again.' },
          loading: false,
        });
      });
  };
  onCvChange = (event) => {
    this.setState({
      cvI: true,
    });
    this.setState({
      cv: event.target.value,
    });
  };
  onKvoChange = (event) => {
    this.setState({
      kvoI: true,
    });
    this.setState({
      kvo: event.target.value,
    });
  };
  render() {
    if (this.state.loading) return null;
    return (
      <>
        <CompanyPage
          companyName={CompanyPage}
          content={
            <div className='m-checkin-page'>
              <div className='row'>
                <div className='col-10'>
                  <h1>Company Focus</h1>
                </div>
              </div>

              <div className='form-group mt-3'>
                <div className='row'>
                  <div className='col-11'>
                    <label htmlFor='q2'>
                      <h2>Company Values</h2>
                    </label>
                  </div>

                  <div className='col mb-3'>
                    <button
                      onClick={this.handleSubmit}
                      className='btn btn-submit'
                      type='submit'
                    >
                      Update
                    </button>
                  </div>
                </div>
                <div className='clear-space'>
                  {this.state.cvI ? (
                    <FontAwesomeIcon
                      className='sc-icon-edit'
                      icon={['fad', 'people-group']}
                    />
                  ) : null}
                </div>
                <textarea
                  value={this.state.cv}
                  onChange={this.onCvChange}
                  type='text'
                  className='form-control'
                  rows='8'
                />
              </div>
              <br />
              <div className='form-group'>
                <div className='row'>
                  <div className='col-11'>
                    <label htmlFor='q2'>
                      <h2>Key Company Objectives</h2>
                    </label>
                  </div>

                  <div className='col mb-3'>
                    <button
                      onClick={this.handleSubmit}
                      className='btn btn-submit'
                      type='submit'
                    >
                      Update
                    </button>
                  </div>
                </div>
                <div className='clear-space'>
                  {this.state.kvoI ? (
                    <FontAwesomeIcon
                      className='sc-icon-edit'
                      icon={['fad', 'bullseye']}
                    />
                  ) : null}
                </div>
                <textarea
                  value={this.state.kvo}
                  onChange={this.onKvoChange}
                  type='text'
                  className='form-control'
                  rows='8'
                />
              </div>
            </div>
          }
        />
      </>
    );
  }
}

export default AdminCompanyFocus;
