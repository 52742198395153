import axios from 'axios';
import React, { Component } from 'react';

class Res2Mentor extends Component {
  constructor() {
    super();
    this.state = {
      question: '',
      response: '',
      loading: false,
      errors: {},
    };
  }

  onResponseChange = (event) => {
    this.setState({
      response: event.target.value,
    });
  };

  handleSubmit = (event) => {
    event.preventDefault();

    const formData = {
      response: this.state.response,
    };

    axios
      .post('/resMentorQuestion', formData)
      .then((res) => {
        this.setState({
          loading: false,
        });
        window.location.reload();
      })
      .catch((err) => {
        console.log(err.response.data);
        this.setState({
          errors: {
            general: 'Error: Mentor relationships are not quite ready yet!',
          },
          loading: false,
        });
      });
  };

  render() {
    return (
      <>
        <div
          className='modal '
          id={`replyToMentor${this.props.mentorId}`}
          tabIndex='-1'
          role='dialog'
          aria-labelledby='exampleModalCenterTitle'
          aria-hidden='true'
        >
          <div className='col-xs-2'>
            <div className='modal-arrow' id='top'></div>
          </div>
          <div className='modal-dialog modal-dialog-centered' role='document'>
            <div className='modal-content main-action'>
              <div className='modal-header'>
                <h3 className='modal-title' id='exampleModalLongTitle'>
                  Respond to Mentor
                </h3>
                <button
                  type='button'
                  className='closeBtn mt-1'
                  data-dismiss='modal'
                  aria-label='Close'
                >
                  <span aria-hidden='true'>&times;</span>
                </button>
              </div>
              <div className='modal-body'>
                <form>
                  <div className='form-group'>
                    <div className='label action-label'>Prompt: </div>
                    <p className=''>{this.props.question}</p>
                  </div>

                  <div className='form-group'>
                    <div className='label action-label'>Respond: </div>
                    <textarea
                      value={this.state.response}
                      onChange={this.onResponseChange}
                      type='text'
                      className='form-control action-input feed-textbox'
                      placeholder=''
                    />
                  </div>
                </form>
              </div>
              <p className='errors-p p-3'>{this.state.errors.general}</p>
              <div className='modal-footer'>
                <button
                  type='button'
                  onClick={this.handleSubmit}
                  className='btn btn-submit'
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Res2Mentor;
