import React, { Component } from 'react';
import Page from '../../Components/NavBars/Page';
import './manager.css';
import { PieChart } from 'react-minimal-pie-chart';
import BarChart from '../../Components/NavBars/Charts/BarChart';
import SideBarChart from '../../Components/NavBars/Charts/SideBarChart';
import Rating from '../../Components/NavBars/Charts/Rating';

import AreaChart from '../../Components/NavBars/Charts/AreaChart';
import Momentum from '../../Components/Profile/Momentum';

import axios from 'axios';

class ManagerDash extends Component {
  constructor() {
    super();
    this.state = {
      currentIndex: 0,
      totalActions: '',
      passedDue: '',
      complete: '',
      uncomplete: '',
      momentum: '',
      rating: '',
      code: '',
      se: '',
      rTeam: [],
      pTeam: [],
      mTeam: [],
      pN: [],
      pC: [],
      pU: [],
      teamGraph: false,
      loading: true,
      errors: {},
    };
  }

  componentDidMount() {
    axios
      .get('/getDashActions')
      .then((res) => {
        const thisData = res.data;

        const myMonth = thisData.filter(
          (action) =>
            new Date(action.created).getTime() <= new Date().getTime() &&
            new Date(action.created).getTime() >=
              new Date().getTime() - 30 * 24 * 60 * 60 * 1000,
        );
        const myThree = thisData.filter(
          (action) =>
            new Date(action.created).getTime() <= new Date().getTime() &&
            new Date(action.created).getTime() >=
              new Date().getTime() - 90 * 24 * 60 * 60 * 1000,
        );
        const myYear = thisData.filter(
          (action) =>
            new Date(action.created).getTime() <= new Date().getTime() &&
            new Date(action.created).getTime() >=
              new Date().getTime() - 365 * 24 * 60 * 60 * 1000,
        );

        this.setState({
          totalActions: [
            thisData.length,
            myMonth.length,
            myThree.length,
            myYear.length,
          ],
        });

        const passedDue = thisData.filter(
          (action) =>
            new Date(action.dueDate).getTime() <= new Date().getTime() &&
            !action.completed,
        );

        this.setState({ passedDue: passedDue.length });

        const completedNums = [];
        const incompletedNums = [];
        let cNum = 0;
        let iNum = 0;

        for (let i = 0; i < thisData.length; i++) {
          if (thisData[i].completed === true) {
            cNum++;
          } else {
            iNum++;
          }
        }
        completedNums.push(cNum);
        incompletedNums.push(iNum);
        iNum = 0;
        cNum = 0;

        for (let i = 0; i < myMonth.length; i++) {
          if (myMonth[i].completed === true) {
            cNum++;
          } else {
            iNum++;
          }
        }
        completedNums.push(cNum);
        incompletedNums.push(iNum);
        iNum = 0;
        cNum = 0;

        for (let i = 0; i < myThree.length; i++) {
          if (myThree[i].completed === true) {
            cNum++;
          } else {
            iNum++;
          }
        }
        completedNums.push(cNum);
        incompletedNums.push(iNum);
        iNum = 0;
        cNum = 0;

        for (let i = 0; i < myYear.length; i++) {
          if (myYear[i].completed === true) {
            cNum++;
          } else {
            iNum++;
          }
        }
        completedNums.push(cNum);
        incompletedNums.push(iNum);
        iNum = 0;
        cNum = 0;

        this.setState({ complete: completedNums });
        this.setState({ uncomplete: incompletedNums });
      })
      .catch((error) => {
        console.error('ManagerDash - Error fetching dashboard actions:', error);

        this.setState({
          loading: false,
          error: 'An error occurred while fetching dashboard actions.',
        });
      });

    axios
      .get('/user')
      .then((res) => {
        const cred = res.data.credentials;
        this.setState({ code: cred.managerCode });
        this.setState({ se: cred.successEngagements });
        let credRating = cred.rating;
        if (!isNaN(credRating)) {
          credRating = [{ date: '', rated: '5' }];
        }
        const last90 = credRating.filter(
          (rate) =>
            new Date(rate.date).getTime() >=
            new Date().getTime() - 90 * 24 * 60 * 60 * 1000,
        );

        const prev90 = credRating.filter(
          (rate) =>
            new Date(rate.date).getTime() <=
              new Date().getTime() - 90 * 24 * 60 * 60 * 1000 &&
            new Date(rate.date).getTime() >=
              new Date().getTime() - 180 * 24 * 60 * 60 * 1000,
        );

        let last90sum = 0;
        let prev90sum = 0;

        last90.forEach((rating) => {
          last90sum += rating.rated;
        });

        prev90.forEach((rating) => {
          prev90sum += rating.rated;
        });

        let momentum = 0;
        if (prev90sum > 0) {
          momentum = last90sum / prev90sum;
        } else {
          momentum = last90sum;
        }
        this.setState({ momentum: momentum });
        const ratingMonth = credRating?.filter(
          (action) =>
            new Date(action.date).getTime() <= new Date().getTime() &&
            new Date(action.date).getTime() >=
              new Date().getTime() - 30 * 24 * 60 * 60 * 1000,
        );
        const ratingThree = credRating?.filter(
          (action) =>
            new Date(action.date).getTime() <= new Date().getTime() &&
            new Date(action.date).getTime() >=
              new Date().getTime() - 90 * 24 * 60 * 60 * 1000,
        );
        const ratingYear = credRating?.filter(
          (action) =>
            new Date(action.date).getTime() <= new Date().getTime() &&
            new Date(action.date).getTime() >=
              new Date().getTime() - 365 * 24 * 60 * 60 * 1000,
        );
        let myRatings = [];
        let totalNum = 0;
        for (let j = 0; j < credRating?.length; j++) {
          const rating = credRating[j].rated;
          totalNum = totalNum + rating;
        }
        const finalNum = totalNum / credRating?.length;

        if (finalNum <= 1) {
          myRatings.push('☆ ☆ ☆ ☆ ☆');
        } else if (finalNum <= 2) {
          myRatings.push('★ ☆ ☆ ☆ ☆');
        } else if (finalNum <= 3) {
          myRatings.push('★ ★ ☆ ☆ ☆');
        } else if (finalNum <= 4) {
          myRatings.push('★ ★ ★ ☆ ☆');
        } else if (finalNum < 5) {
          myRatings.push('★ ★ ★ ★ ☆');
        } else {
          myRatings.push('★ ★ ★ ★ ★');
        }

        totalNum = 0;

        for (let j = 0; j < ratingMonth?.length; j++) {
          const rating = ratingMonth[j].rated;
          totalNum = totalNum + rating;
        }
        const finalNum1 = totalNum / ratingMonth?.length;

        if (finalNum1 <= 1) {
          myRatings.push('☆ ☆ ☆ ☆ ☆');
        } else if (finalNum1 <= 2) {
          myRatings.push('★ ☆ ☆ ☆ ☆');
        } else if (finalNum1 <= 3) {
          myRatings.push('★ ★ ☆ ☆ ☆');
        } else if (finalNum1 <= 4) {
          myRatings.push('★ ★ ★ ☆ ☆');
        } else if (finalNum1 < 5) {
          myRatings.push('★ ★ ★ ★ ☆');
        } else {
          myRatings.push('★ ★ ★ ★ ★');
        }

        totalNum = 0;

        for (let j = 0; j < ratingThree?.length; j++) {
          const rating = ratingThree[j].rated;
          totalNum = totalNum + rating;
        }
        const finalNum3 = totalNum / ratingThree?.length;

        if (finalNum3 <= 1) {
          myRatings.push('☆ ☆ ☆ ☆ ☆');
        } else if (finalNum3 <= 2) {
          myRatings.push('★ ☆ ☆ ☆ ☆');
        } else if (finalNum3 <= 3) {
          myRatings.push('★ ★ ☆ ☆ ☆');
        } else if (finalNum3 <= 4) {
          myRatings.push('★ ★ ★ ☆ ☆');
        } else if (finalNum3 < 5) {
          myRatings.push('★ ★ ★ ★ ☆');
        } else {
          myRatings.push('★ ★ ★ ★ ★');
        }

        totalNum = 0;

        for (let j = 0; j < ratingYear?.length; j++) {
          const rating = ratingYear[j].rated;
          totalNum = totalNum + rating;
        }
        const finalNumY = totalNum / ratingYear?.length;

        if (finalNumY <= 1) {
          myRatings.push('☆ ☆ ☆ ☆ ☆');
        } else if (finalNumY <= 2) {
          myRatings.push('★ ☆ ☆ ☆ ☆');
        } else if (finalNumY <= 3) {
          myRatings.push('★ ★ ☆ ☆ ☆');
        } else if (finalNumY <= 4) {
          myRatings.push('★ ★ ★ ☆ ☆');
        } else if (finalNumY < 5) {
          myRatings.push('★ ★ ★ ★ ☆');
        } else {
          myRatings.push('★ ★ ★ ★ ★');
        }

        totalNum = 0;
        this.setState({ rating: myRatings });
      })
      .catch((error) => {
        console.error('ManagerDash - Error fetching user data:', error);

        this.setState({
          loading: false,
          error: 'An error occurred while fetching user data.',
        });
      });

    axios
      .get('/getManagedTeam')
      .then((res) => {
        const employeeStats = res.data;
        let momentum = [];
        let rating = [];
        let progress = [];
        let pCompleted = [];
        let pUncompleted = [];
        let pNames = [];
        let pIds = [];

        // Team Graph
        let mUnderMe = [];

        for (let i = 0; i < employeeStats.length; i++) {
          if (employeeStats[i].managerCode !== '') {
            mUnderMe.push({
              id: employeeStats[i].userId,
              name: `${employeeStats[i].firstName} ${employeeStats[i].lastName}`,
            });
          }

          let credRating = employeeStats[i].rating;
          if (!isNaN(credRating)) {
            credRating = [{ date: '', rated: '5' }];
          }
          const last90 = credRating.filter(
            (rate) =>
              new Date(rate.date).getTime() >=
              new Date().getTime() - 90 * 24 * 60 * 60 * 1000,
          );

          const prev90 = credRating.filter(
            (rate) =>
              new Date(rate.date).getTime() <=
                new Date().getTime() - 90 * 24 * 60 * 60 * 1000 &&
              new Date(rate.date).getTime() >=
                new Date().getTime() - 180 * 24 * 60 * 60 * 1000,
          );

          let last90sum = 0;
          let prev90sum = 0;

          last90.forEach((rating) => {
            last90sum += rating.rated;
          });

          prev90.forEach((rating) => {
            prev90sum += rating.rated;
          });

          let thismomentum = 0;
          if (prev90sum > 0) {
            thismomentum = last90sum / prev90sum;
          } else {
            thismomentum = last90sum;
          }

          const name =
            employeeStats[i].firstName + ' ' + employeeStats[i].lastName;

          const ratingMonth = credRating?.filter(
            (action) =>
              new Date(action.date).getTime() <= new Date().getTime() &&
              new Date(action.date).getTime() >=
                new Date().getTime() - 30 * 24 * 60 * 60 * 1000,
          );
          const ratingThree = credRating?.filter(
            (action) =>
              new Date(action.date).getTime() <= new Date().getTime() &&
              new Date(action.date).getTime() >=
                new Date().getTime() - 90 * 24 * 60 * 60 * 1000,
          );
          const ratingYear = credRating?.filter(
            (action) =>
              new Date(action.date).getTime() <= new Date().getTime() &&
              new Date(action.date).getTime() >=
                new Date().getTime() - 365 * 24 * 60 * 60 * 1000,
          );

          let theRating = [];

          let totalNum = 0;
          for (let j = 0; j < credRating?.length; j++) {
            const rate = credRating[j].rated;
            totalNum = totalNum + rate;
          }
          const finalNum = totalNum / credRating?.length;
          if (finalNum <= 1.0) {
            theRating.push('☆ ☆ ☆ ☆ ☆');
          } else if (finalNum <= 2.0) {
            theRating.push('★ ☆ ☆ ☆ ☆');
          } else if (finalNum <= 3.0) {
            theRating.push('★ ★ ☆ ☆ ☆');
          } else if (finalNum <= 4.0) {
            theRating.push('★ ★ ★ ☆ ☆');
          } else if (finalNum < 5.0) {
            theRating.push('★ ★ ★ ★ ☆');
          } else {
            theRating.push('★ ★ ★ ★ ★');
          }
          totalNum = 0;

          for (let j = 0; j < ratingMonth?.length; j++) {
            const rate = ratingMonth[j].rated;
            totalNum = totalNum + rate;
          }
          const finalNumOne = totalNum / ratingMonth?.length;
          if (finalNumOne <= 1.0) {
            theRating.push('☆ ☆ ☆ ☆ ☆');
          } else if (finalNumOne <= 2.0) {
            theRating.push('★ ☆ ☆ ☆ ☆');
          } else if (finalNumOne <= 3.0) {
            theRating.push('★ ★ ☆ ☆ ☆');
          } else if (finalNumOne <= 4.0) {
            theRating.push('★ ★ ★ ☆ ☆');
          } else if (finalNumOne < 5) {
            theRating.push('★ ★ ★ ★ ☆');
          } else {
            theRating.push('★ ★ ★ ★ ★');
          }
          totalNum = 0;

          for (let j = 0; j < ratingThree?.length; j++) {
            const rate = ratingThree[j].rated;
            totalNum = totalNum + rate;
          }
          const finalNumThree = totalNum / ratingThree?.length;
          if (finalNumThree <= 1.0) {
            theRating.push('☆ ☆ ☆ ☆ ☆');
          } else if (finalNumThree <= 2.0) {
            theRating.push('★ ☆ ☆ ☆ ☆');
          } else if (finalNumThree <= 3.0) {
            theRating.push('★ ★ ☆ ☆ ☆');
          } else if (finalNumThree <= 4.0) {
            theRating.push('★ ★ ★ ☆ ☆');
          } else if (finalNumThree < 5) {
            theRating.push('★ ★ ★ ★ ☆');
          } else {
            theRating.push('★ ★ ★ ★ ★');
          }
          totalNum = 0;

          for (let j = 0; j < ratingYear?.length; j++) {
            const rate = ratingYear[j].rated;
            totalNum = totalNum + rate;
          }
          const finalNumYear = totalNum / ratingYear?.length;
          if (finalNumYear <= 1.0) {
            theRating.push('☆ ☆ ☆ ☆ ☆');
          } else if (finalNumYear <= 2.0) {
            theRating.push('★ ☆ ☆ ☆ ☆');
          } else if (finalNumYear <= 3.0) {
            theRating.push('★ ★ ☆ ☆ ☆');
          } else if (finalNumYear <= 4.0) {
            theRating.push('★ ★ ★ ☆ ☆');
          } else if (finalNumYear < 5) {
            theRating.push('★ ★ ★ ★ ☆');
          } else {
            theRating.push('★ ★ ★ ★ ★');
          }
          totalNum = 0;

          momentum.push({
            name: name,
            momentum: thismomentum,
            id: employeeStats[i].userId,
            img: employeeStats[i].profileImg,
          });
          rating.push({
            name: name,
            rating: theRating,
            momentum: thismomentum,
            id: employeeStats[i].userId,
            img: employeeStats[i].profileImg,
          });
          pNames.push(name);
          pIds.push(employeeStats[i].userId);
        }

        // Team Graph
        // TODO: Check this
        if (mUnderMe.length !== 0) {
          this.setState({ teamGraph: true });
          axios
            .post('/getTeamStats', mUnderMe)
            .then((res) => {
              // TODO: ......
            })
            .catch((error) => {
              console.error('ManagerDash - Error posting team stats:', error);

              this.setState({
                loading: false,
                error: 'An error occurred while posting team stats.',
              });
            });
        }

        this.setState({ mTeam: momentum });
        this.setState({ rTeam: rating });
        this.setState({ pN: pNames });

        axios.get('/getTeamDash')
          .then((res) => {
          const teamData = res.data;

          const byName = [];

          for (let i = 0; i < pIds.length; i++) {
            const data = teamData.filter(
              (action) => action.creatorId === pIds[i],
            );
            byName.push(data);
          }

          for (let i = 0; i < byName.length; i++) {
            const thisId = pIds[i];
            const myMonth = byName[i].filter(
              (action) =>
                new Date(action.created).getTime() <= new Date().getTime() &&
                new Date(action.created).getTime() >=
                  new Date().getTime() - 30 * 24 * 60 * 60 * 1000,
            );
            const myThree = byName[i].filter(
              (action) =>
                new Date(action.created).getTime() <= new Date().getTime() &&
                new Date(action.created).getTime() >=
                  new Date().getTime() - 90 * 24 * 60 * 60 * 1000,
            );
            const myYear = byName[i].filter(
              (action) =>
                new Date(action.created).getTime() <= new Date().getTime() &&
                new Date(action.created).getTime() >=
                  new Date().getTime() - 365 * 24 * 60 * 60 * 1000,
            );

            const completedA = byName[i].filter(
              (action) => action.completed === true,
            );
            const completed1 = myMonth.filter(
              (action) => action.completed === true,
            );
            const completed3 = myThree.filter(
              (action) => action.completed === true,
            );
            const completedY = myYear.filter(
              (action) => action.completed === true,
            );

            const uncompletedA = byName[i].filter(
              (action) => action.completed === false,
            );
            const uncompleted1 = myMonth.filter(
              (action) => action.completed === false,
            );
            const uncompleted3 = myThree.filter(
              (action) => action.completed === false,
            );
            const uncompletedY = myYear.filter(
              (action) => action.completed === false,
            );

            const low = completedA.filter(
              (action) => action.priority === 'low',
            );
            const low1 = completed1.filter(
              (action) => action.priority === 'low',
            );
            const low3 = completed3.filter(
              (action) => action.priority === 'low',
            );
            const lowY = completedY.filter(
              (action) => action.priority === 'low',
            );

            const med = completedA.filter(
              (action) => action.priority === 'medium',
            );
            const med1 = completed1.filter(
              (action) => action.priority === 'medium',
            );
            const med3 = completed3.filter(
              (action) => action.priority === 'medium',
            );
            const medY = completedY.filter(
              (action) => action.priority === 'medium',
            );

            const high = completedA.filter(
              (action) => action.priority === 'high',
            );
            const high1 = completed1.filter(
              (action) => action.priority === 'high',
            );
            const high3 = completed3.filter(
              (action) => action.priority === 'high',
            );
            const highY = completedY.filter(
              (action) => action.priority === 'high',
            );

            const critical = completedA.filter(
              (action) => action.priority === 'critical',
            );
            const critical1 = completed1.filter(
              (action) => action.priority === 'critical',
            );
            const critical3 = completed3.filter(
              (action) => action.priority === 'critical',
            );
            const criticalY = completedY.filter(
              (action) => action.priority === 'critical',
            );

            const lowU = uncompletedA.filter(
              (action) => action.priority === 'low',
            );
            const lowU1 = uncompleted1.filter(
              (action) => action.priority === 'low',
            );
            const lowU3 = uncompleted3.filter(
              (action) => action.priority === 'low',
            );
            const lowUY = uncompletedY.filter(
              (action) => action.priority === 'low',
            );

            const medU = uncompletedA.filter(
              (action) => action.priority === 'medium',
            );
            const medU1 = uncompleted1.filter(
              (action) => action.priority === 'medium',
            );
            const medU3 = uncompleted3.filter(
              (action) => action.priority === 'medium',
            );
            const medUY = uncompletedY.filter(
              (action) => action.priority === 'medium',
            );

            const highU = uncompletedA.filter(
              (action) => action.priority === 'high',
            );
            const highU1 = uncompleted1.filter(
              (action) => action.priority === 'high',
            );
            const highU3 = uncompleted3.filter(
              (action) => action.priority === 'high',
            );
            const highUY = uncompletedY.filter(
              (action) => action.priority === 'high',
            );

            const criticalU = uncompletedA.filter(
              (action) => action.priority === 'critical',
            );
            const criticalU1 = uncompleted1.filter(
              (action) => action.priority === 'critical',
            );
            const criticalU3 = uncompleted3.filter(
              (action) => action.priority === 'critical',
            );
            const criticalUY = uncompletedY.filter(
              (action) => action.priority === 'critical',
            );

            const completedData = [
              completedA.length,
              completed1.length,
              completed3.length,
              completedY.length,
            ];
            const uncompletedData = [
              uncompletedA.length,
              uncompleted1.length,
              uncompleted3.length,
              uncompletedY.length,
            ];
            const priorityData = [
              [
                {
                  low: low.length,
                  medium: med.length,
                  high: high.length,
                  critical: critical.length,
                },
                {
                  low: lowU.length,
                  medium: medU.length,
                  high: highU.length,
                  critical: criticalU.length,
                },
              ],
              [
                {
                  low: low1.length,
                  medium: med1.length,
                  high: high1.length,
                  critical: critical1.length,
                },
                {
                  low: lowU1.length,
                  medium: medU1.length,
                  high: highU1.length,
                  critical: criticalU1.length,
                },
              ],
              [
                {
                  low: low3.length,
                  medium: med3.length,
                  high: high3.length,
                  critical: critical3.length,
                },
                {
                  low: lowU3.length,
                  medium: medU3.length,
                  high: highU3.length,
                  critical: criticalU3.length,
                },
              ],
              [
                {
                  low: lowY.length,
                  medium: medY.length,
                  high: highY.length,
                  critical: criticalY.length,
                },
                {
                  low: lowUY.length,
                  medium: medUY.length,
                  high: highUY.length,
                  critical: criticalUY.length,
                },
              ],
            ];
            pCompleted.push(completedData);
            pUncompleted.push(uncompletedData);
            progress.push({
              name: thisId,
              completed: completedData,
              uncompleted: uncompletedData,
              data: priorityData,
              id: thisId,
            });
          }

          this.setState({ pTeam: progress });
          this.setState({ pC: pCompleted });
          this.setState({ pU: pUncompleted });
          this.setState({ loading: false });
        });
      })
      .catch((error) => {
        console.error('ManagerDash - Error fetching managed team:', error);

        this.setState({
          loading: false,
          error: 'An error occurred while fetching managed team.',
        });
      });
  }

  setCurrentIndex = (indexNum) => {
    this.setState({ currentIndex: indexNum });
  };

  render() {
    if (this.state.loading) return null;
    return (
      <>
        <Page
          content={
            <div className='dash-container'>
              <div className='row'>
                <div className='col-12'>
                  <h1>Manager Dashboard</h1>
                  <h2 className='m-subheader'>My Stats</h2>

                  <div className='row time-btn-row'>
                    <div className='col-3 p-0'>
                      <button
                        className='btn btn-1m'
                        onClick={() => this.setCurrentIndex(1)}
                      >
                        1M
                      </button>
                    </div>
                    <div className='col-3 p-0'>
                      <button
                        className='btn btn-3m'
                        onClick={() => this.setCurrentIndex(2)}
                      >
                        3M
                      </button>
                    </div>
                    <div className='col-3 p-0'>
                      <button
                        className='btn btn-1y'
                        onClick={() => this.setCurrentIndex(3)}
                      >
                        1Y
                      </button>
                    </div>
                    <div className='col-3 p-0'>
                      <button
                        className='btn btn-A'
                        onClick={() => this.setCurrentIndex(0)}
                      >
                        ALL
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div className='dash-row row'>
                <div className='col-6 dash-col'>
                  <div className='actions-box text-center'>
                    <p className='action-text'>Total Actions</p>
                    <p className='action-num'>
                      {this.state.totalActions[this.state.currentIndex]}
                    </p>
                  </div>
                </div>
                <div className='col-6 dash-col-2'>
                  <div className='past-box text-center'>
                    <p className='action-text'>
                      Past Due <small className='all-time'>All TIME</small>
                    </p>

                    <p className='action-num'>{this.state.passedDue}</p>
                  </div>
                </div>
              </div>

              <div className='action-status-row row'>
                <div className='action-status'>
                  <h4 className='action-status-header'>My Action Status</h4>
                  <div className='pie-chart'>
                    <PieChart
                      animation
                      animationDuration={500}
                      animationEasing='ease-out'
                      center={[60, 60]}
                      data={[
                        {
                          color: '#1d4b24',
                          title: 'Un-Completed',
                          value: this.state.uncomplete[this.state.currentIndex],
                          label: `${
                            this.state.uncomplete[this.state.currentIndex]
                          }`,
                        },
                        {
                          color: '#388c44',
                          title: 'Completed',
                          value: this.state.complete[this.state.currentIndex],
                          label: `${
                            this.state.complete[this.state.currentIndex]
                          }`,
                        },
                      ]}
                      label={(data) => data.dataEntry.value}
                      labelPosition={65}
                      labelStyle={{
                        fontSize: '5px',
                        fontFamily: 'sans-serif',
                        fill: 'rgb(255 255 255)',
                      }}
                      segmentsShift={1}
                      lengthAngle={360}
                      lineWidth={60}
                      paddingAngle={0}
                      radius={50}
                      startAngle={0}
                      viewBoxSize={[120, 120]}
                    />
                  </div>
                  <div className='row chart-label-row text-center'>
                    <div className='col-6'>
                      <div className='completed-text'>█ Completed</div>
                    </div>
                    <div className='col-6'>
                      <div className=' not-completed-text'>█ Not Completed</div>
                    </div>
                  </div>
                </div>
              </div>

              <div className='action-status-row row'>
                <div className='action-small'>
                  <div className='row'>
                    <div className='col-4'>
                      <h5 className='action-status-header'>My Rating</h5>
                    </div>
                    <div className='col-8'>
                      <div className='rating-stars'>
                        {this.state.rating[this.state.currentIndex]}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className='action-status-row row'>
                <div className='action-small'>
                  <div className='row'>
                    <div className='col-4'>
                      <h5 className='action-status-header'>Momentum</h5>
                    </div>
                    <div className='col-8'>
                      <Momentum momentum={this.state.momentum} />
                    </div>
                  </div>
                </div>
              </div>

              <div className='action-status-row row'>
                <div className='action-small'>
                  <div className='row'>
                    <div className='col-10'>
                      <h5 className='action-status-header'>
                        Success Engagement Conversations
                      </h5>
                    </div>
                    <div className='col-2'>
                      <div className='se-num'>{this.state.se}</div>
                    </div>
                  </div>
                </div>
              </div>

              <div className={this.state.teamGraph ? 'team-graph' : 'hidden'}>
                <h2 className='team-stats-header'>My Team Stats</h2>
                <div className='action-status-row row'>
                  <div className='action-small'>
                    <h5>Actions Status by Team</h5>

                    <BarChart />

                    <h5 className='mt-3 pt-3'>Quarterly Momentum by Team</h5>

                    <AreaChart />
                  </div>
                </div>
              </div>

              <h2 className='team-stats-header pb-2'>Employee Stats</h2>

              <div className='row time-btn-row'>
                <div className='col-3 p-0'>
                  <button
                    className='btn btn-1m'
                    onClick={() => this.setCurrentIndex(1)}
                  >
                    1M
                  </button>
                </div>
                <div className='col-3 p-0'>
                  <button
                    className='btn btn-3m'
                    onClick={() => this.setCurrentIndex(2)}
                  >
                    3M
                  </button>
                </div>
                <div className='col-3 p-0'>
                  <button
                    className='btn btn-1y'
                    onClick={() => this.setCurrentIndex(3)}
                  >
                    1Y
                  </button>
                </div>
                <div className='col-3 p-0'>
                  <button
                    className='btn btn-A'
                    onClick={() => this.setCurrentIndex(0)}
                  >
                    ALL
                  </button>
                </div>
              </div>

              <div className='action-status-row row'>
                <div className='action-small'>
                  <h5>Action Stats</h5>

                  <SideBarChart
                    actions={this.state.pTeam}
                    names={this.state.pN}
                    completed={this.state.pC}
                    uncompleted={this.state.pU}
                    currentIndex={this.state.currentIndex}
                  />

                  <h5 className='mt-2'>Rating & Momentum</h5>

                  <Rating
                    data={this.state.rTeam}
                    currentIndex={this.state.currentIndex}
                  />
                </div>
              </div>
            </div>
          }
        />
      </>
    );
  }
}

export default ManagerDash;
