import React, { Component } from 'react';
import PayDue from './PayDue';
import ModalCloseBtn from '../../Constants/ModalCloseBtn';

class PaymentModal extends Component {
  constructor() {
    super();
    this.state = {
      employees: 0,
      price: 0,
      stripeId: '',
      amountDue: '',
      paymentDue: '',
      passedDue: false,
      loading: true,
      errors: {},
    };
  }

  componentDidMount() {
    this.setState({ employees: this.props.employees });
    this.setState({ price: this.props.price });
    this.setState({ stripeId: this.props.stripeId });
    this.setState({ amountDue: this.props.amountDue });
    this.setState({ paymentDue: this.props.paymentDue });
    if (this.props.paymentDue <= new Date()) {
      this.setState({ passedDue: true });
    }
    this.setState({ loading: false });
  }

  render() {
    if (this.state.loading) return null;
    return (
      <>
        <div
          className='modal '
          id={`pay${this.state.stripeId}`}
          tabIndex='-1'
          role='dialog'
          aria-labelledby='exampleModalCenterTitle'
          aria-hidden='true'
        >
          <div className='col-xs-2'>
            <div className='modal-arrow-sub' id='top'></div>
          </div>
          <div
            className='modal-dialog modal-dialog-centered payment-modal'
            role='document'
          >
            <div className='modal-content '>
              <div className='modal-header'>
                <h5 id='exampleModalLongTitle'>Pay Now</h5>

                <ModalCloseBtn />
              </div>
              <div className='modal-body'>
                <div className='row'>
                  <p className='package-month'>
                    <strong className='price-strong'>{`$${
                      this.state.price * this.state.employees
                    }`}</strong>{' '}
                    {`${this.state.employees} users x $${this.state.price} per month per
                    user*`}
                  </p>
                </div>
                <div className='row'>
                  {this.state.passedDue ? (
                    <p className='package-billed mt-1 pb-3 red'>
                      {`Overdue on ${this.state.paymentDue}`}
                    </p>
                  ) : (
                    <p className='package-billed mt-1 pb-3'>
                      {`Due on or before ${this.state.paymentDue}`}
                    </p>
                  )}
                </div>
                <PayDue
                  employees={this.state.employees}
                  price={this.state.price}
                  stripeId={this.state.stripeId}
                  userData={this.props.userData}
                />
              </div>
              <div className='modal-footer'></div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default PaymentModal;
