import React, { useEffect } from 'react';
import AreaChart from '../../Components/NavBars/Charts/AreaChart';
import BarChart from '../../Components/NavBars/Charts/BarChart';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { useState } from 'react';
import CompanyPage from '../../Components/NavBars/CompanyPage';
import PaymentModal from '../../Components/Payments/PaymentModal';

const AdminCompanyDash = (props) => {
  const [loading, setLoading] = useState(true);
  const [passedDue, setPassedDue] = useState(false);
  const [companyCode, setCompanyCode] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [companyId, setCompanyId] = useState('');
  const [stripeId, setStripeId] = useState('');
  const [nextPayment, setNextPayment] = useState('');
  const [price, setPrice] = useState(8);
  const [amount, setAmount] = useState(0);
  const [amountDue, setAmountDue] = useState(0);
  const [PayNowModal, setPayNowModal] = useState();

  const finishLoading = ({ amountUsers, stripeId, price, data }) => {
    console.log(amountUsers);
    const upcoming = data.nextPayment;
    const nextPayment = new Date(upcoming * 1);
    setPayNowModal(
      <PaymentModal
        employees={amountUsers}
        price={price}
        stripeId={stripeId}
        userData={data}
        paymentDue={nextPayment}
      />,
    );
    setLoading(false);
  };

  const getAmountOfUsers = ({ price, stripeId, data }) => {
    axios
      .get('/getEmployeeAmount')
      .then((res) => {
        const amountUsers = res.data.credentials.employees;
        setAmount(amountUsers);
        setAmountDue(amountUsers * parseInt(price));
        finishLoading({ amountUsers, stripeId, price, data });
      })
      .error((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const getCompany = () => {
    axios
      .get('/company')
      .then((res) => {
        const data = res.data.credentials;
        const upcoming = data.nextPayment;
        const price = data.price;
        const d = new Date(upcoming * 1);
        if (d <= new Date()) {
          setPassedDue(true);
        }
        setNextPayment(d);
        setPrice(price);
        setCompanyCode(data.companyCode);
        setCompanyId(data.companyId);
        setStripeId(data.stripeId);
        setCompanyName(data.companyName);
        getAmountOfUsers({ price, stripeId: data.stripeId, data });
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getCompany();
  }, []);

  if (loading) return null;

  return (
    <>
      <CompanyPage
        companyName={companyName}
        content={
          <>
            <div className='company-dash'>
              <div className='dash-inner'>
                <div className=''>
                  <h1 className='team-stats-header'>
                    My Companies Stats (upper tier)
                  </h1>
                  <div className='action-status-row row'>
                    <div className='action-small'>
                      <h1 className='ts-header'>Actions Status by Team</h1>

                      <BarChart />

                      <h1 className='ts-header mt-3 pt-3'>
                        Quarterly Momentum by Team
                      </h1>

                      <AreaChart />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        }
      />
    </>
  );
};

export default AdminCompanyDash;
