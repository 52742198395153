import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, useHistory } from 'react-router-dom';
import { analytics } from '../../firebaseConfig.js';
import { useDispatch } from 'react-redux';
import { logoutUser } from '../../Redux/actions/userActions';
import { withRouter } from 'react-router-dom';

const Footer = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const takeAnalytics = (page) => {
    let type = props.isManager ? 'm' : 'e';

    if (page !== 'no') {
      analytics.logEvent(`${type}_${page}_visited`);
    }
  };

  const handleLogout = () => {
    analytics.logEvent(`user_logged_out`);
    dispatch(logoutUser(history));
  };

  const footerMenuItems = [
    { to: '/dashboard', icon: 'chart-line', text: 'Dash', page: 'dash' },
    { to: '/notif', icon: 'bell', text: 'Notif', page: 'notif' },
    { to: '/actions', icon: 'tasks', text: 'Actions', page: 'actions' },
    { to: '/check-in', icon: 'badge-check', text: 'Check-In', page: 'check' },
    { to: '/feedback', icon: 'sync', text: 'Insights', page: 'insights' },
    { to: '/mentor', icon: 'user-chart', text: 'Mentor', page: 'mentor' },
    { to: '/directory', icon: 'users', text: 'Directory', page: 'directory' },
  ];

  const renderFooterMenuItems = () => {
    return (
      <>
        {footerMenuItems.map((item, index) => (
          <div key={index} className='mobile-block text-center'>
            <Link
              to={item.to}
              className='no-underline'
              onClick={() => takeAnalytics(item.page)}
            >
              <FontAwesomeIcon
                icon={['fal', item.icon]}
                className='icon-mobile'
              />
            </Link>
          </div>
        ))}
        {/* Logout Button */}

        <div className='mobile-block text-center'>
          <button type='button' onClick={(e) => handleLogout()}>
            <FontAwesomeIcon
              className='icon-mobile'
              icon={['fal', 'sign-out']}
            />
          </button>
        </div>
      </>
    );
  };

  return (
    <div>
      <footer className='footer sticky-bottom nav-foot'>
        <div className='row nav-block-mobile'>{renderFooterMenuItems()}</div>
      </footer>
    </div>
  );
};

export default withRouter(Footer);
