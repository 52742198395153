import axios from 'axios';
import React, { Component } from 'react';
import Page from '../../Components/NavBars/Page';
import { analytics } from '../../firebaseConfig';

class CompanyFocus extends Component {
  constructor() {
    super();
    this.state = {
      cv: '',
      kvo: '',
      cvI: false,
      kcoI: false,
      loading: true,
      errors: {},
    };
  }

  componentDidMount() {
    analytics.logEvent(`cf_visited`);
    axios.get('/getCompanyFocus').then((res) => {
      //TODO: Fetching unnecessary data here. Can just fetch at res.data.focus[]
      const data = res.data.focus[0];
      this.setState({ cv: data.cv });
      this.setState({ kvo: data.kvo });
      this.setState({ loading: false });
    });
  }

  render() {
    if (this.state.loading) return null;
    return (
      <>
        <Page
          content={
            <div className='company-focus-page'>
              <div className='col-10'>
                <h1>Company Focus</h1>
                <p className='pe-5 pre-line'>
                  {this.state.cv === '' ? (
                    <>In progress. Please check back later.</>
                  ) : (
                    this.state.cv
                  )}
                </p>
                <br />
                <h2>Key Company Objectives</h2>
                <p className='pe-5 pre-line'>
                  {this.state.cv === '' ? (
                    <>In progress. Please check back later.</>
                  ) : (
                    this.state.kvo
                  )}
                </p>
              </div>
            </div>
          }
        />
      </>
    );
  }
}

export default CompanyFocus;
