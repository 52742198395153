import React from 'react';
import ViewShout from '../Actions/ViewShout';

function ShoutOut(props) {
  return (
    <div className='notif-outside row'>
      <div className='col-1 mb-3'>
        <img className=' engagement-icon' src={props.img} alt={props.name} />
      </div>
      <div className='col-7'>
        <p className='notif-text'>
          <strong>{props.name}</strong> {props.text} <strong>{props.to}</strong>
        </p>
      </div>
      <div className='col-4'>
        <button
          className=' btn-shout'
          type='button'
          data-toggle='modal'
          data-target={`#viewShoutOut${props.id}`}
        >
          view
        </button>

        <ViewShout
          name={props.name}
          id={props.id}
          to={props.to}
          message={props.message}
          img={props.img}
          created={props.created}
        />
      </div>
    </div>
  );
}

export default ShoutOut;
