import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { analytics } from '../../firebaseConfig.js';

const CompanyFooter = (props) => {
  const takeAnalytics = (page) => {
    if (page !== 'no') {
      analytics.logEvent(`${page}_visited`);
      console.log(`${page}_visited`);
    }
  };

  return (
    <div>
      <footer className='footer sticky-bottom nav-foot'>
        <div className='row nav-block-mobile'>
          <div className='mobile-block mobile-block-2 text-center'>
            <Link
              to='/dashboard'
              className='no-underline'
              onClick={() => takeAnalytics('dash')}
            >
              <FontAwesomeIcon
                icon={['fal', 'chart-line']}
                className='icon-mobile'
              />
              <p className='mobile-text'>Dash</p>
            </Link>
          </div>

          <div className='mobile-block mobile-block-2 text-center'>
            <Link
              to='/mysurveys'
              className='no-underline'
              onClick={() => takeAnalytics('surveys')}
            >
              <FontAwesomeIcon
                icon={['fal', 'square-poll-horizontal']}
                className='icon-mobile'
              />
              <p className='mobile-text'>Survey</p>
            </Link>
          </div>

          <div className='mobile-block mobile-block-2 text-center'>
            <Link to='/company/directory' className='no-underline'>
              <FontAwesomeIcon
                icon={['fal', 'users']}
                className='icon-mobile'
              />
              <p className='mobile-text'>Directory</p>
            </Link>
          </div>

          <div className='mobile-block mobile-block-2 text-center'>
            <Link to='/company/focus' className='no-underline'>
              <FontAwesomeIcon
                icon={['fal', 'bullseye']}
                className='icon-mobile'
              />
              <p className='mobile-text'>Focus</p>
            </Link>
          </div>

          <div className='mobile-block mobile-block-2 text-center'>
            <Link to='/company/plan' className='no-underline'>
              <FontAwesomeIcon
                icon={['fal', 'wallet']}
                className='icon-mobile'
              />
              <p className='mobile-text'>Plan</p>
            </Link>
          </div>

          <div className='mobile-block mobile-block-2 text-center'>
            <Link to='/company/settings' className='no-underline'>
              <FontAwesomeIcon icon={['fal', 'gear']} className='icon-mobile' />
              <p className='mobile-text'>Settings</p>
            </Link>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default CompanyFooter;
