import React, { Component } from 'react';
import './signup.css';
import { Link } from 'react-router-dom';
import axios from 'axios';
import $ from 'jquery';
import Policy from '../../Components/Actions/Policy';

class Signup extends Component {
  constructor() {
    super();
    this.state = {
      firstName: '',
      lastName: '',
      phone: '',
      email: '',
      companyCode: '',
      password: '',
      confirmPassword: '',
      checkBox: false,
      loading: false,
      errors: {},
      checkError: '',
    };
  }
  handleSubmit = (event) => {
    event.preventDefault();
    this.setState({
      loading: true,
    });
    const signUpData = {
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      phone: this.state.phone,
      email: this.state.email,
      companyCode: this.state.companyCode,
      password: this.state.password,
      confirmPassword: this.state.confirmPassword,
    };
    console.log(signUpData);
    if (this.state.checkBox === false) {
      this.setState({
        checkError: 'Agree to our Terms and Privacy Policy to continue.',
      });
    } else {
      axios
        .post('/userSignup', signUpData)
        .then((res) => {
          localStorage.setItem('IdToken', `Bearer ${res.data.idToken}`);
          this.setState({
            loading: false,
          });
          this.props.history.push('/loading');
          window.location.reload();
        })
        .catch((err) => {
          console.error(
            'Signup - Error posting userSignup:',
            err.response.data,
          );
          this.setState({
            errors: err.response.data,
            loading: false,
          });
        });
    }
  };
  onFirstNameChange = (event) => {
    this.setState({
      firstName: event.target.value,
    });
  };
  onLastNameChange = (event) => {
    this.setState({
      lastName: event.target.value,
    });
  };
  onPhoneChange = (event) => {
    $('.number').keyup(function () {
      if (this.value.length === this.maxLength) {
        $(this).next('.number').focus();
      }
    });
    const val1 = $('#number1').val();
    const val2 = $('#number2').val();
    const val3 = $('#number3').val();
    this.setState({
      phone: val1 + '-' + val2 + '-' + val3,
    });
  };
  onEmailChange = (event) => {
    this.setState({
      email: event.target.value,
    });
  };
  onCompanyCodeChange = (event) => {
    this.setState({
      companyCode: event.target.value,
    });
  };
  onPasswordChange = (event) => {
    this.setState({
      password: event.target.value,
    });
  };
  onConfirmPasswordChange = (event) => {
    this.setState({
      confirmPassword: event.target.value,
    });
  };
  checkBoxChange = (event) => {
    // if (event.target.checked === true) {
    //     console.log()
    // }
    this.setState({
      checkBox: event.target.checked,
    });
  };
  render() {
    return (
      <div className='login-page'>
        <div className='row justify-content-center'>
          <img
            src='../Images/successrocketlogo.png'
            className='signup-logo'
            alt='Landing'
          />
        </div>
        <div className='row justify-content-center'>
          <div className='form-row'>
            <form>
              <h1 className='login-header'>Sign Up</h1>
              <div className='form-group'>
                <label htmlFor='fName' className='sign-label'>
                  First Name
                </label>
                <input
                  value={this.state.firstName}
                  onChange={this.onFirstNameChange}
                  type='text'
                  className='form-control sign-input'
                  id='fName'
                  aria-describedby='First Name'
                  placeholder=''
                />
              </div>
              <p className='errors-p2'>{this.state.errors.firstName}</p>
              <div className='form-group'>
                <label htmlFor='lName' className='sign-label'>
                  Last Name
                </label>
                <input
                  value={this.state.lastName}
                  onChange={this.onLastNameChange}
                  type='text'
                  className='form-control sign-input'
                  id='lName'
                  aria-describedby='Last Name'
                  placeholder=''
                />
              </div>
              <p className='errors-p2'>{this.state.errors.lastName}</p>
              <div className='form-group'>
                <label htmlFor='phone' className='sign-label'>
                  Phone Number
                </label>
                {/* <label>Phone Number</label> */}
                <div className='row'></div>
                <input
                  type='text'
                  id='number1'
                  className='number sign-input'
                  maxLength='3'
                  size='3'
                  onChange={this.onPhoneChange}
                />{' '}
                -{' '}
                <input
                  type='text'
                  id='number2'
                  className='number sign-input'
                  maxLength='3'
                  size='3'
                  onChange={this.onPhoneChange}
                />{' '}
                -{' '}
                <input
                  type='text'
                  id='number3'
                  className='number sign-input'
                  maxLength='4'
                  size='5'
                  onChange={this.onPhoneChange}
                />
                {/* <input value={this.state.phone} onChange={this.onPhoneChange}type="number" className="form-control sign-input" id="phone" aria-describedby="Phone Number" placeholder=""/> */}
              </div>
              <p className='errors-p2'>{this.state.errors.phone}</p>
              <div className='form-group'>
                <label htmlFor='email' className='sign-label'>
                  Email
                </label>
                <input
                  value={this.state.email}
                  onChange={this.onEmailChange}
                  type='email'
                  className='form-control sign-input'
                  id='exampleInputEmail1'
                  aria-describedby='emailHelp'
                  placeholder=''
                />
              </div>
              <p className='errors-p2'>{this.state.errors.email}</p>
              <div className='form-group'>
                <label htmlFor='code' className='sign-label'>
                  Company Code
                </label>
                <input
                  value={this.state.companyCode}
                  onChange={this.onCompanyCodeChange}
                  type='text'
                  className='form-control sign-input'
                  id='code'
                  placeholder=''
                />
              </div>
              <p className='errors-p2'>{this.state.errors.companyCode}</p>
              <div className='form-group'>
                <label htmlFor='password' className='sign-label'>
                  Password
                </label>
                <input
                  value={this.state.password}
                  onChange={this.onPasswordChange}
                  type='password'
                  className='form-control sign-input'
                  id='exampleInputPassword1'
                  placeholder=''
                />
              </div>
              <p className='errors-p2'>{this.state.errors.password}</p>
              <div className='form-group'>
                <label htmlFor='Cpassword' className='sign-label'>
                  Confirm Password
                </label>
                <input
                  value={this.state.confirmPassword}
                  onChange={this.onConfirmPasswordChange}
                  type='password'
                  className='form-control sign-input'
                  id='Cpassword'
                  placeholder=''
                />
              </div>
              <p className='errors-p2'>{this.state.errors.confirmPassword}</p>

              <p className='pt-3 disclaimer-text'>
                View our{' '}
                <button
                  className='policyterms'
                  type='button'
                  data-toggle='modal'
                  data-target='#policyandterms'
                >
                  Terms of use
                </button>{' '}
                and{' '}
                <button
                  className='policyterms'
                  type='button'
                  data-toggle='modal'
                  data-target='#policyandterms'
                >
                  Privacy Policy
                </button>
              </p>
              <Policy />

              <div className='form-check'>
                <input
                  type='checkbox'
                  className='form-check-input form-check-success'
                  id='exampleCheck1'
                  value={this.state.checkBox}
                  onClick={this.checkBoxChange}
                />
                <label
                  className='form-check-label form-label-success'
                  htmlFor='exampleCheck1'
                >
                  I agree to the{' '}
                  <strong>Terms of Services and Privacy Policy.</strong>
                </label>
              </div>
              <p className='errors-p2'>{this.state.checkError}</p>
              <div className='row justify-content-center'>
                <div className='signin-btn-row'>
                  <button onClick={this.handleSubmit} className='sign-btn'>
                    Sign In
                  </button>
                </div>
              </div>

              <div className='row'>
                <p className='no-company-text'>
                  Don't have a company code?{' '}
                  <Link to='/createCompany' className=''>
                    Click here
                  </Link>{' '}
                  for more information or create a new company account below.
                </p>
              </div>
              <div className='row justify-content-center'>
                <button className='forgot-btn bottom'>
                  <Link to='/createCompany' className='signup-link'>
                    Create New Company Account
                  </Link>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

export default Signup;
