import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';

class ImManager extends Component {
  constructor() {
    super();
    this.state = {
      alert: '',
      code: '',
      image: '',
      loading: true,
      errors: {},
    };
  }
  handleSubmit = (event) => {
    event.preventDefault();
    this.setState({
      loading: true,
    });
    const chooseManager = {
      manager: this.state.manager,
    };
    console.log(chooseManager);
    axios
      .post('/chooseManager', chooseManager)
      .then((res) => {
        this.setState({
          loading: false,
        });
        this.props.history.push('/firstSuccess');
      })
      .catch((err) => {
        console.error('ImManager - Error choosing manager:', err);
        this.setState({
          errors: err.response.data,
          loading: false,
        });
      });
  };

  componentDidMount() {
    setTimeout(() => {
      axios.get('/user').then((res) => {
        const cred = res.data.credentials;
        this.setState({ image: cred.profileImg });
      });

      this.setState({ code: this.props.location.state.code.code });
      this.setState({ loading: false });
    }, 1000);
  }

  render() {
    if (this.state.loading) return null;
    return (
      <>
        <div className='upload-photo-page justify-content-center row'>
          <div className='upload-photo-col'>
            <div className='row'>
              <img
                src={this.state.image}
                className='profile-img'
                alt='Example'
              />
            </div>
            <div className='row'>
              <p className='choose-manager-text'>
                Before you get started, send this code to your employees so they
                can start tracking their Success.
              </p>
            </div>
            <div className='row justify-content-center '>
              <button className='sign-btn next-step-btn'>
                <Link to='/firstSuccess' className='signin-link'>
                  Next Step
                </Link>
              </button>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default ImManager;
