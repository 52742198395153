import React, { useEffect } from 'react';
import Spinner from '../../Components/Loading/Spinner';

const LoadingPage = (props) => {
  useEffect(() => {
    props.history.push('/login');
    console.log('Redirecting...');
  }, [props.history]);

  return <Spinner />;
};

export default LoadingPage;
