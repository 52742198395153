import React from 'react';
import Momentum from './Momentum';

const ViewProfInfo = (props) => {
  let manager;
  if (props.manager === '') {
    manager = 'None';
  } else {
    manager = props.manager;
  }
  return (
    <>
      <div className='col-md-5 prof-info-card'>
        <div className='text-center'>
          <img
            src={props.profileImg}
            className='my-profile-img'
            alt='Example'
          />
        </div>
        <div className='prof-info-box'>
          <h1 className='prof-info-header'>Profile Information</h1>

          <div className='row prof-info-row'>
            <div className='col-4'>
              <p className='prof-info-title'>Name</p>
            </div>
            <div className='col-8'>
              <p className='prof-info-text'>{props.name}</p>
            </div>
            <hr className='prof-info-hr'></hr>
          </div>

          <div className='row prof-info-row'>
            <div className='col-4'>
              <p className='prof-info-title'>Phone Number</p>
            </div>
            <div className='col-8'>
              <p className='prof-info-text'>{props.phone}</p>
            </div>
            <hr className='prof-info-hr'></hr>
          </div>

          <div className='row prof-info-row'>
            <div className='col-4'>
              <p className='prof-info-title'>Email</p>
            </div>
            <div className='col-8'>
              <p className='prof-info-text'>{props.email}</p>
            </div>
            <hr className='prof-info-hr'></hr>
          </div>

          <div className='row prof-info-row'>
            <div className='col-4'>
              <p className='prof-info-title'>Manager</p>
            </div>
            <div className='col-8'>
              <p className='prof-info-text'>{manager}</p>
            </div>
            <hr className='prof-info-hr'></hr>
          </div>

          <div className='row prof-info-row'>
            <div className='col-4'>
              <p className='prof-info-title'>Momentum</p>
            </div>
            <div className='col-8'>
              <p className='prof-info-text'>
                <Momentum momentum={props.momentum} />
              </p>
            </div>
            <hr className='prof-info-hr'></hr>
          </div>

          <div className='row prof-info-row'>
            <div className='col-4'>
              <p className='prof-info-title'>Rating</p>
            </div>
            <div className='col-8'>
              <p className='prof-info-text'>{props.rating}</p>
            </div>
            <hr className='prof-info-hr'></hr>
          </div>

          <div className='row prof-info-row'>
            <div className='row'>
              <div className='col-11'>
                <p className='prof-info-title'>
                  Success Engagement Conversations
                </p>
              </div>
              <div className='col-1'>
                <div className='se-num'>{props.se}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewProfInfo;
