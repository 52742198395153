import React from 'react';
import './nav.css';

const CompanyHeader = (props) => {
  return (
    <nav className='header-outside sticky-top'>
      <div className='row header'>
        <div className='col-11 header-text-row'>
          <div className='row'>
            <h1 className='header-text'>{props.companyName}</h1>
          </div>
          <div className='row'></div>
        </div>
        <div className='col-1 text-center text-light'>
          <img className='prof-img' src='../favicon.ico' alt='Profile' />
        </div>
      </div>
      <div className='outerSlant'></div>
      <div className='innerSlant'></div>
    </nav>
  );
};

export default CompanyHeader;
