import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/pro-solid-svg-icons';

const priorityLevels = [
  {
    icon: faCircle,
    color: 'red',
    label: 'Critical',
  },
  {
    icon: faCircle,
    color: 'orange',
    label: 'High',
  },
  {
    icon: faCircle,
    color: 'yellow',
    label: 'Medium',
  },
  {
    icon: faCircle,
    color: 'green',
    label: 'Low',
  },
];

const PriorityKey = () => {
  return (
    <footer className='footer priority-key sticky-bottom'>
      <div className='row justify-content-center'>
        <div className='col-2 p-0 priority-header'>Priority Key</div>
        {priorityLevels.map((level, index) => (
          <div className='col-2 p-0 text-center priority-text' key={index}>
            <FontAwesomeIcon
              className={`priority-icon-${level.color}`}
              icon={level.icon}
            />{' '}
            {level.label}
          </div>
        ))}
      </div>
    </footer>
  );
};

export default PriorityKey;
