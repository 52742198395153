import React from 'react';
import { analytics } from '../../firebaseConfig.js';
import { useHistory } from 'react-router-dom';

function SurveyNotif(props) {
  const history = useHistory();

  const viewNotif = () => {
    const surveyId = props.surveyId;
    analytics.logEvent(`nps_notif_viewed`);
    history.push(`/survey/${surveyId}`);
    // $(`#viewShoutOut${props.id}btn`).addClass('btn-notif-grey');
    // axios.post(`/notifications/${props.id}`).then((res) => {
    //   console.log(res.data)
    // })
  };

  return (
    <div className='notif-outside row'>
      <div className='col-1 mb-3 icon-col'>
        <img
          className=' notif-icon'
          src='../Images/red-overdue-notif-icon.png'
          alt='survey icon'
        />
      </div>
      <div className='col-7'>
        <p className='notif-text'>
          Your company has launched a NPS Survey, and requests all employees to
          respond.
        </p>
      </div>
      <div className='col-4 notif-btn-row'>
        <button
          className={props.read ? 'btn-notif btn-notif-grey' : ' btn-notif'}
          type='button'
          id={`viewSurvey${props.id}btn`}
          onClick={() => viewNotif()}
        >
          respond
        </button>
      </div>
    </div>
  );
}

export default SurveyNotif;
