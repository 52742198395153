import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component } from 'react';
import { faCircle } from '@fortawesome/pro-solid-svg-icons';
import axios from 'axios';
import $ from 'jquery';
import ModalCloseBtn from '../../Constants/ModalCloseBtn';

class RateSub extends Component {
  constructor() {
    super();
    this.state = {
      userId: '',
      id: '',
      type: '',
      sub: '',
      main: '',
      name: '',
      action: '',
      description: '',
      priority: '',
      dDate: '',
      status: '',
      rating: '',
      comment: '',
      loading: true,
      errors: {},
    };
  }

  componentDidMount() {
    this.setState({ userId: this.props.userId });
    this.setState({ id: this.props.id });
    this.setState({ type: this.props.type });
    this.setState({ sub: this.props.sub });
    this.setState({ main: this.props.main });
    this.setState({ name: this.props.name });
    this.setState({ action: this.props.title });
    this.setState({ dDate: this.props.dDate });
    this.setState({ description: this.props.description });
    this.setState({ priority: this.props.priority });
    this.setState({ status: this.props.status });

    this.setState({ loading: false });
  }

  handleSubmit = (event) => {
    event.preventDefault();

    this.setState({
      loading: true,
    });

    const rateAction = {
      rating: parseInt(this.state.rating),
      comment: this.state.comment,
    };

    console.log(rateAction);

    if (rateAction.rating === '' || rateAction.comment === '') {
      this.setState({
        errors: { general: 'Error: Please check information and try again.' },
        loading: false,
      });
    } else {
      axios
        .post(`/rateAction/${this.props.id}`, rateAction)
        .then((res) => {
          this.setState({
            loading: false,
          });

          window.location.reload();
        })
        .catch((err) => {
          console.log(err.response.data);
          this.setState({
            errors: {
              general: 'Error: Please check information and try again.',
            },
            loading: false,
          });
          $(`#rate${this.props.id}btn`).trigger('click');
        });
    }
  };

  onRatingChange = (event) => {
    this.setState({
      rating: event.target.value,
    });
  };
  onCommentChange = (event) => {
    this.setState({
      comment: event.target.value,
    });
  };

  render() {
    if (this.state.loading) return null;
    return (
      <>
        <div
          className='modal '
          id={`edit${this.props.id}`}
          tabIndex='-1'
          role='dialog'
          aria-labelledby='exampleModalCenterTitle'
          aria-hidden='true'
        >
          <div className='col-xs-2'>
            <div className='modal-arrow-sub' id='top'></div>
          </div>
          <div className='modal-dialog modal-dialog-centered' role='document'>
            <div className='modal-content sub-action'>
              <div className='modal-header'>
                <h5 id='exampleModalLongTitle'>
                  {this.props.status ? 'Rate Action' : 'View Incomplete Action'}
                </h5>
                <ModalCloseBtn />
              </div>
              <div className='modal-body'>
                <form>
                  <div
                    className={
                      this.props.sub
                        ? 'form-group disabled-group'
                        : 'form-group hidden-group'
                    }
                  >
                    <label
                      htmlFor='exampleFormControlInput5'
                      className='action-label'
                    >
                      Main Action
                    </label>
                    <div className=''>
                      <div className=' action-input-col'>
                        <select className='form-select action-input' disabled>
                          <option>{this.props.main}</option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <div
                    className={
                      this.props.sub
                        ? 'form-group hidden-group'
                        : 'form-group disabled-group'
                    }
                  >
                    <label
                      htmlFor='exampleFormControlInput5'
                      className='action-label'
                    >
                      Action
                    </label>
                    <div className=''>
                      <div className=' action-input-col'>
                        <select className='form-select action-input' disabled>
                          <option>{this.props.main}</option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className='form-group disabled-group'>
                    <label
                      htmlFor='exampleFormControlInput1'
                      className='action-label'
                    >
                      Employee Name
                    </label>
                    <div className=''>
                      <div className=' action-input-col'>
                        <div className='form-control action-input'>
                          <a href={`/profile/${this.props.sender}`}>
                            <p className='p-0 m-0'>{this.props.name}</p>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className={
                      this.props.sub
                        ? 'form-group disabled-group'
                        : 'form-group hidden-group'
                    }
                  >
                    <label
                      htmlFor='exampleFormControlInput3'
                      className='action-label'
                    >
                      Action
                    </label>
                    <input
                      type='text'
                      className='form-control action-input'
                      value={this.props.title}
                      disabled
                    ></input>
                  </div>

                  <div
                    className={
                      this.props.sub
                        ? 'form-group hidden-group'
                        : 'form-group disabled-group'
                    }
                  >
                    <label
                      htmlFor='exampleFormControlInput4'
                      className='action-label'
                    >
                      Description
                    </label>
                    <input
                      type='text'
                      className='form-control action-input'
                      placeholder=''
                      value={this.props.description}
                      disabled
                    />
                  </div>

                  <div
                    className={
                      this.props.sub
                        ? 'form-group hidden-group'
                        : 'form-group disabled-group'
                    }
                  >
                    <label
                      htmlFor='exampleFormControlInput5'
                      className='action-label'
                    >
                      Priority
                    </label>
                    <div className='row'>
                      <div className='col-11 action-input-col'>
                        <p
                          type='text'
                          className='form-control action-input'
                          disabled
                        >
                          <FontAwesomeIcon
                            className={`priority-icon-${this.props.priority}`}
                            icon={faCircle}
                          />{' '}
                          {this.props.priority}
                        </p>
                      </div>
                      <div className='col-1 action-icon-col'>
                        <span className='action-icon'>
                          <FontAwesomeIcon
                            className='action-i form-control-feedback'
                            icon={['fal', 'chevron-down']}
                          />
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className='form-group disabled-group'>
                    <label
                      htmlFor='exampleFormControlInput6'
                      className='action-label'
                    >
                      Due Date
                    </label>
                    <p className='form-control action-input'>
                      {this.props.dDate}
                    </p>
                  </div>
                  <div className='form-group disabled-group'>
                    <label
                      htmlFor='exampleFormControlInput7'
                      className='action-label'
                    >
                      Status
                    </label>
                    <select className='form-select action-select ' disabled>
                      <option>
                        {this.props.status ? 'Complete' : 'Not Complete'}
                      </option>
                    </select>
                  </div>
                  <div
                    className={
                      this.props.status
                        ? this.props.sub
                          ? 'form-group hidden-group'
                          : 'form-group'
                        : 'form-group hidden-group'
                    }
                  >
                    <label
                      htmlFor='exampleFormControlInput7'
                      className='action-label'
                    >
                      Comment
                    </label>
                    <input
                      onChange={this.onCommentChange}
                      value={this.state.comment}
                      type='text'
                      className='form-control action-input'
                      placeholder=''
                    />
                  </div>
                </form>
              </div>
              <p className='errors-p p-3'>{this.state.errors.general}</p>
              <div
                className={
                  this.props.status
                    ? this.props.sub
                      ? 'modal-footer hidden'
                      : 'modal-footer'
                    : 'modal-footer hidden'
                }
              >
                <button
                  type='button'
                  onClick={this.handleSubmit}
                  className='btn btn-submit'
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default RateSub;
