import React from 'react';
import { Tooltip, IconButton } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { analytics } from '../../firebaseConfig.js';
import { logoutUser } from '../../Redux/actions/userActions';
import { withRouter } from 'react-router-dom';

const CompanySideNav = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const takeAnalytics = (page) => {
    if (page !== 'no') {
      analytics.logEvent(`${page}_visited`);
    }
  };

  const menuItems = [
    { to: '/company', icon: 'chart-line', text: 'Dashboard', page: 'dash' },
    {
      to: '/mysurveys',
      icon: 'square-poll-horizontal',
      text: 'Survey',
      page: 'survey',
    },
    {
      to: '/company/directory',
      icon: 'users',
      text: 'Directory',
      page: 'directory',
    },
    {
      to: '/company/focus',
      icon: 'bullseye',
      text: 'Focus',
      page: 'focus',
    },
    { to: '/company/plan', icon: 'wallet', text: 'Plan', page: 'plan' },
    {
      to: '/company/settings',
      icon: 'gear',
      text: 'Settings',
      page: 'settings',
    },
  ];

  const handleLogout = () => {
    analytics.logEvent('user_logged_out');
    dispatch(logoutUser(history));
  };

  return (
    <>
      <div className='float-left nav-block-col -col'>
        {menuItems.map((item) => (
          <Link
            to={item.to}
            className='no-underline'
            onClick={() => takeAnalytics(item.page)}
            key={item.to}
          >
            <Tooltip title={item.text} placement='right'>
              <div className='nav-block text-center button'>
                <IconButton
                  sx={{
                    '&:hover': {
                      backgroundColor: 'transparent !important',
                    },
                  }}
                >
                  <FontAwesomeIcon
                    icon={['fal', item.icon]}
                    className='desktop-icon icon float-center'
                  />
                </IconButton>
              </div>
            </Tooltip>
          </Link>
        ))}

        {/* Logout Button */}
        <button type='button' onClick={handleLogout}>
          <div className='nav-block logout-btn text-center position-absolute bottom-0 start-0 w-100'>
            <FontAwesomeIcon
              icon={['fal', 'sign-out']}
              className='desktop-icon icon float-center'
            />
            <p className='icon-text'>Logout</p>
          </div>
        </button>
      </div>
    </>
  );
};

export default withRouter(CompanySideNav);
