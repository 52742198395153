import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
// import PlacesAutocomplete from 'react-places-autocomplete';
// import {
//     geocodeByAddress,
//     geocodeByPlaceId,
//     getLatLng,
//   } from 'react-places-autocomplete';

class Payment extends Component {
  constructor() {
    super();
    this.state = {
      name: '',
      address: '',
      city: '',
      state: '',
      zip: '',
      loading: false,
      errors: {},
    };
  }
  handleSubmit = (event) => {
    this.props.history.push('/welcome'); // Remove when works

    event.preventDefault();
    this.setState({
      loading: true,
    });
    const paymentData = {
      fullName: this.state.name,
      street: this.state.address,
      city: this.state.city,
      state: this.state.state,
      zip: this.state.zip,
    };
    console.log(paymentData);
    axios
      .post('/editMyCompanyBilling', paymentData)
      .then((res) => {
        localStorage.setItem('IdToken', `Bearer ${res.data.IdToken}`);
        this.setState({
          loading: false,
        });
        this.props.history.push('/welcome');
      })
      .catch((err) => {
        console.log(err.response.data);
        this.setState({
          errors: err.response.data,
          loading: false,
        });
      });
  };
  onNameChange = (event) => {
    this.setState({
      name: event.target.value,
    });
  };
  // handleSelect = address => {
  //     geocodeByAddress(address)
  //       .then(results => getLatLng(results[0]))
  //       .then(latLng => console.log('Success', latLng))
  //       .catch(error => console.error('Error', error));
  //   };
  onAddressChange = (event) => {
    this.setState({ address: event.target.value });
  };
  onCityChange = (event) => {
    this.setState({
      city: event.target.value,
    });
  };
  onStateChange = (event) => {
    this.setState({
      state: event.target.value,
    });
  };
  onZipChange = (event) => {
    this.setState({
      zip: event.target.value,
    });
  };
  render() {
    return (
      <div className='login-page'>
        <div className='row justify-content-center'>
          <img
            src='../Images/successrocketlogo.png'
            className='signup-logo'
            alt='Landing'
          />
        </div>
        <div className='row justify-content-center'>
          <div className='form-row'>
            <form>
              <h1 className='login-header'>Selected Package</h1>
              <div className='row'>
                <div className='col-1'>
                  <FontAwesomeIcon icon={['fal', 'check-circle']} />
                </div>
                <div className='col-11'>
                  <p className='package-selected'>All In - $8 month per user</p>
                </div>
              </div>
              <h1 className='login-header'>Payment</h1>
              <div className='form-group'>
                <label htmlFor='name' className='sign-label'>
                  Full Name (as it appears on card)
                </label>
                <input
                  value={this.state.name}
                  onChange={this.onNameChange}
                  type='text'
                  className='form-control sign-input'
                  id='name'
                  aria-describedby='Address'
                  placeholder=''
                />
              </div>
              <p className='errors-p2'>{this.state.errors.name}</p>
              <div className='form-group'>
                <label htmlFor='address' className='sign-label'>
                  Billing Street Address
                </label>
                <input
                  value={this.state.address}
                  onChange={this.onAddressChange}
                  type='city'
                  className='form-control sign-input'
                  id='address'
                  aria-describedby='City'
                  placeholder=''
                />
                {/* <PlacesAutocomplete
                    
                    value={this.state.address}
                    onChange={this.onAddressChange}
                    onSelect={this.handleSelect}
                  >
                    {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                      <div>
                        <input
                          {...getInputProps({
                            placeholder: 'Search Places ...',
                            className: 'location-search-input form-control sign-input',
                          })}
                        />
                        <div className="autocomplete-dropdown-container">
                          {loading && <div>Loading...</div>}
                          {suggestions.map(suggestion => {
                            const className = suggestion.active
                              ? 'suggestion-item--active'
                              : 'suggestion-item';
                            // inline style for demonstration purpose
                            const style = suggestion.active
                              ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                              : { backgroundColor: '#ffffff', cursor: 'pointer' };
                            return (
                              <div
                                {...getSuggestionItemProps(suggestion, {
                                  className,
                                  style,
                                })}
                              >
                                <span>{suggestion.description}</span>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    )}
                  </PlacesAutocomplete> */}
              </div>
              <p className='errors-p2'>{this.state.errors.address}</p>
              <div className='form-group'>
                <label htmlFor='city' className='sign-label'>
                  Billing City
                </label>
                <input
                  value={this.state.city}
                  onChange={this.onCityChange}
                  type='state'
                  className='form-control sign-input'
                  id='city'
                  aria-describedby='State'
                  placeholder=''
                />
              </div>
              <p className='errors-p2'>{this.state.errors.city}</p>
              <div className='form-group'>
                <label htmlFor='state' className='sign-label'>
                  Billing State
                </label>
                <input
                  value={this.state.state}
                  onChange={this.onStateChange}
                  type='state'
                  className='form-control sign-input'
                  id='state'
                  aria-describedby='State'
                  placeholder=''
                />
              </div>
              <p className='errors-p2'>{this.state.errors.state}</p>
              <div className='form-group'>
                <label htmlFor='zip' className='sign-label'>
                  Billing Zip
                </label>
                <input
                  value={this.state.zip}
                  onChange={this.onZipChange}
                  type='zip'
                  className='form-control sign-input'
                  id='zip'
                  aria-describedby='Zip Code'
                  placeholder=''
                />
              </div>
              <p className='errors-p2'>{this.state.errors.zip}</p>
              <p className='payment-disclaimer'>
                *You will not be charged at time of setup. You will be billed
                based off how many active users you have on the 1st of each
                month. No contact required/cancel at anytime.
              </p>

              <div className='row justify-content-center'>
                <div className='signin-btn-row bottom'>
                  <button
                    className='payment-btn'
                    type='submit'
                    onClick={this.handleSubmit}
                  >
                    Confirm Purchase
                  </button>
                </div>
              </div>
              <p className='errors-p2'>{this.state.errors.general}</p>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

export default Payment;
