import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import React, { Component } from 'react';
import './actions.css';

class GiveFeedback extends Component {
  constructor() {
    super();
    this.state = {
      creatorId: '',
      creatorName: '',
      feedbackId: '',
      feedbackReqId: '',
      description: '',
      anon: '',
      response1: '',
      response2: '',
      response3: '',
      rating: '',
      anonymous: '',
      myName: '',
      loading: true,
      errors: {},
    };
  }

  componentDidMount() {
    this.setState({
      feedbackReqId: this.props.feedbackReqId,
      feedbackId: this.props.feedbackId,
      creatorId: this.props.creatorId,
      creatorName: this.props.creatorName,
      anon: this.props.anon,
      description: this.props.description,
      myName: this.props.myName,
    });
    if (this.props.anon === true) {
      this.setState({ anonymous: 'Your response is anonymous' });
    } else {
      this.setState({ anonymous: 'Your response is NOT anonymous' });
    }
    this.setState({ loading: false });
  }

  handleSubmit = (event) => {
    let name;
    name = this.state.myName;

    const formData = {
      a1: this.state.response1,
      a2: this.state.response2,
      a3: this.state.response3,
      rating: this.state.rating,
      description: this.state.description,
      anon: this.props.anon,
      feedbackReqId: this.state.feedbackReqId,
      feedbackId: this.state.feedbackId,
      responderName: name,
      creatorName: this.state.creatorName,
      creatorId: this.state.creatorId,
    };
    axios
      .post('/respondToFeedbackRequest', formData)
      .then((response) => {
        this.setState({
          loading: false,
        });
        window.location.reload();
      })
      .catch((err) => {
        this.setState({
          errors: err.response.data,
          loading: false,
        });
      });
  };

  onResponse1Change = (event) => {
    this.setState({
      response1: event.target.value,
    });
  };

  onResponse2Change = (event) => {
    this.setState({
      response2: event.target.value,
    });
  };

  onResponse3Change = (event) => {
    this.setState({
      response3: event.target.value,
    });
  };

  onRatingChange = (event) => {
    this.setState({
      rating: event.target.value,
    });
  };

  render() {
    if (this.state.loading) return null;
    return (
      <>
        <div
          className='modal '
          id={`respondFeedback${this.props.feedbackId}`}
          tabIndex='-1'
          role='dialog'
          aria-labelledby='exampleModalCenterTitle'
          aria-hidden='true'
        >
          <div className='col-xs-2'>
            <div className='modal-arrow' id='top'></div>
          </div>
          <div className='modal-dialog modal-dialog-centered' role='document'>
            <div className='modal-content main-action'>
              <div className='modal-header'>
                <h3 className='modal-title' id='exampleModalLongTitle'>
                  <FontAwesomeIcon
                    className='icon-mobile'
                    icon={['fal', 'sync']}
                  />
                </h3>
                <button
                  type='button'
                  className='closeBtn mt-1'
                  data-dismiss='modal'
                  aria-label='Close'
                >
                  <span aria-hidden='true'>&times;</span>
                </button>
              </div>
              <div className='modal-header'>
                <h3 className='modal-title' id='exampleModalLongTitle'>
                  {`Give ${this.state.creatorName} Feedback`}
                </h3>
              </div>
              <div className='row feed-m-row'>
                <p className='feed-message'>
                  <small className='p-2'>{this.state.anonymous}</small>
                </p>
              </div>
              <div className='modal-body'>
                <form>
                  <div className='form-group'>
                    <label
                      htmlFor='exampleFormControlInput4'
                      className='action-label'
                    >
                      Description
                    </label>
                    <textarea
                      value={this.state.description}
                      type='text'
                      className='form-control action-input feed-textbox'
                      placeholder=''
                      disabled
                    />
                  </div>

                  <div className='form-group mt-3'>
                    <label
                      htmlFor='exampleFormControlInput4'
                      className='action-label'
                    >
                      How do you feel I'm doing in driving success for my
                      department?
                    </label>
                    <input
                      onChange={this.onResponse1Change}
                      value={this.state.response1}
                      type='text'
                      className='form-control action-input'
                      placeholder=''
                    />
                  </div>

                  <div className='form-group mt-3'>
                    <label
                      htmlFor='exampleFormControlInput4'
                      className='action-label'
                    >
                      How do you feel I'm doing to contribute to the success of
                      our organization?
                    </label>
                    <input
                      onChange={this.onResponse2Change}
                      value={this.state.response2}
                      type='text'
                      className='form-control action-input'
                      placeholder=''
                    />
                  </div>

                  <div className='form-group mt-3'>
                    <label
                      htmlFor='exampleFormControlInput4'
                      className='action-label'
                    >
                      How do you feel I'm doing in engaging with you and your
                      team (as applicable)?
                    </label>
                    <input
                      onChange={this.onResponse3Change}
                      value={this.state.response3}
                      type='text'
                      className='form-control action-input'
                      placeholder=''
                    />
                  </div>

                  <div className={'form-group'}>
                    <label
                      htmlFor='exampleFormControlInput7'
                      className='action-label'
                    >
                      Feedback Rating
                    </label>
                    <select
                      onChange={this.onRatingChange}
                      value={this.state.rating}
                      className='form-select action-input star-select'
                    >
                      <option></option>
                      <option value='★ ★ ★ ★ ★'>★ ★ ★ ★ ★</option>
                      <option value='★ ★ ★ ★ ☆'>★ ★ ★ ★ ☆</option>
                      <option value='★ ★ ★ ☆ ☆'>★ ★ ★ ☆ ☆</option>
                      <option value='★ ★ ☆ ☆ ☆'>★ ★ ☆ ☆ ☆</option>
                      <option value='★ ☆ ☆ ☆ ☆'>★ ☆ ☆ ☆ ☆</option>
                      <option value='☆ ☆ ☆ ☆ ☆'>☆ ☆ ☆ ☆ ☆</option>
                    </select>
                  </div>
                </form>
              </div>
              <p className='errors-p p-3'>{this.state.errors.general}</p>
              <div className='modal-footer'>
                <button
                  type='button'
                  onClick={this.handleSubmit}
                  className='btn btn-submit'
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default GiveFeedback;
