import axios from 'axios';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { analytics } from '../../firebaseConfig.js';

function Notif(props) {
  const history = useHistory();

  const ViewEvent = (event) => {
    console.log(event);
    viewNotif();
    history.push({
      pathname: props.link,
      state: { userId: event },
    });
  };

  const viewNotif = () => {
    analytics.logEvent(`misc_notif_viewed`);
    axios.post(`/notifications/${props.id}`).then((res) => {
      console.log(res.data);
    });
  };

  return (
    <div className='notif-outside row'>
      <div className='col-1 mb-3 icon-col'>
        <img className=' notif-icon' src={props.img} alt={props.name} />
      </div>
      <div className='col-7'>
        <p className='notif-text'>
          <strong>{props.name}</strong> {props.text}
        </p>
      </div>
      <div className='col-4 notif-btn-row'>
        <button
          className={props.read ? 'btn-notif btn-notif-grey' : ' btn-notif'}
          onClick={(e) => ViewEvent(props.id)}
        >
          view
        </button>
      </div>
    </div>
  );
}

export default Notif;
