import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import axios from 'axios';

function IsManager(props) {
  const [loading, setLoading] = useState(true);
  const [name, setName] = useState();
  const [image, setImage] = useState();
  const history = useHistory();

  useEffect(() => {
    axios
      .get('/user')
      .then((res) => {
        const cred = res.data.credentials;
        const name = cred.firstName + ' ' + cred.lastName;
        setName(name);
        setImage(cred.profileImg);
      })
      .catch((err) => {
        console.error('IsManager - Error fetching user data:', err);
        setLoading(false);
      });
  }, [setLoading]);

  const createManager = () => {
    setLoading(true);
    axios
      .post('/generateManagerCode')
      .then((res) => {
        setLoading(false);
        history.push({ pathname: '/createManager', state: { code: res.data } });
        window.location.reload();
      })
      .catch((err) => {
        console.error('IsManager - Error creating manager:', err.response.data);
        setLoading(false);
      });
  };

  if (loading) return null;

  return (
    <>
      <div className='upload-photo-page justify-content-center row'>
        <div className='upload-photo-col'>
          <div className='row'>
            <img src={image} className='profile-img' alt='Example' />
          </div>
          <div className='row'>
            <p className='choose-manager-text'>{`Welcome ${name}, are you a Manager or Employee?`}</p>
          </div>

          <div className='row justify-content-center mt-3'>
            <button
              onClick={(e) => createManager()}
              className='sign-btn mb-3 manager-btn'
            >
              Manager
            </button>
          </div>
          <div className='row justify-content-center '>
            <button className='sign-btn '>
              <Link to='/chooseManager' className='signin-link'>
                Employee
              </Link>
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default IsManager;
