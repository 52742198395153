import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component } from 'react';
import { faCircle } from '@fortawesome/pro-solid-svg-icons';
import $ from 'jquery';
import axios from 'axios';
import ConfettiExplosion from '@reonomy/react-confetti-explosion';
import ModalCloseBtn from '../../Constants/ModalCloseBtn';

class IncompleteAction extends Component {
  constructor() {
    super();
    this.state = {
      id: '',
      type: '',
      sub: '',
      main: '',
      name: '',
      action: '',
      description: '',
      priority: '',
      dDate: '',
      status: false,
      loading: true,
      isExploding: false,
      errors: {},
    };
  }

  // componentDidMount() {
  //   this.setState({ id: this.props.id });
  //   this.setState({ type: this.props.type });
  //   this.setState({ sub: this.props.sub });
  //   this.setState({ main: this.props.main });
  //   this.setState({ name: this.props.name });
  //   this.setState({ action: this.props.title });
  //   this.setState({ dDate: this.props.dDate });
  //   this.setState({ description: this.props.description });
  //   this.setState({ priority: this.props.priority });
  //   this.setState({ status: this.props.status });

  //   this.setState({ loading: false });
  // }

  handleSubmit = (event) => {
    event.preventDefault();
    this.setState({
      loading: true,
    });
    const editAction = {
      id: this.state.id,
      status: this.state.status,
    };
    console.log(editAction);
    axios
      .post('/editAction', editAction)
      .then((res) => {
        this.setState({
          loading: false,
        });
        if (this.state.status === 'Complete') {
          $(`#sub${this.state.id}btn`).trigger('click');
          // confetti
          this.setState({ isExploding: true });
          setTimeout(() => {
            this.setState({ isExploding: false });
          }, 2000);
        } else {
          window.location.reload();
        }
      })
      .catch((err) => {
        console.log(err.response.data);
        this.setState({
          errors: { general: 'Error: Please check information and try again.' },
          loading: false,
        });
        $(`#incomplete${this.state.id}btn`).trigger('click');
        this.setState({ isExploding: true });
        setTimeout(() => {
          this.setState({ isExploding: false });
        }, 2000);
      });
  };

  onStatusChange = (event) => {
    this.setState({
      status: event.target.value,
    });
  };

  render() {
    // if (this.state.loading) return null;
    return (
      <>
        <div
          className='modal '
          id={`incomplete${this.props.id}`}
          tabIndex='-1'
          role='dialog'
          aria-labelledby='exampleModalCenterTitle'
          aria-hidden='true'
        >
          <div className='col-xs-2'>
            <div className='modal-arrow-sub' id='top'></div>
          </div>
          <div className='modal-dialog modal-dialog-centered' role='document'>
            <div className='modal-content sub-action'>
              <div className='modal-header'>
                <h5 className='modal-edit-title' id='exampleModalLongTitle'>
                  {this.state.type}
                </h5>
                <ModalCloseBtn />
              </div>
              {this.state.isExploding && <ConfettiExplosion />}
              <div className='modal-body'>
                <form>
                  <div
                    className={
                      this.props.sub
                        ? 'form-group form-group disabled-group'
                        : 'form-group hidden-group disabled-group'
                    }
                  >
                    <label
                      htmlFor='exampleFormControlInput5'
                      className='action-label'
                    >
                      Main Action
                    </label>
                    <div className='disabled-input'>
                      <div className=' action-input-col'>
                        <select className='form-select action-input'>
                          <option>{this.props.main}</option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <div
                    className={
                      this.props.sub
                        ? 'form-group hidden-group disabled-group'
                        : 'form-group form-group disabled-group'
                    }
                  >
                    <label
                      htmlFor='exampleFormControlInput5'
                      className='action-label'
                    >
                      Action
                    </label>
                    <div className=''>
                      <div className=' action-input-col'>
                        <select className='form-select action-input'>
                          <option>{this.props.main}</option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <div
                    className={
                      this.props.sub
                        ? 'form-group form-group disabled-group'
                        : 'form-group hidden-group disabled-group'
                    }
                  >
                    <label
                      htmlFor='exampleFormControlInput3'
                      className='action-label'
                    >
                      Action
                    </label>
                    <input
                      type='text'
                      className='form-control action-input'
                      value={this.props.title}
                    ></input>
                  </div>

                  <div
                    className={
                      this.props.sub
                        ? 'form-group hidden-group disabled-group'
                        : 'form-group form-group disabled-group'
                    }
                  >
                    <label
                      htmlFor='exampleFormControlInput4'
                      className='action-label'
                    >
                      Description
                    </label>
                    <input
                      type='text'
                      className='form-control action-input'
                      placeholder=''
                      value={this.props.description}
                    />
                  </div>

                  <div
                    className={
                      this.props.sub
                        ? 'form-group hidden-group disabled-group'
                        : 'form-group form-group disabled-group'
                    }
                  >
                    <label
                      htmlFor='exampleFormControlInput5'
                      className='action-label'
                    >
                      Priority
                    </label>
                    <div className='row'>
                      <div className='col-11 action-input-col'>
                        <p type='text' className='form-control action-input'>
                          <FontAwesomeIcon
                            className={`priority-icon-${this.props.priority}`}
                            icon={faCircle}
                          />{' '}
                          {this.props.priority}
                        </p>
                      </div>
                      <div className='col-1 action-icon-col'>
                        <span className='action-icon'>
                          <FontAwesomeIcon
                            className='action-i form-control-feedback'
                            icon={['fal', 'chevron-down']}
                          />
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className='form-group disabled-group'>
                    <label
                      htmlFor='exampleFormControlInput6'
                      className='action-label'
                    >
                      Due Date
                    </label>
                    <p className='form-control action-input'>
                      {this.props.dDate}
                    </p>
                  </div>
                  <div className='form-group form-group'>
                    <label
                      htmlFor='exampleFormControlInput7'
                      className='action-label'
                    >
                      Status
                    </label>
                    <select
                      onChange={this.onStatusChange}
                      value={this.state.status}
                      className='form-select action-select '
                    >
                      <option value={false}>Not Complete</option>
                      <option value={true}>Complete</option>
                    </select>
                  </div>
                </form>
              </div>
              <p className='errors-p p-3'>{this.state.errors.general}</p>
              <div className='modal-footer'>
                <button
                  type='button'
                  onClick={this.handleSubmit}
                  className='btn btn-submit'
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default IncompleteAction;
