import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component } from 'react';
import Page from '../../Components/NavBars/Page';
import $ from 'jquery';
import BackBtn from '../../Components/Back/BackBtn';
import axios from 'axios';
import { analytics } from '../../firebaseConfig.js';

class NewCheck extends Component {
  constructor() {
    super();
    this.state = {
      checkins: [],
      q1: '',
      q2: '',
      q3: '',

      loading: true,
      errors: {},
    };
  }

  componentDidMount() {
    this._isMounted = true;
    analytics.logEvent(`new_check_visited`);
    axios
      .get('/getCheckins')
      .then((res) => {
        if (res.data.length === 0) {
          console.log('no checkin');
          if (this._isMounted) {
            this.setState({ loading: false });
          }
        } else {
          this.setState({
            checkins: res.data[0].checkin.sort(
              (a, b) => Date.parse(b.created) - Date.parse(a.created),
            ),
          });
        }
        this.setState({ loading: false });
      })
      .catch((error) => {
        console.error('NewCheck - Error fetching checkins:', error);

        this.setState({
          loading: false,
          error: 'An error occurred while fetching checkins.',
        });
      });
  }

  handleSubmit = (event) => {
    event.preventDefault();
    this.setState({
      loading: true,
    });
    const checkInData = {
      a1: this.state.q1,
      a2: this.state.q2,
      a3: this.state.q3,
    };
    console.log(checkInData);
    axios
      .post('/createNewCheckin', checkInData)
      .then((res) => {
        this.setState({
          loading: false,
        });
        this.props.history.push('/check-in');
      })
      .catch((err) => {
        console.error(
          'NewCheck - Error creating new checkin:',
          err.response.data,
        );
        if (this._isMounted) {
          this.setState({
            errors: {
              general: 'Error: Please check information and try again.',
            },
            loading: false,
          });
        }
      });
  };

  onFirstQuestionChange = (event) => {
    this.setState({
      q1: event.target.value,
    });
  };
  onSecondQuestionChange = (event) => {
    this.setState({
      q2: event.target.value,
    });
  };
  onThirdQuestionChange = (event) => {
    this.setState({
      q3: event.target.value,
    });
  };

  viewPast = (value) => {
    $(`.view-past-q.${value}`).addClass('show-past-q');
    $(`.view-past.${value}`).addClass('hide-past-btn');
    $(`.hide-past.${value}`).addClass('show-past-btn');
  };

  hidePast = (value) => {
    $(`.view-past-q.${value}`).removeClass('show-past-q');
    $(`.hide-past.${value}`).removeClass('show-past-btn');
    $(`.view-past.${value}`).removeClass('hide-past-btn');
  };

  render() {
    if (this.state.loading) return null;

    return (
      <>
        <Page
          content={
            <div className='sc-page'>
              <BackBtn link='check' className='mb-3' />
              <div className='row sc-col'>
                <p className='errors-p p-3'>{this.state.errors.general}</p>
                <h1 className='sc-header'>Check-in</h1>
                <form>
                  <div className='form-group'>
                    <label htmlFor='q1' className='sc-question'>
                      From 3 months ago how do you feel like you are doing?
                    </label>
                    <textarea
                      onChange={this.onFirstQuestionChange}
                      value={this.state.q1}
                      type='text'
                      className='form-control sc-text-input'
                      id='q1'
                      placeholder='Type here'
                    />
                    <button
                      className='view-past 1'
                      type='button'
                      onClick={(event) => {
                        this.viewPast(1);
                      }}
                    >
                      <FontAwesomeIcon
                        className='past-icon'
                        icon={['fal', 'history']}
                      />
                      View Question History
                    </button>
                    <button
                      className='hide-past 1'
                      type='button'
                      onClick={(event) => {
                        this.hidePast(1);
                      }}
                    >
                      <FontAwesomeIcon
                        className='past-icon'
                        icon={['fal', 'history']}
                      />
                      Hide Question History
                    </button>
                    <div className='view-past-q 1'>
                      {this.state.checkins.map((res, i) => (
                        <div id='accordion1' key={res.created}>
                          <div className='past-q'>
                            <button
                              className='btn btn-light mt-2'
                              data-toggle='collapse'
                              data-target={`#collapse1${i}`}
                              aria-expanded='false'
                              aria-controls={`collapse1${i}`}
                              type='button'
                            >
                              {res.created.substring(0, 10)}
                            </button>
                            <div
                              id={`collapse1${i}`}
                              className={'collapse'}
                              aria-labelledby={`heading1${i}`}
                              data-parent='#accordion1'
                            >
                              <p className='past-q-text'>{res.a1}</p>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className='form-group'>
                    <label htmlFor='q2' className='sc-question'>
                      What things do you need to further work on to achieve
                      sustained success?
                    </label>
                    <textarea
                      onChange={this.onSecondQuestionChange}
                      value={this.state.q2}
                      type='text'
                      className='form-control sc-text-input'
                      id='q2'
                      placeholder='Type here'
                    />
                    <button
                      className='view-past 2'
                      type='button'
                      onClick={(event) => {
                        this.viewPast(2);
                      }}
                    >
                      <FontAwesomeIcon
                        className='past-icon'
                        icon={['fal', 'history']}
                      />
                      View Question History
                    </button>
                    <button
                      className='hide-past 2'
                      type='button'
                      onClick={(event) => {
                        this.hidePast(2);
                      }}
                    >
                      <FontAwesomeIcon
                        className='past-icon'
                        icon={['fal', 'history']}
                      />
                      Hide Question History
                    </button>
                    <div className='view-past-q 2'>
                      {this.state.checkins.map((res, i) => (
                        <div id='accordion2' key={res.created}>
                          <div className='past-q'>
                            <button
                              className='btn btn-light mt-2'
                              data-toggle='collapse'
                              data-target={`#collapse2${i}`}
                              aria-expanded='false'
                              aria-controls={`collapse2${i}`}
                              type='button'
                            >
                              {res.created.substring(0, 10)}
                            </button>
                            <div
                              id={`collapse2${i}`}
                              className={'collapse'}
                              aria-labelledby={`heading2${i}`}
                              data-parent='#accordion2'
                            >
                              <p className='past-q-text'>{res.a2}</p>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className='form-group'>
                    <label htmlFor='q3' className='sc-question'>
                      What barriers to further success exist?
                    </label>
                    <textarea
                      onChange={this.onThirdQuestionChange}
                      value={this.state.q3}
                      type='text'
                      className='form-control sc-text-input'
                      id='q3'
                      placeholder='Type here'
                    />
                    <button
                      className='view-past 3'
                      type='button'
                      onClick={(event) => {
                        this.viewPast(3);
                      }}
                    >
                      <FontAwesomeIcon
                        className='past-icon'
                        icon={['fal', 'history']}
                      />
                      View Question History
                    </button>
                    <button
                      className='hide-past 3'
                      type='button'
                      onClick={(event) => {
                        this.hidePast(3);
                      }}
                    >
                      <FontAwesomeIcon
                        className='past-icon'
                        icon={['fal', 'history']}
                      />
                      Hide Question History
                    </button>
                    <div className='view-past-q 3'>
                      {this.state.checkins.map((res, i) => (
                        <div id='accordion3' key={res.created}>
                          <div className='past-q'>
                            <button
                              className='btn btn-light mt-2'
                              data-toggle='collapse'
                              data-target={`#collapse3${i}`}
                              aria-expanded='false'
                              aria-controls={`collapse3${i}`}
                              type='button'
                            >
                              {res.created.substring(0, 10)}
                            </button>
                            <div
                              id={`collapse3${i}`}
                              className={'collapse'}
                              aria-labelledby={`heading3${i}`}
                              data-parent='#accordion3'
                            >
                              <p className='past-q-text'>{res.a3}</p>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>

                  <div className='col-12 text-center mb-3'>
                    <button
                      onClick={this.handleSubmit}
                      className='btn btn-submit'
                      type='submit'
                    >
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          }
        />
      </>
    );
  }
}

export default NewCheck;
