import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import React, { Component } from 'react';
import './actions.css';
import Select from 'react-select';

class GetMentor extends Component {
  constructor() {
    super();
    this.state = {
      teamNames: [],
      responders: [],
      description: '',
      anon: '',
      loading: false,
      errors: {},
    };
  }

  componentDidMount() {
    this.setState({ loading: true });
    axios.get('/getCompanyDirectory').then((res) => {
      const data = res.data.directory;
      let names = [];
      for (let i = 0; i < data.length; i++) {
        let fName = data[i].firstName.toUpperCase();
        let lName = data[i].lastName.toUpperCase();
        if (data[i].userId !== this.props.me) {
          names.push({
            name: `${fName} ${lName}`,
            id: data[i].userId,
            img: data[i].profileImg,
          });
        }
      }
      names.sort((a, b) =>
        a.name
          .split(' ')[1]
          .charAt(0)
          .localeCompare(b.name.split(' ')[1].charAt(0), 'es', {
            sensitivity: 'base',
          }),
      );
      const formOptions = [];
      for (let i = 0; i < names.length; i++) {
        let thisUser = {
          value: `${names[i].id},${names[i].name}`,
          label: (
            <div>
              {' '}
              <img
                src={names[i].img}
                alt={names[i].id}
                className='newShoutImg'
              />{' '}
              {names[i].name}{' '}
            </div>
          ),
        };
        formOptions.push(thisUser);
      }
      this.setState({ teamNames: formOptions });
    });
  }

  onReceiverChange = (value) => {
    this.setState({
      responders: value,
    });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    const responders = this.state.responders;

    let mentor = responders.value.split(',')[0];

    const formData = {
      mentorId: mentor,
    };

    axios
      .post('/createMentorRequest', formData)
      .then((res) => {
        this.setState({
          loading: false,
        });
        window.location.reload();
      })
      .catch((err) => {
        console.log(err.response.data);
        this.setState({
          errors: {
            general: 'Error: Mentor relationships are not quite ready yet!',
          },
          loading: false,
        });
      });
  };

  render() {
    return (
      <>
        <div
          className='modal '
          id='getMentor'
          tabIndex='-1'
          role='dialog'
          aria-labelledby='exampleModalCenterTitle'
          aria-hidden='true'
        >
          <div className='col-xs-2'>
            <div className='modal-arrow' id='top'></div>
          </div>
          <div className='modal-dialog modal-dialog-centered' role='document'>
            <div className='modal-content main-action'>
              <div className='modal-header'>
                <h3 className='modal-title' id='exampleModalLongTitle'>
                  <FontAwesomeIcon
                    className='icon-mobile'
                    icon={['fal', 'sync']}
                  />
                </h3>
                <button
                  type='button'
                  className='closeBtn mt-1'
                  data-dismiss='modal'
                  aria-label='Close'
                >
                  <span aria-hidden='true'>&times;</span>
                </button>
              </div>
              <div className='modal-body'>
                <form>
                  <div className='form-group mt-3'>
                    <label
                      htmlFor='exampleFormControlInput5'
                      className='action-label'
                    >
                      Mentor
                    </label>
                    <div className='row'>
                      <div className='col-11 action-input-col'>
                        <Select
                          onChange={this.onReceiverChange.bind(this)}
                          className='action-selector feedback-selector'
                          options={this.state.teamNames}
                        />
                      </div>
                      <div className='col-1 action-icon-col'>
                        <span className=''>
                          <FontAwesomeIcon
                            className='action-i form-control-feedback'
                            icon={['fal', 'plus-circle']}
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              <p className='errors-p p-3'>{this.state.errors.general}</p>
              <div className='modal-footer'>
                <button
                  type='button'
                  onClick={this.handleSubmit}
                  className='btn btn-submit'
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default GetMentor;
