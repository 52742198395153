import axios from 'axios';
import React, { useEffect, useState } from 'react';
import IncompleteAction from '../Actions/IncompleteAction';
import $ from 'jquery';
import { analytics } from '../../firebaseConfig.js';

function IncompleteEmployee({ id, id2, text, read, img }) {
  const [loading, setLoading] = useState(true);
  const [sub, setSub] = useState();
  const [thisCheck, setThisCheck] = useState({});

  const getAction = () => {
    if (id !== undefined) {
      axios.get(`/getActionByID/${id}`).then((res) => {
        setThisCheck(res.data);
        if (res.data.actionType === 'sub') {
          setSub(true);
        } else {
          setSub(false);
        }
      });
    }
  };

  const viewNotif = () => {
    analytics.logEvent(`incomplete_a_notif_viewed`);
    $(`#incomplete${id}btn`).addClass('btn-notif-grey');
    axios.post(`/notifications/${id2}`).then((res) => {
      console.log(res.data);
    });
  };

  useEffect(() => {
    getAction();

    setLoading(false);
  }, [id]);

  if (loading) return null;

  return (
    <div className='notif-outside row'>
      <div className='col-1 mb-3 icon-col'>
        <img className=' notif-icon' src={img} alt={id} />
      </div>
      <div className='col-7'>
        <p className='notif-text'>
          <strong>Your</strong> {text}
        </p>
      </div>
      <div className='col-4 notif-btn-row'>
        <button
          id={`incomplete${id}btn`}
          className={read ? 'btn-notif btn-notif-grey' : ' btn-notif'}
          data-toggle='modal'
          data-target={`#incomplete${id}`}
          onClick={() => viewNotif()}
        >
          view
        </button>
      </div>
      <IncompleteAction
        title={thisCheck.title}
        status={thisCheck.completed}
        id={thisCheck.actionId}
        name={thisCheck.name}
        main={thisCheck.mainActionTitle}
        sub={sub}
        priority={thisCheck.priority}
        dDate={thisCheck.dueDate}
        description={thisCheck.description}
      />
    </div>
  );
}

export default IncompleteEmployee;
