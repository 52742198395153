import React, { useState } from 'react';
import CompanyHeader from './CompanyHeader';
import CompanySideNav from './CompanySideNav';
import CompanyFooter from './CompanyFooter';
import Spinner from '../Loading/Spinner';

const CompanyPage = (props) => {
  const [loading, setLoading] = useState(false);

  if (loading) return <Spinner />;

  return (
    <>
      <CompanyHeader companyName={props.companyName} />
      <div className='row'>
        <CompanySideNav />

        <div className='content-outside'>
          {props.content}
          {/* <div className='header-button'>
            <button className='fixed-btn'>Chat</button>
          </div> */}
        </div>
      </div>
      <CompanyFooter />
    </>
  );
};

export default CompanyPage;
