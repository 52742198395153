import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';

const Mcontact = (props) => {
  const [overseer, setOverseer] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (props.currentOverseer === props.overseer) {
      setOverseer(true);
      setLoading(false);
    } else {
      setOverseer(false);
      setLoading(false);
    }
  }, []);

  if (loading) return null;

  return (
    <>
      <div className='contact row'>
        {overseer ? (
          <div className='col-3 text-center'>
            <Link to={`/profile/${props.id}`}>
              <img
                className=' mcontact-icon '
                src={props.profileImg}
                alt={props.name}
              />{' '}
            </Link>
            <p className='small-p'>In my team</p>
          </div>
        ) : (
          <div className='col-3 text-center'>
            <img
              className=' mcontact-icon'
              src={props.profileImg}
              alt={props.name}
            />
          </div>
        )}
        <div className='col-6 col-lg-4'>
          <p className='contact-text'>
            <strong>{props.name}</strong>
          </p>
          <p className='contact-text'>{props.number}</p>
          <p className='contact-text'>{props.email}</p>
        </div>
        <div className='col-3 text-left'>
          <div className='row'>
            <div className='col-6 c-btn-outside'>
              <a
                className='btn btn-light contact-btn'
                href={`tel:${props.number}`}
              >
                <FontAwesomeIcon
                  icon={['fal', 'phone']}
                  className='contact-action-icon'
                />
              </a>
            </div>
            <div className='col-6 c-btn-outside'>
              <a
                className='btn btn-light contact-btn'
                href={`mailto:${props.email}`}
              >
                <FontAwesomeIcon
                  icon={['fal', 'envelope']}
                  className='contact-action-icon'
                />
              </a>
            </div>
          </div>
        </div>
        <div className='col-lg-2'></div>
      </div>
    </>
  );
};

export default Mcontact;
