import React from 'react';
import { Route, Redirect } from 'react-router-dom';

const ProtectedRoute = ({
  mcomponent,
  component,
  auth,
  isManager,
  page,
  ...rest
}) => {
  // const manager = store.user.credentials.managerCode;
  // const manager = "0899987890";
  const Component = component;
  const MComponent = mcomponent;

  return (
    <Route
      {...rest}
      render={(props) =>
        auth ? (
          isManager ? (
            <MComponent {...props} />
          ) : (
            <Component {...props} />
          )
        ) : (
          <Redirect to='/' />
        )
      }
    />
  );
};

export default ProtectedRoute;
