import React, { Component } from 'react';
import BackBtn from '../../Components/Back/BackBtn';
import axios from 'axios';

class ForgotPass extends Component {
  constructor() {
    super();
    this.state = {
      email: '',
      loading: false,
      image: '',
      errors: {},
    };
  }

  handleSubmit = (event) => {
    event.preventDefault();
    this.setState({
      loading: true,
    });
    const passData = {
      email: this.state.email,
    };

    axios
      .post('/resetPasswordEmail', passData)
      .then((res) => {
        this.setState({
          errors: { general: 'Check your email for a link to reset password.' },
          loading: false,
        });
      })
      .catch((err) => {
        console.error('ForgotPass - Error resetting password:', err);
        this.setState({
          errors: {
            general: 'Please enter the email associated with your account.',
          },
          loading: false,
        });
      });
  };

  onEmailChange = (event) => {
    this.setState({
      email: event.target.value,
    });
  };
  render() {
    if (this.state.loading) return null;
    return (
      <>
        <div className='upload-photo-page justify-content-center row'>
          <div className='upload-photo-col'>
            <BackBtn link='editProfile' className='mb-3' />
            <div className='row mt-3 pt-3'>
              <p className='choose-manager-text mt-3'>
                Let's get your password reset.
              </p>
            </div>
            <div className='row'>
              <form>
                <div className='mb-3'>
                  <label htmlFor='email' className='form-label sign-label'>
                    Email associated with your account
                  </label>
                  <input
                    onChange={this.onEmailChange}
                    className='form-control'
                    value={this.state.email}
                    type='email'
                    Name='form-control sign-input'
                    id='email'
                    aria-describedby='email'
                  />
                </div>
                <p className='errors-p p-3'>{this.state.errors.general}</p>
                <div className='row justify-content-center '>
                  <button
                    onClick={this.handleSubmit}
                    className='sign-btn next-step-btn'
                  >
                    Update
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default ForgotPass;
