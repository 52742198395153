import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import NewShout from './NewShout';
import { DEFAULT_IMAGE_URL } from '../../Constants/Constants';
import ModalCloseBtn from '../../Constants/ModalCloseBtn';

const ViewShout = (props) => {
  return (
    <>
      <div
        className='modal '
        id={`viewShoutOut${props.id}`}
        tabIndex='-1'
        role='dialog'
        aria-labelledby='exampleModalCenterTitle'
        aria-hidden='true'
      >
        <div className='col-xs-2'>
          <div className='modal-arrow-sub' id='top'></div>
        </div>
        <div className='modal-dialog modal-dialog-centered' role='document'>
          <div className='modal-content shout-modal'>
            <div className='modal-header'>
              <h5 className='shout-header' id='exampleModalLongTitle'>
                Shout Out
              </h5>
              <ModalCloseBtn />
            </div>
            <div className='modal-body '>
              <div className='row shout-row'>
                <div className='col-3'>
                  <img
                    className='shout-img'
                    src={props.img || DEFAULT_IMAGE_URL}
                    alt='1'
                  />
                </div>
                <div className='col-9'>
                  <p className='shout-text'>{props.message}</p>
                  <p className='shout-text'>
                    <strong>-{props.name}</strong> to {props.to}
                  </p>
                  {/* <p className="likes-num">
                    {" "}
                    14
                    <FontAwesomeIcon
                      className="likes"
                      icon={["fal", "thumbs-up"]}
                    />
                  </p> */}
                </div>
              </div>
            </div>
            <div className='modal-footer'>
              <button
                id={`newShoutOutPopover${props.id}`}
                className=''
                type='button'
                data-toggle='modal'
                data-target={`#newShoutOut${props.id}`}
              >
                <FontAwesomeIcon
                  className='shout-icon text-light'
                  icon={['fal', 'plus-circle']}
                />
              </button>
              <NewShout id={props.id} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewShout;
