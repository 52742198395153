import {
  SET_ACTIONS,
  SET_ACTION,
  SET_SHOUTOUT,
  SET_SHOUTOUTS,
  DELETE_ACTION,
  DELETE_SHOUTOUT,
  POST_ACTION,
  POST_SHOUTOUT,
  LOADING_DATA,
} from '../types';

const initialState = {
  actions: [],
  action: {},
  shoutouts: [],
  shoutout: {},
  loading: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case LOADING_DATA:
      return {
        ...state,
        loading: true,
      };
    case SET_ACTIONS:
      return {
        ...state,
        actions: action.payload,
        loading: false,
      };
    case SET_ACTION:
      return {
        ...state,
        action: action.payload,
      };
    case SET_SHOUTOUTS:
      return {
        ...state,
        actions: action.payload,
        loading: false,
      };
    case SET_SHOUTOUT:
      return {
        ...state,
        shoutout: action.payload,
      };
    case DELETE_ACTION:
      let index = state.actions.findIndex(
        (action) => action.actionId === action.payload,
      );
      state.action.splice(index, 1);
      return {
        ...state,
      };
    case DELETE_SHOUTOUT:
      index = state.shoutouts.findIndex(
        (shoutout) => shoutout.shoutoutId === action.payload,
      );
      state.shoutout.splice(index, 1);
      return {
        ...state,
      };
    case POST_ACTION:
      return {
        ...state,
        actions: [action.payload, ...state.actions],
      };
    case POST_SHOUTOUT:
      return {
        ...state,
        shoutouts: [action.payload, ...state.shoutouts],
      };
    default:
      return state;
  }
}
