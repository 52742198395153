import React, { Component } from 'react';
import Page from '../../Components/NavBars/Page';
import PriorityKey from '../../Components/Actions/PriorityKey';

import ConfettiExplosion from '@reonomy/react-confetti-explosion';
import axios from 'axios';

import { ActionComponent } from '../../Components/Actions/MainActionComponent';

class Actions extends Component {
  constructor() {
    super();
    this.state = {
      myId: '',
      ninety: false,
      actions: [
        {
          id: ' ',
          status: ' ',
          main: ' ',
          name: ' ',
          priority: ' ',
          description: ' ',
          dDate: ' ',
          rating: ' ',
          sub: [
            {
              name: ' ',
              id: ' ',
              dDate: ' ',
              status: false,
              rating: ' ',
              priority: '',
            },
          ],
        },
      ],
      loading: true,
      isExploding: false,
      exploding: false,
      errors: {},
    };
  }

  getMyId = () => {
    axios.get('/user').then((res) => {
      const cred = res.data.credentials;
      const days = cred.created;
      const newDate = new Date(days);
      let ninetyDay = new Date(newDate.setDate(newDate.getDate() + 90));
      let today = new Date();
      if (ninetyDay > today) {
        this.setState({ ninety: true });
      } else {
        this.setState({ ninety: false });
      }
      this.setState({ myId: res.data.credentials.userId });
      this.runData();
    });
    // this.forceUpdate();
  };

  runData = () => {
    axios.get('/getUserActions').then((res) => {
      const data = res.data;
      let theActions = [];
      let subActions = [];
      for (let i = 0; i < data.length; i++) {
        if (data[i].actionType === 'main') {
          theActions.push({
            id: data[i].actionId,
            main: data[i].title,
            priority: data[i].priority,
            dDate: data[i].dueDate,
            description: data[i].description,
            status: data[i].completed,
          });
        } else {
          subActions.push({
            id: data[i].actionId,
            name: data[i].title,
            priority: data[i].priority,
            dDate: data[i].dueDate,
            status: data[i].completed,
            mainActionId: data[i].mainAction,
          });
        }
      }
      // console.log(theActions);
      const newActions = theActions.map((item) => {
        return {
          ...item,
          sub: subActions.filter((element) => element.mainActionId === item.id),
        };
      });

      this.setState({ actions: newActions });
    });
    this.setState({ loading: false });
  };

  componentDidMount() {
    this.getMyId();
  }

  confettiSlider = (event) => {
    const actionID = event.target.getAttribute('id');
    const oldStatus = event.target.getAttribute('value');
    let newStatus;
    if (oldStatus === 'false') {
      newStatus = 'true';
    } else {
      newStatus = 'false';
    }
    const editAction = {
      completed: newStatus,
    };

    // set all subs to true
    axios
      .post(`/editStatus/${actionID}`, editAction)
      .then((res) => {
        if (editAction.completed === 'true') {
          this.setState({ isExploding: true });
          setTimeout(() => {
            this.setState({ isExploding: false });
          }, 5000);
          this.runData();
          this.forceUpdate();
        } else if (editAction.completed === 'false') {
          this.setState({ isExploding: false });
          this.runData();
          this.forceUpdate();
        }
      })
      .catch((err) => {
        console.log(err.response.data);
        this.setState({
          errors: { general: 'Error: Please check information and try again.' },
        });
        if (editAction.completed === 'false') {
          this.setState({ isExploding: true });
          setTimeout(() => {
            this.setState({ isExploding: false });
          }, 5000);
        } else if (editAction.completed === 'true') {
          this.setState({ isExploding: false });
          this.setState({ exploding: false });
        }
      });
  };

  confetti = (event) => {
    const actionID = event.target.getAttribute('id');
    const oldStatus = event.target.getAttribute('value');
    let newStatus;
    if (oldStatus === 'false') {
      newStatus = 'true';
    } else {
      newStatus = 'false';
    }
    const editAction = {
      completed: newStatus,
    };
    axios
      .post(`/editStatus/${actionID}`, editAction)
      .then((res) => {
        if (editAction.completed === 'true') {
          this.setState({ isExploding: true });
          setTimeout(() => {
            this.setState({ isExploding: false });
          }, 5000);
          this.runData();
          this.forceUpdate();
        } else if (editAction.completed === 'false') {
          this.runData();
          this.forceUpdate();
        }
      })
      .catch((err) => {
        console.log(err.response.data);
        this.setState({
          errors: { general: 'Error: Please check information and try again.' },
        });
        if (editAction.status === 'false') {
          this.setState({ exploding: true });
          setTimeout(() => {
            this.setState({ exploding: false });
          }, 5000);
        } else if (editAction.status === 'true') {
          this.setState({ exploding: false });
          this.setState({ isExploding: false });
        }
      });
  };

  render() {
    if (this.state.loading) return null;
    return (
      <>
        <div className='row justify-content-center confetti-row'>
          {this.state.isExploding && (
            <ConfettiExplosion force='0' floorHeight='3000' duration='7000' />
          )}
          {this.state.exploding && (
            <ConfettiExplosion force='0' floorHeight='3000' duration='7000' />
          )}
        </div>
        <Page
          content={
            <div className='actions-page'>
              <ActionComponent
                ninety={this.state.ninety}
                actions={this.state.actions}
                confetti={this.confetti}
                confettiSlider={this.confettiSlider}
              />
              <PriorityKey />
            </div>
          }
        />
      </>
    );
  }
}

export default Actions;
