import React, { useEffect } from 'react';
import './actions.css';
import { analytics } from '../../firebaseConfig.js';
import ModalCloseBtn from '../../Constants/ModalCloseBtn';

const Help = (props) => {
  useEffect(() => {
    let currentSection;
    if (props.section === 'your Profile') {
      currentSection = 'profile';
    } else if (props.section === 'Check-Ins') {
      currentSection = 'check';
    } else if (props.section === 'Actions') {
      currentSection = 'actions';
    } else if (props.section === 'Success Rocket') {
      currentSection = 'sRocket';
    } else {
      currentSection = 'insights';
    }
    analytics.logEvent(`${currentSection}_help_audio`);
  }, [props.section]);

  const pauseAudio = () => {
    const audioElement = document.getElementById('audioPlayer');
    if (audioElement) {
      audioElement.pause();
    }
  };

  return (
    <>
      <div
        className='modal '
        id='helpModal'
        tabIndex='-1'
        role='dialog'
        aria-labelledby='exampleModalCenterTitle'
        aria-hidden='true'
      >
        <div className='col-xs-2'>
          <div className='modal-arrow' id='top'></div>
        </div>
        <div className='modal-dialog modal-dialog-centered' role='document'>
          <div className='modal-content main-action'>
            <div className='modal-header'>
              <h5 className='modal-title' id='exampleModalLongTitle'>
                {` Need Help with ${props.section}?`}
              </h5>
              <ModalCloseBtn pauseAudio={pauseAudio} />
            </div>
            <div className='modal-body'>
              <div className='row'>
                <p className='action-label'>
                  {!props.text
                    ? `Listen below for useful tips on how to properly utilize the ${props.section} Section.`
                    : props.text}
                </p>
              </div>
              <div className='row'>
                <audio
                  id='audioPlayer'
                  controls
                  controlsList='nodownload noplaybackrate'
                >
                  <source src={props.source} type='audio/mp3' />
                </audio>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Help;
