import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import NewShout from './NewShout';
import { DEFAULT_IMAGE_URL } from '../../Constants/Constants';
import ModalCloseBtn from '../../Constants/ModalCloseBtn';

const ViewShoutToMe = (props) => {
  const [loading, setLoading] = useState(true);
  const [shoutOut, setShoutout] = useState();

  useEffect(() => {
    // TODO: This is causing a noticable 404 error
    axios
      .get(`/getShoutoutByID/${props.shoutId}`)
      .then((res) => {
        setShoutout(res.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error('ViewShoutToMe Error fetching shoutout:', error);
      });
  }, [props.shoutId]);

  if (loading) return null;

  return (
    <>
      <div
        className='modal '
        id={`viewShout${props.shoutId}`}
        tabIndex='-1'
        role='dialog'
        aria-labelledby='exampleModalCenterTitle'
        aria-hidden='true'
      >
        <div className='col-xs-2'>
          <div className='modal-arrow-sub' id='top'></div>
        </div>
        <div className='modal-dialog modal-dialog-centered' role='document'>
          <div className='modal-content shout-modal'>
            <div className='modal-header'>
              <h5 className='shout-header' id='exampleModalLongTitle'>
                Shout Out
              </h5>
              <ModalCloseBtn />
            </div>
            <div className='modal-body '>
              <div className='row shout-row'>
                <div className='col-3'>
                  <img
                    className='shout-img'
                    src={shoutOut.creatorImg || DEFAULT_IMAGE_URL}
                    alt='1'
                  />
                </div>
                <div className='col-9'>
                  <p className='shout-text'>{shoutOut.message}</p>
                  <p className='shout-text'>
                    <strong>- {shoutOut.creatorName}</strong> to You!
                  </p>
                  {/* <p className="likes-num">
                    {" "}
                    14
                    <FontAwesomeIcon
                      className="likes"
                      icon={["fal", "thumbs-up"]}
                    />
                  </p> */}
                </div>
              </div>
            </div>
            <div className='modal-footer'>
              <button
                id={`newShoutOutPopover${shoutOut.creatorId}`}
                className=''
                type='button'
                data-toggle='modal'
                data-target={`#newShoutOut${shoutOut.creatorId}`}
              >
                <FontAwesomeIcon
                  className='shout-icon text-light'
                  icon={['fal', 'plus-circle']}
                />
              </button>
              <NewShout id={shoutOut.creatorId} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewShoutToMe;
