import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle, faSortDown } from '@fortawesome/pro-solid-svg-icons';
import EMain from '../../Components/Actions/EMain';
import ESub from '../../Components/Actions/ESub';
import InsideSub from '../../Components/Actions/InsideSub';
import EditSub from '../../Components/Actions/EditSub';
import EditMain from '../../Components/Actions/EditMain';
import RateSub from '../../Components/Actions/RateSub';
import { Link } from 'react-router-dom';
import HelpBtn from './HelpBtn';

export function ActionComponent({
  ninety,
  actions = [],
  confettiSlider,
  manager = false,
  team = [],
}) {
  return (
    <>
      <div className='row'>
        <div className='col-10'>
          <h1>Actions</h1>
        </div>
        <div className='col-2 d-flex align-items-center'>
          <HelpBtn source='../Audio/actionsaudio2.mp3' section='Actions' />
        </div>
      </div>
      <div>
        <button
          id='createMainActionPopoverE'
          className='action-btn'
          type='button'
          data-toggle='modal'
          data-target='#mainAction'
        >
          <FontAwesomeIcon
            className='past-icon'
            icon={['fal', 'plus-circle']}
          />
          Create Main Action
        </button>
      </div>
      <div>
        <button
          id='createSubActionPopoverE'
          className='action-btn'
          type='button'
          data-toggle='modal'
          data-target='#subAction'
        >
          <FontAwesomeIcon
            className='past-icon'
            icon={['fal', 'plus-circle']}
          />
          Create Sub Action
        </button>
      </div>

      <EMain onboardPlan={ninety} />

      <ESub onboardPlan={ninety} />

      <div className={manager ? 'row actions-display' : 'actions-display'}>
        <div className={manager ? 'col-md-6' : ''}>
          <div className='actions row'>
            <h4 className='my-actions-header'>My Actions</h4>
            {actions.map((action, index) => (
              <>
                <div className='row' key={action.id}>
                  <div className='col-7 action-zindex'>
                    <p className='action-p'>
                      <button
                        className='btn btn-main-action collapsed'
                        type='button'
                        data-toggle='collapse'
                        data-target={`#collapse${action.id}`}
                        aria-expanded='false'
                        aria-controls='collapseExample'
                      >
                        <p className='action-sort'>
                          <FontAwesomeIcon
                            className='sort-btn'
                            icon={faSortDown}
                          />{' '}
                          <FontAwesomeIcon
                            className={`priority-icon-${action.priority}`}
                            icon={faCircle}
                          />{' '}
                          {action.main}
                        </p>
                      </button>
                    </p>
                  </div>
                  <div className='col-5'>
                    <div className='form-check form-check-inline'>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        defaultChecked={action.status ? true : false}
                        onClick={confettiSlider}
                        id={action.id}
                        value={action.status}
                      />
                      <label className='form-check-label'></label>
                    </div>
                    <button
                      key={index}
                      id={`edit${action.id}btn`}
                      className=''
                      type='button'
                      data-toggle='modal'
                      data-target={`#edit${action.id}`}
                    >
                      <div className='the-sub-action'>
                        <FontAwesomeIcon
                          className='main-pencil'
                          icon={['fal', 'pencil']}
                        />
                      </div>
                    </button>
                    <EditMain
                      action={action.main}
                      description={action.description}
                      priority={action.priority}
                      dDate={action.dDate}
                      status={action.status}
                      id={action.id}
                    />
                  </div>
                </div>

                <div className='collapse' id={`collapse${action.id}`}>
                  <div className='card action-card-body'>
                    <div className='sub-actions'>
                      {action.sub.map((sub, index) => (
                        <div className='row' key={sub.id}>
                          <div
                            className={
                              index % 2 ? 'white-bg col-8' : 'grey-bg col-8'
                            }
                          >
                            <button
                              id={`sub${sub.id}btn`}
                              className=''
                              type='button'
                              data-toggle='modal'
                              data-target={`#edit${sub.id}`}
                            >
                              <div className='the-sub-action'>
                                <FontAwesomeIcon
                                  className='pencil-icon'
                                  icon={['fal', 'pencil']}
                                />
                                {sub.name}
                              </div>
                            </button>
                            <EditSub
                              id={sub.id}
                              title={sub.name}
                              main={action.main}
                              status={sub.status}
                              priority={sub.priority}
                              dDate={sub.dDate}
                            />
                          </div>
                          <div className='col-4 pt-1'>
                            <div className='form-check form-check-inline'>
                              <input
                                className='form-check-input'
                                type='checkbox'
                                defaultChecked={action.status ? true : false}
                                onClick={confettiSlider}
                                id={action.id}
                                value={action.status}
                              />
                              <label className='form-check-label'></label>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                    <div>
                      <button
                        id={`inside${action.id}btn`}
                        className='action-btn inside-action'
                        type='button'
                        data-toggle='modal'
                        data-target={`#inside${action.id}`}
                      >
                        <FontAwesomeIcon
                          className='past-icon'
                          icon={['fal', 'plus-circle']}
                        />
                        Create Sub Action
                      </button>
                    </div>
                    <InsideSub
                      key={action.id}
                      title={action.main}
                      name={action.name}
                      id={action.id}
                      onboardPlan={ninety}
                    />
                  </div>
                </div>
              </>
            ))}
          </div>
        </div>
        {manager && (
          <>
            <div className='col-md-6'>
              <div className='actions row'>
                <h4 className='my-actions-header'>My Team's Actions</h4>
                {team.map((action, index) => (
                  <>
                    <div className='row ' key={action.id}>
                      <div className='col-7 action-zindex'>
                        <p className='action-p'>
                          <button
                            className='button button-main-action collapsed'
                            type='button'
                            data-toggle='collapse'
                            data-target={`#collapse${action.id}`}
                            aria-expanded='false'
                            aria-controls='collapseExample'
                          >
                            <p className='action-sort'>
                              <FontAwesomeIcon
                                className='sort-btn'
                                icon={faSortDown}
                              />{' '}
                              <FontAwesomeIcon
                                className={`priority-icon-${action.priority}`}
                                icon={faCircle}
                              />{' '}
                              {action.main}
                            </p>
                          </button>
                        </p>
                      </div>
                      <div className='col-5'>
                        <div className='form-check form-check-inline'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            defaultChecked={action.status ? true : false}
                            onClick={confettiSlider}
                            id={action.id}
                            value={action.status}
                            disabled
                          />
                          <label className='form-check-label'></label>
                        </div>
                        <RateSub
                          title={action.main}
                          status={action.status}
                          id={action.id}
                          userId={action.userId}
                          name={action.name}
                          main={action.main}
                          priority={action.priority}
                          sub={false}
                          description={action.description}
                          dDate={action.dDate}
                        />
                      </div>
                    </div>

                    <div className='collapse' id={`collapse${action.id}`}>
                      <div className='card action-card-body'>
                        <div className='sub-actions'>
                          {action.sub.map((subA, index) => (
                            <div className='row' key={subA.id}>
                              <div
                                className={
                                  index % 2 ? 'white-bg col-8' : 'grey-bg col-8'
                                }
                              >
                                <button
                                  id={`edit${subA.id}btn`}
                                  type='button'
                                  data-toggle='modal'
                                  data-target={`#edit${subA.id}`}
                                >
                                  <div className='the-sub-action'>
                                    {/* <FontAwesomeIcon
                                            className='pencil-icon'
                                            icon={[
                                              'fal',
                                              subA.status ? 'check' : 'circle',
                                            ]}
                                          /> */}
                                    {subA.name}
                                  </div>
                                </button>

                                <RateSub
                                  userId={subA.userId}
                                  title={subA.name}
                                  status={subA.status}
                                  id={subA.id}
                                  name={action.name}
                                  main={action.main}
                                  dDate={subA.dDate}
                                  sub={true}
                                />
                              </div>
                              <div className='col-4 pt-1'>
                                <div className='form-check form-check-inline'>
                                  <input
                                    className='form-check-input'
                                    type='checkbox'
                                    defaultChecked={subA.status ? true : false}
                                    onClick={confettiSlider}
                                    id={action.id}
                                    value={action.status}
                                    disabled
                                  />
                                  <label className='form-check-label'></label>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </>
                ))}
              </div>
            </div>
          </>
        )}
        <div className='archive-btn-row mt-3'>
          <Link to='/archive'>
            <button type='button' className='btn btn-danger archive-btn'>
              Archive
            </button>
          </Link>
        </div>
      </div>
    </>
  );
}
