import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import React, { Component } from 'react';

class ManagerNotes extends Component {
  constructor() {
    super();
    this.state = {
      notes: [],
      newNote: '',
      loading: true,
      errors: {},
    };
  }
  getNotes = () => {
    axios.get(`/getNotes/${this.props.id}`).then((res) => {
      const theNotes = res.data.note;
      if (!res.data.note) {
        const fakeNotes = [{ message: 'Create your first note below!' }];
        this.setState({ notes: fakeNotes });
        this.setState({ fake: true });
        this.setState({ loading: false });
      } else {
        this.setState({ fake: false });
        this.setState({ notes: theNotes });
        this.setState({ loading: false });
      }
    });
  };

  componentDidMount() {
    console.log(this.props.id);
    this.getNotes();

    // this.setState({notes: this.props.notes})
  }

  handleSubmit = (event) => {
    event.preventDefault();
    this.setState({
      loading: true,
    });
    const noteData = {
      message: this.state.newNote,
    };
    console.log(noteData);
    axios
      .post(`/postNote/${this.props.id}`, noteData)
      .then((res) => {
        this.setState({
          loading: false,
        });
        this.setState({
          newNote: '',
        });
        this.getNotes();
        this.forceUpdate();
      })
      .catch((err) => {
        console.log(err);
        // this.setState({
        //     errors: err.response.data,
        //     loading: false
        // });
      });
  };
  onNoteChange = (event) => {
    this.setState({
      newNote: event.target.value,
    });
  };
  deleteNote = (note) => {
    console.log(`delete ${note.message}`);
  };

  render() {
    if (this.state.loading) return null;
    return (
      <>
        <div className='note-card'>
          <h1 className='notes-header'>Notes</h1>
          <div className='lines'></div>
          <ul className='note-list'>
            {this.state.notes.map((note, index) => (
              <>
                <li key={index}>
                  <div className='row row-2'>
                    <div className='col-10' id='notesMessage'>
                      {note.message}
                    </div>
                    <div className='col-1'>
                      {this.state.fake ? (
                        <div></div>
                      ) : (
                        <button
                          className='delete-note'
                          type='button'
                          target='#notesMessage'
                          onClick={() => this.deleteNote(note)}
                        >
                          <FontAwesomeIcon
                            className='shout-icon text-danger'
                            icon={['fas', 'trash']}
                          />
                        </button>
                      )}
                    </div>
                  </div>
                </li>
              </>
            ))}

            <li>
              <form className='form-inline'>
                <div className='row row-2'>
                  <div className='col-8 p-0'>
                    <div className='form-group note-form'>
                      <input
                        onChange={this.onNoteChange}
                        value={this.state.newNote}
                        type='text'
                        className=' note-input'
                        id='exampleInputEmail1'
                        aria-describedby='newNote'
                        placeholder='New note...'
                      />
                    </div>
                  </div>
                  <div className='col-4 p-0'>
                    <button
                      type='submit'
                      onClick={this.handleSubmit}
                      className=''
                    >
                      <FontAwesomeIcon
                        className='shout-icon text-dark'
                        icon={['fal', 'plus-circle']}
                      />
                    </button>
                  </div>
                </div>
              </form>
            </li>
          </ul>
        </div>
      </>
    );
  }
}

export default ManagerNotes;
