import React, { useEffect } from 'react';
import Spinner from '../../Components/Loading/Spinner';

const CompanyRedirect = (props) => {
  useEffect(() => {
    props.history.push('/company');
    console.log('Redirecting...');
  }, [props.history]);

  return <Spinner />;
};

export default CompanyRedirect;
