import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Page from '../../Components/NavBars/Page';
import { faExclamationCircle } from '@fortawesome/pro-solid-svg-icons';
import axios from 'axios';
import HelpBtn from '../../Components/Actions/HelpBtn';

class EmployeeCheckIn extends Component {
  constructor() {
    super();
    this.state = {
      checkIns: [],
      id: '',
      next: ' ',
      loading: true,
      errors: {},
      usersId: '',
    };
  }

  componentDidMount() {
    axios
      .get('/getCheckins')
      .then((res) => {
        if (res.data.length === 0) {
          console.log('no checkins');
          this.setState({ loading: false });
        } else {
          const data = res.data;
          let check = [];
          for (let i = 0; i < data.length; i++) {
            const checkins = data[i].checkin;
            const date = data[i].checkin[checkins.length - 1].created.substring(
              0,
              10,
            );

            const status = data[i].checkin[checkins.length - 1].response;
            check.push({ date: date, id: data[i].checkinId, status: status });
          }
          this.setState({
            checkIns: res.data[0].checkin.sort(
              (a, b) => Date.parse(b.created) - Date.parse(a.created),
            ),
            usersId: res.data[0].creatorId,
          });
        }
      })
      .catch((error) => {
        console.error('EmployeeCheckIn - Error fetching checkins:', error);

        this.setState({
          loading: false,
          error: 'An error occurred while fetching checkins.',
        });
      });

    axios
      .get('/user')
      .then((res) => {
        const data = res.data.credentials.overseer;
        if (data === '') {
          this.setState({ overseer: false });
        } else {
          this.setState({ overseer: true });

          const next = 'Today';
          this.setState({ next: next });
        }
        this.setState({ loading: false });
      })
      .catch((error) => {
        console.error('EmployeeCheckIn - Error fetching user data:', error);

        this.setState({
          loading: false,
          error: 'An error occurred while fetching user data.',
        });
      });
  }

  render() {
    if (this.state.loading) return null;
    return (
      <>
        <Page
          content={
            <div className='m-checkin-page'>
              <div className='row view-check'>
                <div className='row check-alert'>
                  <div className='col-9 col-md-10 '>
                    <p className='alert-text'>
                      <FontAwesomeIcon
                        className='alert-icon'
                        icon={faExclamationCircle}
                      />
                      <strong className='alert-p'>
                        {`Next Check-in: ${this.state.next}`}
                      </strong>
                    </p>
                  </div>
                </div>
                <div className='col-10'>
                  <Link to='/newcheck'>
                    <button
                      className='btn addcheck-btn'
                      type='button'
                      style={{ color: 'white', textDecoration: 'none' }}
                    >
                      + Add Check-In
                    </button>{' '}
                  </Link>
                </div>
                <div className='col-2'>
                  <HelpBtn
                    source='../Audio/checkinsaudio2.mp3'
                    section='Check-Ins'
                  />
                </div>
              </div>

              <>
                <h1>My Check-ins</h1>
                <div>
                  <table className='m-check-in-table mb-5'>
                    <thead>
                      <tr>
                        <th>Date</th>
                        <th>Response</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.checkIns.map((checkIn, index) => (
                        <tr key={index}>
                          <td>
                            <Link
                              className='link-btn'
                              to={{
                                pathname: `/response/${this.state.usersId}`,
                                state: {
                                  id: this.state.usersId,
                                  created: checkIn.created,
                                },
                              }}
                            >
                              {checkIn.created.substring(0, 10)}
                            </Link>
                          </td>
                          <td>{checkIn.response === false ? 'None' : 'Yes'}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </>
            </div>
          }
        />
      </>
    );
  }
}

export default EmployeeCheckIn;
