import React, { Component } from 'react';
import Page from '../../Components/NavBars/Page';
import './employee.css';
import { PieChart } from 'react-minimal-pie-chart';
import Momentum from '../../Components/Profile/Momentum';
import axios from 'axios';

class EmployeeDash extends Component {
  constructor() {
    super();
    this.state = {
      currentIndex: 0,
      totalActions: '',
      passedDue: '',
      complete: '',
      uncomplete: '',
      momentum: '',
      rating: '',
      se: '',
      loading: true,
      errors: {},
    };
  }

  componentDidMount() {
    axios
      .get('/getDashActions')
      .then((res) => {
        const thisData = res.data;

        const myMonth = thisData.filter(
          (action) =>
            new Date(action.created).getTime() <= new Date().getTime() &&
            new Date(action.created).getTime() >=
              new Date().getTime() - 30 * 24 * 60 * 60 * 1000,
        );
        const myThree = thisData.filter(
          (action) =>
            new Date(action.created).getTime() <= new Date().getTime() &&
            new Date(action.created).getTime() >=
              new Date().getTime() - 90 * 24 * 60 * 60 * 1000,
        );
        const myYear = thisData.filter(
          (action) =>
            new Date(action.created).getTime() <= new Date().getTime() &&
            new Date(action.created).getTime() >=
              new Date().getTime() - 365 * 24 * 60 * 60 * 1000,
        );

        this.setState({
          totalActions: [
            thisData.length,
            myMonth.length,
            myThree.length,
            myYear.length,
          ],
        });

        const passedDue = thisData.filter(
          (action) =>
            new Date(action.dueDate).getTime() <= new Date().getTime() &&
            !action.completed,
        );

        this.setState({ passedDue: passedDue.length });

        const completedNums = [];
        const incompletedNums = [];
        let cNum = 0;
        let iNum = 0;

        for (let i = 0; i < thisData.length; i++) {
          if (thisData[i].completed === true) {
            cNum++;
          } else {
            iNum++;
          }
        }
        completedNums.push(cNum);
        incompletedNums.push(iNum);
        iNum = 0;
        cNum = 0;

        for (let i = 0; i < myMonth.length; i++) {
          if (myMonth[i].completed === true) {
            cNum++;
          } else {
            iNum++;
          }
        }
        completedNums.push(cNum);
        incompletedNums.push(iNum);
        iNum = 0;
        cNum = 0;

        for (let i = 0; i < myThree.length; i++) {
          if (myThree[i].completed === true) {
            cNum++;
          } else {
            iNum++;
          }
        }
        completedNums.push(cNum);
        incompletedNums.push(iNum);
        iNum = 0;
        cNum = 0;

        for (let i = 0; i < myYear.length; i++) {
          if (myYear[i].completed === true) {
            cNum++;
          } else {
            iNum++;
          }
        }
        completedNums.push(cNum);
        incompletedNums.push(iNum);
        iNum = 0;
        cNum = 0;

        this.setState({ complete: completedNums });
        this.setState({ uncomplete: incompletedNums });
      })
      .catch((error) => {
        console.error(
          'EmployeeDash - Error fetching dashboard actions:',
          error,
        );

        this.setState({
          error: 'An error occurred while fetching dashboard actions.',
        });
      });
    axios
      .get('/user')
      .then((res) => {
        const cred = res.data.credentials;
        let credRating = cred.rating;
        if (!isNaN(credRating)) {
          credRating = [{ date: '', rated: '5' }];
        }
        const last90 = credRating.filter(
          (rate) =>
            new Date(rate.date).getTime() >=
            new Date().getTime() - 90 * 24 * 60 * 60 * 1000,
        );

        const prev90 = credRating.filter(
          (rate) =>
            new Date(rate.date).getTime() <=
              new Date().getTime() - 90 * 24 * 60 * 60 * 1000 &&
            new Date(rate.date).getTime() >=
              new Date().getTime() - 180 * 24 * 60 * 60 * 1000,
        );

        let last90sum = 0;
        let prev90sum = 0;

        last90.forEach((rating) => {
          last90sum += rating.rated;
        });

        prev90.forEach((rating) => {
          prev90sum += rating.rated;
        });

        let momentum = 0;
        if (prev90sum > 0) {
          momentum = last90sum / prev90sum;
        } else {
          momentum = last90sum;
        }
        this.setState({ momentum: momentum });
        let totalNum = 0;
        for (let j = 0; j < credRating?.length; j++) {
          const rating = credRating[j].rated;
          // const date = credRating[j].date;
          totalNum = totalNum + rating;
        }
        const finalNum = totalNum / credRating?.length;

        if (finalNum <= 1) {
          this.setState({ rating: '☆ ☆ ☆ ☆ ☆' });
        } else if (finalNum <= 2) {
          this.setState({ rating: '★ ☆ ☆ ☆ ☆' });
        } else if (finalNum <= 3) {
          this.setState({ rating: '★ ★ ☆ ☆ ☆' });
        } else if (finalNum <= 4) {
          this.setState({ rating: '★ ★ ★ ☆ ☆' });
        } else if (finalNum < 5) {
          this.setState({ rating: '★ ★ ★ ★ ☆' });
        } else {
          this.setState({ rating: '★ ★ ★ ★ ★' });
        }
        totalNum = 0;

        this.setState({ se: cred.successEngagements });
        this.setState({ loading: false });
      })
      .catch((error) => {
        console.error('EmployeeDash - Error fetching user data:', error);

        this.setState({
          loading: false,
          error: 'An error occurred while fetching user data.',
        });
      });
  }

  setCurrentIndex = (indexNum) => {
    this.setState({ currentIndex: indexNum });
  };

  render() {
    if (this.state.loading) return null;
    return (
      <>
        <Page
          content={
            <div className='dash-container'>
              <h1 className='dash-header'> Dashboard</h1>
              <div className='row time-btn-row'>
                <div className='col-3 p-0'>
                  <button
                    className='btn btn-1m'
                    onClick={() => this.setCurrentIndex(1)}
                  >
                    1M
                  </button>
                </div>
                <div className='col-3 p-0'>
                  <button
                    className='btn btn-3m'
                    onClick={() => this.setCurrentIndex(2)}
                  >
                    3M
                  </button>
                </div>
                <div className='col-3 p-0'>
                  <button
                    className='btn btn-1y'
                    onClick={() => this.setCurrentIndex(3)}
                  >
                    1Y
                  </button>
                </div>
                <div className='col-3 p-0'>
                  <button
                    className='btn btn-A'
                    onClick={() => this.setCurrentIndex(0)}
                  >
                    ALL
                  </button>
                </div>
              </div>
              <div className='dash-row row'>
                <div className='col-6 dash-col'>
                  <div className='actions-box text-center'>
                    <p className='action-text'>Total Actions</p>
                    <p className='action-num'>
                      {this.state.totalActions[this.state.currentIndex]}
                    </p>
                  </div>
                </div>
                <div className='col-6 dash-col-2'>
                  <div className='past-box text-center'>
                    <p className='action-text'>
                      Past Due <small className='all-time'>All TIME</small>
                    </p>

                    <p className='action-num'>{this.state.passedDue}</p>
                  </div>
                </div>
              </div>

              <div className='action-status-row row'>
                <div className='action-status'>
                  <h4 className='action-status-header'>My Action Status</h4>
                  <div className='pie-chart'>
                    <PieChart
                      animation
                      animationDuration={500}
                      animationEasing='ease-out'
                      center={[60, 60]}
                      data={[
                        {
                          color: '#1d4b24',
                          title: 'Un-Completed',
                          value: this.state.uncomplete[this.state.currentIndex],
                          label: `${
                            this.state.uncomplete[this.state.currentIndex]
                          }`,
                        },
                        {
                          color: '#388c44',
                          title: 'Completed',
                          value: this.state.complete[this.state.currentIndex],
                          label: `${
                            this.state.complete[this.state.currentIndex]
                          }`,
                        },
                      ]}
                      label={(data) => data.dataEntry.value}
                      labelPosition={65}
                      labelStyle={{
                        fontSize: '5px',
                        fontFamily: 'sans-serif',
                        fill: 'rgb(255 255 255)',
                      }}
                      segmentsShift={1}
                      lengthAngle={360}
                      lineWidth={60}
                      paddingAngle={0}
                      radius={50}
                      startAngle={0}
                      viewBoxSize={[120, 120]}
                    />
                  </div>
                  <div className='row chart-label-row text-center'>
                    <div className='col-6'>
                      <div className='completed-text'>█ Completed</div>
                    </div>
                    <div className='col-6'>
                      <div className=' not-completed-text'>█ Not Completed</div>
                    </div>
                  </div>
                </div>
              </div>

              <div className='action-status-row row'>
                <div className='action-small'>
                  <div className='row'>
                    <div className='col-3'>
                      <h5 className='action-status-header'>My Rating</h5>
                    </div>
                    <div className='col-9'>
                      <p className='rating-stars'>{this.state.rating}</p>
                    </div>
                  </div>
                </div>
              </div>

              <div className='action-status-row row'>
                <div className='action-small'>
                  <div className='row'>
                    <div className='col-3'>
                      <h5 className='action-status-header'>Momentum</h5>
                    </div>
                    <div className='col-9'>
                      <Momentum momentum={this.state.momentum} />
                    </div>
                  </div>
                </div>
              </div>

              <div className='action-status-row row'>
                <div className='action-small'>
                  <div className='row'>
                    <div className='col-10'>
                      <h5 className='action-status-header'>
                        Success Engagement Conversations
                      </h5>
                    </div>
                    <div className='col-2'>
                      <div className='se-num'>{this.state.se}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          }
        />
      </>
    );
  }
}

export default EmployeeDash;
