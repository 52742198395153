import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { analytics } from '../../firebaseConfig.js';
import { logoutUser } from '../../Redux/actions/userActions';
import { withRouter } from 'react-router-dom';

const SideNav = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const takeAnalytics = (page) => {
    let type = props.isManager ? 'm' : 'e';

    if (page !== 'no') {
      analytics.logEvent(`${type}_${page}_visited`);
    }
  };

  const handleLogout = () => {
    analytics.logEvent(`user_logged_out`);
    dispatch(logoutUser(history));
  };

  const menuItems = [
    { to: '/focus', icon: 'bullseye', text: 'Focus', page: 'focus' },
    { to: '/actions', icon: 'tasks', text: 'Actions', page: 'actions' },
    { to: '/feedback', icon: 'sync', text: 'Insights', page: 'insights' },
    { to: '/check-in', icon: 'badge-check', text: 'Check-In', page: 'check' },
    { to: '/notif', icon: 'bell', text: 'Notifications', page: 'notif' },
    { to: '/dashboard', icon: 'chart-line', text: 'Dashboard', page: 'dash' },
    { to: '/mentor', icon: 'user-chart', text: 'Mentor', page: 'mentor' },
    { to: '/directory', icon: 'users', text: 'Directory', page: 'directory' },
  ];

  const renderMenuItems = () => {
    return (
      <>
        {menuItems.map((item, index) => (
          <Link
            key={index}
            to={item.to}
            className='no-underline'
            onClick={() => takeAnalytics(item.page)}
          >
            <div className='nav-block text-center' key={item.to}>
              <FontAwesomeIcon
                icon={['fal', item.icon]}
                className='desktop-icon icon float-center'
              />
              <p className='icon-text'>{item.text}</p>
            </div>
          </Link>
        ))}
        {/* Logout Button */}
        <button
          type='button'
          className='nav-block logout-btn text-center m-auto w-100 p-0'
          onClick={(e) => handleLogout()}
        >
          <FontAwesomeIcon
            className='desktop-icon icon float-center'
            icon={['fal', 'sign-out']}
          />
          <p className='icon-text'>Logout</p>
        </button>
      </>
    );
  };

  return (
    <nav id='sidebar' className='col bg-light fixed-top'>
      <div className='col sidebar vh-100 d-flex flex-column flex-wrap justify-content-between position-fixed bg-light'>
        <ul className='nav flex-column'>{renderMenuItems()}</ul>
        {/* Logo */}
        <div className='text-center w-100'>
          <img
            className='sidenav-logo'
            src='../Images/sidebar-logo.png'
            alt='Logo'
          />
        </div>
      </div>
    </nav>
  );
};

export default withRouter(SideNav);
