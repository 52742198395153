import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import NewSuccessEng from '../Actions/NewSuccessEng';
import Momentum from './Momentum';
import RemoveSuccessEng from '../Actions/RemoveSuccessEng';

const ProfInfo = (props) => {
  let manager;
  if (props.manager === '') {
    manager = 'None';
  } else {
    manager = props.manager;
  }

  return (
    <>
      <div className='col-md-5 prof-info-card'>
        <div className='text-center'>
          <img
            src={props.profileImg}
            className='my-profile-img'
            alt='Example'
          />{' '}
        </div>
        <div className='prof-info-box'>
          <div className='row'>
            <div className='col-10'>
              <h1 className='prof-info-header'>Profile Information</h1>
            </div>
            <div className='col-2'>
              <Link to='/editProfile'>
                <FontAwesomeIcon
                  className='edit-prof-icon text-dark'
                  icon={['fal', 'pencil']}
                />
              </Link>
            </div>
          </div>

          <div className='row prof-info-row'>
            <div className='col-4'>
              <p className='prof-info-title'>Name</p>
            </div>
            <div className='col-8'>
              <p className='prof-info-text'>{props.name}</p>
            </div>
            <hr className='prof-info-hr'></hr>
          </div>

          <div className='row prof-info-row'>
            <div className='col-4'>
              <p className='prof-info-title'>Phone Number</p>
            </div>
            <div className='col-8'>
              <p className='prof-info-text'>{props.phone}</p>
            </div>
            <hr className='prof-info-hr'></hr>
          </div>

          <div className='row prof-info-row'>
            <div className='col-4'>
              <p className='prof-info-title'>Email</p>
            </div>
            <div className='col-8'>
              <p className='prof-info-text'>{props.email}</p>
            </div>
            <hr className='prof-info-hr'></hr>
          </div>

          <div className='row prof-info-row'>
            <div className='col-4'>
              <p className='prof-info-title'>Manager</p>
            </div>
            <div className='col-8'>
              <p className='prof-info-text'>{manager}</p>
            </div>
            <hr className='prof-info-hr'></hr>
          </div>

          <div className='row prof-info-row'>
            <div className='col-4'>
              <p className='prof-info-title'>Momentum</p>
            </div>
            <div className='col-8'>
              <div className='prof-info-text'>
                <Momentum momentum={props.momentum} />
              </div>
            </div>
            <hr className='prof-info-hr'></hr>
          </div>

          <div className='row prof-info-row'>
            <div className='col-4'>
              <p className='prof-info-title'>Rating</p>
            </div>
            <div className='col-8'>
              <p className='prof-info-text'>{props.rating}</p>
            </div>
            <hr className='prof-info-hr'></hr>
          </div>

          <div className='row prof-info-row'>
            <div className='row'>
              <div className='col-10 '>
                <p
                  className='prof-info-title'
                  style={{ display: 'inline-block' }}
                >
                  Success Engagement Conversations
                </p>
                <div style={{ display: 'inline-block' }} className='se-num'>
                  {props.se}
                </div>
              </div>

              <div className='col-1'>
                <button
                  id='newSuccessEngPopover'
                  className=''
                  type='button'
                  data-toggle='modal'
                  data-target='#NewSuccessEng'
                >
                  <FontAwesomeIcon
                    className='shout-icon text-dark'
                    icon={['fal', 'plus-circle']}
                  />
                </button>
                <NewSuccessEng />
              </div>
              <div className='col-1'>
                <button
                  id='removeSuccessEngPopover'
                  className=''
                  type='button'
                  data-toggle='modal'
                  data-target='#RemoveSuccessEng'
                >
                  <FontAwesomeIcon
                    className='shout-icon text-dark'
                    icon={['fal', 'minus-circle']}
                  />
                </button>
                <RemoveSuccessEng />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProfInfo;
