import React, { useState, useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import CheckoutForm from './CheckoutForm';
import axios from 'axios';
import './payments.css';

// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
// This is your test publishable API key.
// Test const stripePromise = loadStripe("pk_test_51M1FuuC20oaERGpOK4IOUt1GLznPfOo01y1FvsOsbBkgKA641OgvzcYkQ6QYfagmwbf3bpcsdHvWT6Li7cmk934w00X50j6ZOS");
const stripePromise = loadStripe(
  'pk_live_51M1FuuC20oaERGpOxTkcT1Hbl8B4vgKXDGbZZ3DSDqMv7kquRHvKwCjkdIvtYxGgpRSO9IG8j4HAyqW0GW06UOhd003XZKgjI5',
);

const PayDue = (props) => {
  const [clientSecret, setClientSecret] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Create PaymentIntent as soon as the page loads
    const data = {
      employees: props.employees,
      price: props.price,
      stripeId: props.stripeId,
    };
    axios.post('/api/create-payment-intent', data).then((data) => {
      setClientSecret(data.data.clientSecret);
      setLoading(false);
    });
  }, [props]);

  const appearance = {
    theme: 'stripe',
  };
  const options = {
    clientSecret,
    appearance,
  };

  if (loading) return null;

  return (
    <div className='stripe-element-pay'>
      {clientSecret && (
        <Elements options={options} stripe={stripePromise}>
          <CheckoutForm userData={props.userData} />
        </Elements>
      )}
    </div>
  );
};

export default PayDue;
