import axios from 'axios';
import React, { Component } from 'react';
import $ from 'jquery';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import Select from 'react-select';
import { faCircle } from '@fortawesome/pro-solid-svg-icons';
import ModalCloseBtn from '../../Constants/ModalCloseBtn';

const today = moment().format('YYYY-MM-DD');

const formOptions = [
  {
    data: 'low',
    label: (
      <div>
        {' '}
        <FontAwesomeIcon
          className='priority-icon-low'
          icon={faCircle}
        /> Low{' '}
      </div>
    ),
  },
  {
    data: 'medium',
    label: (
      <div>
        {' '}
        <FontAwesomeIcon
          className='priority-icon-medium'
          icon={faCircle}
        />{' '}
        Medium
      </div>
    ),
  },
  {
    data: 'high',
    label: (
      <div>
        <FontAwesomeIcon className='priority-icon-high' icon={faCircle} /> High
      </div>
    ),
  },
  {
    data: 'critical',
    label: (
      <div>
        <FontAwesomeIcon className='priority-icon-critical' icon={faCircle} />{' '}
        Critical
      </div>
    ),
  },
];

class InsideSub extends Component {
  constructor() {
    super();
    this.state = {
      id: '',
      main: '',
      action: '',
      priority: '',
      dDate: '',
      status: '',
      checkBox: false,
      checkBox2: false,
      loading: true,
      errors: {},
    };
  }

  componentDidMount() {
    this.setState({ id: this.props.id });
    this.setState({ main: this.props.title });

    this.setState({ loading: false });
  }

  handleSubmit = (event) => {
    event.preventDefault();
    this.setState({
      loading: true,
    });
    let checkbox2 = this.state.checkBox2;
    if (this.props.onboardPlan === false) {
      checkbox2 = true;
    }
    const newSubAction = {
      mainActionTitle: this.state.main,
      title: this.state.action,
      priority: this.state.priority.data,
      dueDate: this.state.dDate,
    };
    console.log(newSubAction);
    if (
      newSubAction.title === '' ||
      newSubAction.priority === '' ||
      newSubAction.dueDate === ''
    ) {
      this.setState({
        errors: { general: 'Error: Please check information and try again.' },
        loading: false,
      });
    } else {
      if (this.state.checkBox === false) {
        this.setState({
          errors: {
            general:
              'Error: Please confirm this action aligns with your Success Criteria.',
          },
          loading: false,
        });
      } else if (checkbox2 === false) {
        this.setState({
          errors: {
            general:
              'Error: Please confirm this action aligns with your Onboarding Plan.',
          },
          loading: false,
        });
      } else {
        axios
          .post(`/subaction/${this.state.id}`, newSubAction)
          .then((res) => {
            this.setState({
              loading: false,
            });

            window.location.reload();
          })
          .catch((err) => {
            console.log(err.response.data);
            this.setState({
              errors: {
                general: 'Error: Please check information and try again.',
              },
              loading: false,
            });
            $(`#inside${this.state.id}btn`).trigger('click');
          });
      }
    }
  };

  onActionChange = (event) => {
    this.setState({
      action: event.target.value,
    });
  };
  onPriorityChange = (value) => {
    this.setState({
      priority: value,
    });
  };
  onDateChange = (event) => {
    this.setState({
      dDate: event.target.value,
    });
  };
  checkBoxChange = (event) => {
    this.setState({
      checkBox: event.target.checked,
    });
  };
  checkBox2Change = (event) => {
    this.setState({
      checkBox2: event.target.checked,
    });
  };

  render() {
    if (this.state.loading) return null;
    return (
      <>
        <div
          className='modal '
          id={`inside${this.state.id}`}
          tabIndex='-1'
          role='dialog'
          aria-labelledby='exampleModalCenterTitle'
          aria-hidden='true'
        >
          <div className='col-xs-2'>
            <div className='modal-arrow-sub' id='top'></div>
          </div>
          <div className='modal-dialog modal-dialog-centered' role='document'>
            <div className='modal-content sub-action'>
              <div className='modal-header'>
                <h5 className='modal-title' id='exampleModalLongTitle'>
                  Sub Action
                </h5>
                <ModalCloseBtn />
              </div>
              <div className='modal-body'>
                <form>
                  <div className='form-group disabled-input'>
                    <label
                      htmlFor='exampleFormControlInput5'
                      className='action-label'
                    >
                      Main Action
                    </label>
                    <div className=''>
                      <div className=' action-input-col'>
                        <select className='form-select action-input' disabled>
                          <option>{this.state.main}</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  {/* <div className="form-group disabled-input">
                    <label htmlFor="exampleFormControlInput1" className="action-label">
                      Employee Name
                    </label>
                    <div className="">
                      <div className=" action-input-col">
                        <select
                          className="form-select action-input selectPicker"
                          
                          data-live-search="true"
                          disabled
                        >
                          <option>{this.state.name}</option>
                        </select>
                      </div>
                    </div>
                  </div> */}
                  <div className='form-group'>
                    <label
                      htmlFor='exampleFormControlInput3'
                      className='action-label'
                    >
                      Action
                    </label>
                    <input
                      onChange={this.onActionChange}
                      value={this.state.action}
                      type='text'
                      className='form-control action-input'
                      placeholder=''
                    />
                  </div>
                  <div className='form-group'>
                    <label
                      htmlFor='exampleFormControlInput5'
                      className='action-label'
                    >
                      Priority
                    </label>
                    <div className='row'>
                      <div className='col-11 action-input-col'>
                        <Select
                          onChange={this.onPriorityChange.bind(this)}
                          className='action-selector'
                          value={this.state.priority}
                          options={formOptions}
                        />
                        {/* <select
                        onChange={this.onPriorityChange} value={this.state.priority}
                          className="form-select action-input"
                          
                        >
                          <option></option>
                          <option value='low'>🟡‎‎ ‏‏‎ ‎‏‏‎ ‎Low</option>
                          <option value="medium">🟢‏‏‎ ‎‏‏‎ ‎ Medium</option>
                          <option value="high">🔵 ‏‏‎ ‎‏‏‎ ‎High</option>
                          <option value="critical">🔴 ‏‏‎ ‎‏‏‎ ‎Critical</option>
                        </select> */}
                      </div>
                      <div className='col-1 action-icon-col'>
                        <span className='action-icon'>
                          <FontAwesomeIcon
                            className='action-i form-control-feedback'
                            icon={['fal', 'chevron-down']}
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className='form-group'>
                    <label
                      htmlFor='exampleFormControlInput6'
                      className='action-label'
                    >
                      Due Date
                    </label>
                    <input
                      onChange={this.onDateChange}
                      value={this.state.dDate}
                      type='date'
                      className='form-control action-input'
                      placeholder=''
                      min={today}
                    />
                  </div>
                  <div className='form-check'>
                    <input
                      type='checkbox'
                      className='form-check-input form-check-success'
                      id='exampleCheck1'
                      value={this.state.checkBox}
                      onClick={this.checkBoxChange}
                    />
                    <label
                      className='form-check-label action-label'
                      htmlFor='exampleCheck1'
                    >
                      This action aligns with my Success Criteria
                    </label>
                  </div>
                  {this.props.onboardPlan ? (
                    <div className='form-check'>
                      <input
                        type='checkbox'
                        className='form-check-input form-check-success'
                        id='exampleCheck2'
                        value={this.state.checkBox2}
                        onClick={this.checkBox2Change}
                      />
                      <label
                        className='form-check-label action-label'
                        htmlFor='exampleCheck2'
                      >
                        This action aligns with my Onboarding Plan
                      </label>
                    </div>
                  ) : (
                    <div></div>
                  )}
                </form>
              </div>
              <p className='errors-p p-3'>{this.state.errors.general}</p>
              <div className='modal-footer'>
                <button
                  type='button'
                  onClick={this.handleSubmit}
                  className='btn btn-submit'
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default InsideSub;
