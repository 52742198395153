import React, { Component } from 'react';
import Page from '../../Components/NavBars/Page';
import ShoutCard from '../../Components/Profile/ShoutCard';
import UserSuccess from '../../Components/Profile/UserSuccess';
import './profile.css';
import axios from 'axios';
import EProfInfo from '../../Components/Profile/EProfInfo';
import HelpBtn from '../../Components/Actions/HelpBtn';
import { analytics } from '../../firebaseConfig.js';
import OnCard from '../../Components/Onboarding/OnCard';

class EmployeeProfile extends Component {
  constructor() {
    super();
    this.state = {
      id: '',
      name: '',
      phone: '',
      email: '',
      manager: '',
      overseer: '',
      birthday: '',
      ninety: '',
      momentum: '',
      code: '',
      rating: '',
      se: '',
      sc: {
        a1: '',
        a2: '',
        a3: '',
      },
      shoutOuts: [],
      loading: true,
      errors: {},
    };
  }

  componentDidMount() {
    axios
      .get('/user')
      .then((res) => {
        analytics.logEvent('e_prof_visited');
        const cred = res.data.credentials;
        const name = cred.firstName + ' ' + cred.lastName;
        const days = cred.created;
        const newDate = new Date(days);
        let ninetyDay = new Date(newDate.setDate(newDate.getDate() + 90));
        let today = new Date();
        if (ninetyDay > today) {
          this.setState({ ninety: true });
        } else {
          this.setState({ ninety: false });
        }
        const theShoutOuts = res.data.shoutouts;
        let credRating = cred.rating;
        if (!isNaN(credRating)) {
          credRating = [{ date: '', rated: '5' }];
        }
        const last90 = credRating.filter(
          (rate) =>
            new Date(rate.date).getTime() >=
            new Date().getTime() - 90 * 24 * 60 * 60 * 1000,
        );

        const prev90 = credRating.filter(
          (rate) =>
            new Date(rate.date).getTime() <=
              new Date().getTime() - 90 * 24 * 60 * 60 * 1000 &&
            new Date(rate.date).getTime() >=
              new Date().getTime() - 180 * 24 * 60 * 60 * 1000,
        );

        let last90sum = 0;
        let prev90sum = 0;

        last90.forEach((rating) => {
          last90sum += rating.rated;
        });

        prev90.forEach((rating) => {
          prev90sum += rating.rated;
        });

        let momentum = 0;
        if (prev90sum > 0) {
          momentum = last90sum / prev90sum;
        } else {
          momentum = last90sum;
        }
        this.setState({ momentum: momentum });

        let totalNum = 0;
        for (let j = 0; j < cred.rating?.length; j++) {
          const rating = cred.rating[j].rated;
          totalNum = totalNum + rating;
        }
        const finalNum = totalNum / cred.rating?.length;
        if (finalNum <= 1) {
          this.setState({ rating: '☆ ☆ ☆ ☆ ☆' });
        } else if (finalNum <= 2) {
          this.setState({ rating: '★ ☆ ☆ ☆ ☆' });
        } else if (finalNum <= 3) {
          this.setState({ rating: '★ ★ ☆ ☆ ☆' });
        } else if (finalNum <= 4) {
          this.setState({ rating: '★ ★ ★ ☆ ☆' });
        } else if (finalNum < 5) {
          this.setState({ rating: '★ ★ ★ ★ ☆' });
        } else {
          this.setState({ rating: '★ ★ ★ ★ ★' });
        }
        totalNum = 0;
        this.setState({ id: cred.id });
        this.setState({ name: name });
        this.setState({ first: cred.firstName });
        this.setState({ phone: cred.phone });
        this.setState({ email: cred.email });
        this.setState({ se: cred.successEngagements });
        this.setState({ sc: { a1: cred.a1, a2: cred.a2, a3: cred.a3 } });
        if (cred.a1 === '' || cred.a2 === '' || cred.a3 === '') {
          this.props.history.push('/editSuccess');
        }
        this.setState({ profileImg: cred.profileImg });
        this.setState({ manager: cred.overseerName });
        this.setState({ overseer: cred.overseer });
        this.setState({ code: cred.managerCode });
        this.setState({ shoutOuts: theShoutOuts });
        this.setState({ loading: false });
      })
      .catch((error) => {
        console.error('EmployeeProfile - Error fetching user data:', error);

        this.setState({
          loading: false,
          error: 'An error occurred while fetching user data.',
        });
      });
  }

  render() {
    if (this.state.loading) return null;
    return (
      <>
        <Page
          content={
            <div className='edit-profile'>
              <div className='row edit-col'>
                <div className='row mt-3 justify-content-center'>
                  <div className='col-8'>
                    {/* <a    href="/survey"
                    className="btn btn-success nps-btn"
                    type="button"
                  >
                   NPS Survey
                  </a> */}
                  </div>
                  <div className='col-2 text-end'>
                    <HelpBtn
                      source='../Audio/profileaudio2.mp3'
                      section='your Profile'
                      text='Listen below for useful tips on how to best utilize your Profile Section.'
                    />
                  </div>

                  {this.state.ninety ? (
                    <div className='row'>
                      <OnCard ninety={this.state.ninety} />
                    </div>
                  ) : (
                    <div></div>
                  )}

                  <EProfInfo
                    id={this.state.id}
                    profileImg={this.state.profileImg}
                    name={this.state.name}
                    phone={this.state.phone}
                    email={this.state.email}
                    manager={this.state.manager}
                    momentum={this.state.momentum}
                    code={this.state.code}
                    rating={this.state.rating}
                    se={this.state.se}
                    overseer={this.state.overseer}
                  />

                  <UserSuccess
                    first={this.state.first}
                    q1={this.state.sc.a1}
                    q2={this.state.sc.a2}
                    q3={this.state.sc.a3}
                  />
                </div>

                <div className='row'>
                  <ShoutCard shout={this.state.shoutOuts} />
                </div>
              </div>
            </div>
          }
        />
      </>
    );
  }
}

export default EmployeeProfile;
