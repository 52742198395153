import axios from 'axios';
import React from 'react';
import $ from 'jquery';
import ViewShoutToMe from '../Actions/ViewShoutToMe';
import { analytics } from '../../firebaseConfig.js';

function EmployeeShout(props) {
  const viewNotif = () => {
    analytics.logEvent(`shout_notif_viewed`);
    $(`#viewShoutOut${props.id}btn`).addClass('btn-notif-grey');
    axios.post(`/notifications/${props.id}`).then((res) => {
      console.log(res.data);
    });
  };

  return (
    <div className='notif-outside row'>
      <div className='col-1 mb-3 icon-col'>
        <img
          className=' notif-icon'
          src='../Images/green-checkmark.png'
          alt={props.name}
        />
      </div>
      <div className='col-7'>
        <p className='notif-text'>
          <strong>{props.name}</strong> {props.text} <strong>{props.to}</strong>
        </p>
      </div>
      <div className='col-4 notif-btn-row'>
        <button
          className={props.read ? 'btn-notif btn-notif-grey' : ' btn-notif'}
          type='button'
          data-toggle='modal'
          id={`viewShoutOut${props.id}btn`}
          data-target={`#viewShout${props.shoutId}`}
          onClick={() => viewNotif()}
        >
          view
        </button>

        <ViewShoutToMe id={props.id} shoutId={props.shoutId} />
      </div>
    </div>
  );
}

export default EmployeeShout;
