import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import React, { Component } from 'react';
import ModalCloseBtn from '../../Constants/ModalCloseBtn';

class AskMentor extends Component {
  constructor() {
    super();
    this.state = {
      question: '',
      custom: false,
      loading: false,
      errors: {},
    };
  }

  buttonClicked = (event) => {
    this.setState({
      custom: false,
    });
  };

  onQuestionChange = (event) => {
    this.setState({
      question: event.target.value,
    });
  };

  onQuestionSelectChange = (event) => {
    if (event.target.value === 'Custom:') {
      this.setState({ custom: true });
    } else
      this.setState({
        question: event.target.value,
      });
  };

  handleSubmit = (event) => {
    event.preventDefault();

    const formData = {
      question: this.state.question,
    };
    // console.log(formData)
    // this.setState({
    //   errors: {
    //     general: "Sorry this functionality is not quite ready yet.",
    //   },
    // });

    axios
      .post('/askMentorQuestion', formData)
      .then((res) => {
        this.setState({
          loading: false,
        });
        window.location.reload();
      })
      .catch((err) => {
        console.log(err.response.data);
        this.setState({
          errors: {
            general: 'Error: Mentor relationships are not quite ready yet!',
          },
          loading: false,
        });
      });
  };

  render() {
    return (
      <>
        <div
          className='modal '
          id='newMentorQ'
          tabIndex='-1'
          role='dialog'
          aria-labelledby='exampleModalCenterTitle'
          aria-hidden='true'
        >
          <div className='col-xs-2'>
            <div className='modal-arrow' id='top'></div>
          </div>
          <div className='modal-dialog modal-dialog-centered' role='document'>
            <div className='modal-content main-action'>
              <div className='modal-header'>
                <h3 className='modal-title' id='exampleModalLongTitle'>
                  <FontAwesomeIcon
                    className='icon-mobile'
                    icon={['fal', 'sync']}
                  />
                </h3>
                <ModalCloseBtn />
              </div>
              <div className='modal-body'>
                <form>
                  {this.state.custom ? (
                    <div></div>
                  ) : (
                    <div className='form-group'>
                      <label
                        htmlFor='exampleFormControlInput4'
                        className='action-label'
                      >
                        Question
                      </label>
                      <select
                        onChange={this.onQuestionSelectChange}
                        value={this.state.question}
                        type='text'
                        className='form-control action-input'
                        placeholder=''
                      >
                        <option></option>
                        <option>
                          How did you learn to embrace risk-taking?
                        </option>
                        <option>
                          Tell me about your career success journey, and what
                          you've learned along the way.
                        </option>
                        <option>
                          Did you envision your career as it is today?
                        </option>
                        <option>
                          Was there ever a role you applied for, and landed, but
                          weren't 100% qualified to do? How did you proceed?
                        </option>
                        <option>
                          Which leadership skills were most difficult to
                          develop?
                        </option>
                        <option>
                          How did you make the shift to success engagement
                          versus just everyday engagement?
                        </option>
                        <option>
                          Who are the people I should align to within the
                          company to achieve success?
                        </option>
                        <option>
                          How can I let my boss know that I don't need to be
                          micromanaged?
                        </option>
                        <option>
                          Where do you see my strengths as well my blind spots
                          for future development?
                        </option>
                        <option>
                          How can I best shift from tactical to more strategic
                          in my career success perspective?
                        </option>
                        <option>
                          How can I become a more assertive negotiator?
                        </option>
                        <option>
                          What are the skills to best establish credibility for
                          myself?
                        </option>
                        <option>Custom:</option>
                      </select>
                    </div>
                  )}
                  {this.state.custom ? (
                    <div className='form-group'>
                      <div className='row p-3'>
                        <button
                          className='btn btn-submit'
                          onClick={this.buttonClicked}
                        >
                          {'Pick a Question'}
                        </button>
                      </div>

                      <label
                        htmlFor='exampleFormControlInput4'
                        className='action-label'
                      >
                        Custom Question:
                      </label>
                      <input
                        onChange={this.onQuestionChange}
                        value={this.state.question}
                        type='text'
                        className='form-control action-input'
                        placeholder=''
                      />
                    </div>
                  ) : (
                    <div></div>
                  )}
                </form>
              </div>
              <p className='errors-p p-3'>{this.state.errors.general}</p>
              <div className='modal-footer'>
                <button
                  type='button'
                  onClick={this.handleSubmit}
                  className='btn btn-submit'
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default AskMentor;
