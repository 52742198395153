import axios from 'axios';
import React, { Component } from 'react';
import Policy from '../../Components/Actions/Policy';
import './company.css';
import $ from 'jquery';

class CreateCompany extends Component {
  constructor() {
    super();
    this.state = {
      firstName: '',
      lastName: '',
      phone: '',
      email: '',
      companyName: '',
      password: '',
      confirmPassword: '',
      loading: false,
      errors: {},
    };
  }
  handleSubmit = (event) => {
    event.preventDefault();
    this.setState({
      loading: true,
    });
    const signUpData = {
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      phone: this.state.phone,
      email: this.state.email,
      companyName: this.state.companyName,
      password: this.state.password,
      confirmPassword: this.state.confirmPassword,
    };
    console.log(signUpData);
    axios
      .post('/companySignup', signUpData)
      .then((res) => {
        localStorage.setItem('IdToken', `Bearer ${res.data.IdToken}`);
        this.setState({
          loading: false,
        });
        this.props.history.push('/credirect');
      })
      .catch((err) => {
        console.log(err.response.data);
        this.setState({
          errors: err.response.data,
          loading: false,
        });
      });
  };
  onFirstNameChange = (event) => {
    this.setState({
      firstName: event.target.value,
    });
  };
  onLastNameChange = (event) => {
    this.setState({
      lastName: event.target.value,
    });
  };
  onPhoneChange = (event) => {
    $('.number').keyup(function () {
      if (this.value.length === this.maxLength) {
        $(this).next('.number').focus();
      }
    });
    const val1 = $('#number1').val();
    const val2 = $('#number2').val();
    const val3 = $('#number3').val();
    this.setState({
      phone: val1 + '-' + val2 + '-' + val3,
    });
  };
  onEmailChange = (event) => {
    this.setState({
      email: event.target.value,
    });
  };
  onCompanyNameChange = (event) => {
    this.setState({
      companyName: event.target.value,
    });
  };
  onPasswordChange = (event) => {
    this.setState({
      password: event.target.value,
    });
  };
  onConfirmPasswordChange = (event) => {
    this.setState({
      confirmPassword: event.target.value,
    });
  };
  render() {
    if (this.state.loading) return null;
    return (
      <div className='login-page'>
        <div className='row justify-content-center'>
          <img
            src='../Images/successrocketlogo.png'
            className='signup-logo'
            alt='Landing'
          />
        </div>
        <div className='row justify-content-center'>
          <div className='form-row'>
            <form>
              <h1 className='login-header'>Create New Account</h1>
              <div className='form-group'>
                <label htmlFor='fName' className='sign-label'>
                  First Name
                </label>
                <input
                  value={this.state.firstName}
                  onChange={this.onFirstNameChange}
                  type='text'
                  className='form-control sign-input'
                  id='fName'
                  aria-describedby='First Name'
                  placeholder=''
                />
              </div>
              <p className='errors-p2'>{this.state.errors.lastName}</p>
              <div className='form-group'>
                <label htmlFor='lName' className='sign-label'>
                  Last Name
                </label>
                <input
                  value={this.state.lastName}
                  onChange={this.onLastNameChange}
                  type='text'
                  className='form-control sign-input'
                  id='lName'
                  aria-describedby='Last Name'
                  placeholder=''
                />
              </div>
              <p className='errors-p2'>{this.state.errors.firstName}</p>

              <div className='form-group'>
                <label htmlFor='phone' className='sign-label'>
                  Phone Number
                </label>
                <div className='row'></div>
                <input
                  type='text'
                  id='number1'
                  className='number sign-input'
                  maxLength='3'
                  size='3'
                  onChange={this.onPhoneChange}
                />{' '}
                -{' '}
                <input
                  type='text'
                  id='number2'
                  className='number sign-input'
                  maxLength='3'
                  size='3'
                  onChange={this.onPhoneChange}
                />{' '}
                -{' '}
                <input
                  type='text'
                  id='number3'
                  className='number sign-input'
                  maxLength='4'
                  size='5'
                  onChange={this.onPhoneChange}
                />
                {/* <input value={this.state.phone} onChange={this.onPhoneChange} type="phone" className="form-control sign-input" id="phone" aria-describedby="Phone Number" placeholder=""/> */}
              </div>

              <p className='errors-p2'>{this.state.errors.phone}</p>
              <div className='form-group'>
                <label htmlFor='email' className='sign-label'>
                  Email
                </label>
                <input
                  value={this.state.email}
                  onChange={this.onEmailChange}
                  type='email'
                  className='form-control sign-input'
                  id='email'
                  aria-describedby='emailHelp'
                  placeholder=''
                />
              </div>
              <p className='errors-p2'>{this.state.errors.email}</p>
              <div className='form-group'>
                <label htmlFor='companyName' className='sign-label'>
                  Company Name
                </label>
                <input
                  value={this.state.companyName}
                  onChange={this.onCompanyNameChange}
                  type='text'
                  className='form-control sign-input'
                  id='companyName'
                  placeholder=''
                />
              </div>
              <p className='errors-p2'>{this.state.errors.companyName}</p>
              <div className='form-group'>
                <label htmlFor='password' className='sign-label'>
                  Password
                </label>
                <input
                  value={this.state.password}
                  onChange={this.onPasswordChange}
                  type='password'
                  className='form-control sign-input'
                  id='password'
                  placeholder=''
                />
              </div>
              <p className='errors-p2'>{this.state.errors.password}</p>
              <div className='form-group'>
                <label htmlFor='Cpassword' className='sign-label'>
                  Confirm Password
                </label>
                <input
                  value={this.state.confirmPassword}
                  onChange={this.onConfirmPasswordChange}
                  type='password'
                  className='form-control sign-input'
                  id='Cpassword'
                  placeholder=''
                />
              </div>
              <p className='errors-p2'>{this.state.errors.confirmPassword}</p>
              <p className='pt-3 disclaimer-text'>
                View our{' '}
                <button
                  className='policyterms'
                  type='button'
                  data-toggle='modal'
                  data-target='#policyandterms'
                >
                  Terms of use
                </button>{' '}
                and{' '}
                <button
                  className='policyterms'
                  type='button'
                  data-toggle='modal'
                  data-target='#policyandterms'
                >
                  Privacy Policy
                </button>
              </p>
              <Policy />
              <div className='form-check'>
                <input
                  type='checkbox'
                  className='form-check-input form-check-success'
                  id='exampleCheck1'
                />
                <label
                  className='form-check-label form-label-success'
                  htmlFor='exampleCheck1'
                >
                  I agree to the{' '}
                  <strong>Terms of Services and Privacy Policy.</strong>
                </label>
              </div>
              <div className='row justify-content-center'>
                <div className='signin-btn-row bottom'>
                  <button
                    className='company-btn'
                    type='submit'
                    onClick={this.handleSubmit}
                  >
                    Create New Company
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

export default CreateCompany;
