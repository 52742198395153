import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component } from 'react';
import Page from '../../Components/NavBars/Page';
import { faCircle, faSortDown } from '@fortawesome/pro-solid-svg-icons';
import BackBtn from '../../Components/Back/BackBtn';
import ArchivedMain from '../../Components/Actions/ArchivedMain';
import ArchivedSub from '../../Components/Actions/ArchivedSub';
import axios from 'axios';
import { analytics } from '../../firebaseConfig.js';

class MArchive extends Component {
  constructor() {
    super();
    this.state = {
      actions: [
        {
          id: ' ',
          status: ' ',
          main: ' ',
          name: ' ',
          priority: ' ',
          description: ' ',
          dDate: ' ',
          rating: ' ',
          comment: ' ',
          sub: [
            {
              name: ' ',
              id: ' ',
              dDate: ' ',
              status: false,
              rating: ' ',
              priority: '',
            },
          ],
        },
      ],
      loading: true,
      errors: {},
    };
  }

  componentDidMount() {
    analytics.logEvent(`m_archive_visited`);
    axios
      .get('/getArchivedActions')
      .then((res) => {
        const data = res.data;
        let theActions = [];
        let subActions = [];
        for (let i = 0; i < data.length; i++) {
          if (data[i].actionType === 'main') {
            theActions.push({
              id: data[i].actionId,
              main: data[i].title,
              priority: data[i].priority,
              dDate: data[i].dueDate,
              description: data[i].description,
              status: data[i].completed,
              rating: data[i].actionRating,
              comment: data[i].ratingComment,
            });
          } else {
            subActions.push({
              id: data[i].actionId,
              name: data[i].title,
              priority: data[i].priority,
              dDate: data[i].dueDate,
              status: data[i].completed,
              mainActionId: data[i].mainAction,
            });
          }
        }

        const newActions = theActions.map((item) => {
          return {
            ...item,
            sub: subActions.filter(
              (element) => element.mainActionId === item.id,
            ),
          };
        });

        this.setState({ actions: newActions });
        this.setState({ loading: false });
      })
      .catch((error) => {
        console.error('MArchive - Error getting archived actions:', error);

        this.setState({
          loading: false,
          error: 'An error occurred while fetching archived actions.',
        });
      });
  }

  render() {
    if (this.state.loading) return null;
    return (
      <>
        <Page
          content={
            <div className='actions-page'>
              <BackBtn link='actions' className='mb-3' />
              <h1 className='actions-header'>Archive</h1>

              <div className='row actions-display'>
                <div className='col-md-6'>
                  <div className='actions row'>
                    <h2 className='my-actions-header'>My Actions</h2>
                    {this.state.actions.map((action, index) => (
                      <>
                        <div className='row ' key={index}>
                          <div className='col-8 action-zindex'>
                            <p className='action-p'>
                              <button
                                className='button button-main-action collapsed'
                                type='button'
                                data-toggle='collapse'
                                data-target={`#collapse${action.id}`}
                                aria-expanded='false'
                                aria-controls='collapseExample'
                              >
                                <p className='action-sort'>
                                  <FontAwesomeIcon
                                    className='sort-button'
                                    icon={faSortDown}
                                  />{' '}
                                  <FontAwesomeIcon
                                    className={`priority-icon-${action.priority}`}
                                    icon={faCircle}
                                  />{' '}
                                  {action.main}
                                </p>
                              </button>
                            </p>
                          </div>
                          <div className='col-4'>
                            <button
                              key={index}
                              className=''
                              type='button'
                              data-toggle='modal'
                              data-target={`#edit${action.id}`}
                            >
                              <div className='the-sub-action'>
                                <FontAwesomeIcon
                                  className='main-pencil'
                                  icon={['fal', 'eye']}
                                />
                              </div>
                            </button>
                            <ArchivedMain
                              rating={action.rating}
                              comment={action.comment}
                              name={action.name}
                              action={action.main}
                              description={action.description}
                              priority={action.priority}
                              dDate={action.dDate}
                              id={action.id}
                            />
                          </div>
                        </div>

                        <div className='collapse' id={`collapse${action.id}`}>
                          <div className='card action-card-body'>
                            <div className='sub-actions'>
                              {action.sub.map((sub, index) => (
                                <div className='row' key={index}>
                                  <div
                                    className={
                                      index % 2
                                        ? 'white-bg col-8'
                                        : 'grey-bg col-8'
                                    }
                                  >
                                    <button
                                      key={index}
                                      className=''
                                      type='button'
                                      data-toggle='modal'
                                      data-target={`#edit${sub.id}`}
                                    >
                                      <div className='the-sub-action'>
                                        <FontAwesomeIcon
                                          className='pencil-icon'
                                          icon={['fal', 'eye']}
                                        />
                                        {'  '}
                                        {sub.name}{' '}
                                      </div>
                                    </button>
                                    <ArchivedSub
                                      priority={sub.priority}
                                      title={sub.name}
                                      name={action.name}
                                      main={action.main}
                                      dDate={sub.dDate}
                                      id={sub.id}
                                    />
                                  </div>
                                  <div className='col-4 pt-1'></div>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      </>
                    ))}
                  </div>
                </div>
              </div>

              <footer className='footer priority-key sticky-bottom'>
                <div className='row'>
                  <h3 className='priority-header'>Priority Key</h3>
                </div>
                <div className='row'>
                  <div className='col-3 p-0 text-center priority-text'>
                    <FontAwesomeIcon
                      className='priority-icon-yellow'
                      icon={faCircle}
                    />{' '}
                    Low
                  </div>
                  <div className='col-3 p-0 text-center priority-text'>
                    <FontAwesomeIcon
                      className='priority-icon-green'
                      icon={faCircle}
                    />{' '}
                    Medium
                  </div>
                  <div className='col-3 p-0 text-center priority-text'>
                    <FontAwesomeIcon
                      className='priority-icon-blue'
                      icon={faCircle}
                    />{' '}
                    High
                  </div>
                  <div className='col-3 p-0 text-center priority-text'>
                    <FontAwesomeIcon
                      className='priority-icon-red'
                      icon={faCircle}
                    />{' '}
                    Critical
                  </div>
                </div>
              </footer>
            </div>
          }
        />
      </>
    );
  }
}

export default MArchive;
