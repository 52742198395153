import React, { useEffect } from 'react';
import axios from 'axios';
import { useState } from 'react';
import CompanyPage from '../../Components/NavBars/CompanyPage';
import Payment2 from './Payment2';
import PaymentModal from '../../Components/Payments/PaymentModal';

const AdminCompanyPlan = (props) => {
  const [loading, setLoading] = useState(true);
  const [passedDue, setPassedDue] = useState(false);
  const [companyCode, setCompanyCode] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [companyId, setCompanyId] = useState('');
  const [stripeId, setStripeId] = useState('');
  const [nextPayment, setNextPayment] = useState('');
  const [price, setPrice] = useState(8);
  const [amount, setAmount] = useState(0);
  const [amountDue, setAmountDue] = useState(0);
  const [PayNowModal, setPayNowModal] = useState();

  // const copyCode = (event) => {
  //   const copyTextarea = document.querySelector('.js-copytextarea');
  //   copyTextarea.focus();
  //   copyTextarea.select();
  //   try {
  //     var successful = document.execCommand('copy');
  //     var msg = successful ? 'successfully' : 'unsuccessfully';
  //     this.setState({ alert: 'Code copied ' + msg });
  //   } catch (err) {
  //     console.log('Oops, unable to copy');
  //   }
  // };

  const finishLoading = ({ amountUsers, stripeId, price, data }) => {
    console.log(amountUsers);
    const upcoming = data.nextPayment;
    const nextPayment = new Date(upcoming * 1);
    setPayNowModal(
      <PaymentModal
        employees={amountUsers}
        price={price}
        stripeId={stripeId}
        userData={data}
        paymentDue={nextPayment}
      />,
    );
    setLoading(false);
  };

  const getAmountOfUsers = ({ price, stripeId, data }) => {
    axios
      .get('/getEmployeeAmount')
      .then((res) => {
        const amountUsers = res.data.credentials.employees;
        setAmount(amountUsers);
        setAmountDue(amountUsers * parseInt(price));
        finishLoading({ amountUsers, stripeId, price, data });
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const getStripeInfo = ({ stripeId, price, data }) => {
    axios
      .post('/api/get-customer', { stripeId: stripeId })
      .then((res) => {
        console.log(res);
        getAmountOfUsers({ price, stripeId, data });
      })
      .catch((err) => {
        getAmountOfUsers({ price, stripeId, data });
      });
  };

  const getCompany = () => {
    axios
      .get('/company')
      .then((res) => {
        const data = res.data.credentials;
        const upcoming = data.nextPayment;
        const price = data.price;
        const d = new Date(upcoming * 1);
        if (d <= new Date()) {
          setPassedDue(true);
        }
        setNextPayment(d);
        setPrice(price);
        setCompanyCode(data.companyCode);
        setCompanyId(data.companyId);
        setStripeId(data.stripeId);
        setCompanyName(data.companyName);
        getStripeInfo({ price, stripeId: data.stripeId, data });
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getCompany();
  }, []);

  if (loading) return null;

  return (
    <>
      {/* <div className="enterprise-card">
                      <label className="form-check-label" htmlFor="enterprise">
                        <h1 className="package-header">Enterprise</h1>
                        <ul className="package-ul">
                          <li className="package-li">
                            <strong>All Standard Features +</strong>
                          </li>
                          <li className="package-li">
                            Configure Success Criteria
                          </li>
                          <li className="package-li">
                            Configure Check-in Questions
                          </li>
                          <li className="package-li">
                            Auto-updates with new features
                          </li>
                          <li className="package-li">Generate Reports</li>
                        </ul>
                        <p className="package-month">
                          <strong className="price-strong">$9</strong> per month
                          per user*
                        </p>
                        <p className="package-billed">
                          *Billed monthly on the 1st of each month.
                        </p>
                      </label>
                    </div> */}

      <CompanyPage
        companyName={companyName}
        content={
          <>
            <div className='company-dash'>
              <div className='dash-inner'>
                <div className='row'>
                  <div className='col-md-6 mb-3'>
                    <div className='package-card'>
                      <label className='form-check-label' htmlFor='standard'>
                        <h1 className='package-header'>All-In</h1>
                        <ul className='package-ul'>
                          <li className='package-li'>
                            Employee & Manager Dashboards
                          </li>
                          <li className='package-li'>Employee Directory</li>
                          <li className='package-li'>Check-in Questions</li>
                          <li className='package-li'>Success Criteria</li>
                          <li className='package-li'>Success Insights</li>
                          <li className='package-li'>Action Management</li>
                          <li className='package-li'>Profile Management</li>
                          <li className='package-li'>Employee Shout-Outs</li>
                        </ul>
                        <p className='package-month'>
                          <strong className='price-strong'>{`$${price}`}</strong>{' '}
                          per month per user*
                        </p>
                        {passedDue ? (
                          <p className='package-billed mt-1 pb-3 red'>
                            {`Overdue on ${nextPayment}`}
                          </p>
                        ) : (
                          <p className='package-billed mt-1 pb-3'>
                            {`Next payment is due on or before ${nextPayment}`}
                          </p>
                        )}

                        <p className='package-billed'>
                          *Billed monthly on the 1st of each month.
                        </p>
                      </label>
                    </div>
                  </div>
                  <div className='col-md-6 mb-3'>
                    <div
                      className={
                        passedDue
                          ? 'row add-r-box add-r-box-red'
                          : 'row add-r-box'
                      }
                    >
                      {passedDue ? (
                        <h1 className='package-header'>Overdue Payment</h1>
                      ) : (
                        <h1 className='package-header'>Upcoming Payment</h1>
                      )}

                      {/* <p className="package-billed mt-1 pb-3">
                    {`Next payment is due ${nextPayment}`}
                  </p> */}
                      {passedDue ? (
                        <p className='package-billed mt-1 pb-3 red'>
                          {`Overdue on ${nextPayment}`}
                        </p>
                      ) : (
                        <p className='package-billed mt-1 pb-3'>
                          {`Next payment is due on or before ${nextPayment}`}
                        </p>
                      )}
                      <p className='package-month'>
                        <strong className='price-strong'>{`$${amountDue}`}</strong>{' '}
                        {`${amount} users x $${price} per month per
                    user*`}
                      </p>
                      <div className='row'>
                        <button
                          id={`pay${stripeId}btn`}
                          type='button'
                          className='btn'
                          data-toggle='modal'
                          data-target={`#pay${stripeId}`}
                        >
                          Pay Now
                        </button>
                        {!loading ? PayNowModal : null}
                      </div>
                    </div>

                    <div>
                      <Payment2 />
                    </div>
                  </div>
                </div>
                <div className='row'>{/* <p>List of past payments</p> */}</div>
              </div>{' '}
            </div>
          </>
        }
      />
    </>
  );
};

export default AdminCompanyPlan;
