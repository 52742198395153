import React, { useState } from 'react';
import './survey.css';

const SurveyQ = ({ question, handleClick, key }) => {
  const [qv, setQuestionValue] = useState();

  const onQuestionChange = (event) => {
    setQuestionValue(event.target.value);
    handleClick(event);
  };

  return (
    <>
      <form>
        <div className='row survey-row' key={key}>
          <h5 className='prof-info-title'>{question}</h5>
          <div className='row text-center' key={key}>
            <div className='col-1 small text-center survey-col'>1</div>

            <div className='col-1 survey-col'>
              <div className='form-check'>
                <input
                  className='form-check-input'
                  type='radio'
                  name={`flexRadioDefault${key}`}
                  id={`flexRadioDefault${key}`}
                  value='1'
                  onChange={(e) => onQuestionChange(e)}
                />
                <label
                  className='form-check-label'
                  htmlFor={`flexRadioDefault${key}`}
                ></label>
              </div>
            </div>

            <div className='col-1 survey-col'>
              <div className='form-check'>
                <input
                  className='form-check-input'
                  type='radio'
                  name={`flexRadioDefault${key}`}
                  id={`flexRadioDefault${key}`}
                  value='2'
                  onChange={(e) => onQuestionChange(e)}
                />
                <label
                  className='form-check-label'
                  htmlFor={`flexRadioDefault${key}`}
                ></label>
              </div>
            </div>

            <div className='col-1 survey-col'>
              <div className='form-check'>
                <input
                  className='form-check-input'
                  type='radio'
                  name={`flexRadioDefault${key}`}
                  id={`flexRadioDefault${key}`}
                  value='3'
                  onChange={(e) => onQuestionChange(e)}
                />
                <label
                  className='form-check-label'
                  htmlFor={`flexRadioDefault${key}`}
                ></label>
              </div>
            </div>

            <div className='col-1 survey-col'>
              <div className='form-check'>
                <input
                  className='form-check-input'
                  type='radio'
                  name={`flexRadioDefault${key}`}
                  id={`flexRadioDefault${key}`}
                  value='4'
                  onChange={(e) => onQuestionChange(e)}
                />
                <label
                  className='form-check-label'
                  htmlFor={`flexRadioDefault${key}`}
                ></label>
              </div>
            </div>

            <div className='col-1 survey-col'>
              <div className='form-check'>
                <input
                  className='form-check-input'
                  type='radio'
                  name={`flexRadioDefault${key}`}
                  id={`flexRadioDefault${key}`}
                  value='5'
                  onChange={(e) => onQuestionChange(e)}
                />
                <label
                  className='form-check-label'
                  htmlFor={`flexRadioDefault${key}`}
                ></label>
              </div>
            </div>

            <div className='col-1 survey-col'>
              <div className='form-check'>
                <input
                  className='form-check-input'
                  type='radio'
                  name={`flexRadioDefault${key}`}
                  id={`flexRadioDefault${key}`}
                  value='6'
                  onChange={(e) => onQuestionChange(e)}
                />
                <label
                  className='form-check-label'
                  htmlFor={`flexRadioDefault${key}`}
                ></label>
              </div>
            </div>

            <div className='col-1 survey-col'>
              <div className='form-check'>
                <input
                  className='form-check-input'
                  type='radio'
                  name={`flexRadioDefault${key}`}
                  id={`flexRadioDefault${key}`}
                  value='7'
                  onChange={(e) => onQuestionChange(e)}
                />
                <label
                  className='form-check-label'
                  htmlFor={`flexRadioDefault${key}`}
                ></label>
              </div>
            </div>

            <div className='col-1 survey-col'>
              <div className='form-check'>
                <input
                  className='form-check-input'
                  type='radio'
                  name={`flexRadioDefault${key}`}
                  id={`flexRadioDefault${key}`}
                  value='8'
                  onChange={(e) => onQuestionChange(e)}
                />
                <label
                  className='form-check-label'
                  htmlFor={`flexRadioDefault${key}`}
                ></label>
              </div>
            </div>

            <div className='col-1 survey-col'>
              <div className='form-check'>
                <input
                  className='form-check-input'
                  type='radio'
                  name={`flexRadioDefault${key}`}
                  id={`flexRadioDefault${key}`}
                  value='9'
                  onChange={(e) => onQuestionChange(e)}
                />
                <label
                  className='form-check-label'
                  htmlFor={`flexRadioDefault${key}`}
                ></label>
              </div>
            </div>

            <div className='col-1 survey-col'>
              <div className='form-check'>
                <input
                  className='form-check-input'
                  type='radio'
                  name={`flexRadioDefault${key}`}
                  id={`flexRadioDefault${key}`}
                  value='10'
                  onChange={(e) => onQuestionChange(e)}
                />
                <label
                  className='form-check-label'
                  htmlFor={`flexRadioDefault${key}`}
                ></label>
              </div>
            </div>

            <div className='col-1 survey-col small text-center'>10</div>
          </div>
        </div>
      </form>
    </>
  );
};

export default SurveyQ;
