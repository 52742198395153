import axios from 'axios';
import React, { Component } from 'react';
import $ from 'jquery';
import ModalCloseBtn from '../../Constants/ModalCloseBtn';

class RemoveSuccessEng extends Component {
  constructor() {
    super();
    this.state = {
      loading: false,
      errors: {},
    };
  }

  handleSubmit = (event) => {
    event.preventDefault();
    this.setState({
      loading: true,
    });
    axios
      .post(`/revertSuccessEngagement`)
      .then((res) => {
        this.setState({
          loading: false,
        });

        window.location.reload();
      })
      .catch((err) => {
        console.error(
          'RemoveSuccessEng - Error reverting success engagement:',
          err,
        );
        this.setState({
          errors: { general: 'Error: Please check information and try again.' },
          loading: false,
        });
        $('#removeSuccessEngPopover').trigger('click');
      });
  };

  render() {
    if (this.state.loading) return null;
    return (
      <>
        <div
          className='modal '
          id='RemoveSuccessEng'
          tabIndex='-1'
          role='dialog'
          aria-labelledby='exampleModalCenterTitle'
          aria-hidden='true'
        >
          <div className='col-xs-2'>
            <div className='modal-arrow-sub' id='top'></div>
          </div>
          <div className='modal-dialog modal-dialog-centered' role='document'>
            <div className='modal-content sub-action'>
              <div className='modal-header'>
                <h5 id='exampleModalLongTitle'>
                  Success Engagement Conversation
                </h5>
                <ModalCloseBtn />
              </div>
              <div className='modal-body'>
                <form>
                  <div className='form-group'>
                    <label
                      htmlFor='exampleFormControlInput1'
                      className='action-label'
                    >
                      Are you sure you want to reset your Success Engagement
                      Conversations?
                    </label>
                  </div>
                </form>
              </div>
              <p className='errors-p p-3'>{this.state.errors.general}</p>
              <div className='modal-footer'>
                <button
                  type='button'
                  data-dismiss='modal'
                  aria-label='Close'
                  className='btn btn-submit'
                >
                  No
                </button>
                <button
                  type='button'
                  onClick={this.handleSubmit}
                  className='btn btn-danger'
                >
                  Yes, reset
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default RemoveSuccessEng;
