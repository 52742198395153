import React from 'react';
import './nav.css';
import { Link } from 'react-router-dom';

const Header = (props) => {
  return (
    <nav className='row header'>
      <div className='col-11 header-text-row'>
        <div className='row'>
          <p className='header-text'>{props.name}</p>
        </div>
        <div className='row'>
          <p className='header-star'>{props.rating}</p>
        </div>
      </div>
      <div className='col-1 text-center text-light'>
        <Link to='/profile'>
          <img className='prof-img' src={props.photo} alt='Profile' />
        </Link>
      </div>
    </nav>
  );
};

export default Header;
