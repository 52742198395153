import Chart from 'react-apexcharts';
import './charts.css';

const BarChart = () => {
  const data = {
    options: {
      chart: {
        id: 'apexchart-manager-teams',
        stacked: true,
        toolbar: {
          show: false,
        },
      },
      grid: {
        show: false,
      },
      yaxis: {
        show: false,
      },
      xaxis: {
        categories: ['Team A', 'Team B', 'Team C', 'Team D', 'Team E'],
      },
    },
    series: [
      {
        name: 'Completed',
        data: [17, 81, 10, 22, 43],
        color: '#023858',
      },
      {
        name: 'Not Completed',
        data: [32, 12, 100, 54, 54],
        color: '#0570b0',
      },
    ],
  };

  return (
    <Chart
      options={data.options}
      series={data.series}
      type='bar'
      height={300}
    />
  );
};

export default BarChart;
