import axios from 'axios';
import React, { Component } from 'react';
import BackBtn from '../../Components/Back/BackBtn';
import Page from '../../Components/NavBars/Page';
import { analytics } from '../../firebaseConfig.js';
import SurveyQ from './SurveyQ';

class Survey extends Component {
  constructor() {
    super();
    this.state = {
      id: '',
      first: '',
      last: '',
      email: '',
      q1: '',
      q2: '',
      q3: '',
      q4: '',
      q5: '',
      q6: '',
      q7: '',
      q8: '',
      q9: '',
      q10: '',
      q11: '',
      q12: '',
      q13: '',
      q14: '',
      q15: '',
      q16: '',
      q17: '',
      q18: '',
      q19: '',
      q20: '',
      q21: '',
      q22: '',
      loading: true,
      errors: {},
    };
  }

  componentDidMount() {
    analytics.logEvent(`survey_visited`);
    axios
      .get('/user')
      .then((res) => {
        const cred = res.data.credentials;
        this.setState({ id: cred.id });
        this.setState({ first: cred.firstName });
        this.setState({ last: cred.lastName });
        this.setState({ email: cred.email });
      })
      .catch((error) => {
        console.error('Survey - Error fetching user data:', error);

        this.setState({
          loading: false,
          error: 'An error occurred while fetching user data.',
        });
      });
    this.setState({ loading: false });
  }

  handleSubmit = (event) => {
    event.preventDefault();
    this.setState({
      loading: true,
    });
    if (
      this.state.q1 === '' ||
      this.state.q2 === '' ||
      this.state.q3 === '' ||
      this.state.q4 === '' ||
      this.state.q5 === '' ||
      this.state.q6 === '' ||
      this.state.q7 === '' ||
      this.state.q8 === '' ||
      this.state.q9 === '' ||
      this.state.q10 === '' ||
      this.state.q11 === '' ||
      this.state.q12 === '' ||
      this.state.q13 === '' ||
      this.state.q14 === '' ||
      this.state.q15 === '' ||
      this.state.q16 === '' ||
      this.state.q17 === '' ||
      this.state.q18 === '' ||
      this.state.q19 === '' ||
      this.state.q20 === '' ||
      this.state.q21 === '' ||
      this.state.q22 === ''
    ) {
      this.setState({
        loading: false,
      });
    } else {
      const responses = {
        q1: this.state.q1,
        q2: this.state.q2,
        q3: this.state.q3,
        q4: this.state.q4,
        q5: this.state.q5,
        q6: this.state.q6,
        q7: this.state.q7,
        q8: this.state.q8,
        q9: this.state.q9,
        q10: this.state.q10,
        q11: this.state.q11,
        q12: this.state.q12,
        q13: this.state.q13,
        q14: this.state.q14,
        q15: this.state.q15,
        q16: this.state.q16,
        q17: this.state.q17,
        q18: this.state.q18,
        q19: this.state.q19,
        q20: this.state.q20,
        q21: this.state.q21,
        q22: this.state.q22,
      };
      axios
        .post(`/surveyResponse/${this.props.match.params.surveyId}`, responses)
        .then((res) => {
          this.setState({
            loading: false,
          });
          this.props.history.push('/profile');
        })
        .catch((err) => {
          console.error(
            'Survey - Error posting surveyResponse:',
            err.response.data,
          );
          this.setState({
            errors: {
              general:
                'Survey - Error: Please check information and try again.',
            },
            loading: false,
          });
        });
    }
  };

  onQuestionChange = (event) => {
    this.setState({
      q1: event.target.value,
    });
  };

  onQuestionChange2 = (event) => {
    this.setState({
      q2: event.target.value,
    });
  };

  onQuestionChange3 = (event) => {
    this.setState({
      q3: event.target.value,
    });
  };

  onQuestionChange4 = (event) => {
    this.setState({
      q4: event.target.value,
    });
  };

  onQuestionChange5 = (event) => {
    this.setState({
      q5: event.target.value,
    });
  };

  onQuestionChange6 = (event) => {
    this.setState({
      q6: event.target.value,
    });
  };

  onQuestionChange7 = (event) => {
    this.setState({
      q7: event.target.value,
    });
  };

  onQuestionChange8 = (event) => {
    this.setState({
      q8: event.target.value,
    });
  };

  onQuestionChange9 = (event) => {
    this.setState({
      q9: event.target.value,
    });
  };

  onQuestionChange10 = (event) => {
    this.setState({
      q10: event.target.value,
    });
  };

  onQuestionChange11 = (event) => {
    this.setState({
      q11: event.target.value,
    });
  };

  onQuestionChange12 = (event) => {
    this.setState({
      q12: event.target.value,
    });
  };

  onQuestionChange13 = (event) => {
    this.setState({
      q13: event.target.value,
    });
  };

  onQuestionChange14 = (event) => {
    this.setState({
      q14: event.target.value,
    });
  };

  onQuestionChange15 = (event) => {
    this.setState({
      q15: event.target.value,
    });
  };

  onQuestionChange16 = (event) => {
    this.setState({
      q16: event.target.value,
    });
  };

  onQuestionChange17 = (event) => {
    this.setState({
      q17: event.target.value,
    });
  };

  onQuestionChange18 = (event) => {
    this.setState({
      q18: event.target.value,
    });
  };

  onQuestionChange19 = (event) => {
    this.setState({
      q19: event.target.value,
    });
  };
  onQuestionChange20 = (event) => {
    this.setState({
      q20: event.target.value,
    });
  };

  onQuestionChange21 = (event) => {
    this.setState({
      q21: event.target.value,
    });
  };

  onQuestionChange22 = (event) => {
    this.setState({
      q22: event.target.value,
    });
  };

  render() {
    if (this.state.loading) return null;
    return (
      <>
        <Page
          content={
            <div className=''>
              <div className='row edit-col'>
                <BackBtn link='profile' className='space-below' />
                <h1 className='edit-header'>NPS Survey</h1>

                <SurveyQ
                  question='I understand the strategic goals of my company.'
                  handleClick={this.onQuestionChange}
                  key='1'
                />

                <SurveyQ
                  question='I am given good opportunities to develop my knowledge and improve my sklls at work.'
                  handleClick={this.onQuestionChange2}
                  key='2'
                />

                <SurveyQ
                  question='How satisfied are you with your opportunity for growth and development at my company?'
                  handleClick={this.onQuestionChange3}
                  key='3'
                />

                <SurveyQ
                  question='There are good ways for employees to share their feedback and ideas with senior leadership'
                  handleClick={this.onQuestionChange4}
                  key='4'
                />

                <SurveyQ
                  question='I am informed of the reasons and rationale behind changes at my company that impact my work.'
                  handleClick={this.onQuestionChange5}
                  key='5'
                />

                <SurveyQ
                  question='Conflicting views and opinions are valued while developing ideas and solutions.'
                  handleClick={this.onQuestionChange6}
                  key='6'
                />

                <SurveyQ
                  question='I am proud to work for my company.'
                  handleClick={this.onQuestionChange7}
                  key='7'
                />

                <SurveyQ
                  question='I am proud to work for my company.'
                  handleClick={this.onQuestionChange8}
                  key='8'
                />

                <SurveyQ
                  question='I feel a strong sense of commitment to my company.'
                  handleClick={this.onQuestionChange9}
                  key='9'
                />

                <SurveyQ
                  question='In order to help my company, I am willing to put in extra effort'
                  handleClick={this.onQuestionChange10}
                  key='10'
                />

                <SurveyQ
                  question='I can openly discuss my issues and concerns with my manager.'
                  handleClick={this.onQuestionChange11}
                  key='11'
                />

                <SurveyQ
                  question='Overall, how good a job do you feel your immediate manager is doing?'
                  handleClick={this.onQuestionChange12}
                  key='12'
                />

                <SurveyQ
                  question='My immediate manager is helpful when I want to discuss my career plans and objectives.'
                  handleClick={this.onQuestionChange13}
                  key='13'
                />

                <SurveyQ
                  question='My job makes the best use of my capabilities.'
                  handleClick={this.onQuestionChange14}
                  key='14'
                />

                <SurveyQ
                  question='Considering everything, how satisfied are you with your job?'
                  handleClick={this.onQuestionChange15}
                  key='15'
                />
                <SurveyQ
                  question='I understand how my work contributes to the success of my company.'
                  handleClick={this.onQuestionChange16}
                  key='16'
                />

                <SurveyQ
                  question='I am confident in the ability of senior leadership to lead LifeWorks'
                  handleClick={this.onQuestionChange17}
                  key='17'
                />
                <SurveyQ
                  question='Senior leadership communicates a clear vision of where my company is headed.'
                  handleClick={this.onQuestionChange18}
                  key='18'
                />
                <SurveyQ
                  question='There is good cooperation between my team and other teams within my company.'
                  handleClick={this.onQuestionChange19}
                  key='19'
                />
                <SurveyQ
                  question='In my department we work well together as a team'
                  handleClick={this.onQuestionChange20}
                  key='20'
                />
                <SurveyQ
                  question='How likely is it that you would recommend my company as a great place to work to a colleague or friend?'
                  handleClick={this.onQuestionChange21}
                  key='21'
                />
                <SurveyQ
                  question='How likely is it that you will leave the company for another job in the next 12 months?'
                  handleClick={this.onQuestionChange22}
                  key='22'
                />
                <div className='row text-center'>
                  <button
                    className='login-btn'
                    onClick={(e) => this.handleSubmit(e)}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          }
        />
      </>
    );
  }
}

export default Survey;
