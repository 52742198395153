import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import React, { Component } from 'react';
import $ from 'jquery';
import Select from 'react-select';
import ModalCloseBtn from '../../Constants/ModalCloseBtn';

class NewShout extends Component {
  constructor() {
    super();
    this.state = {
      id: '',
      receiver: '',
      receiverName: '',
      receiverData: '',
      message: '',
      employeeNames: [],
      teamNames: [],
      loading: true,
      errors: {},
    };
  }

  handleSubmit = (event) => {
    event.preventDefault();
    this.setState({
      loading: true,
    });
    const newShoutOut = {
      receiverName: this.state.receiverName,
      receiverId: this.state.receiver,
      message: this.state.message,
    };
    if (newShoutOut.receiverId === '' || newShoutOut.message === '') {
      this.setState({
        errors: { general: 'Error: Please check information and try again.' },
        loading: false,
      });
    } else {
      axios
        .post('/shoutout', newShoutOut)
        .then((res) => {
          this.setState({
            loading: false,
          });

          window.location.reload();
          $(`#newShoutOutPopover${this.state.id}`).trigger('click');
        })
        .catch((err) => {
          console.error(
            'NewShout - Error posting new shoutout',
            err.response.data,
          );
          this.setState({
            errors: {
              general: 'Error: Please check information and try again.',
            },
            loading: false,
          });
          $(`#newShoutOutPopover${this.state.id}`).trigger('click');
        });
    }
  };

  componentDidMount() {
    const allNames = [];
    axios
      .get('/getTeamDirectory')
      .then((res) => {
        const data = res.data;
        let names = [];
        for (let i = 0; i < data.length; i++) {
          let fName = data[i].firstName.toUpperCase();
          let lName = data[i].lastName.toUpperCase();
          if (data[i].userId !== this.props.me) {
            names.push({
              name: `${fName} ${lName}`,
              id: data[i].userId,
              img: data[i].profileImg,
            });
          }
        }
        names.sort((a, b) =>
          a.name
            .split(' ')[1]
            .charAt(0)
            .localeCompare(b.name.split(' ')[1].charAt(0), 'es', {
              sensitivity: 'base',
            }),
        );
        const formOptions = [];
        for (let i = 0; i < names.length; i++) {
          let thisUser = {
            value: `${names[i].id},${names[i].name}`,
            label: (
              <div>
                {' '}
                <img
                  src={names[i].img}
                  alt={names[i].id}
                  className='newShoutImg'
                />{' '}
                {names[i].name}{' '}
              </div>
            ),
          };
          formOptions.push(thisUser);
        }
        allNames.push(...formOptions);
        this.setState({ teamNames: formOptions });
      })
      .catch((err) => {
        console.error('NewShout - Error getting team directory', err);
      });

    this.setState({ id: this.props.id });
    this.setState({ loading: false });
  }

  onReceiverChange = (value) => {
    this.setState({
      receiverData: value,
    });
    this.setState({
      receiver: value.value.split(',')[0],
    });
    this.setState({
      receiverName: value.value.split(',')[1],
    });
  };
  onMessageChange = (event) => {
    this.setState({
      message: event.target.value,
    });
  };

  render() {
    if (this.state.loading) return null;
    return (
      <>
        <div
          className='modal '
          id={`newShoutOut${this.props.id}`}
          tabIndex='-1'
          role='dialog'
          aria-labelledby='exampleModalCenterTitle'
          aria-hidden='true'
        >
          <div className='col-xs-2'>
            <div className='modal-arrow-sub' id='top'></div>
          </div>
          <div className='modal-dialog modal-dialog-centered' role='document'>
            <div className='modal-content sub-action'>
              <div className='modal-header'>
                <h5 className='modal-edit-title' id='exampleModalLongTitle'>
                  Shout Out
                </h5>
                <ModalCloseBtn />
              </div>
              <div className='modal-body'>
                <form>
                  <div className='form-group'>
                    <label
                      htmlFor='exampleFormControlInput1'
                      className='action-label'
                    >
                      Employee Name
                    </label>
                    <div className='row'>
                      <div className='col-11 action-input-col'>
                        <Select
                          onChange={this.onReceiverChange.bind(this)}
                          className='action-selector'
                          value={this.state.receiverData}
                          options={this.state.teamNames}
                          placeholder='Search for a teammate...'
                        />
                      </div>
                      <div className='col-1'>
                        <FontAwesomeIcon
                          className='action-i form-control-feedback'
                          icon={['fal', 'search']}
                        />
                      </div>
                    </div>
                  </div>

                  <div className='form-group'>
                    <label
                      htmlFor='exampleFormControlInput4'
                      className='action-label'
                    >
                      Message
                    </label>

                    <textarea
                      onChange={this.onMessageChange}
                      value={this.state.message}
                      type='text'
                      className='form-control shout-out-input'
                      id='q3'
                      placeholder='Type here'
                    />
                  </div>
                </form>
              </div>
              <p className='errors-p p-3'>{this.state.errors.general}</p>
              <div className='modal-footer'>
                <button
                  onClick={this.handleSubmit}
                  type='button'
                  className='btn btn-submit'
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default NewShout;
