import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import Help from './Help';

const HelpBtn = (props) => {
  return (
    <>
      <button
        id='helpModalBtn'
        type='button'
        data-toggle='modal'
        data-target='#helpModal'
      >
        <FontAwesomeIcon className='past-icon' icon={['fas', 'info']} />
      </button>
      <Help source={props.source} section={props.section} text={props.text} />
    </>
  );
};

export default HelpBtn;
