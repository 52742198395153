import React, { useEffect, useRef } from 'react';
import axios from 'axios';
import { useState } from 'react';
import CompanyPage from '../../Components/NavBars/CompanyPage';
import html2canvas from 'html2canvas';

// for individual survey page
import { useParams } from 'react-router-dom';
import moment from 'moment';
import StackedBarChart from '../../Components/NavBars/Charts/StackedBarChart';

const ThisSurvey = (props) => {
  const [loading, setLoading] = useState(true);
  const [companyCode, setCompanyCode] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [companyId, setCompanyId] = useState('');
  const [surveys, setSurveys] = useState([]);

  // questions
  const [q1, setQ1] = useState([]);
  const [q2, setQ2] = useState([]);
  const [q3, setQ3] = useState([]);
  const [q4, setQ4] = useState([]);
  const [q5, setQ5] = useState([]);
  const [q6, setQ6] = useState([]);
  const [q7, setQ7] = useState([]);
  const [q8, setQ8] = useState([]);
  const [q9, setQ9] = useState([]);
  const [q10, setQ10] = useState([]);
  const [q11, setQ11] = useState([]);
  const [q12, setQ12] = useState([]);
  const [q13, setQ13] = useState([]);
  const [q14, setQ14] = useState([]);
  const [q15, setQ15] = useState([]);
  const [q16, setQ16] = useState([]);
  const [q17, setQ17] = useState([]);
  const [q18, setQ18] = useState([]);
  const [q19, setQ19] = useState([]);
  const [q20, setQ20] = useState([]);
  const [q21, setQ21] = useState([]);
  const [q22, setQ22] = useState([]);

  // for individual survey page
  const params = useParams();

  const continuedSorting = (sorted) => {
    setQ1(sorted.x1);
    setQ2(sorted.x2);
    setQ3(sorted.x3);
    setQ4(sorted.x4);
    setQ5(sorted.x5);
    setQ6(sorted.x6);
    setQ7(sorted.x7);
    setQ8(sorted.x8);
    setQ9(sorted.x9);
    setQ10(sorted.x10);
    setQ11(sorted.x11);
    setQ12(sorted.x12);
    setQ13(sorted.x13);
    setQ14(sorted.x14);
    setQ15(sorted.x15);
    setQ16(sorted.x16);
    setQ17(sorted.x17);
    setQ18(sorted.x18);
    setQ19(sorted.x19);
    setQ20(sorted.x20);
    setQ21(sorted.x21);
    setQ22(sorted.x22);
    setLoading(false);
  };

  const sortData = (responses) => {
    const x1 = [];
    const x2 = [];
    const x3 = [];
    const x4 = [];
    const x5 = [];
    const x6 = [];
    const x7 = [];
    const x8 = [];
    const x9 = [];
    const x10 = [];
    const x11 = [];
    const x12 = [];
    const x13 = [];
    const x14 = [];
    const x15 = [];
    const x16 = [];
    const x17 = [];
    const x18 = [];
    const x19 = [];
    const x20 = [];
    const x21 = [];
    const x22 = [];
    for (let i = 0; i < responses.length; i++) {
      x1.push(responses[i].q1);
      x2.push(responses[i].q2);
      x3.push(responses[i].q3);
      x4.push(responses[i].q4);
      x5.push(responses[i].q5);
      x6.push(responses[i].q6);
      x7.push(responses[i].q7);
      x8.push(responses[i].q8);
      x9.push(responses[i].q9);
      x10.push(responses[i].q10);
      x11.push(responses[i].q11);
      x12.push(responses[i].q12);
      x13.push(responses[i].q13);
      x14.push(responses[i].q14);
      x15.push(responses[i].q15);
      x16.push(responses[i].q16);
      x17.push(responses[i].q17);
      x18.push(responses[i].q18);
      x19.push(responses[i].q19);
      x20.push(responses[i].q20);
      x21.push(responses[i].q21);
      x22.push(responses[i].q22);
    }
    continuedSorting({
      x1,
      x2,
      x3,
      x4,
      x5,
      x6,
      x7,
      x8,
      x9,
      x10,
      x11,
      x12,
      x13,
      x14,
      x15,
      x16,
      x17,
      x18,
      x19,
      x20,
      x21,
      x22,
    });
  };

  const getCompanySurveys = () => {
    axios
      .get(`/company/surveys/${params.surveyId}`)
      .then((res) => {
        const data = res.data;
        const responses = data.responses;
        setSurveys(data);
        sortData(responses);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const getCompany = () => {
    axios
      .get('/company')
      .then((res) => {
        const data = res.data.credentials;
        setCompanyCode(data.companyCode);
        setCompanyId(data.companyId);
        setCompanyName(data.companyName);
        getCompanySurveys();
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getCompany();
  }, []);

  const launchSurvey = (e) => {
    e.preventDefault();
    setLoading(true);
    const data = {
      companyId: companyId,
    };
    axios
      .post('/createSurvey', data)
      .then((res) => {
        console.log(res);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const exportRef = useRef();
  const exportAsImage = async (element, imageFileName) => {
    const html = document.getElementsByTagName('html')[0];
    const body = document.getElementsByTagName('body')[0];
    let htmlWidth = html.clientWidth;
    let bodyWidth = body.clientWidth;
    const newWidth = element.scrollWidth - element.clientWidth;
    if (newWidth > element.clientWidth) {
      htmlWidth += newWidth;
      bodyWidth += newWidth;
    }
    html.style.width = htmlWidth + 'px';
    body.style.width = bodyWidth + 'px';
    const canvas = await html2canvas(element);
    const image = canvas.toDataURL('image/png', 1.0);
    downloadImage(image, imageFileName);
    html.style.width = null;
    body.style.width = null;
  };
  const downloadImage = (blob, fileName) => {
    const fakeLink = window.document.createElement('a');
    fakeLink.style = 'display:none;';
    fakeLink.download = fileName;

    fakeLink.href = blob;

    document.body.appendChild(fakeLink);
    fakeLink.click();
    document.body.removeChild(fakeLink);

    fakeLink.remove();
  };

  if (loading) return null;

  return (
    <>
      <CompanyPage
        companyName={companyName}
        content={
          <>
            <div className='company-dash'>
              <div className='dash-inner'>
                <div className=''>
                  <div className='row '>
                    <div className='col-6'>
                      <h1 className='team-stats-header'>Survey</h1>
                      <p className=''>{`launched on ${moment(
                        surveys.created,
                      ).format('MMMM Do YYYY')}`}</p>
                    </div>
                    <div className='col-6'>
                      {' '}
                      <button
                        className='js-textareacopybtn make-div-right'
                        onClick={(e) => launchSurvey(e)}
                      >
                        Launch New Survey
                      </button>
                    </div>
                  </div>
                  <div className='row mb-3 pb-3'>
                    <button
                      className='ticket-btn'
                      onClick={() =>
                        exportAsImage(exportRef.current, 'SuccessRocketSurvey')
                      }
                    >
                      <div className='cta'>Download Survey as PDF ↓</div>
                    </button>
                  </div>
                  {/* {surveys.length >= 1 ?
                                surveys.map((sur) => (
                                    <div className="action-status-row row">
                                        <div className="action-small row" key={sur.surveyId}>
                                            <div className="col-8">
                                                {" "}
                                                <h1 className="ts-header mt-2">
                                                    {`Survey launced on ${sur.created}`}
                                                </h1>
                                            </div>
                                            <div className="col-4">
                                                {" "}
                                                <h1 className="ts-header make-div-right mt-2">View</h1>
                                            </div>
                                        </div>
                                    </div>
                                ))
                                : null} */}
                </div>
              </div>
            </div>

            <div ref={exportRef}>
              <div className='container'>
                <div className='row'>
                  <p1>1. I understand the strategic goals of my company.</p1>
                </div>
                <div className='row'>
                  <StackedBarChart data={q1} />
                </div>
              </div>
              <div className='container'>
                <div className='row'>
                  <p2>
                    2. I am given good opportunities to develop my knowledge and
                    improve my sklls at work.
                  </p2>
                </div>
                <div className='row'>
                  <StackedBarChart data={q2} />
                </div>
              </div>
              <div className='container'>
                <div className='row'>
                  <p3>
                    3. How satisfied are you with your opportunity for growth
                    and development at my company?
                  </p3>
                </div>
                <div className='row'>
                  <StackedBarChart data={q3} />
                </div>
              </div>
              <div className='container'>
                <div className='row'>
                  <p4>
                    4. There are good ways for employees to share their feedback
                    and ideas with senior leadership.
                  </p4>
                </div>
                <div className='row'>
                  <StackedBarChart data={q4} />
                </div>
              </div>
              <div className='container'>
                <div className='row'>
                  <p5>
                    5. I am informed of the reasons and rationale behind changes
                    at my company that impact my work.
                  </p5>
                </div>
                <div className='row'>
                  <StackedBarChart data={q5} />
                </div>
              </div>
              <div className='container'>
                <div className='row'>
                  <p6>
                    6. Conflicting views and opinions are valued while
                    developing ideas and solutions.
                  </p6>
                </div>
                <div className='row'>
                  <StackedBarChart data={q6} />
                </div>
              </div>
              <div className='container'>
                <div className='row'>
                  <p7>7. I am proud of my company.</p7>
                </div>
                <div className='row'>
                  <StackedBarChart data={q7} />
                </div>
              </div>
              <div className='container'>
                <div className='row'>
                  <p8>8. I am proud to work for my company.</p8>
                </div>
                <div className='row'>
                  <StackedBarChart data={q8} />
                </div>
              </div>
              <div className='container'>
                <div className='row'>
                  <p9>9. I feel a strong sense of commitment to my company.</p9>
                </div>
                <div className='row'>
                  <StackedBarChart data={q9} />
                </div>
              </div>
              <div className='container'>
                <div className='row'>
                  <p10>
                    10. In order to help my company, I am willing to put in
                    extra effort
                  </p10>
                </div>
                <div className='row'>
                  <StackedBarChart data={q10} />
                </div>
              </div>
              <div className='container'>
                <div className='row'>
                  <p11>
                    11. I can openly discuss my issues and concerns with my
                    manager.
                  </p11>
                </div>
                <div className='row'>
                  <StackedBarChart data={q11} />
                </div>
              </div>
              <div className='container'>
                <div className='row'>
                  <p12>
                    12. Overall, how good a job do you feel your immediate
                    manager is doing?
                  </p12>
                </div>
                <div className='row'>
                  <StackedBarChart data={q12} />
                </div>
              </div>
              <div className='container'>
                <div className='row'>
                  <p13>
                    13. My immediate manager is helpful when I want to discuss
                    my career plans and objectives.
                  </p13>
                </div>
                <div className='row'>
                  <StackedBarChart data={q13} />
                </div>
              </div>
              <div className='container'>
                <div className='row'>
                  <p14>14. My job makes the best use of my capabilities.</p14>
                </div>
                <div className='row'>
                  <StackedBarChart data={q14} />
                </div>
              </div>
              <div className='container'>
                <div className='row'>
                  <p15>
                    15. Considering everything, how satisfied are you with your
                    job?
                  </p15>
                </div>
                <div className='row'>
                  <StackedBarChart data={q15} />
                </div>
              </div>
              <div className='container'>
                <div className='row'>
                  <p16>
                    16. I understand how my work contributes to the success of
                    my company.
                  </p16>
                </div>
                <div className='row'>
                  <StackedBarChart data={q16} />
                </div>
              </div>
              <div className='container'>
                <div className='row'>
                  <p17>
                    17. I am confident in the ability of senior leadership to
                    lead LifeWorks
                  </p17>
                </div>
                <div className='row'>
                  <StackedBarChart data={q17} />
                </div>
              </div>
              <div className='container'>
                <div className='row'>
                  <p18>
                    18. Senior leadership communicates a clear vision of where
                    my company is headed.
                  </p18>
                </div>
                <div className='row'>
                  <StackedBarChart data={q18} />
                </div>
              </div>
              <div className='container'>
                <div className='row'>
                  <p19>
                    19. There is good cooperation between my team and other
                    teams within my company.
                  </p19>
                </div>
                <div className='row'>
                  <StackedBarChart data={q19} />
                </div>
              </div>
              <div className='container'>
                <div className='row'>
                  <p20>
                    20. In my department we work well together as a team
                  </p20>
                </div>
                <div className='row'>
                  <StackedBarChart data={q20} />
                </div>
              </div>
              <div className='container'>
                <div className='row'>
                  <p21>
                    21. How likely is it that you would recommend my company as
                    a great place to work to a colleague or friend?
                  </p21>
                </div>
                <div className='row'>
                  <StackedBarChart data={q21} />
                </div>
              </div>
              <div className='container'>
                <div className='row'>
                  <p22>
                    22. How likely is it that you will leave the company for
                    another job in the next 12 months?
                  </p22>
                </div>
                <div className='row'>
                  <StackedBarChart data={q22} />
                </div>
              </div>
              <div className='container'>
                <div className='row text-center pt-3 my-3'>
                  <p className='team-stats-header'>{`Launched via Success Rocket on ${moment(
                    surveys.created,
                  ).format('MMMM Do YYYY')}`}</p>
                </div>
              </div>
            </div>
          </>
        }
      />
    </>
  );
};

export default ThisSurvey;
