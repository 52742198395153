import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useHistory } from 'react-router-dom';

const BackBtn = (props) => {
  const history = useHistory();

  const back = () => {
    history.go(-1);
  };
  return (
    <>
      <button onClick={back} className='back-btn'>
        <FontAwesomeIcon
          className={`return-icon ${props.class}`}
          icon={['fal', 'reply']}
        />
      </button>
    </>
  );
};

export default BackBtn;
