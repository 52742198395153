import Chart from 'react-apexcharts';
import './charts.css';

const SideBarChart = (props) => {
  const data = {
    options: {
      chart: {
        id: 'apexchart-action-stats',
        stacked: true,
        type: 'bar',
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          horizontal: true,
          dataLabels: {
            position: 'center',
          },
        },
      },
      grid: {
        show: false,
      },
      yaxis: {
        labels: {
          align: 'left',
          style: {
            colors: [],
            fontSize: '14px',
            fontWeight: 400,
            cssClass: 'apexcharts-yaxis-label',
          },
        },
        axisTicks: {
          show: true,
          width: 6,
          offsetX: 0,
          offsetY: 0,
        },
      },
      xaxis: {
        categories: props.names,
      },
      legend: {
        position: 'bottom',
      },
      tooltip: {
        fixed: {
          enabled: true,
        },
        custom: function ({ series, seriesIndex, dataPointIndex, w }) {
          return (
            '<div className="arrow_box">' +
            '<p className="row arrow_title">' +
            w.globals.labels[dataPointIndex] +
            ': ' +
            series[seriesIndex][dataPointIndex] +
            ' ' +
            w.globals.initialSeries[seriesIndex].name +
            ' Actions' +
            '</p>' +
            '<p className="row"> 🟡‎‎ Low: ' +
            props.actions[dataPointIndex].data[props.currentIndex][seriesIndex]
              .low +
            '</p>' +
            '<p className="row"> 🟢‏‏‎‎‏‏‎ Medium: ' +
            props.actions[dataPointIndex].data[props.currentIndex][seriesIndex]
              .medium +
            '</p>' +
            '<p className="row"> 🔵 High: ' +
            props.actions[dataPointIndex].data[props.currentIndex][seriesIndex]
              .high +
            '</p>' +
            '<p className="row"> 🔴 Critical: ' +
            props.actions[dataPointIndex].data[props.currentIndex][seriesIndex]
              .critical +
            '</p>' +
            '</div>'
          );
        },
      },
    },
    series: [
      {
        name: 'Completed',
        data: props.completed.map((action) => action[props.currentIndex]),
        color: '#800026',
      },
      {
        name: 'Uncompleted',
        data: props.uncompleted.map((action) => action[props.currentIndex]),
        color: '#bd0026',
      },
    ],
  };

  return (
    <Chart
      options={data.options}
      series={data.series}
      type='bar'
      height={300}
    />
  );
};

export default SideBarChart;
