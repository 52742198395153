import React from 'react';
import Header from '../../Components/NavBars/Header';
import { Component } from 'react';
import axios from 'axios';

class FirstSuccess extends Component {
  constructor() {
    super();
    this.state = {
      one: '',
      two: '',
      three: '',
      name: '',
      photo: '',
      rating: '',
      loading: true,
      errors: {},
    };
  }

  componentDidMount = () => {
    axios
      .get('/user')
      .then((res) => {
        const info = res.data.credentials;
        const rating = info.rating;
        this.setState({ name: `Hello ${info.firstName}, Have a nice day!` });
        this.setState({ photo: info.profileImg });
        if (rating === 0) {
          this.setState({ rating: '☆ ☆ ☆ ☆ ☆' });
        } else if (rating === 1) {
          this.setState({ rating: '★ ☆ ☆ ☆ ☆' });
        } else if (rating === 2) {
          this.setState({ rating: '★ ★ ☆ ☆ ☆' });
        } else if (rating === 3) {
          this.setState({ rating: '★ ★ ★ ☆ ☆' });
        } else if (rating === 4) {
          this.setState({ rating: '★ ★ ★ ★ ☆' });
        } else {
          this.setState({ rating: '★ ★ ★ ★ ★' });
        }
        this.setState({ loading: false });
      })
      .catch((error) => {
        console.error('FirstSuccess - Error fetching user data:', error);

        this.setState({
          loading: false,
          error: 'An error occurred while fetching user data.',
        });
      });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    this.setState({
      loading: true,
    });
    let editSuccess = {
      a1: this.state.one,
      a2: this.state.two,
      a3: this.state.three,
    };
    if (editSuccess.a1 === '') {
      editSuccess.a1 = '...';
    } else if (editSuccess.a2 === '') {
      editSuccess.a2 = '...';
    } else if (editSuccess.a3 === '') {
      editSuccess.a3 = '...';
    }
    axios
      .post('/updateScAnswers', editSuccess)
      .then((res) => {
        this.setState({
          loading: false,
        });
        this.props.history.push('/dashboard');
        window.location.reload();
      })
      .catch((err) => {
        console.log(err.response.data);
        this.setState({
          errors: {
            general: 'Error: Please reply to all inputs and try again.',
          },
          loading: false,
        });
      });
  };

  onOneChange = (event) => {
    this.setState({
      one: event.target.value,
    });
  };
  onTwoChange = (event) => {
    this.setState({
      two: event.target.value,
    });
  };
  onThreeChange = (event) => {
    this.setState({
      three: event.target.value,
    });
  };

  render() {
    if (this.state.loading) return null;
    return (
      <>
        <Header
          name={this.state.name}
          rating={this.state.rating}
          photo={this.state.photo}
        />
        <div className='first-sc'>
          <div className='row first-sc-col'>
            <h1 className='first-sc-header'>Success Criteria</h1>
            <form>
              <div className='form-group'>
                <label htmlFor='q1' className='sc-label'>
                  What does success look like in my role?
                </label>
                <textarea
                  value={this.state.one}
                  onChange={this.onOneChange}
                  type='text'
                  className='form-control sc-input'
                  id='q1'
                  placeholder='Type here'
                />
              </div>
              <div className='form-group'>
                <label htmlFor='q2' className='sc-label'>
                  How does my success impact my departments success?
                </label>
                <textarea
                  value={this.state.two}
                  onChange={this.onTwoChange}
                  type='text'
                  className='form-control sc-input'
                  id='q2'
                  placeholder='Type here'
                />
              </div>
              <div className='form-group'>
                <label htmlFor='q3' className='sc-label'>
                  How does my success impact my company success?
                </label>
                <textarea
                  value={this.state.three}
                  onChange={this.onThreeChange}
                  type='text'
                  className='form-control sc-input'
                  id='q3'
                  placeholder='Type here'
                />
              </div>
              <div className='row justify-content-center'>
                <button
                  onClick={this.handleSubmit}
                  type='button'
                  className='sc-btn'
                >
                  Create Profile
                </button>
              </div>
            </form>
          </div>
        </div>
      </>
    );
  }
}

export default FirstSuccess;
