import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import React, { Component } from 'react';
import './actions.css';
import Select from 'react-select';

class GetFeedback extends Component {
  constructor() {
    super();
    this.state = {
      teamNames: [],
      responders: [],
      description: '',
      anon: '',
      loading: false,
      errors: {},
    };
  }

  componentDidMount() {
    this.setState({ loading: true });
    axios.get('/getCompanyDirectory').then((res) => {
      const data = res.data.directory;
      let names = [];
      for (let i = 0; i < data.length; i++) {
        let fName = data[i].firstName.toUpperCase();
        let lName = data[i].lastName.toUpperCase();
        if (data[i].userId !== this.props.me) {
          names.push({
            name: `${fName} ${lName}`,
            id: data[i].userId,
            img: data[i].profileImg,
          });
        }
      }
      names.sort((a, b) =>
        a.name
          .split(' ')[1]
          .charAt(0)
          .localeCompare(b.name.split(' ')[1].charAt(0), 'es', {
            sensitivity: 'base',
          }),
      );
      const formOptions = [];
      for (let i = 0; i < names.length; i++) {
        let thisUser = {
          value: `${names[i].id},${names[i].name}`,
          label: (
            <div>
              {' '}
              <img
                src={names[i].img}
                alt={names[i].id}
                className='newShoutImg'
              />{' '}
              {names[i].name}{' '}
            </div>
          ),
        };
        formOptions.push(thisUser);
      }
      this.setState({ teamNames: formOptions });
    });
  }

  onAnonChange = (event) => {
    this.setState({
      anon: event.target.value,
    });
  };

  onDescriptionChange = (event) => {
    this.setState({
      description: event.target.value,
    });
  };

  onReceiverChange = (value) => {
    this.setState({
      responders: value,
    });
    // this.setState({
    //   receiver: value.value.split(",")[0],
    // });
    // this.setState({
    //   receiverName: value.value.split(",")[1],
    // });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    const responders = this.state.responders;
    let list = [];
    for (let i = 0; i < responders.length; i++) {
      let receiverName;
      receiverName = responders[i].value.split(',')[1];

      let receiver = responders[i].value.split(',')[0];
      list.push({
        name: receiverName,
        responderId: receiver,
      });
    }
    const formData = {
      responders: list,
      description: this.state.description,
      anon: this.state.anon,
    };
    if (formData.responders.length === 0 || formData.responders.length < 3) {
      this.setState({
        errors: { general: 'Error: Please select at least 3 responders.' },
        loading: false,
      });
    } else {
      if (formData.description === '' || formData.anon === '') {
        this.setState({
          errors: { general: 'Error: Please respond to all inputs.' },
          loading: false,
        });
      } else {
        if (formData.responders.length >= 6) {
          this.setState({
            errors: {
              general: 'Please select no more than 5 responders.',
            },
            loading: false,
          });
        } else {
          axios
            .post('/createFeedbackRequest', formData)
            .then((res) => {
              this.setState({
                loading: false,
              });
              window.location.reload();
            })
            .catch((err) => {
              console.log(err.response.data);
              this.setState({
                errors: {
                  general: 'Error: Please check information and try again.',
                },
                loading: false,
              });
            });
        }
      }
    }
  };

  render() {
    return (
      <>
        <div
          className='modal '
          id='getFeedback'
          tabIndex='-1'
          role='dialog'
          aria-labelledby='exampleModalCenterTitle'
          aria-hidden='true'
        >
          <div className='col-xs-2'>
            <div className='modal-arrow' id='top'></div>
          </div>
          <div className='modal-dialog modal-dialog-centered' role='document'>
            <div className='modal-content main-action'>
              <div className='modal-header'>
                <h3 className='modal-title' id='exampleModalLongTitle'>
                  <FontAwesomeIcon
                    className='icon-mobile'
                    icon={['fal', 'sync']}
                  />
                </h3>
                <button
                  type='button'
                  className='closeBtn mt-1'
                  data-dismiss='modal'
                  aria-label='Close'
                >
                  <span aria-hidden='true'>&times;</span>
                </button>
              </div>
              <div className='modal-body'>
                <form>
                  <div className='form-group'>
                    <label
                      htmlFor='exampleFormControlInput4'
                      className='action-label'
                    >
                      Description
                    </label>
                    <input
                      onChange={this.onDescriptionChange}
                      value={this.state.description}
                      type='text'
                      className='form-control action-input'
                      placeholder=''
                    />
                  </div>

                  <div className='form-group mt-3'>
                    <label
                      htmlFor='exampleFormControlInput5'
                      className='action-label'
                    >
                      Responders
                    </label>
                    <div className='row'>
                      <div className='col-11 action-input-col'>
                        <Select
                          onChange={this.onReceiverChange.bind(this)}
                          isMulti
                          className='action-selector feedback-selector'
                          options={this.state.teamNames}
                        />
                      </div>
                      <div className='col-1 action-icon-col'>
                        <span className=''>
                          <FontAwesomeIcon
                            className='action-i form-control-feedback'
                            icon={['fal', 'plus-circle']}
                          />
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className='form-group mt-3'>
                    <div className='row'>
                      <label
                        htmlFor='exampleFormControlInput4'
                        className='action-label'
                      >
                        Responses
                      </label>
                    </div>
                    <div className='p-2 '>
                      <input
                        onChange={this.onAnonChange}
                        className='form-check-input'
                        type='radio'
                        name='inlineRadioOptions'
                        id='inlineRadio1'
                        value={true}
                      />
                      <label
                        className='form-check-label action-label'
                        htmlFor='inlineRadio1'
                      >
                        Anonymous
                      </label>
                    </div>
                    <div className='p-2 '>
                      <input
                        onChange={this.onAnonChange}
                        className='form-check-input'
                        type='radio'
                        name='inlineRadioOptions'
                        id='inlineRadio2'
                        value={false}
                      />
                      <label
                        className='form-check-label action-label'
                        htmlFor='inlineRadio2'
                      >
                        Not Anonymous
                      </label>
                    </div>
                  </div>
                </form>
              </div>
              <p className='errors-p p-3'>{this.state.errors.general}</p>
              <div className='modal-footer'>
                <button
                  type='button'
                  onClick={this.handleSubmit}
                  className='btn btn-submit'
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default GetFeedback;
