import axios from 'axios';
import React, { Component } from 'react';
import $ from 'jquery';
import ConfettiExplosion from '@reonomy/react-confetti-explosion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/pro-solid-svg-icons';
import DeleteAction from './DeleteAction';
import ModalCloseBtn from '../../Constants/ModalCloseBtn';

class EditSub extends Component {
  constructor() {
    super();
    this.state = {
      id: '',
      main: '',
      action: '',
      dDate: '',
      priority: '',
      status: '',
      loading: true,
      isExploding: false,
      errors: {},
    };
  }

  componentDidMount() {
    this.setState({ id: this.props.id });
    this.setState({ priority: this.props.priority });
    this.setState({ action: this.props.title });
    this.setState({ dDate: this.props.dDate });
    this.setState({ main: this.props.main });
    this.setState({ status: this.props.status });

    this.setState({ loading: false });
  }

  handleSubmit = (event) => {
    event.preventDefault();
    this.setState({
      loading: true,
    });
    const editSubAction = {
      actionId: this.state.id,
      title: this.state.action,
      completed: this.state.status,
    };
    console.log(editSubAction);
    if (editSubAction.title === '' || editSubAction.completed === '') {
      this.setState({
        errors: { general: 'Error: Please check information and try again.' },
        loading: false,
      });
    } else {
      axios
        .post(`/editSubAction/${this.state.id}`, editSubAction)
        .then((res) => {
          this.setState({
            loading: false,
          });
          if (this.props.status === false && this.state.status === 'true') {
            $(`#sub${this.state.id}btn`).trigger('click');
            this.setState({ isExploding: true });
            setTimeout(() => {
              window.location.reload();
            }, 2000);
          } else {
            window.location.reload();
          }
        })
        .catch((err) => {
          console.log(err.response.data);
          this.setState({
            errors: {
              general: 'Error: Please check information and try again.',
            },
            loading: false,
          });
          $(`#sub${this.state.id}btn`).trigger('click');
          this.setState({ isExploding: true });
        });
    }
  };
  onActionChange = (event) => {
    this.setState({
      action: event.target.value,
    });
  };
  onStatusChange = (event) => {
    this.setState({
      status: event.target.value,
    });
  };

  render() {
    if (this.state.loading) return null;
    return (
      <>
        <div
          className='modal '
          id={`edit${this.state.id}`}
          tabIndex='-1'
          role='dialog'
          aria-labelledby='exampleModalCenterTitle'
          aria-hidden='true'
        >
          <div className='col-xs-2'>
            <div className='modal-arrow-sub' id='top'></div>
          </div>
          <div className='modal-dialog modal-dialog-centered' role='document'>
            <div className='modal-content sub-action'>
              <div className='modal-header'>
                <h5 className='modal-edit-title' id='exampleModalLongTitle'>
                  Edit Action
                </h5>
                <ModalCloseBtn />
              </div>
              {this.state.isExploding && <ConfettiExplosion />}
              <div className='modal-body'>
                <form>
                  <div className='form-group '>
                    <label
                      htmlFor='exampleFormControlInput5'
                      className='action-label'
                    >
                      Main Action
                    </label>
                    <div className=''>
                      <div className='disabled-input action-input-col'>
                        <select className='form-select action-input' disabled>
                          <option>{this.state.main}</option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className='form-group'>
                    <label
                      htmlFor='exampleFormControlInput3'
                      className='action-label'
                    >
                      Action
                    </label>
                    <input
                      type='text'
                      className='form-control action-input'
                      onChange={this.onActionChange}
                      value={this.state.action}
                    ></input>
                  </div>
                  <div className='form-group'>
                    <label
                      htmlFor='exampleFormControlInput5'
                      className='action-label'
                    >
                      Priority
                    </label>
                    <div className='row'>
                      <div className='col-11 action-input-col'>
                        <p
                          type='text'
                          className='form-control action-input disabled-group'
                        >
                          <FontAwesomeIcon
                            className={`priority-icon-${this.state.priority}`}
                            icon={faCircle}
                          />{' '}
                          {this.state.priority}
                        </p>
                      </div>
                      <div className='col-1 action-icon-col'>
                        <span className='action-icon'>
                          <FontAwesomeIcon
                            className='action-i form-control-feedback'
                            icon={['fal', 'chevron-down']}
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className='form-group'>
                    <label
                      htmlFor='exampleFormControlInput6'
                      className='action-label'
                    >
                      Due Date
                    </label>
                    <input
                      type=''
                      className='form-control action-input disabled-input'
                      value={this.state.dDate}
                      disabled
                    />
                  </div>
                  <div className='form-group'>
                    <label
                      htmlFor='exampleFormControlInput7'
                      className='action-label'
                    >
                      Status
                    </label>
                    <select
                      onChange={this.onStatusChange}
                      value={this.state.status}
                      className='form-select action-select '
                    >
                      <option value={false}>Not Complete</option>
                      <option value={true}>Complete</option>
                    </select>
                  </div>
                </form>
              </div>
              <p className='errors-p p-3'>{this.state.errors.general}</p>
              <div className='row'>
                <div className='col-6'>
                  <div className='modal-footer modal-footer-2'>
                    <button
                      type='button'
                      // onClick={this.onDelete}
                      className='btn btn-delete'
                      data-toggle='modal'
                      id={`delete${this.state.id}btn`}
                      data-target={`#delete${this.state.id}`}
                    >
                      <FontAwesomeIcon
                        className='pencil-icon'
                        icon={['fas', 'trash']}
                      />
                    </button>
                    <DeleteAction id={this.state.id} name={this.state.action} />
                  </div>
                </div>
                <div className='col-6'>
                  <div className='modal-footer'>
                    <button
                      type='button'
                      onClick={this.handleSubmit}
                      className='btn btn-submit'
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default EditSub;
