import axios from 'axios';
import React, { useEffect, useState } from 'react';

function OnCardView(props) {
  const [loading, setLoading] = useState(true);
  const [goal, setGoal] = useState('');
  const [name, setName] = useState('');
  const [errors, setErrors] = useState({});
  useEffect(() => {
    const name = props.name;
    const last = name.charAt(name.length - 1);
    let finalName = '';
    if (last === 's' || last === 'S') {
      finalName = `${name}'`;
    } else {
      finalName = `${name}'s`;
    }
    setName(finalName);
    const id = props.id;
    axios
      .get(`/getOnboardById/${id}`)
      .then((res) => {
        const answer = res.data[0].answer;
        let theGoal;
        console.log(res.data);
        if (!answer) {
          theGoal = '';
        } else {
          theGoal = answer;
        }
        setGoal(theGoal);
        setLoading(false);
      })
      .catch((err) => {
        setErrors(err);
        setLoading(false);
      });
  }, []);
  if (loading) return null;
  return (
    <>
      <div className='col-md-5 shout-card text-light mb-3'>
        <div className='row'>
          <div className='col-10'>
            <h3 className='shout-header'>{`${name} Onboarding Plan:`}</h3>
          </div>
          <div className='col-2'>
            {/* <button
                    id="onModal"
                    className="onboard-modal"
                    type="button"
                    data-toggle="modal"
                    data-target="#onmodal-edit"
                  >
                    <FontAwesomeIcon
                      className="text-light"
                      icon={["fas", "pencil"]}
                    />
                  </button> */}
            {/* <OnboardEdit
                        goal={goal}
                  /> */}
          </div>
        </div>
        <div className='shout-text'>{goal}</div>
      </div>
    </>
  );
}

export default OnCardView;
