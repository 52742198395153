import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component } from 'react';
import { faCircle } from '@fortawesome/pro-solid-svg-icons';
import $ from 'jquery';
import axios from 'axios';
import ModalCloseBtn from '../../Constants/ModalCloseBtn';

class RatedAction extends Component {
  constructor() {
    super();
    this.state = {
      id: '',
      type: '',
      sub: false,
      main: '',
      name: '',
      action: '',
      description: '',
      priority: '',
      dDate: '',
      status: true,
      rating: '',
      comment: '',
      loading: true,
      errors: {},
    };
  }

  // componentDidMount() {

  //   this.setState({ loading: false });
  // }

  handleSubmit = (event) => {
    event.preventDefault();
    this.setState({
      loading: true,
    });
    const archiveAction = {
      id: this.props.id,
    };
    console.log(archiveAction);
    axios
      .post('/editAction', archiveAction)
      .then((res) => {
        window.location.reload();
      })
      .catch((err) => {
        console.log(err.response.data);
        this.setState({
          errors: { general: 'Error: Please check information and try again.' },
          loading: false,
        });
        $(`#rated${this.props.id}btn`).trigger('click');
      });
  };

  render() {
    // if (this.state.loading) return null;
    return (
      <>
        <div
          className='modal '
          id={`rated${this.props.id}`}
          tabIndex='-1'
          role='dialog'
          aria-labelledby='exampleModalCenterTitle'
          aria-hidden='true'
        >
          <div className='col-xs-2'>
            <div className='modal-arrow-sub' id='top'></div>
          </div>
          <div className='modal-dialog modal-dialog-centered' role='document'>
            <div className='modal-content sub-action'>
              <div className='modal-header'>
                <h5 className='modal-edit-title' id='exampleModalLongTitle'>
                  Rated Action
                </h5>
                <ModalCloseBtn />
              </div>
              <div className='modal-body'>
                <form>
                  <div
                    className={
                      this.props.sub
                        ? 'form-group disabled-group'
                        : 'form-group hidden-group'
                    }
                  >
                    <label
                      htmlFor='exampleFormControlInput5'
                      className='action-label'
                    >
                      Main Action
                    </label>
                    <div className=''>
                      <div className=' action-input-col'>
                        <select className='form-select action-input' disabled>
                          <option>{this.props.main}</option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <div
                    className={
                      this.props.sub
                        ? 'form-group hidden-group'
                        : 'form-group disabled-group'
                    }
                  >
                    <label
                      htmlFor='exampleFormControlInput5'
                      className='action-label'
                    >
                      Action
                    </label>
                    <div className=''>
                      <div className=' action-input-col'>
                        <select className='form-select action-input' disabled>
                          <option>{this.props.title}</option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <div
                    className={
                      this.props.sub
                        ? 'form-group disabled-group'
                        : 'form-group hidden-group'
                    }
                  >
                    <label
                      htmlFor='exampleFormControlInput3'
                      className='action-label'
                    >
                      Action
                    </label>
                    <input
                      type='text'
                      className='form-control action-input'
                      value={this.props.title}
                      disabled
                    ></input>
                  </div>

                  <div
                    className={
                      this.props.sub
                        ? 'form-group hidden-group'
                        : 'form-group disabled-group'
                    }
                  >
                    <label
                      htmlFor='exampleFormControlInput4'
                      className='action-label'
                    >
                      Description
                    </label>
                    <input
                      type='text'
                      className='form-control action-input'
                      placeholder=''
                      value={this.props.description}
                      disabled
                    />
                  </div>

                  <div className='form-group disabled-group'>
                    <label
                      htmlFor='exampleFormControlInput5'
                      className='action-label'
                    >
                      Priority
                    </label>
                    <div className='row'>
                      <div className='col-11 action-input-col'>
                        <p
                          type='text'
                          className='form-control action-input'
                          disabled
                        >
                          <FontAwesomeIcon
                            className={`priority-icon-${this.props.priority}`}
                            icon={faCircle}
                          />{' '}
                          {this.props.priority}
                        </p>
                      </div>
                      <div className='col-1 action-icon-col'>
                        <span className='action-icon'>
                          <FontAwesomeIcon
                            className='action-i form-control-feedback'
                            icon={['fal', 'chevron-down']}
                          />
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className='form-group disabled-group'>
                    <label
                      htmlFor='exampleFormControlInput6'
                      className='action-label'
                    >
                      Due Date
                    </label>
                    <p className='form-control action-input'>
                      {this.props.dDate}
                    </p>
                  </div>
                  <div className='form-group disabled-group'>
                    <label
                      htmlFor='exampleFormControlInput7'
                      className='action-label'
                    >
                      Status
                    </label>
                    <select className='form-select action-select ' disabled>
                      <option>
                        {this.props.status ? 'Complete' : 'Not Complete'}
                      </option>
                    </select>
                  </div>
                  <div
                    className={
                      this.props.status
                        ? 'form-group'
                        : 'form-group disabled-group'
                    }
                  >
                    <label
                      htmlFor='exampleFormControlInput7'
                      className='action-label'
                    >
                      Action Rating
                    </label>
                    <select
                      className='form-select action-input star-select'
                      {...(this.props.status
                        ? { disabled: false }
                        : { disabled: true })}
                      disabled
                    >
                      <option>{this.props.rating}</option>
                      <option>★ ★ ★ ★ ☆</option>
                      <option>★ ★ ★ ☆ ☆</option>
                      <option>★ ★ ☆ ☆ ☆</option>
                      <option>★ ☆ ☆ ☆ ☆</option>
                      <option>☆ ☆ ☆ ☆ ☆</option>
                    </select>
                  </div>
                  <div className='form-group'>
                    <label
                      htmlFor='exampleFormControlInput7'
                      className='action-label'
                    >
                      Comment
                    </label>
                    <input
                      type='text'
                      className='form-control action-input'
                      placeholder=''
                      value={this.props.comment}
                      disabled
                    />
                  </div>
                </form>
              </div>
              <p className='errors-p p-3'>{this.state.errors.general}</p>
              <div className='modal-footer'>
                <button
                  onClick={this.handleSubmit}
                  type='button'
                  className='btn btn-submit'
                >
                  Archive
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default RatedAction;
