import React, { Component } from 'react';
import Page from '../../Components/NavBars/Page';
import BackBtn from '../../Components/Back/BackBtn';
import axios from 'axios';
import { analytics } from '../../firebaseConfig.js';

class ViewResponse extends Component {
  constructor() {
    super();
    this.state = {
      check: [],
      name: '',
      date: '',
      r1: '',
      r2: '',
      r3: '',
      loading: true,
      errors: {},
    };
    this.accordionRefs = [];
  }

  componentDidMount(props) {
    analytics.logEvent(`view_res_visited`);

    axios
      .get('/getCheckins')
      .then((res) => {
        if (res.data === 0) {
          console.log('no checkins');
          this.setState({ loading: false });
        } else {
          const data = res.data;
          for (let i = 0; i < data.length; i++) {
            if (this.props.match.params.id === data[i].checkinId) {
              this.setState({
                check: data[i].checkin.sort(
                  (a, b) => Date.parse(b.created) - Date.parse(a.created),
                ),
              });
              if (data[i].response === true) {
                this.setState({ name: 'Your Overseer' });
                this.setState({ date: ':' });
              } else {
                this.setState({ name: 'Your Overseer has not' });
                this.setState({ date: ' yet.' });
              }
            }
          }
        }
      })
      .catch((error) => {
        console.error('ViewResponse - Error fetching checkins:', error);

        this.setState({
          loading: false,
          error: 'An error occurred while fetching checkins.',
        });
      });

    axios
      .get(`/getCheckinResponses/${this.props.match.params.id}`)
      .then((res) => {
        const data = res.data[0];
        if (data === undefined) {
          this.setState({ r1: '' });
          this.setState({ r2: '' });
          this.setState({ r3: '' });
        } else {
          const r1 = data.r1;
          const r2 = data.r2;
          const r3 = data.r3;
          this.setState({ r1: r1 });
          this.setState({ r2: r2 });
          this.setState({ r3: r3 });
        }
        this.setState({ loading: false });
      })
      .catch((error) => {
        console.error(
          'ViewResponse - Error fetching checkin responses:',
          error,
        );

        this.setState({
          loading: false,
          error: 'An error occurred while fetching checkin responses.',
        });
      });
  }

  render() {
    if (this.state.loading) return null;

    return (
      <>
        <Page
          content={
            <div className='view-check-page'>
              <BackBtn link='check' className='back-check' />
              <div className='row'>
                <h1 className='view-check-header'>View Check-in Responses</h1>
              </div>

              <div className='row'>
                <h3>From 3 months ago how do you feel like you are doing?</h3>
              </div>
              <div id='accordion1'>
                {this.state.check &&
                  this.state.check.map((checkIn, i) => {
                    let expanded =
                      this.props.location.state.created === checkIn.created
                        ? true
                        : false;
                    return (
                      <div className='card' key={checkIn.created}>
                        <div className='card-header' id={`heading1${i}`}>
                          <h5 className='mb-0'>
                            <button
                              className='btn'
                              data-toggle='collapse'
                              data-target={`#collapse1${i}`}
                              aria-expanded={expanded}
                              aria-controls={`collapse1${i}`}
                            >
                              {checkIn.created.substring(0, 10)}
                            </button>
                          </h5>
                        </div>

                        <div
                          id={`collapse1${i}`}
                          className={expanded ? 'collapse show' : 'collapse'}
                          aria-labelledby={`heading1${i}`}
                          data-parent='#accordion1'
                        >
                          <div className='card-body'>
                            {checkIn.a1}
                            <hr />
                            {this.state.r1 !== ''
                              ? this.state.r1
                              : 'No response yet'}
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>

              <div className='row'>
                <h3>
                  What things do you need to further work on to achieve
                  sustained success?
                </h3>
              </div>
              <div id='accordion2'>
                {this.state.check &&
                  this.state.check.map((checkIn, i) => {
                    let expanded = i === 0 ? true : false;
                    return (
                      <div className='card' key={checkIn.created}>
                        <div className='card-header' id={`heading2${i}`}>
                          <h5 className='mb-0'>
                            <button
                              className='btn'
                              data-toggle='collapse'
                              data-target={`#collapse2${i}`}
                              aria-expanded={expanded}
                              aria-controls={`collapse2${i}`}
                            >
                              {checkIn.created.substring(0, 10)}
                            </button>
                          </h5>
                        </div>

                        <div
                          id={`collapse2${i}`}
                          className={expanded ? 'collapse show' : 'collapse'}
                          aria-labelledby={`heading2${i}`}
                          data-parent='#accordion2'
                        >
                          <div className='card-body'>
                            {checkIn.a2}
                            <hr />
                            {this.state.r2 !== ''
                              ? this.state.r2
                              : 'No response yet'}
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>

              <div className='row'>
                <h3>What barriers to further success exist?</h3>
              </div>
              <div id='accordion3'>
                {this.state.check &&
                  this.state.check.map((checkIn, i) => {
                    let expanded = i === 0 ? true : false;
                    return (
                      <div className='card' key={checkIn.created}>
                        <div className='card-header' id={`heading3${i}`}>
                          <h5 className='mb-0'>
                            <button
                              className='btn'
                              data-toggle='collapse'
                              data-target={`#collapse3${i}`}
                              aria-expanded={expanded}
                              aria-controls={`collapse3${i}`}
                            >
                              {checkIn.created.substring(0, 10)}
                            </button>
                          </h5>
                        </div>

                        <div
                          id={`collapse3${i}`}
                          className={expanded ? 'collapse show' : 'collapse'}
                          aria-labelledby={`heading3${i}`}
                          data-parent='#accordion3'
                        >
                          <div className='card-body'>
                            {checkIn.a3}
                            <hr />
                            {this.state.r3 !== ''
                              ? this.state.r3
                              : 'No response yet'}
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          }
        />
      </>
    );
  }
}

export default ViewResponse;
