import { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import './charts.css';

const StackedBarChart = (props) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});
  const [promoters, setPromoters] = useState(0);
  const [passive, setPassive] = useState(0);
  const [detractors, setDetractors] = useState(0);
  const sortData = (theData) => {
    let pr = 0;
    let pa = 0;
    let d = 0;
    for (let i = 0; i < theData.data.length; i++) {
      console.log(theData.data[i]);
      if (parseInt(theData.data[i]) <= 3) {
        d = d + 1;
      } else if (
        parseInt(theData.data[i]) <= 6 &&
        parseInt(theData.data[i]) > 3
      ) {
        pa = pa + 1;
      } else if (parseInt(theData.data[i]) > 6) {
        pr = pr + 1;
      }
    }
    setPromoters(pr);
    setPassive(pa);
    setDetractors(d);
    const xdata = {
      options: {
        chart: {
          id: 'apexchart-this-survey',
          stacked: true,
          stackType: '100%',
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            horizontal: true,
          },
        },
        grid: {
          show: false,
        },
        yaxis: {
          show: false,
        },
        xaxis: {
          categories: ['Amount'],
        },
      },
      series: [
        {
          name: 'Promoters',
          data: [pr],
          color: '#21881F',
        },
        {
          name: 'Passive',
          data: [pa],
          color: '#0570b0',
        },
        {
          name: 'Detractors',
          data: [d],
          color: '#BE2136',
        },
      ],
    };
    setData(xdata);
    setLoading(false);
  };
  useEffect(() => {
    sortData(props);
  }, []);

  if (loading) return null;

  return (
    <Chart
      options={data.options}
      series={data.series}
      type='bar'
      height={300}
    />
  );
};

export default StackedBarChart;
