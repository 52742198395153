import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Contact = (props) => {
  return (
    <>
      <div className='contact row'>
        <div className='col-3 text-center'>
          <img
            className=' contact-icon'
            src={props.profileImg}
            alt={props.name}
          />
        </div>
        <div className='col-6 col-lg-4'>
          <p className='contact-text'>
            <strong>{props.name}</strong>
          </p>
          <p className='contact-text'>{props.number}</p>
          <p className='contact-text'>{props.email}</p>
        </div>
        <div className='col-3 text-left'>
          <div className='row'>
            <div className='col-6 c-btn-outside'>
              <a
                className='btn btn-light contact-btn'
                href={`tel:${props.number}`}
              >
                <FontAwesomeIcon
                  icon={['fal', 'phone']}
                  className='contact-action-icon'
                />
              </a>
            </div>
            <div className='col-6 c-btn-outside'>
              <a
                className='btn btn-light contact-btn'
                href={`mailto:${props.email}`}
              >
                <FontAwesomeIcon
                  icon={['fal', 'envelope']}
                  className='contact-action-icon'
                />
              </a>
            </div>
          </div>
        </div>
        <div className='col-lg-2'></div>
      </div>
    </>
  );
};

export default Contact;
