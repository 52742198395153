import React, { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { useState } from 'react';
import CompanyPage from '../../Components/NavBars/CompanyPage';
import CompanyInfo2 from './Companyinfo2';

const AdminCompanySettings = (props) => {
  const [loading, setLoading] = useState(true);
  const [companyCode, setCompanyCode] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [companyId, setCompanyId] = useState('');

  const getCompany = () => {
    axios
      .get('/company')
      .then((res) => {
        const data = res.data.credentials;
        setCompanyCode(data.companyCode);
        setCompanyId(data.companyId);
        setCompanyName(data.companyName);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getCompany();
  }, []);

  if (loading) return null;

  return (
    <>
      <CompanyPage
        companyName={companyName}
        content={
          <>
            <div className='company-dash'>
              <div className='dash-inner'>
                <div className='row'>
                  <div className='col-md-6'>
                    <div className='row'>
                      <div className='col-7 mb-3 pb-3'>
                        <button className='js-textareacopybtn'>
                          Generate New
                        </button>
                      </div>
                    </div>
                    <div className='row add-r-box'>
                      <div className='col-9'>
                        <p>Add or Remove users</p>
                      </div>
                      <div className='col-1'>
                        <p>
                          <FontAwesomeIcon
                            className=''
                            icon={['fas', 'plus']}
                          />
                        </p>
                      </div>
                      <div className='col-1'>
                        <p>
                          <FontAwesomeIcon
                            className=''
                            icon={['fas', 'minus']}
                          />
                        </p>
                      </div>
                    </div>
                    <div className='row'>
                      <div className='col-11'>
                        <h2 className='success-info-header'>
                          Your Companies Success Criteria Questions
                        </h2>
                      </div>
                      <div className='col-1'>
                        <Link to='/editCompanySuccess'>
                          <FontAwesomeIcon
                            className='edit-success-icon text-dark'
                            icon={['fal', 'pencil']}
                          />
                        </Link>
                      </div>
                    </div>
                    <div className='row'>
                      <p className='success-info-title'>
                        What does success look like in my role?
                      </p>
                    </div>
                    <div className='row'>
                      <p className='success-info-title'>
                        How does my success impact my departments success?{' '}
                      </p>
                    </div>
                    <div className='row'>
                      <p className='success-info-title'>
                        How does my success impact my companies success?
                      </p>
                    </div>
                    {/* <div className="row justify-content-center mt-3 pt-3">

                <Link to="/companyDirectory" className="no-decor">
                  <div className="row download-box mt-3">
                    <div className="col-2 icon-col">
                      <FontAwesomeIcon
                        className="text-light company-directory"
                        icon={["fal", "users"]}
                      />
                    </div>
                    <div className="col-10 text-col">
                      <p className="box-text text-left mt-1 pb-1">
                        Click here to view your users.
                      </p>
                    </div>
                  </div>
                </Link>
              </div> */}
                  </div>
                  <div className='col-md-6'>
                    <div className='row justify-content-center'>
                      <div className='form-row'>
                        <div>
                          <CompanyInfo2 />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>{' '}
            </div>
          </>
        }
      />
    </>
  );
};

export default AdminCompanySettings;
