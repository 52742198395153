import axios from 'axios';
import React, { Component } from 'react';
import './company.css';
import $ from 'jquery';

class CreateCompany extends Component {
  constructor() {
    super();
    this.state = {
      firstName: '',
      lastName: '',
      phone: '',
      email: '',
      companyName: '',
      city: '',
      state: '',
      zip: '',
      address: '',
      password: '',
      confirmPassword: '',
      loading: false,
      errors: {},
    };
  }
  getCompany = () => {
    axios
      .get('/company')
      .then((res) => {
        const data = res.data.credentials;
        const companyInfo = res.data.credentials.companyInformation;
        const upcoming = data.nextPayment;
        const d = new Date(upcoming);
        this.setState({
          phone: companyInfo.phone,
          address: companyInfo.street,
          city: companyInfo.city,
          state: companyInfo.state,
          zip: companyInfo.zip,
          companyUrl: companyInfo.url,
          nextPayment: d,
          companyCode: data.companyCode,
          companyId: data.companyId,
          companyName: data.companyName,
          loading: false,
        });
      })
      .catch((err) => {
        this.setState({
          loading: false,
        });
      });
  };

  componentDidMount = () => {
    this.getCompany();
  };
  handleSubmit = (event) => {
    event.preventDefault();
    this.setState({
      loading: true,
    });
    const signUpData = {
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      phone: this.state.phone,
      email: this.state.email,
      companyName: this.state.companyName,
      password: this.state.password,
      confirmPassword: this.state.confirmPassword,
    };
    console.log(signUpData);
    axios
      .post('/companySignup', signUpData)
      .then((res) => {
        localStorage.setItem('IdToken', `Bearer ${res.data.IdToken}`);
        this.setState({
          loading: false,
        });
        this.props.history.push('/companyInfo');
      })
      .catch((err) => {
        console.log(err.response.data);
        this.setState({
          errors: err.response.data,
          loading: false,
        });
      });
  };
  onFirstNameChange = (event) => {
    this.setState({
      firstName: event.target.value,
    });
  };
  onLastNameChange = (event) => {
    this.setState({
      lastName: event.target.value,
    });
  };
  onPhoneChange = (event) => {
    $('.number').keyup(function () {
      if (this.value.length === this.maxLength) {
        $(this).next('.number').focus();
      }
    });
    const val1 = $('#number1').val();
    const val2 = $('#number2').val();
    const val3 = $('#number3').val();
    this.setState({
      phone: val1 + '-' + val2 + '-' + val3,
    });
  };
  onEmailChange = (event) => {
    this.setState({
      email: event.target.value,
    });
  };
  onCompanyNameChange = (event) => {
    this.setState({
      companyName: event.target.value,
    });
  };
  onPasswordChange = (event) => {
    this.setState({
      password: event.target.value,
    });
  };
  onConfirmPasswordChange = (event) => {
    this.setState({
      confirmPassword: event.target.value,
    });
  };
  render() {
    return (
      <div className='login-page'>
        <div className='row justify-content-center'>
          <p className='errors-p2'>{this.state.errors.address}</p>
          <div className='form-group'>
            <label htmlFor='city' className='sign-label'>
              Company City
            </label>
            <input
              value={this.state.city}
              onChange={this.onCityChange}
              type='city'
              className='form-control sign-input'
              id='city'
              aria-describedby='City'
              placeholder=''
            />
          </div>
          <p className='errors-p2'>{this.state.errors.city}</p>
          <div className='form-group'>
            <label htmlFor='state' className='sign-label'>
              Company State
            </label>
            <input
              value={this.state.state}
              onChange={this.onStateChange}
              type='state'
              className='form-control sign-input'
              id='state'
              aria-describedby='State'
              placeholder=''
            />
          </div>
          <p className='errors-p2'>{this.state.errors.state}</p>
          <div className='form-group'>
            <label htmlFor='zip' className='sign-label'>
              Company Zip
            </label>
            <input
              value={this.state.zip}
              onChange={this.onZipChange}
              type='zip'
              className='form-control sign-input'
              id='zip'
              aria-describedby='Zip Code'
              placeholder=''
            />
          </div>
          <p className='errors-p2'>{this.state.errors.zip}</p>
          <div className='form-group'>
            <label htmlFor='phone' className='sign-label'>
              Company Phone
            </label>
            <div className='row'></div>
            <input
              type='text'
              id='number1'
              className='number sign-input'
              maxLength='3'
              size='3'
              onChange={this.onPhoneChange}
            />{' '}
            -{' '}
            <input
              type='text'
              id='number2'
              className='number sign-input'
              maxLength='3'
              size='3'
              onChange={this.onPhoneChange}
            />{' '}
            -{' '}
            <input
              type='text'
              id='number3'
              className='number sign-input'
              maxLength='4'
              size='5'
              onChange={this.onPhoneChange}
            />
            {/* <input value={this.state.companyPhone} onChange={this.onCompanyPhoneChange} type="phone" className="form-control sign-input" id="phone" placeholder=""/> */}
          </div>
          <p className='errors-p2'>{this.state.errors.companyPhone}</p>
          <div className='form-group'>
            <label htmlFor='url' className='sign-label'>
              Company Website URL
            </label>
            <input
              value={this.state.companyUrl}
              onChange={this.onCompanyUrlChange}
              type='text'
              className='form-control sign-input'
              id='url'
              placeholder=''
            />
          </div>
          <p className='errors-p2'>{this.state.errors.companyUrl}</p>
          <div className='row justify-content-center'>
            <div className='signin-btn-row bottom'>
              <button
                className='package-btn'
                type='submit'
                onClick={this.handleSubmit}
              >
                Update
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CreateCompany;
