// User reducer types
export const SET_AUTHENTICATED = 'SET_AUTHENTICATED';
export const SET_UNAUTHENTICATED = 'SET_UNAUTHENTICATED';
export const SET_USER = 'SET_USER';
export const LOADING_USER = 'LOADING_USER';
export const MARK_NOTIFICATIONS_READ = 'MARK_NOTIFICATIONS_READ';
// UI reducer types
export const SET_ERRORS = 'SET_ERRORS';
export const LOADING_UI = 'LOADING_UI';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';
export const LOADING_DATA = 'LOADING_DATA';
export const STOP_LOADING_UI = 'STOP_LOADING_UI';
// Data reducer types
export const SET_MANAGER = 'SET_MANAGER';
export const SET_ACTIONS = 'SET_ACTIONS';
export const SET_ACTION = 'SET_ACTION';
export const SET_SHOUTOUTS = 'SET_SHOUTOUTS';
export const SET_SHOUTOUT = 'SET_SHOUTOUT';
export const DELETE_ACTION = 'DELETE_ACTION';
export const DELETE_SHOUTOUT = 'DELETE_SHOUTOUT';
export const POST_ACTION = 'POST_ACTION';
export const POST_SHOUTOUT = 'POST_SHOUTOUT';
