import React, { useEffect } from 'react';
import Spinner from '../../Components/Loading/Spinner';

const CompanyLoading = (props) => {
  useEffect(() => {
    const id = localStorage.getItem('IdToken');
    console.log('Getting ID Token...');
    if (id) {
      props.history.push('/companyInfo');
    } else {
      props.history.push('/createCompany');
    }
  }, [props.history]);

  return <Spinner />;
};

export default CompanyLoading;
