import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import NewShout from '../Actions/NewShout';
import axios from 'axios';
import { DEFAULT_IMAGE_URL } from '../../Constants/Constants';

const ShoutCard = (props) => {
  const data = {
    id: 'randomForShoutCard',
  };

  const [me, setMe] = useState();

  useEffect(() => {
    axios
      .get('/user')
      .then((res) => {
        setMe(res.data.credentials.userId);
      })
      .catch((err) => {
        console.error('ShoutCard - Error setting user credentials', err);
      });
  });

  // Function to check if the image URL is valid
  const isImageValid = (url) => {
    const image = new Image();
    image.src = url;
    return image.complete || image.width + image.height > 0;
  };

  return (
    <>
      <div className='shout-card'>
        <div className='row'>
          <div className='col-11'>
            <p className='shout-header'>Shout Outs</p>
          </div>
          <div className='col-1'>
            <button
              id={`newShoutOutPopover${data.id}`}
              className=''
              type='button'
              data-toggle='modal'
              data-target={`#newShoutOut${data.id}`}
            >
              <FontAwesomeIcon
                className='shout-icon text-light'
                icon='fa-light fa-messages'
              />
            </button>
            <NewShout id={data.id} me={me} />
          </div>
        </div>

        {props.shout.map((shouts, index) => {
          const imageSrc = isImageValid(shouts.creatorImg)
            ? shouts.creatorImg
            : DEFAULT_IMAGE_URL;

          return (
            <div key={index} className='row shout-row'>
              <div className='col-3 col-md-2'>
                <img className='shout-img' src={imageSrc} alt='1' />
              </div>
              <div className='col-9 col-md-10'>
                <p className='shout-text'>{shouts.message}</p>
                <p className='shout-text'>
                  <strong>-{shouts.creatorName.toUpperCase()}</strong> to{' '}
                  {shouts.receiverName}
                </p>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default ShoutCard;
