import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import BackBtn from '../../Components/Back/BackBtn';
import axios from 'axios';

class NewManager extends Component {
  constructor() {
    super();
    this.state = {
      manager: '',
      loading: true,
      image: '',
      errors: {},
    };
  }
  componentDidMount() {
    axios.get('/user').then((res) => {
      const cred = res.data.credentials;
      this.setState({ image: cred.profileImg });
    });
    this.setState({ loading: false });
  }
  handleSubmit = (event) => {
    event.preventDefault();
    this.setState({
      loading: true,
    });
    axios
      .post('/updateManager/' + this.state.manager)
      .then((res) => {
        this.props.history.push('/editProfile');
        window.location.reload();
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          errors: {
            general: 'Invalid Manager Code: Please check information.',
          },
          loading: false,
        });
      });
  };

  onManagerChange = (event) => {
    this.setState({
      manager: event.target.value,
    });
  };
  render() {
    if (this.state.loading) return null;
    return (
      <>
        <div className='upload-photo-page justify-content-center row'>
          <div className='upload-photo-col'>
            <BackBtn link='editProfile' className='mb-3' />
            <div className='row'>
              <img
                src={this.state.image}
                className='profile-img'
                alt='Example'
              />
            </div>
            <div className='row'>
              <p className='choose-manager-text'>
                Let's get you assigned to your new manager.
              </p>
            </div>
            <div className='row'>
              <form>
                <div className='mb-3'>
                  <label htmlFor='manager' className='form-label sign-label'>
                    Manager
                  </label>
                  <input
                    onChange={this.onManagerChange}
                    value={this.state.manager}
                    type='text'
                    className='form-control sign-input'
                    id='manager'
                    aria-describedby='choose manager'
                  />
                  <FontAwesomeIcon
                    className='manager-search-icon'
                    icon={['fal', 'search']}
                  />
                </div>
                <p className='errors-p p-3'>{this.state.errors.general}</p>
                <div className='row justify-content-center '>
                  <button
                    onClick={this.handleSubmit}
                    className='sign-btn next-step-btn'
                  >
                    Update
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default NewManager;
