import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component } from 'react';
import Page from '../../Components/NavBars/Page';
import { faExclamationCircle } from '@fortawesome/pro-solid-svg-icons';
import BackBtn from '../../Components/Back/BackBtn';
import axios from 'axios';
import { analytics } from '../../firebaseConfig.js';
import { Redirect } from 'react-router-dom';

class ViewCheck extends Component {
  constructor() {
    super();
    this.state = {
      checkIns: [],
      creatorName: '',
      creatorId: '',
      r1: '',
      r2: '',
      r3: '',
      loading: true,
      errors: {},
      redirectToCheck: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onResponseOneChange = this.onResponseOneChange.bind(this);
    this.onResponseTwoChange = this.onResponseTwoChange.bind(this);
    this.onResponseThreeChange = this.onResponseThreeChange.bind(this);
  }

  componentDidMount(props) {
    analytics.logEvent(`view_check_visited`);
    axios
      .get('/getManagedCheckins')
      .then((res) => {
        if (res.data[0] === undefined) {
          console.log('no checkins');
          this.setState({ loading: false });
        } else {
          const data = res.data;

          let rightEmployee;
          for (let i = 0; i < data.length; i++) {
            if (data[i].creatorId === this.props.match.params.id) {
              rightEmployee = data[i];
            }
          }
          const employeeName = rightEmployee.creatorName;
          const creatorId = rightEmployee.creatorId;
          this.setState({
            creatorId: creatorId,
            creatorName: employeeName,
            checkIns: rightEmployee.checkin.sort(
              (a, b) => Date.parse(b.created) - Date.parse(a.created),
            ),
          });
          this.setState({ loading: false });
        }
      })
      .catch((error) => {
        console.error(
          'ViewCheck-getManagedCheckings - Error fetching managed checkins:',
          error,
        );
        this.setState({ loading: false });
      });

    this.setState({ loading: false });
  }

  handleSubmit = (event) => {
    event.preventDefault();
    this.setState({
      loading: true,
    });
    const responseData = {
      checkinId: this.state.creatorId,
      creatorName: this.state.creatorName,
      r1: this.state.r1,
      r2: this.state.r2,
      r3: this.state.r3,
    };
    axios
      .post(`/responseToCheckin/${this.state.creatorId}`, responseData)
      .then((res) => {
        this.setState({
          loading: false,
          redirectToCheck: true,
        });

        this.props.history.push('/check-in');
      })
      .catch((err) => {
        console.log('ViewCheck-responseToChecking ', err);
        this.setState({
          errors: { general: 'Error: Please check information and try again.' },
          loading: false,
        });
      });
  };
  onResponseOneChange = (event) => {
    this.setState({ r1: event.target.value });
  };
  onResponseTwoChange = (event) => {
    this.setState({ r2: event.target.value });
  };
  onResponseThreeChange = (event) => {
    this.setState({ r3: event.target.value });
  };

  render() {
    if (this.state.loading) return null;
    if (this.state.redirectToCheck) {
      return <Redirect to='/check-in' />;
    }

    return (
      <>
        <Page
          content={
            <>
              <div className='view-check-page'>
                <BackBtn link='check' className='back-check' />
                <div className='row check-alert mt-3'>
                  <p className='alert-text'>
                    <FontAwesomeIcon
                      className='alert-icon'
                      icon={faExclamationCircle}
                    />
                    <strong className='alert-p'>
                      {`You are viewing ${this.state.creatorName}'s Check-ins`}
                    </strong>
                  </p>
                </div>
                <div>
                  <h3>
                    From 3 months ago, how do you feel like you are doing?
                  </h3>
                  <div id='accordion1'>
                    {this.state.checkIns.map((checkIn, i) => {
                      let expanded = i === 0 ? true : false;
                      return (
                        <div className='card' key={checkIn.created}>
                          <div className='card-header' id={`heading1${i}`}>
                            <h5 className='mb-0'>
                              <button
                                className='btn'
                                data-toggle='collapse'
                                data-target={`#collapse1${i}`}
                                aria-expanded={expanded}
                                aria-controls={`collapse1${i}`}
                              >
                                {checkIn.created.substring(0, 10)}
                              </button>
                            </h5>
                          </div>

                          <div
                            id={`collapse1${i}`}
                            className={expanded ? 'collapse show' : 'collapse'}
                            aria-labelledby={`heading1${i}`}
                            data-parent='#accordion1'
                          >
                            <div className='card-body'>
                              {checkIn.a1}
                              {expanded && (
                                <div>
                                  {checkIn.response ? (
                                    <div>
                                      <h4>Your response:</h4>
                                      <p>{checkIn.r1}</p>
                                    </div>
                                  ) : (
                                    <>
                                      <h4>Comment</h4>
                                      <textarea
                                        onChange={this.onResponseOneChange}
                                        value={this.state.r1}
                                        className='form-control reply-form-box'
                                        aria-label='With textarea'
                                        placeholder='Type Here'
                                      ></textarea>
                                      <p className='errors-p p-3'>
                                        {this.state.errors.general}
                                      </p>
                                    </>
                                  )}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div></div>

                <div>
                  <h3>
                    What things do you need to work on to achieve sustained
                    success?
                  </h3>

                  <div id='accordion2'>
                    {this.state.checkIns.map((checkIn, i) => {
                      let expanded = i === 0 ? true : false;
                      return (
                        <div className='card' key={checkIn.created}>
                          <div className='card-header' id={`heading2${i}`}>
                            <h5 className='mb-0'>
                              <button
                                className='btn'
                                data-toggle='collapse'
                                data-target={`#collapse2${i}`}
                                aria-expanded={expanded}
                                aria-controls={`collapse2${i}`}
                              >
                                {checkIn.created.substring(0, 10)}
                              </button>
                            </h5>
                          </div>

                          <div
                            id={`collapse2${i}`}
                            className={expanded ? 'collapse show' : 'collapse'}
                            aria-labelledby={`heading2${i}`}
                            data-parent='#accordion2'
                          >
                            <div className='card-body'>
                              {checkIn.a2}
                              {expanded && (
                                <div>
                                  {checkIn.response ? (
                                    <div>
                                      <h4>Your response:</h4>
                                      <p>{checkIn.r1}</p>
                                    </div>
                                  ) : (
                                    <>
                                      <h4>Comment</h4>
                                      <textarea
                                        onChange={this.onResponseTwoChange}
                                        value={this.state.r2}
                                        className='form-control reply-form-box'
                                        aria-label='With textarea'
                                        placeholder='Type Here'
                                      ></textarea>
                                      <p className='errors-p p-3'>
                                        {this.state.errors.general}
                                      </p>
                                    </>
                                  )}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div></div>

                <div>
                  <h3>What barriers to further success exist?</h3>
                  <div id='accordion3'>
                    {this.state.checkIns.map((checkIn, i) => {
                      let expanded = i === 0 ? true : false;
                      return (
                        <div className='card' key={checkIn.created}>
                          <div className='card-header' id={`heading3${i}`}>
                            <h5 className='mb-0'>
                              <button
                                className='btn'
                                data-toggle='collapse'
                                data-target={`#collapse3${i}`}
                                aria-expanded={expanded}
                                aria-controls={`collapse3${i}`}
                              >
                                {checkIn.created.substring(0, 10)}
                              </button>
                            </h5>
                          </div>

                          <div
                            id={`collapse3${i}`}
                            className={expanded ? 'collapse show' : 'collapse'}
                            aria-labelledby={`heading3${i}`}
                            data-parent='#accordion3'
                          >
                            <div className='card-body'>
                              {checkIn.a3}
                              {expanded && (
                                <div>
                                  {checkIn.response ? (
                                    <div>
                                      <h4>Your response:</h4>
                                      <p>{checkIn.r3}</p>
                                    </div>
                                  ) : (
                                    <>
                                      <h4>Comment</h4>
                                      <textarea
                                        onChange={this.onResponseThreeChange}
                                        value={this.state.r3}
                                        className='form-control reply-form-box'
                                        aria-label='With textarea'
                                        placeholder='Type Here'
                                      ></textarea>
                                      <p className='errors-p p-3'>
                                        {this.state.errors.general}
                                      </p>
                                    </>
                                  )}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div className='row justify-content-center'>
                  <button
                    onClick={this.handleSubmit}
                    type='button'
                    className='sc-btn'
                    style={{ marginTop: '10px' }}
                  >
                    Submit Responses
                  </button>
                </div>
              </div>
            </>
          }
        />
      </>
    );
  }
}

export default ViewCheck;
