import React from 'react';
import './notif.css';

const EmployeeEngagement = (props) => {
  return (
    <div className='notif-outside row'>
      <div className='col-1 mb-3 icon-col'>
        <img className=' notif-icon' src={props.img} alt={props.name} />
      </div>
      <div className='col-7'>
        <p className='notif-text'>
          <strong>{props.name}</strong> {props.text}
        </p>
      </div>
    </div>
  );
};

export default EmployeeEngagement;
