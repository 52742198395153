import Chart from 'react-apexcharts';
import './charts.css';

const AreaChart = () => {
  const data = {
    series: [
      {
        name: 'Team A',
        data: [22, 42, 32, 100],
      },
      {
        name: 'Team B',
        data: [32, 45, 32, 100],
      },
      {
        name: 'Team C',
        data: [52, 42, 62, 100],
      },
      {
        name: 'Team D',
        data: [100, 83, 50, 50],
      },
      {
        name: 'Team E',
        data: [83, 50, 90, 100],
      },
    ],
    options: {
      chart: {
        type: 'area',
        height: 350,
        id: 'apexchart-q-momentum',
        toolbar: {
          show: false,
        },
      },
      colors: ['#c60909', '#21881f', '#6a3d9a', '#0f7bcf', '#c013a0'],
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: 'smooth',
        width: 2,
      },
      fill: {
        type: 'gradient',
        gradient: {
          opacity: 0.4,
        },
      },
      legend: {
        maxHeight: 200,
        position: 'bottom',
        horizontalAlign: 'center',
        show: true,
        markers: {
          radius: 0,
        },
      },
      xaxis: {
        categories: ['Q1', 'Q2', 'Q3', 'Q4'],
      },
      yaxis: {
        show: false,
        max: 100,
      },
    },
  };

  return (
    <Chart
      options={data.options}
      series={data.series}
      type='area'
      height={350}
    />
  );
};

export default AreaChart;
