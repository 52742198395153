import axios from 'axios';
import React, { Component } from 'react';
import $ from 'jquery';
// import PlacesAutocomplete from 'react-places-autocomplete';
// import {
//     geocodeByAddress,
//     geocodeByPlaceId,
//     getLatLng,
//   } from 'react-places-autocomplete';

class CompanyInfo extends Component {
  constructor() {
    super();
    this.state = {
      address: '',
      city: '',
      state: '',
      zip: '',
      companyPhone: '',
      companyUrl: '',
      loading: false,
      errors: {},
    };
  }
  handleSubmit = (event) => {
    this.props.history.push('/selectPrice'); // Remove when this function works

    event.preventDefault();
    this.setState({
      loading: true,
    });
    const signUpData = {
      street: this.state.address,
      city: this.state.city,
      state: this.state.state,
      zip: this.state.zip,
      phone: this.state.companyPhone,
      url: this.state.companyUrl,
    };
    console.log(signUpData);
    axios
      .post('/editMyCompanyInfo', signUpData)
      .then((res) => {
        this.setState({
          loading: false,
        });
        this.props.history.push('/selectPrice');
      })
      .catch((err) => {
        console.log(err.response.data);
        this.setState({
          errors: err.response.data,
          loading: false,
        });
      });
  };
  // handleSelect = address => {
  //     geocodeByAddress(address)
  //       .then(results => getLatLng(results[0]))
  //       .then(latLng => console.log('Success', latLng))
  //       .catch(error => console.error('Error', error));
  //   };
  onAddressChange = (event) => {
    this.setState({ address: event.target.value });
  };
  onCityChange = (event) => {
    this.setState({
      city: event.target.value,
    });
  };
  onStateChange = (event) => {
    this.setState({
      state: event.target.value,
    });
  };
  onZipChange = (event) => {
    this.setState({
      zip: event.target.value,
    });
  };
  onPhoneChange = (event) => {
    $('.number').keyup(function () {
      if (this.value.length === this.maxLength) {
        $(this).next('.number').focus();
      }
    });
    const val1 = $('#number1').val();
    const val2 = $('#number2').val();
    const val3 = $('#number3').val();
    this.setState({
      companyPhone: val1 + '-' + val2 + '-' + val3,
    });
  };
  onCompanyUrlChange = (event) => {
    this.setState({
      companyUrl: event.target.value,
    });
  };
  render() {
    return (
      <div className='login-page'>
        <div className='row justify-content-center'>
          <img
            src='../Images/successrocketlogo.png'
            className='signup-logo'
            alt='Landing'
          />
        </div>
        <div className='row justify-content-center'>
          <div className='form-row'>
            <form>
              <h5 className='company-header'>
                Welcome to Success Rocket, let's finish setting up your
                Company's account.
              </h5>
              <h1 className='login-header'>Company Information</h1>
              <div className='form-group'>
                <label htmlFor='address' className='sign-label'>
                  Company Street Address
                </label>
                <input
                  value={this.state.address}
                  onChange={this.onAddressChange}
                  type='text'
                  className='form-control sign-input'
                  id='address'
                  aria-describedby='Address'
                  placeholder=''
                />

                {/* <PlacesAutocomplete
                    
        value={this.state.address}
        onChange={this.onAddressChange}
        onSelect={this.handleSelect}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div>
            <input
              {...getInputProps({
                placeholder: 'Search Places ...',
                className: 'location-search-input form-control sign-input',
              })}
            />
            <div className="autocomplete-dropdown-container">
              {loading && <div>Loading...</div>}
              {suggestions.map(suggestion => {
                const className = suggestion.active
                  ? 'suggestion-item--active'
                  : 'suggestion-item';
                // inline style for demonstration purpose
                const style = suggestion.active
                  ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                  : { backgroundColor: '#ffffff', cursor: 'pointer' };
                return (
                  <div
                    {...getSuggestionItemProps(suggestion, {
                      className,
                      style,
                    })}
                  >
                    <span>{suggestion.description}</span>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </PlacesAutocomplete> */}
              </div>
              <p className='errors-p2'>{this.state.errors.address}</p>
              <div className='form-group'>
                <label htmlFor='city' className='sign-label'>
                  Company City
                </label>
                <input
                  value={this.state.city}
                  onChange={this.onCityChange}
                  type='city'
                  className='form-control sign-input'
                  id='city'
                  aria-describedby='City'
                  placeholder=''
                />
              </div>
              <p className='errors-p2'>{this.state.errors.city}</p>
              <div className='form-group'>
                <label htmlFor='state' className='sign-label'>
                  Company State
                </label>
                <input
                  value={this.state.state}
                  onChange={this.onStateChange}
                  type='state'
                  className='form-control sign-input'
                  id='state'
                  aria-describedby='State'
                  placeholder=''
                />
              </div>
              <p className='errors-p2'>{this.state.errors.state}</p>
              <div className='form-group'>
                <label htmlFor='zip' className='sign-label'>
                  Company Zip
                </label>
                <input
                  value={this.state.zip}
                  onChange={this.onZipChange}
                  type='zip'
                  className='form-control sign-input'
                  id='zip'
                  aria-describedby='Zip Code'
                  placeholder=''
                />
              </div>
              <p className='errors-p2'>{this.state.errors.zip}</p>
              <div className='form-group'>
                <label htmlFor='phone' className='sign-label'>
                  Company Phone
                </label>
                <div className='row'></div>
                <input
                  type='text'
                  id='number1'
                  className='number sign-input'
                  maxLength='3'
                  size='3'
                  onChange={this.onPhoneChange}
                />{' '}
                -{' '}
                <input
                  type='text'
                  id='number2'
                  className='number sign-input'
                  maxLength='3'
                  size='3'
                  onChange={this.onPhoneChange}
                />{' '}
                -{' '}
                <input
                  type='text'
                  id='number3'
                  className='number sign-input'
                  maxLength='4'
                  size='5'
                  onChange={this.onPhoneChange}
                />
                {/* <input value={this.state.companyPhone} onChange={this.onCompanyPhoneChange} type="phone" className="form-control sign-input" id="phone" placeholder=""/> */}
              </div>
              <p className='errors-p2'>{this.state.errors.companyPhone}</p>
              <div className='form-group'>
                <label htmlFor='url' className='sign-label'>
                  Company Website URL
                </label>
                <input
                  value={this.state.companyUrl}
                  onChange={this.onCompanyUrlChange}
                  type='text'
                  className='form-control sign-input'
                  id='url'
                  placeholder=''
                />
              </div>
              <p className='errors-p2'>{this.state.errors.companyUrl}</p>
              <div className='row justify-content-center'>
                <div className='signin-btn-row bottom'>
                  <button
                    className='package-btn'
                    type='submit'
                    onClick={this.handleSubmit}
                  >
                    Select Package
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

export default CompanyInfo;
