import React, { Component } from 'react';
import Page from '../../Components/NavBars/Page';
import ConfettiExplosion from '@reonomy/react-confetti-explosion';
import axios from 'axios';
import { ActionComponent } from '../../Components/Actions/MainActionComponent';
import PriorityKey from '../../Components/Actions/PriorityKey';

class MActions extends Component {
  constructor() {
    super();
    this.state = {
      myId: '',
      ninety: false,
      actions: [
        {
          id: ' ',
          status: false,
          main: ' ',
          name: ' ',
          priority: ' ',
          dDate: ' ',
          description: ' ',
          rating: ' ',
          sub: [
            {
              name: ' ',
              id: ' ',
              dDate: ' ',
              status: false,
              rating: ' ',
              priority: '',
            },
          ],
        },
      ],
      team: [
        {
          id: ' ',
          userId: ' ',
          status: false,
          main: ' ',
          name: ' ',
          priority: ' ',
          dDate: ' ',
          description: ' ',
          rating: ' ',
          sub: [
            {
              name: ' ',
              id: ' ',
              userId: ' ',
              dDate: ' ',
              status: false,
              rating: ' ',
            },
          ],
        },
      ],
      loading: true,
      isExploding: false,
      exploding: false,
      errors: {},
    };
  }

  getMyId = () => {
    axios
      .get('/user')
      .then((res) => {
        const cred = res.data.credentials;
        const days = cred.created;
        const newDate = new Date(days);
        let ninetyDay = new Date(newDate.setDate(newDate.getDate() + 90));
        let today = new Date();
        if (ninetyDay > today) {
          this.setState({ ninety: true });
        } else {
          this.setState({ ninety: false });
        }
        this.setState({ myId: res.data.credentials.userId });
        this.runData();
      })
      .catch((error) => {
        console.error('MActions - Error fetching user data:', error);

        this.setState({
          error: 'An error occurred while fetching user data.',
        });
      });
  };

  runData = () => {
    axios
      .get('/getUserActions')
      .then((res) => {
        const data = res.data;
        let theActions = [];
        let subActions = [];
        for (let i = 0; i < data.length; i++) {
          if (data[i].actionType === 'main') {
            theActions.push({
              id: data[i].actionId,
              main: data[i].title,
              priority: data[i].priority,
              dDate: data[i].dueDate,
              description: data[i].description,
              status: data[i].completed,
            });
          } else {
            subActions.push({
              id: data[i].actionId,
              name: data[i].title,
              priority: data[i].priority,
              dDate: data[i].dueDate,
              status: data[i].completed,
              mainActionId: data[i].mainAction,
            });
          }
        }
        const newActions = theActions.map((item) => {
          return {
            ...item,
            sub: subActions.filter(
              (element) => element.mainActionId === item.id,
            ),
          };
        });

        this.setState({ actions: newActions });
      })
      .catch((error) => {
        console.error('MActions - Error fetching user actions:', error);

        this.setState({
          error: 'An error occurred while fetching user actions.',
        });
      });

    axios
      .get('/getTeamActions')
      .then((res) => {
        const data = res.data;

        let theActions = [];
        let subActions = [];

        for (let i = 0; i < data.length; i++) {
          if (data[i].creatorId === this.state.myId) {
            console.log('My Action');
          } else {
            if (data[i].actionType === 'main') {
              theActions.push({
                userId: data[i].creatorId,
                id: data[i].actionId,
                main: data[i].title,
                priority: data[i].priority,
                dDate: data[i].dueDate,
                name: data[i].employeeName,
                description: data[i].description,
                status: data[i].completed,
              });
            } else {
              subActions.push({
                userId: data[i].creatorId,
                id: data[i].actionId,
                name: data[i].title,
                priority: data[i].priority,
                dDate: data[i].dueDate,
                status: data[i].completed,
                mainActionId: data[i].mainAction,
              });
            }
          }
        }
        const newActions = theActions.map((item) => {
          return {
            ...item,
            sub: subActions.filter(
              (element) => element.mainActionId === item.id,
            ),
          };
        });

        this.setState({ team: newActions });
        this.setState({ loading: false });
      })
      .catch((error) => {
        console.error('MActions - Error fetching team actions:', error);

        this.setState({
          loading: false,
          error: 'An error occurred while fetching team actions.',
        });
      });
  };

  componentDidMount() {
    this.getMyId();
  }

  confettiSlider = (event) => {
    const actionID = event.target.getAttribute('id');
    const oldStatus = event.target.getAttribute('value');
    let newStatus;
    if (oldStatus === 'false') {
      newStatus = 'true';
    } else {
      newStatus = 'false';
    }
    const editAction = {
      completed: newStatus,
    };

    // set all subs to true
    axios
      .post(`/editStatus/${actionID}`, editAction)
      .then((res) => {
        if (editAction.completed === 'true') {
          this.setState({ isExploding: true });
          setTimeout(() => {
            this.setState({ isExploding: false });
          }, 5000);
          this.runData();
          this.forceUpdate();
        } else if (editAction.completed === 'false') {
          this.setState({ isExploding: false });
          this.runData();
          this.forceUpdate();
        }
      })
      .catch((err) => {
        console.error(
          'MActions - Error editing action status:',
          err.response.data,
        );
        this.setState({
          errors: { general: 'Error: Please check information and try again.' },
        });
        if (editAction.completed === 'false') {
          this.setState({ isExploding: true });
          setTimeout(() => {
            this.setState({ isExploding: false });
          }, 5000);
        } else if (editAction.completed === 'true') {
          this.setState({ isExploding: false });
          this.setState({ exploding: false });
        }
      });
  };

  confetti = (event) => {
    const actionID = event.target.getAttribute('id');
    const oldStatus = event.target.getAttribute('value');
    let newStatus;
    if (oldStatus === 'false') {
      newStatus = 'true';
    } else {
      newStatus = 'false';
    }
    const editAction = {
      completed: newStatus,
    };
    axios
      .post(`/editStatus/${actionID}`, editAction)
      .then((res) => {
        if (editAction.completed === 'true') {
          this.setState({ isExploding: true });
          setTimeout(() => {
            this.setState({ isExploding: false });
          }, 5000);
          this.runData();
          this.forceUpdate();
        } else if (editAction.completed === 'false') {
          this.runData();
          this.forceUpdate();
        }
      })
      .catch((err) => {
        console.error(
          'MActions - Error editing action status:',
          err.response.data,
        );
        this.setState({
          errors: { general: 'Error: Please check information and try again.' },
        });
        if (editAction.status === 'false') {
          this.setState({ exploding: true });
          setTimeout(() => {
            this.setState({ exploding: false });
          }, 5000);
        } else if (editAction.status === 'true') {
          this.setState({ exploding: false });
          this.setState({ isExploding: false });
        }
      });
  };

  render() {
    if (this.state.loading) return null;
    return (
      <>
        <div className='row justify-content-center confetti-row'>
          {this.state.isExploding && (
            <ConfettiExplosion force='0' floorHeight='3000' duration='7000' />
          )}
          {this.state.exploding && (
            <ConfettiExplosion force='0' floorHeight='3000' duration='7000' />
          )}
        </div>
        <Page
          content={
            <>
              <div className='actions-page'>
                <ActionComponent
                  ninety={this.state.ninety}
                  actions={this.state.actions}
                  confetti={this.confetti}
                  confettiSlider={this.confettiSlider}
                  manager={true}
                  team={this.state.team}
                />

                <PriorityKey />
              </div>
            </>
          }
        />
      </>
    );
  }
}

export default MActions;
