import React, { useEffect, useState } from 'react';
import RateSub from '../Actions/RateSub';

function OverdueAction(props) {
  const data = [
    {
      id: '001',
      main: 'Back-End',
      name: 'Johnny Appleseed',
      status: false,
      priority: 'High',
      dDate: '08/01/2021',
      description: 'Back End of Application',
      sub: [
        {
          name: 'Mobile Sign in',
          status: false,
          id: '001-01',
          dDate: '08/01/2021',
        },
        {
          name: 'Mobile Directory',
          status: false,
          id: '001-02',
          dDate: '08/01/2021',
        },
        {
          name: 'Employee Check-in',
          status: false,
          id: '001-03',
          dDate: '08/01/2021',
        },
        {
          name: 'Manager Actions',
          status: true,
          id: '001-04',
          dDate: '08/01/2021',
        },
        {
          name: 'Dashboard',
          status: true,
          id: '001-05',
          dDate: '08/01/2021',
        },
      ],
    },
    {
      id: '002',
      main: 'Front-End',
      name: 'Jane Doe',
      priority: 'Low',
      status: true,
      dDate: '08/01/2021',
      description: 'Front End of Application',
      sub: [
        {
          name: 'Mobile Sign in',
          status: true,
          id: '002-01',
          dDate: '08/01/2021',
        },
        {
          name: 'Mobile Directory',
          status: true,
          id: '002-02',
          dDate: '08/01/2021',
        },
        {
          name: 'Employee Check-in',
          status: true,
          id: '002-03',
          dDate: '08/01/2021',
        },
        {
          name: 'Manager Actions',
          status: true,
          id: '002-04',
          dDate: '08/01/2021',
        },
        {
          name: 'Dashboard',
          status: true,
          id: '002-05',
          dDate: '08/01/2021',
        },
      ],
    },
  ];

  const [loading, setLoading] = useState(true);
  const [thisCheck, setThisCheck] = useState({});

  useEffect(() => {
    const setBigCheck = data.filter((x) => x.id === props.id.substring(0, 3));

    if (props.id.length > 4) {
      const smallCheck = setBigCheck[0].sub.filter((x) => x.id === props.id);

      setThisCheck({
        sub: true,
        info: {
          main: setBigCheck[0].main,
          name: setBigCheck[0].name,
          status: smallCheck[0].status,
          title: smallCheck[0].name,
          dDate: smallCheck[0].dDate,
        },
      });
      // info: setBigCheck.sub.filter(x => x.id === props.id) });
    } else {
      setThisCheck({
        sub: false,
        info: {
          priority: setBigCheck[0].priority,
          main: setBigCheck[0].main,
          name: setBigCheck[0].name,
          status: setBigCheck[0].status,
          title: setBigCheck[0].main,
          dDate: setBigCheck[0].dDate,
          description: setBigCheck[0].description,
        },
      });
    }

    setLoading(false);
  }, [setLoading, props.id]);

  if (loading) return null;

  return (
    <div className='notif-outside row'>
      <div className='col-1 mb-3 icon-col'>
        <img className=' notif-icon' src={props.img} alt={props.name} />
      </div>
      <div className='col-7'>
        <p className='notif-text'>
          <strong>{props.name}</strong> has an action that is passed due.
        </p>
      </div>
      <div className='col-4 notif-btn-row'>
        <button
          className=' btn-notif'
          data-toggle='modal'
          data-target={`#edit${props.id}`}
        >
          view
        </button>
      </div>
      <RateSub
        title={thisCheck.info.title}
        status={thisCheck.info.status}
        id={props.id}
        name={props.name}
        main={thisCheck.info.main}
        sub={thisCheck.sub}
        priority={thisCheck.info.priority}
        dDate={thisCheck.info.dDate}
        description={thisCheck.info.description}
      />
    </div>
  );
}

export default OverdueAction;
