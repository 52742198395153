import React from 'react';
import { Link } from 'react-router-dom';
import Momentum from '../../Profile/Momentum';
const Rating = (props) => {

  return (
    <div className='rating-page'>
      {props.data.map((userData, index) => (
        <div
          className={
            index % 2 ? 'grey-bg rating-row row' : 'white-bg rating-row row'
          }
          key={index}
        >
          <div className='col-1 col-md-5'>
            <Link className='no-underline' to={`/profile/${userData.id}`}>
              {' '}
              <div className='rating-text centered-name'>
                <div>
                  {' '}
                  <img
                    src={userData.img}
                    alt={userData.id}
                    className='newRateImg'
                  />{' '}
                  {userData.name}{' '}
                </div>
              </div>
            </Link>
          </div>
          <div className='col-5 col-md-1'></div>
          <div className='col-6 verticle-align-center'>
            <div className='row'>
              <p className='centered-stars'>
                {userData.rating[props.currentIndex]}
              </p>
            </div>

            <div className='row cenetered-momemtum'>
              <Momentum momentum={userData.momentum} />
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Rating;
