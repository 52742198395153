import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import React, { Component } from 'react';
import Page from '../../Components/NavBars/Page';
import HelpBtn from '../../Components/Actions/HelpBtn';
import GetMentor from '../../Components/Actions/GetMentor';
import './mentor.css';
import AskMentor from '../../Components/Actions/AskMentor';
import PromptProto from '../../Components/Actions/PromtProto';
import Res2Mentor from '../../Components/Actions/Res2Mentor';
import Res2Protege from '../../Components/Actions/Res2Protege';
import { faExclamationCircle } from '@fortawesome/pro-solid-svg-icons';

class Mentor extends Component {
  constructor() {
    super();
    this.state = {
      myId: '',
      myMentor: [],
      myProtege: [],
      moreProtege: true,
      hasMentor: true,
      hasProtege: true,
      loading: true,
      errors: {},
    };
  }

  componentDidMount() {
    axios.get('user').then((response) => {
      this.setState({ myId: response.data.credentials.userId });
    });

    // get mentor and protege, display

    // TODO: use real data
    const fakeData = {
      mentor: {
        mentorId: 'dnfsjabfkbgfagfdksf',
        mentorName: 'John Doe',
        qA: [
          {
            prompt: true,
            question: 'What are your near-term success goals?',
            answer: '',
          },
          {
            prompt: false,
            question: 'How did you learn to embrace risk-taking?',
            answer: '',
          },
          {
            prompt: false,
            question: 'Did you envision your career as it is today?',
            answer:
              'No, not exactly, but I did envision being in a leadership role.',
          },
        ],
        profileImg:
          'https://firebasestorage.googleapis.com/v0/b/sr-server-e8ea5.appspot.com/o/Profile-Img.jpeg?alt=media&token=53ddffa0-0b30-48c2-9d14-20de6503198c',
      },
      proteges: [
        {
          protegeId: 'dnfsdgssggghhhfkbgfagfdksf',
          protegeName: 'George Doe',
          qA: [
            {
              prompt: true,
              question: 'What are your near-term success goals?',
              answer: '',
            },
            {
              prompt: false,
              question: 'How did you learn to embrace risk-taking?',
              answer: '',
            },
            {
              prompt: false,
              question: 'Did you envision your career as it is today?',
              answer:
                'No, not exactly, but I did envision being in a leadership role.',
            },
          ],
          profileImg:
            'https://firebasestorage.googleapis.com/v0/b/sr-server-e8ea5.appspot.com/o/457094951229025660.png?alt=media',
        },
        {
          protegeId: 'dnfsagjafbfkbgfasgfdksf',
          protegeName: 'Arnold Doe',
          qA: [
            {
              prompt: false,
              question: 'How did you learn to embrace risk-taking?',
              answer: '',
            },
            {
              prompt: false,
              question: 'Did you envision your career as it is today?',
              answer:
                'No, not exactly, but I did envision being in a leadership role.',
            },
          ],
          profileImg:
            'https://firebasestorage.googleapis.com/v0/b/sr-server-e8ea5.appspot.com/o/810821002954261800.jpg?alt=media',
        },
      ],
    };

    this.setState({ myMentor: [fakeData.mentor] });
    this.setState({ hasMentor: true });

    this.setState({ myProtege: fakeData.proteges });
    this.setState({ hasProtege: true });

    this.setState({ loading: false });
  }

  noMore = (event) => {
    this.setState({
      moreProtege: false,
    });
  };

  yesMore = (event) => {
    this.setState({
      moreProtege: true,
    });
  };

  render() {
    if (this.state.loading) return null;
    return (
      <>
        <Page
          content={
            <div className='m-checkin-page feedback-page'>
              <div className='row view-check'>
                <div className='row check-alert'>
                  <div className='col-9 col-md-10 '>
                    <p className='alert-text'>
                      <FontAwesomeIcon
                        className='alert-icon'
                        icon={faExclamationCircle}
                      />
                      <strong className='alert-p'>
                        {`This page is a work in progress`}
                      </strong>
                    </p>
                  </div>
                </div>
                <div className='col-10'>
                  <h1>
                    {' '}
                    <FontAwesomeIcon
                      className='past-icon'
                      icon={['fal', 'user-chart']}
                    />
                    Mentor
                  </h1>
                </div>
                <div className='col-2 my-auto'>
                  <HelpBtn source='../Audio/Mentor.mp3' section='Mentor' />
                </div>
                <div className='row'>
                  <div>
                    <button
                      id='getFeedbackPopoverBtn'
                      className='action-btn'
                      type='button'
                      data-toggle='modal'
                      data-target='#getMentor'
                    >
                      <FontAwesomeIcon
                        className='past-icon'
                        icon={['fal', 'plus-circle']}
                      />
                      New Mentor Relationship
                    </button>
                  </div>

                  <GetMentor me={this.state.myId} />
                </div>
                <div className='row'>
                  {this.state.hasMentor ? (
                    <div className='col-md-6'>
                      <h3 className='my-actions-header'>My Mentor</h3>
                      {this.state.myMentor.map((mentor, index) => (
                        <>
                          <div className='action-status-row row'>
                            <div className='action-small'>
                              <div className='row'>
                                <div className='col-2'>
                                  <img
                                    className=' mentor-img'
                                    src={mentor.profileImg}
                                    alt='Profile Img'
                                  />
                                </div>
                                <div className='col-10'>
                                  <div className='row'>
                                    <p className='action-label'>
                                      {mentor.mentorName}
                                    </p>
                                  </div>
                                  <div className='row'>
                                    <button
                                      id='getFeedbackPopoverBtn'
                                      className='action-btn action-btn-mentor'
                                      type='button'
                                      data-toggle='modal'
                                      data-target='#newMentorQ'
                                    >
                                      <FontAwesomeIcon
                                        className='past-icon'
                                        icon='fa-light fa-messages'
                                      />
                                      Ask a Question
                                    </button>

                                    <AskMentor me={this.state.myId} />
                                  </div>
                                </div>
                              </div>
                              <div
                                className='row mt-3'
                                data-toggle='collapse'
                                data-target={`#collapse${mentor.mentorId}`}
                                aria-expanded='false'
                                aria-controls='collapseExample'
                              >
                                <div className='btn-outer'>
                                  <div className='se-num convo-se'>1</div>
                                  <button className='btn btn-submit convo-btn'>
                                    View Conversation
                                  </button>
                                </div>
                              </div>
                              <div
                                className='row collapse'
                                id={`collapse${mentor.mentorId}`}
                              >
                                {mentor.qA.map((q, index) => (
                                  <div className='pt-3 mt-3'>
                                    {q.prompt ? (
                                      <>
                                        <p className='action-label purple'>{`${mentor.mentorName} asked: ${q.question}`}</p>
                                        <div className='prof-info-text px-3 mx-3'>
                                          {' '}
                                          {q.answer ? (
                                            <span>{q.answer}</span>
                                          ) : (
                                            <>
                                              <button
                                                id='getFeedbackPopoverBtn'
                                                className='btn btn-submit'
                                                type='button'
                                                data-toggle='modal'
                                                data-target={`#replyToMentor${mentor.mentorId}`}
                                              >
                                                Reply
                                              </button>

                                              <Res2Mentor
                                                me={this.state.myId}
                                                mentorId={mentor.mentorId}
                                                question={q.question}
                                              />
                                            </>
                                          )}
                                        </div>
                                      </>
                                    ) : (
                                      <>
                                        <p className='action-label'>
                                          {' '}
                                          {q.question}
                                        </p>
                                        <p className='prof-info-text px-3 mx-3'>
                                          {' '}
                                          {q.answer ? (
                                            <span>{q.answer}</span>
                                          ) : (
                                            <span>No Response Yet</span>
                                          )}
                                        </p>
                                      </>
                                    )}
                                  </div>
                                ))}
                              </div>
                            </div>
                          </div>
                        </>
                      ))}
                    </div>
                  ) : (
                    <div className='col-md-6'> </div>
                  )}
                  {this.state.hasProtege ? (
                    <div className='col-md-6'>
                      <h3 className='my-actions-header'>My Protégé</h3>
                      <div className='row px-2'>
                        {this.state.moreProtege ? (
                          <button
                            className='btn btn-success'
                            onClick={this.noMore}
                          >
                            Accepting More
                          </button>
                        ) : (
                          <button
                            className='btn btn-danger'
                            onClick={this.yesMore}
                          >
                            Not Accepting More
                          </button>
                        )}
                        <p className='tiny-text'>click to toggle</p>
                      </div>

                      {this.state.myProtege.map((protege, index) => (
                        <div className='action-status-row row' key={index}>
                          <div className='action-small'>
                            <div className='row'>
                              <div className='col-2'>
                                <img
                                  className=' mentor-img'
                                  src={protege.profileImg}
                                  alt='Profile Img'
                                />
                              </div>
                              <div className='col-8'>
                                <div className='row'>
                                  <p className=' action-label'>
                                    {protege.protegeName}
                                  </p>
                                </div>
                                <div className='row'>
                                  <button
                                    id='getFeedbackPopoverBtn'
                                    className='action-btn action-btn-mentor'
                                    type='button'
                                    data-toggle='modal'
                                    data-target='#newPromptP'
                                  >
                                    <FontAwesomeIcon
                                      className='past-icon'
                                      icon='fa-light fa-messages'
                                    />
                                    Prompt Protege
                                  </button>

                                  <PromptProto me={this.state.myId} />
                                </div>
                              </div>
                              <div className='col-2'></div>
                            </div>

                            <div
                              className='row mt-3'
                              data-toggle='collapse'
                              data-target={`#collapse${protege.protegeId}`}
                              aria-expanded='false'
                              aria-controls='collapseExample'
                            >
                              <div className='btn-outer'>
                                <div className='se-num convo-se'>1</div>
                                <button className='btn btn-submit convo-btn'>
                                  View Conversation
                                </button>
                              </div>
                            </div>
                            <div
                              className='row collapse'
                              id={`collapse${protege.protegeId}`}
                            >
                              {protege.qA.map((q, index) => (
                                <div className='mt-3 pt-3'>
                                  {q.prompt ? (
                                    <>
                                      <p className='action-label purple'>{`You prompted: ${q.question}`}</p>
                                      <p className='prof-info-text px-3 mx-3'>
                                        {' '}
                                        {q.answer ? (
                                          <span>{q.answer}</span>
                                        ) : (
                                          <span>No Response Yet</span>
                                        )}
                                      </p>
                                    </>
                                  ) : (
                                    <>
                                      <p className='action-label'>
                                        {' '}
                                        {q.question}
                                      </p>
                                      <div className='prof-info-text px-3 mx-3'>
                                        {' '}
                                        {q.answer ? (
                                          <span>{q.answer}</span>
                                        ) : (
                                          <>
                                            <button
                                              id='getFeedbackPopoverBtn'
                                              className='btn btn-submit'
                                              type='button'
                                              data-toggle='modal'
                                              data-target={`#replyToProtege${protege.protegeId}`}
                                            >
                                              Reply
                                            </button>

                                            <Res2Protege
                                              me={this.state.myId}
                                              protegeId={protege.protegeId}
                                              question={q.question}
                                            />
                                          </>
                                        )}
                                      </div>
                                    </>
                                  )}
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <div className='col-md-6'> </div>
                  )}
                </div>
              </div>
            </div>
          }
        />
      </>
    );
  }
}

export default Mentor;
