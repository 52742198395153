import axios from 'axios';
import React, { Component } from 'react';

class DeleteAction extends Component {
  constructor() {
    super();
    this.state = {
      id: ' ',
      name: ' ',
      loading: true,
      errors: {},
    };
  }

  componentDidMount() {
    this.setState({ id: this.props.id });
    this.setState({ name: this.props.name });
    this.setState({ loading: false });
  }

  onDelete = (event) => {
    axios.delete(`/actions/${this.state.id}`).then(() => {
      console.log(`deleted ${this.state.id}`);
      window.location.reload();
    });
  };

  render() {
    if (this.state.loading) return null;

    return (
      <>
        <div
          className='modal '
          id={`delete${this.state.id}`}
          tabIndex='-1'
          role='dialog'
          aria-labelledby='exampleModalCenterTitle'
          aria-hidden='true'
        >
          <div className='col-xs-2'>
            <div className='modal-arrow-sub' id='top'></div>
          </div>
          <div className='modal-dialog modal-dialog-centered' role='document'>
            <div className='modal-content'>
              <div className='modal-header '>
                <h5
                  className='delete-modal-header text-center'
                  id='exampleModalLongTitle'
                >
                  {`Are you sure you want to delete ${this.state.name}?`}
                </h5>
              </div>
              <div className='modal-body '>
                <div className='row'>
                  <div className='col-3' />
                  <div className='col-2'>
                    <div className='row'>
                      <button
                        className='btn btn-danger '
                        type='button'
                        onClick={this.onDelete}
                      >
                        yes
                      </button>
                    </div>
                  </div>
                  <div className='col-1' />
                  <div className='col-2'>
                    <div className='row'>
                      <button
                        className='btn btn-secondary '
                        type='button'
                        data-dismiss='modal'
                        aria-label='Close'
                      >
                        no
                      </button>
                    </div>
                  </div>
                  <div className='col-3' />
                </div>
              </div>
              <div className='modal-footer'></div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default DeleteAction;
