import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

function Momentum(props) {
  const direction = () => {
    if (props.momentum > 1) {
      return (
        <div className='momentum green-momentum'>
          <FontAwesomeIcon
            className='momentum-icon text-dark momentum-up'
            icon={['fas', 'long-arrow-alt-right']}
          />
        </div>
      );
    } else if (props.momentum < 1) {
      return (
        <div className='momentum red-momentum'>
          <FontAwesomeIcon
            className='momentum-icon text-dark momentum-down'
            icon={['fas', 'long-arrow-alt-right']}
          />
        </div>
      );
    } else {
      return (
        <div className='momentum'>
          <FontAwesomeIcon
            className='momentum-icon text-dark'
            icon={['fas', 'long-arrow-alt-right']}
          />
        </div>
      );
    }
  };

  return <>{direction()}</>;
}

export default Momentum;
