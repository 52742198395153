import firebase from 'firebase/app';
import 'firebase/analytics';

export const firebaseConfig = {
  apiKey: 'AIzaSyDfqUPhpNlGgQyojtvsbE8naPyeIRWdSEg',
  authDomain: 'sr-server-e8ea5.firebaseapp.com',
  projectId: 'sr-server-e8ea5',
  storageBucket: 'sr-server-e8ea5.appspot.com',
  messagingSenderId: '602810064992',
  appId: '1:602810064992:web:0d23e28578ab11514fe305',
  measurementId: 'G-GH9EG3X159',
};

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

export const analytics = firebase.analytics();
