import React from 'react';
import './home.css';
import { Link } from 'react-router-dom';
import Policy from '../../Components/Actions/Policy';

const Landing = (props) => {
  return (
    <>
      <div className='landing-page'>
        <div className='row landing-image-row'>
          <img
            src='../Images/successphone2.png'
            className='landing-image'
            alt='Landing'
          />
        </div>
        <div className='row landing'>
          <div className='landing-row'>
            <ul className='landing-ul'>
              <li>
                <img
                  className='landing-logo'
                  src='../Images/successrocketlogo.png'
                  alt='Landing Logo'
                />
              </li>
              <li>
                <h5 className='landing-header'>Welcome to Success Rocket!</h5>
              </li>
              <li>
                <p className='landing-text'>
                  Login below or create a new account.
                </p>
              </li>
              <li>
                <Link to='/login' className='login-link'>
                  <button className='login-btn'>Login</button>
                </Link>
              </li>
              <li>
                <div className='signup-btn'>
                  <Link to='/signup' className='signup-link'>
                    Signup
                  </Link>
                </div>
              </li>
              <li>
                <p className='disclaimer-text'>
                  By signing up you agree to our{' '}
                  <button
                    className='policyterms'
                    type='button'
                    data-toggle='modal'
                    data-target='#policyandterms'
                  >
                    Terms of use
                  </button>{' '}
                  policy and confirm you have read our{' '}
                  <button
                    className='policyterms'
                    type='button'
                    data-toggle='modal'
                    data-target='#policyandterms'
                  >
                    Privacy Policy
                  </button>
                </p>
                <Policy />
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default Landing;
