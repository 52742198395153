import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import React, { Component } from 'react';
import Page from '../../Components/NavBars/Page';
import HelpBtn from '../../Components/Actions/HelpBtn';
import GetFeedback from '../../Components/Actions/GetFeedback';
import GiveFeedback from '../../Components/Actions/GiveFeedback';
import ViewFeedback from '../../Components/Actions/ViewFeedback';

class Feedback extends Component {
  constructor() {
    super();
    this.state = {
      myFeedback: [],
      feedback2Give: [],
      myId: '',
      loading: true,
      errors: {},
    };
  }

  getData() {
    // get feedback to give
    axios
      .get('/getMyGivenFeedback')
      .then((res) => {
        const data = res.data;
        const feedback = data;
        let feedbackArr = [];
        for (let i = 0; i < feedback.length; i++) {
          if (feedback[i].hasResponded === false) {
            feedbackArr.push(feedback[i]);
          }
        }
        this.setState({ feedback2Give: feedbackArr });
      })
      .catch((error) => {
        console.error('Feedback - Error fetching given feedback:', error);

        this.setState({
          error: 'An error occurred while fetching given feedback.',
        });
      });
    // My feedback
    axios
      .get('/getMyRequestedFeedback')
      .then((res) => {
        const data = res.data;

        const fixedData = data.map((item) => {
          const theRes = item.responses;
          const newRes = [...theRes];
          newRes.splice(0, 1);
          return {
            creatorId: item.creatorId,
            feedbackId: item.feedbackId,
            creatorName: item.creatorName,
            description: item.description,
            isAnon: item.isAnon,
            responses: newRes,
          };
        });
        // const finalData = onlyUniqueItems(fixedData);

        // function onlyUniqueItems(array) {
        //   const map = new Map();
        //   const result = [];

        //   array.forEach((item) => {
        //     let amount = map.get(item) || 0;
        //     map.set(item.description, ++amount); //pre-increment and then set the amount
        //   });

        //   map.forEach((amount, item) => {
        //     if (amount === 1) {
        //       result.push(fixedData.filter((x) => x.description === item)[0]);
        //     }
        //   });

        //   return result;
        // }
        this.setState({ myFeedback: fixedData });
      })
      .catch((error) => {
        console.error('Feedback - Error fetching requested feedback:', error);

        this.setState({
          loading: false,
          error: 'An error occurred while fetching requested feedback.',
        });
      });
  }

  componentDidMount() {
    axios
      .get('user')
      .then((response) => {
        this.setState({ myId: response.data.credentials.userId });
      })
      .catch((error) => {
        console.error('Feedback - Error fetching user data:', error);

        this.setState({
          error: 'An error occurred while fetching user data.',
        });
      });

    this.getData();
    this.setState({ loading: false });
  }

  render() {
    if (this.state.loading) return null;
    return (
      <>
        <Page
          content={
            <div className='m-checkin-page feedback-page'>
              <div className='row view-check'>
                <div className='col-10'>
                  <h1>
                    {' '}
                    <FontAwesomeIcon
                      className='past-icon'
                      icon={['fal', 'sync']}
                    />
                    Success Insights
                  </h1>
                </div>
                <div className='col-2'>
                  <HelpBtn
                    source='../Audio/Feedback.mp3'
                    section='Success Insights'
                  />
                </div>
                <div className='row'>
                  <div>
                    <button
                      id='getFeedbackPopoverBtn'
                      className='action-btn'
                      type='button'
                      data-toggle='modal'
                      data-target='#getFeedback'
                    >
                      <FontAwesomeIcon
                        className='past-icon'
                        icon='fa-light fa-messages'
                      />{' '}
                      Get Feedback
                    </button>
                  </div>

                  <GetFeedback me={this.state.myId} />
                </div>
                <div className='row'>
                  <div className='col-md-6'>
                    <h3 className='my-actions-header'>Give Feedback</h3>
                    {this.state.feedback2Give.map((feedback, index) => (
                      <>
                        <div
                          className='action-status-row row'
                          key={index}
                          type='button'
                          data-toggle='modal'
                          data-target={`#respondFeedback${feedback.feedbackId}`}
                        >
                          <div className='action-small'>
                            <div className='row'>
                              <div className='col-3'>
                                <p className=' action-label'>
                                  {feedback.creatorName}
                                </p>
                              </div>
                              <div className='col-9'>
                                <p className='small'>{feedback.description}</p>
                              </div>
                              <div className='row feed-m-row '>
                                <p className='feed-message'>
                                  <small>click this box to respond</small>
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <GiveFeedback
                          feedbackReqId={feedback.feedbackReqId}
                          feedbackId={feedback.feedbackId}
                          creatorId={feedback.creatorId}
                          creatorName={feedback.creatorName}
                          description={feedback.description}
                          anon={feedback.isAnon}
                          myName={feedback.responderName}
                        />
                      </>
                    ))}
                  </div>
                  <div className='col-md-6'>
                    <h3 className='my-actions-header'>My Feedback</h3>
                    {this.state.myFeedback.map((feedback, index) => (
                      <div className='action-status-row row' key={index}>
                        <div className='action-small'>
                          <div
                            className='row'
                            data-toggle='collapse'
                            data-target={`#collapse${feedback.feedbackId}`}
                            aria-expanded='false'
                            aria-controls='collapseExample'
                          >
                            <div className='col-10'>
                              <p className=' action-label'>
                                {feedback.description}
                              </p>
                            </div>
                            <div className='col-2'>
                              <div className='se-num'>
                                {feedback.responses.length}
                              </div>
                            </div>
                          </div>
                          <div
                            className='collapse'
                            id={`collapse${feedback.feedbackId}`}
                          >
                            {feedback.responses.map((response, index) => (
                              <div
                                className={
                                  index % 2 ? 'row grey-bg' : 'row white-bg'
                                }
                                key={index}
                              >
                                {feedback.isAnon ? (
                                  <div className='col-4 action-label'>
                                    ANONYMOUS
                                  </div>
                                ) : (
                                  <div className='col-4 action-label'>
                                    {response.responderName}
                                  </div>
                                )}
                                <div className='col-5 rating-feedback'>
                                  {response.rating}
                                </div>
                                <div className='col-3 small'>
                                  <button
                                    type='button'
                                    data-toggle='modal'
                                    data-target={`#viewFeedback${response.responderId}${feedback.feedbackId}`}
                                    className='btn pt-0'
                                  >
                                    {' '}
                                    <FontAwesomeIcon
                                      className='eye-icon mt-0'
                                      icon={['fal', 'eye']}
                                    />
                                  </button>
                                  <ViewFeedback
                                    feedbackId={feedback.feedbackId}
                                    key={index}
                                    responseId={response.responderId}
                                    description={feedback.description}
                                    rating={response.rating}
                                    responderName={response.responderName}
                                    response1={response.response1}
                                    response2={response.response2}
                                    response3={response.response3}
                                    anon={response.isAnon}
                                  />
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          }
        />
      </>
    );
  }
}

export default Feedback;
