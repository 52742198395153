import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import $ from 'jquery';
import { Link } from 'react-router-dom';

function UserSuccess(props) {
  const readMore = (num) => {
    $('#sc-1').removeClass('show-more-success');
    $('#sc-2').removeClass('show-more-success');
    $('#sc-3').removeClass('show-more-success');

    $(`#sc-${num}`).addClass('show-more-success');

    $(`#up-${num}`).addClass('hidden');
    $(`#down-${num}`).removeClass('hidden');
  };

  const readLess = (num) => {
    $('#sc-1').removeClass('show-more-success');
    $('#sc-2').removeClass('show-more-success');
    $('#sc-3').removeClass('show-more-success');

    $(`#up-${num}`).removeClass('hidden');
    $(`#down-${num}`).addClass('hidden');
  };

  return (
    <>
      <div className='col-md-5 success-info-box'>
        <div className='row'>
          <div className='col-10'>
            <h2 className='success-info-header'>{`${props.first}'s Success Criteria`}</h2>
          </div>
          <div className='col-2 text-center'>
            <Link to='/editSuccess'>
              <FontAwesomeIcon
                className='edit-success-icon text-dark'
                icon={['fal', 'pencil']}
              />
            </Link>
          </div>
        </div>
        <div className='success-wrapper'>
          <div className='row success-info-row small-info-box'>
            <div className='col-10'>
              <p className='success-info-title'>
                What does success look like in my role?
              </p>
            </div>
            <div className='col-2 text-end'>
              <FontAwesomeIcon
                className='success-info-icon'
                id='up-1'
                icon={['fal', 'angle-up']}
                onClick={(e) => readMore(1)}
              />
              <FontAwesomeIcon
                className='success-info-icon hidden'
                id='down-1'
                icon={['fal', 'angle-down']}
                onClick={(e) => readLess(1)}
              />
            </div>

            <p className='success-info-text' id='sc-1'>
              {props.q1}
            </p>
          </div>
          <div className='row success-info-row small-info-box'>
            <div className='col-10'>
              <p className='success-info-title'>
                How does my success impact my departments success?
              </p>
            </div>
            <div className='col-2 text-end'>
              <FontAwesomeIcon
                className='success-info-icon'
                id='up-2'
                icon={['fal', 'angle-up']}
                onClick={(e) => readMore(2)}
              />

              <FontAwesomeIcon
                className='success-info-icon hidden'
                id='down-2'
                icon={['fal', 'angle-down']}
                onClick={(e) => readLess(2)}
              />
            </div>

            <p className='success-info-text' id='sc-2'>
              {props.q2}
            </p>
          </div>
          <div className='row success-info-row small-info-box'>
            <div className='col-10'>
              <p className='success-info-title'>
                How does my success impact my company's success?
              </p>
            </div>
            <div className='col-2 text-end'>
              <FontAwesomeIcon
                className='success-info-icon'
                id='up-3'
                icon={['fal', 'angle-up']}
                onClick={(e) => readMore(3)}
              />
              <FontAwesomeIcon
                className='success-info-icon hidden'
                id='down-3'
                icon={['fal', 'angle-down']}
                onClick={(e) => readLess(3)}
              />
            </div>

            <p className='success-info-text' id='sc-3'>
              {props.q3}
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default UserSuccess;
