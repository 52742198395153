import axios from 'axios';
import React, { useEffect, useState } from 'react';
import RateSub from '../Actions/RateSub';
import $ from 'jquery';
import { analytics } from '../../firebaseConfig.js';

function ActionNotif({ id, id2, text, read, img, senderName, sender }) {
  const [loading, setLoading] = useState(true);
  const [sub, setSub] = useState();
  const [thisCheck, setThisCheck] = useState({});

  const getAction = () => {
    if (id !== undefined) {
      axios.get(`/getActionByID/${id}`).then((res) => {
        setThisCheck(res.data);
        if (res.data.actionType === 'sub') {
          setSub(true);
        } else {
          setSub(false);
        }
      });
    }
  };

  //TODO: Grey viewed buttons not working properly
  const viewNotif = () => {
    analytics.logEvent(`complete_a_notif_viewed`);
    $(`#rate${id}btn`).addClass('btn-notif-grey');
    axios.post(`/notifications/${id2}`).then((res) => {
      console.log(res.data);
    });
  };

  useEffect(() => {
    getAction();
    setLoading(false);
  }, [id]);

  if (loading) return null;

  return (
    <div className='notif-outside row'>
      <div className='col-1 mb-3 icon-col'>
        <img
          className=' notif-icon'
          src='./Images/green-checkmark.png'
          alt={id}
        />
      </div>
      <div className='col-7'>
        <p className='notif-text'>
          <strong>{senderName}</strong> completed an action, view and rate the
          action that was completed.
        </p>
      </div>
      <div className='col-4 notif-btn-row'>
        <button
          id={`rate${id}btn`}
          className={read ? 'btn-notif btn-notif-grey' : ' btn-notif'}
          data-toggle='modal'
          data-target={`#edit${id}`}
          onClick={() => viewNotif()}
        >
          view
        </button>
      </div>
      <RateSub
        main={thisCheck.title}
        status={true}
        id={id}
        name={senderName}
        sender={sender}
        sub={sub}
        priority={thisCheck.priority}
        dDate={thisCheck.dueDate}
        description={thisCheck.description}
      />
    </div>
  );
}

export default ActionNotif;
