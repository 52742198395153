import React, { useEffect } from 'react';
import axios from 'axios';
import { useState } from 'react';
import CompanyPage from '../../Components/NavBars/CompanyPage';
import moment from 'moment';

// for individual survey page
import { Link } from 'react-router-dom';

const SurveyResponses = (props) => {
  const [loading, setLoading] = useState(true);
  const [companyCode, setCompanyCode] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [companyId, setCompanyId] = useState('');
  const [surveys, setSurveys] = useState([]);

  // for individual survey page
  // const params = useParams();

  // const getCompanySurveys = () => {
  //   axios
  //     .get(`/company/surveys/${params.surveyId}`)
  //     .then((res) => {
  //       const data = res.data;
  //       setSurveys(data)
  //       setLoading(false);
  //     })
  //     .catch((err) => {
  //       setLoading(false);
  //     });
  // };

  const getCompanySurveys = () => {
    axios
      .get('/company/surveys')
      .then((res) => {
        const data = res.data;
        setSurveys(data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const getCompany = () => {
    axios
      .get('/company')
      .then((res) => {
        const data = res.data.credentials;
        setCompanyCode(data.companyCode);
        setCompanyId(data.companyId);
        setCompanyName(data.companyName);
        getCompanySurveys();
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getCompany();
  }, []);

  const launchSurvey = (e) => {
    e.preventDefault();
    setLoading(true);
    const data = {
      companyId: companyId,
    };
    axios
      .post('/createSurvey', data)
      .then((res) => {
        console.log(res);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  //const linkToSurveyPage= ({e, surveyId}) => {
  //e.preventDefault();
  //navigate(`/surveys/${surveyId}`);
  //}

  if (loading) return null;

  return (
    <>
      <CompanyPage
        companyName={companyName}
        content={
          <>
            <div className='company-dash'>
              <div className='dash-inner'>
                <div className=''>
                  <div className='row '>
                    <div className='col-6'>
                      <h1 className='team-stats-header'>Surveys</h1>
                    </div>
                    <div className='col-6'>
                      {' '}
                      <button
                        className='js-textareacopybtn make-div-right'
                        onClick={(e) => launchSurvey(e)}
                      >
                        Launch New Survey
                      </button>
                    </div>
                  </div>
                  {surveys.length >= 1
                    ? surveys.map((sur) => (
                        <div className='action-status-row row'>
                          <Link
                            className='action-small row no-underline'
                            key={sur.surveyId}
                            to={`/surveys/${sur.surveyId}`}
                          >
                            <div className='col-8'>
                              {' '}
                              <h1 className='ts-header mt-2'>
                                {`Survey launced on ${moment(
                                  sur.created,
                                ).format('MMMM Do YYYY')}`}
                              </h1>
                            </div>
                            <div className='col-4'>
                              {' '}
                              <h1 className='ts-header make-div-right mt-2'>
                                View
                              </h1>
                            </div>
                          </Link>
                        </div>
                      ))
                    : null}
                </div>
              </div>{' '}
            </div>
          </>
        }
      />
    </>
  );
};

export default SurveyResponses;
