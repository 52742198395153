import React, { Component } from 'react';
import './login.css';
import { Link } from 'react-router-dom';
import axios from 'axios';

class CompanyLogin extends Component {
  constructor() {
    super();
    this.state = {
      email: '',
      password: '',
      loading: false,
      errors: {},
    };
  }
  handleSubmit = (event) => {
    event.preventDefault();
    this.setState({
      loading: true,
    });
    const loginData = {
      email: this.state.email,
      password: this.state.password,
    };
    axios
      .post('/companyLogin', loginData)
      .then((res) => {
        localStorage.setItem('IdToken', `Bearer ${res.data.idToken}`);
        console.log(res.data);
        this.setState({
          loading: false,
        });

        this.props.history.push('/companyRedirect');
        window.location.reload();
      })
      .catch((err) => {
        console.error('CompanyLogin - Error logging in:', err);
        this.setState({
          errors: 'An error occurred. Please try again later.',
          loading: false,
        });
      });
  };
  onEmailChange = (event) => {
    this.setState({
      email: event.target.value,
    });
  };
  onPasswordChange = (event) => {
    this.setState({
      password: event.target.value,
    });
  };
  render() {
    return (
      <div className='login-page'>
        <div className='row justify-content-center'>
          <a href='/' className='text-center'>
            <img
              src='../Images/successrocketlogo.png'
              className='login-logo'
              alt='Landing'
            />
          </a>
        </div>
        <div className='row justify-content-center'>
          <div className='form-row'>
            <form>
              <h1 className='login-header'>Company Login</h1>
              <div className='form-group'>
                <label htmlFor='exampleInputEmail1' className='success-label'>
                  Email
                </label>
                <input
                  value={this.state.email}
                  onChange={this.onEmailChange}
                  type='email'
                  className='form-control success-input'
                  id='exampleInputEmail1'
                  aria-describedby='emailHelp'
                  placeholder='example@email.com'
                />
              </div>
              <p className='errors-p'>{this.state.errors.email}</p>
              <div className='form-group'>
                <label
                  htmlFor='exampleInputPassword1'
                  className='success-label'
                >
                  Password
                </label>
                <input
                  value={this.state.password}
                  onChange={this.onPasswordChange}
                  type='password'
                  className='form-control success-input'
                  id='exampleInputPassword1'
                  placeholder=''
                />
              </div>
              <p className='errors-p'>{this.state.errors.password}</p>
              <p className='errors-p'>{this.state.errors.general}</p>
              <div className='row justify-content-center'>
                <div className='login-btn-row'>
                  <button onClick={this.handleSubmit} className='login-btn'>
                    Login
                  </button>
                </div>
              </div>
              <div className='row justify-content-center'>
                <button className='forgot-btn'>
                  <Link to='/forgot' className='forgot-link'>
                    Forgot Password?
                  </Link>
                </button>
              </div>
            </form>
            <div className='row'>
              <div className='col-6 pt-1'>
                <button className='login-signup-btn bottom'>
                  <Link to='/login' className='login-signup-link'>
                    User Login
                  </Link>
                </button>
              </div>
              <div className='col-6 '>
                <div className='row text-right'>
                  <button className='login-signup-btn bottom'>
                    <Link to='/signup' className='login-signup-link'>
                      Sign Up
                    </Link>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CompanyLogin;
