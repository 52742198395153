import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const SelectPrice = (props) => {
  const [selectedPackage, setPackage] = useState('');

  const handleClick = (value) => {
    setPackage(value.target.attributes[4].nodeValue);
    console.log(value.target.attributes[4].nodeValue);
  };

  return (
    <div className='login-page'>
      <div className='row justify-content-center'>
        <img
          src='../Images/successrocketlogo.png'
          className='signup-logo'
          alt='Landing'
        />
      </div>
      <div className='row justify-content-center'>
        <div className='form-row'>
          <form>
            <h1 className='pricing-header'>Select Package</h1>
            <fieldset className='radiogroup'>
              <div className='row'>
                {/* <div className="form-check row">
                  <div className="">
                    <input
                      className="form-check-input myRadios"
                      type="radio"
                      name="myRadios"
                      id="standard"
                      value="standard"
                      onClick={(value) => handleClick(value)}
                    />
                  </div>
                  <div className="">
                    <div className="package-card">
                      <label className="form-check-label" htmlFor="standard">
                        <h1 className="package-header">Standard</h1>
                        <ul className="package-ul">
                          <li className="package-li">
                            Employee & Manager Dashboards
                          </li>
                          <li className="package-li">Employee Directory</li>
                          <li className="package-li">
                            Default Check-in Questions
                          </li>
                          <li className="package-li">
                            Default Success Criteria
                          </li>
                          <li className="package-li">Action Management</li>
                          <li className="package-li">Profile Management</li>
                          <li className="package-li">Employee Shout-outs</li>
                        </ul>
                        <p className="package-month">
                          <strong className="price-strong">$7</strong> per month
                          per user*
                        </p>
                        <p className="package-billed">
                          *Billed monthly on the 1st of each month.
                        </p>
                      </label>
                    </div>
                  </div>
                </div> */}
                <div className='form-check row'>
                  <div className=''>
                    <input
                      className='form-check-input myRadios'
                      type='radio'
                      name='myRadios'
                      id='all-in'
                      value='all-in'
                      onClick={(value) => handleClick(value)}
                    />
                  </div>
                  <div className=''>
                    <div className='package-card'>
                      <label className='form-check-label' htmlFor='enterprise'>
                        <h1 className='package-header'>ALL IN</h1>
                        <ul className='package-ul'>
                          {/* <li className="package-li">
                            <strong>All Standard Features +</strong>
                          </li> */}
                          <li className='package-li'>
                            Employee & Manager Dashboards
                          </li>
                          <li className='package-li'>Action Management</li>
                          <li className='package-li'>Profile Management</li>
                          <li className='package-li'>Employee Shout-Outs</li>
                          <li className='package-li'>
                            Configure Success Criteria
                          </li>
                          <li className='package-li'>
                            Configure Check-in Questions
                          </li>
                          <li className='package-li'>
                            Auto-updates with new features
                          </li>
                          <li className='package-li'>Generate Reports</li>
                        </ul>
                        <p className='package-month'>
                          <strong className='price-strong'>$8</strong> per month
                          per user*
                        </p>
                        <p className='package-billed'>
                          *Billed monthly on the 1st of each month.
                        </p>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </fieldset>
            <div className='row justify-content-center'>
              <div className='signin-btn-row bottom'>
                <button className='package-button' type='submit'>
                  <Link
                    to='/payment'
                    className='package-link'
                    state={selectedPackage}
                  >
                    Next Step
                  </Link>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default SelectPrice;
