import React, { useRef, useState, useEffect } from 'react';
import Page from '../../Components/NavBars/Page';
import './directory.css';
import Contact from '../../Components/Contact/Contact';
import axios from 'axios';
import { analytics } from '../../firebaseConfig.js';

const Directory = (props) => {
  const alphabet = [
    { letter: 'A', data: [] },
    { letter: 'B', data: [] },
    { letter: 'C', data: [] },
    { letter: 'D', data: [] },
    { letter: 'E', data: [] },
    { letter: 'F', data: [] },
    { letter: 'G', data: [] },
    { letter: 'H', data: [] },
    { letter: 'I', data: [] },
    { letter: 'J', data: [] },
    { letter: 'K', data: [] },
    { letter: 'L', data: [] },
    { letter: 'M', data: [] },
    { letter: 'N', data: [] },
    { letter: 'O', data: [] },
    { letter: 'P', data: [] },
    { letter: 'Q', data: [] },
    { letter: 'R', data: [] },
    { letter: 'S', data: [] },
    { letter: 'T', data: [] },
    { letter: 'U', data: [] },
    { letter: 'V', data: [] },
    { letter: 'W', data: [] },
    { letter: 'X', data: [] },
    { letter: 'Y', data: [] },
    { letter: 'Z', data: [] },
  ];

  const A = useRef(null);
  const B = useRef(null);
  const C = useRef(null);
  const D = useRef(null);
  const E = useRef(null);
  const F = useRef(null);
  const G = useRef(null);
  const H = useRef(null);
  const I = useRef(null);
  const J = useRef(null);
  const K = useRef(null);
  const L = useRef(null);
  const M = useRef(null);
  const N = useRef(null);
  const O = useRef(null);
  const P = useRef(null);
  const Q = useRef(null);
  const R = useRef(null);
  const S = useRef(null);
  const T = useRef(null);
  const U = useRef(null);
  const V = useRef(null);
  const W = useRef(null);
  const X = useRef(null);
  const Y = useRef(null);
  const Z = useRef(null);

  const executeScroll = (letter) =>
    letter.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
  // run this function from an event handler or an effect to execute scroll

  const [result, setResult] = useState();
  const [loading, setLoading] = useState(true);
  let daTa;

  const getData = () => {
    analytics.logEvent('e_dir_visited');
    axios.get('/getCompanyDirectory').then((res) => {
      const data = res.data.directory;
      let employees = [];

      for (let i = 0; i < data.length; i++) {
        if (data[i].userId !== res.data.credentials.userId) {
          let fName = data[i].firstName
            .toUpperCase()
            .trim()
            .replace(/[0-9]/g, '')
            .replace(/[^a-zA-Z]/g, '');
          let lName = data[i].lastName
            .toUpperCase()
            .trim()
            .replace(/[0-9]/g, '')
            .replace(/[^a-zA-Z]/g, '');
          employees.push({
            name: `${fName} ${lName}`,
            id: data[i].userId,
            number: data[i].phone,
            email: data[i].email,
            profImg: data[i].profileImg,
          });
        }
      }
      daTa = employees;
      sortData();
    });
  };

  const sortData = () => {
    daTa.sort((a, b) =>
      a.name
        .split(' ')[1]
        .charAt(0)
        .localeCompare(b.name.split(' ')[1].charAt(0), 'es', {
          sensitivity: 'base',
        }),
    );
    let employees = daTa.reduce((r, e) => {
      let alphabet = e.name.split(' ')[1].charAt(0);
      if (!r[alphabet]) r[alphabet] = { alphabet, record: [e] };
      else r[alphabet].record.push(e);
      return r;
    }, {});
    setResult();
    fixData(Object.values(employees));
  };

  const fixData = (result) => {
    for (let i = 0; i < result.length; i++) {
      const letter = result[i].alphabet;
      const index = alphabet.findIndex((x) => x.letter === letter);
      alphabet[index].data.push(result[i].record);
    }
    setResult(alphabet);
    setLoading(false);
  };

  useEffect(() => {
    getData();
  }, []);

  const ContactRow = (position) => {
    if (result[position].data.length === 0) {
      contactsToDisplay = <div className='hide-letter'></div>;
    } else {
      contactsToDisplay = (
        <div>
          {result[position].data[0].map((person) => (
            <Contact
              id={person.id}
              name={person.name}
              number={person.number}
              email={person.email}
              profileImg={person.profImg}
            />
          ))}
        </div>
      );
    }
  };
  let contactsToDisplay;

  const DisplayA = (data) => {

    if (data[0].data.length === 0) {
      return (
        <div
          className='directory-box hide-letter-row'
          id={`mdirectory-box-${data.letter}`}
        >
          <p ref={A} className='directory-letter'>
            {data[0].letter}
          </p>

          {ContactRow(data[0].letter.charCodeAt(0) - 65)}
          {contactsToDisplay}
        </div>
      );
    } else {
      return (
        <div className='directory-box ' id={`mdirectory-box-${data.letter}`}>
          <p ref={A} className='directory-letter'>
            {data[0].letter}
          </p>

          {ContactRow(data[0].letter.charCodeAt(0) - 65)}
          {contactsToDisplay}
        </div>
      );
    }
  };

  const DisplayB = (data) => {

    if (data[1].data.length === 0) {
      return (
        <div
          className='directory-box hide-letter-row'
          id={`mdirectory-box-${data.letter}`}
        >
          <p ref={B} className='directory-letter'>
            {data[1].letter}
          </p>

          {ContactRow(data[1].letter.charCodeAt(0) - 65)}
          {contactsToDisplay}
        </div>
      );
    } else {
      return (
        <div className='directory-box ' id={`mdirectory-box-${data.letter}`}>
          <p ref={B} className='directory-letter'>
            {data[1].letter}
          </p>

          {ContactRow(data[1].letter.charCodeAt(0) - 65)}
          {contactsToDisplay}
        </div>
      );
    }
  };

  const DisplayC = (data) => {
    console.log(data[2].letter.charCodeAt(0));

    if (data[2].data.length === 0) {
      return (
        <div
          className='directory-box hide-letter-row'
          id={`mdirectory-box-${data.letter}`}
        >
          <p ref={C} className='directory-letter'>
            {data[2].letter}
          </p>

          {ContactRow(data[2].letter.charCodeAt(0) - 65)}
          {contactsToDisplay}
        </div>
      );
    } else {
      return (
        <div className='directory-box ' id={`mdirectory-box-${data.letter}`}>
          <p ref={C} className='directory-letter'>
            {data[2].letter}
          </p>

          {ContactRow(data[2].letter.charCodeAt(0) - 65)}
          {contactsToDisplay}
        </div>
      );
    }
  };

  const DisplayD = (data) => {
    console.log(data[3].letter.charCodeAt(0));

    if (data[3].data.length === 0) {
      return (
        <div
          className='directory-box hide-letter-row'
          id={`mdirectory-box-${data.letter}`}
        >
          <p ref={D} className='directory-letter'>
            {data[3].letter}
          </p>

          {ContactRow(data[3].letter.charCodeAt(0) - 65)}
          {contactsToDisplay}
        </div>
      );
    } else {
      return (
        <div className='directory-box ' id={`mdirectory-box-${data.letter}`}>
          <p ref={D} className='directory-letter'>
            {data[3].letter}
          </p>

          {ContactRow(data[3].letter.charCodeAt(0) - 65)}
          {contactsToDisplay}
        </div>
      );
    }
  };

  const DisplayE = (data) => {
    console.log(data[4].letter.charCodeAt(0));

    if (data[4].data.length === 0) {
      return (
        <div
          className='directory-box hide-letter-row'
          id={`mdirectory-box-${data.letter}`}
        >
          <p ref={E} className='directory-letter'>
            {data[4].letter}
          </p>

          {ContactRow(data[4].letter.charCodeAt(0) - 65)}
          {contactsToDisplay}
        </div>
      );
    } else {
      return (
        <div className='directory-box ' id={`mdirectory-box-${data.letter}`}>
          <p ref={E} className='directory-letter'>
            {data[4].letter}
          </p>

          {ContactRow(data[4].letter.charCodeAt(0) - 65)}
          {contactsToDisplay}
        </div>
      );
    }
  };

  const DisplayF = (data) => {
    console.log(data[5].letter.charCodeAt(0));

    if (data[5].data.length === 0) {
      return (
        <div
          className='directory-box hide-letter-row'
          id={`mdirectory-box-${data.letter}`}
        >
          <p ref={F} className='directory-letter'>
            {data[5].letter}
          </p>

          {ContactRow(data[5].letter.charCodeAt(0) - 65)}
          {contactsToDisplay}
        </div>
      );
    } else {
      return (
        <div className='directory-box ' id={`mdirectory-box-${data.letter}`}>
          <p ref={F} className='directory-letter'>
            {data[5].letter}
          </p>

          {ContactRow(data[5].letter.charCodeAt(0) - 65)}
          {contactsToDisplay}
        </div>
      );
    }
  };

  const DisplayG = (data) => {
    console.log(data[6].letter.charCodeAt(0));

    if (data[6].data.length === 0) {
      return (
        <div
          className='directory-box hide-letter-row'
          id={`mdirectory-box-${data.letter}`}
        >
          <p ref={G} className='directory-letter'>
            {data[6].letter}
          </p>

          {ContactRow(data[6].letter.charCodeAt(0) - 65)}
          {contactsToDisplay}
        </div>
      );
    } else {
      return (
        <div className='directory-box ' id={`mdirectory-box-${data.letter}`}>
          <p ref={G} className='directory-letter'>
            {data[6].letter}
          </p>

          {ContactRow(data[6].letter.charCodeAt(0) - 65)}
          {contactsToDisplay}
        </div>
      );
    }
  };

  const DisplayH = (data) => {
    console.log(data[7].letter.charCodeAt(0));

    if (data[7].data.length === 0) {
      return (
        <div
          className='directory-box hide-letter-row'
          id={`mdirectory-box-${data.letter}`}
        >
          <p ref={H} className='directory-letter'>
            {data[7].letter}
          </p>

          {ContactRow(data[7].letter.charCodeAt(0) - 65)}
          {contactsToDisplay}
        </div>
      );
    } else {
      return (
        <div className='directory-box ' id={`mdirectory-box-${data.letter}`}>
          <p ref={H} className='directory-letter'>
            {data[7].letter}
          </p>

          {ContactRow(data[7].letter.charCodeAt(0) - 65)}
          {contactsToDisplay}
        </div>
      );
    }
  };

  const DisplayI = (data) => {
    console.log(data[8].letter.charCodeAt(0));

    if (data[8].data.length === 0) {
      return (
        <div
          className='directory-box hide-letter-row'
          id={`mdirectory-box-${data.letter}`}
        >
          <p ref={I} className='directory-letter'>
            {data[8].letter}
          </p>

          {ContactRow(data[8].letter.charCodeAt(0) - 65)}
          {contactsToDisplay}
        </div>
      );
    } else {
      return (
        <div className='directory-box ' id={`mdirectory-box-${data.letter}`}>
          <p ref={I} className='directory-letter'>
            {data[3].letter}
          </p>

          {ContactRow(data[8].letter.charCodeAt(0) - 65)}
          {contactsToDisplay}
        </div>
      );
    }
  };

  const DisplayJ = (data) => {
    console.log(data[9].letter.charCodeAt(0));

    if (data[9].data.length === 0) {
      return (
        <div
          className='directory-box hide-letter-row'
          id={`mdirectory-box-${data.letter}`}
        >
          <p ref={J} className='directory-letter'>
            {data[9].letter}
          </p>

          {ContactRow(data[9].letter.charCodeAt(0) - 65)}
          {contactsToDisplay}
        </div>
      );
    } else {
      return (
        <div className='directory-box ' id={`mdirectory-box-${data.letter}`}>
          <p ref={J} className='directory-letter'>
            {data[9].letter}
          </p>

          {ContactRow(data[9].letter.charCodeAt(0) - 65)}
          {contactsToDisplay}
        </div>
      );
    }
  };

  const DisplayK = (data) => {
    console.log(data[10].letter.charCodeAt(0));

    if (data[10].data.length === 0) {
      return (
        <div
          className='directory-box hide-letter-row'
          id={`mdirectory-box-${data.letter}`}
        >
          <p ref={K} className='directory-letter'>
            {data[10].letter}
          </p>

          {ContactRow(data[10].letter.charCodeAt(0) - 65)}
          {contactsToDisplay}
        </div>
      );
    } else {
      return (
        <div className='directory-box ' id={`mdirectory-box-${data.letter}`}>
          <p ref={K} className='directory-letter'>
            {data[10].letter}
          </p>

          {ContactRow(data[10].letter.charCodeAt(0) - 65)}
          {contactsToDisplay}
        </div>
      );
    }
  };

  const DisplayL = (data) => {
    console.log(data[11].letter.charCodeAt(0));

    if (data[11].data.length === 0) {
      return (
        <div
          className='directory-box hide-letter-row'
          id={`mdirectory-box-${data.letter}`}
        >
          <p ref={L} className='directory-letter'>
            {data[11].letter}
          </p>

          {ContactRow(data[11].letter.charCodeAt(0) - 65)}
          {contactsToDisplay}
        </div>
      );
    } else {
      return (
        <div className='directory-box ' id={`mdirectory-box-${data.letter}`}>
          <p ref={L} className='directory-letter'>
            {data[11].letter}
          </p>

          {ContactRow(data[11].letter.charCodeAt(0) - 65)}
          {contactsToDisplay}
        </div>
      );
    }
  };

  const DisplayM = (data) => {
    console.log(data[12].letter.charCodeAt(0));

    if (data[12].data.length === 0) {
      return (
        <div
          className='directory-box hide-letter-row'
          id={`mdirectory-box-${data.letter}`}
        >
          <p ref={M} className='directory-letter'>
            {data[12].letter}
          </p>

          {ContactRow(data[12].letter.charCodeAt(0) - 65)}
          {contactsToDisplay}
        </div>
      );
    } else {
      return (
        <div className='directory-box ' id={`mdirectory-box-${data.letter}`}>
          <p ref={M} className='directory-letter'>
            {data[12].letter}
          </p>

          {ContactRow(data[12].letter.charCodeAt(0) - 65)}
          {contactsToDisplay}
        </div>
      );
    }
  };

  const DisplayN = (data) => {
    console.log(data[13].letter.charCodeAt(0));

    if (data[13].data.length === 0) {
      return (
        <div
          className='directory-box hide-letter-row'
          id={`mdirectory-box-${data.letter}`}
        >
          <p ref={N} className='directory-letter'>
            {data[13].letter}
          </p>

          {ContactRow(data[13].letter.charCodeAt(0) - 65)}
          {contactsToDisplay}
        </div>
      );
    } else {
      return (
        <div className='directory-box ' id={`mdirectory-box-${data.letter}`}>
          <p ref={N} className='directory-letter'>
            {data[13].letter}
          </p>

          {ContactRow(data[13].letter.charCodeAt(0) - 65)}
          {contactsToDisplay}
        </div>
      );
    }
  };

  const DisplayO = (data) => {
    console.log(data[14].letter.charCodeAt(0));

    if (data[14].data.length === 0) {
      return (
        <div
          className='directory-box hide-letter-row'
          id={`mdirectory-box-${data.letter}`}
        >
          <p ref={O} className='directory-letter'>
            {data[14].letter}
          </p>

          {ContactRow(data[14].letter.charCodeAt(0) - 65)}
          {contactsToDisplay}
        </div>
      );
    } else {
      return (
        <div className='directory-box ' id={`mdirectory-box-${data.letter}`}>
          <p ref={O} className='directory-letter'>
            {data[14].letter}
          </p>

          {ContactRow(data[14].letter.charCodeAt(0) - 65)}
          {contactsToDisplay}
        </div>
      );
    }
  };

  const DisplayP = (data) => {
    console.log(data[15].letter.charCodeAt(0));

    if (data[15].data.length === 0) {
      return (
        <div
          className='directory-box hide-letter-row'
          id={`mdirectory-box-${data.letter}`}
        >
          <p ref={P} className='directory-letter'>
            {data[15].letter}
          </p>

          {ContactRow(data[15].letter.charCodeAt(0) - 65)}
          {contactsToDisplay}
        </div>
      );
    } else {
      return (
        <div className='directory-box ' id={`mdirectory-box-${data.letter}`}>
          <p ref={P} className='directory-letter'>
            {data[15].letter}
          </p>

          {ContactRow(data[15].letter.charCodeAt(0) - 65)}
          {contactsToDisplay}
        </div>
      );
    }
  };

  const DisplayQ = (data) => {
    console.log(data[16].letter.charCodeAt(0));

    if (data[16].data.length === 0) {
      return (
        <div
          className='directory-box hide-letter-row'
          id={`mdirectory-box-${data.letter}`}
        >
          <p ref={Q} className='directory-letter'>
            {data[16].letter}
          </p>

          {ContactRow(data[16].letter.charCodeAt(0) - 65)}
          {contactsToDisplay}
        </div>
      );
    } else {
      return (
        <div className='directory-box ' id={`mdirectory-box-${data.letter}`}>
          <p ref={Q} className='directory-letter'>
            {data[16].letter}
          </p>

          {ContactRow(data[16].letter.charCodeAt(0) - 65)}
          {contactsToDisplay}
        </div>
      );
    }
  };

  const DisplayR = (data) => {
    console.log(data[17].letter.charCodeAt(0));

    if (data[17].data.length === 0) {
      return (
        <div
          className='directory-box hide-letter-row'
          id={`mdirectory-box-${data.letter}`}
        >
          <p ref={R} className='directory-letter'>
            {data[17].letter}
          </p>

          {ContactRow(data[17].letter.charCodeAt(0) - 65)}
          {contactsToDisplay}
        </div>
      );
    } else {
      return (
        <div className='directory-box ' id={`mdirectory-box-${data.letter}`}>
          <p ref={R} className='directory-letter'>
            {data[17].letter}
          </p>

          {ContactRow(data[17].letter.charCodeAt(0) - 65)}
          {contactsToDisplay}
        </div>
      );
    }
  };

  const DisplayS = (data) => {
    console.log(data[18].letter.charCodeAt(0));

    if (data[18].data.length === 0) {
      return (
        <div
          className='directory-box hide-letter-row'
          id={`mdirectory-box-${data.letter}`}
        >
          <p ref={S} className='directory-letter'>
            {data[18].letter}
          </p>

          {ContactRow(data[18].letter.charCodeAt(0) - 65)}
          {contactsToDisplay}
        </div>
      );
    } else {
      return (
        <div className='directory-box ' id={`mdirectory-box-${data.letter}`}>
          <p ref={S} className='directory-letter'>
            {data[18].letter}
          </p>

          {ContactRow(data[18].letter.charCodeAt(0) - 65)}
          {contactsToDisplay}
        </div>
      );
    }
  };

  const DisplayT = (data) => {
    console.log(data[19].letter.charCodeAt(0));

    if (data[19].data.length === 0) {
      return (
        <div
          className='directory-box hide-letter-row'
          id={`mdirectory-box-${data.letter}`}
        >
          <p ref={T} className='directory-letter'>
            {data[19].letter}
          </p>

          {ContactRow(data[19].letter.charCodeAt(0) - 65)}
          {contactsToDisplay}
        </div>
      );
    } else {
      return (
        <div className='directory-box ' id={`mdirectory-box-${data.letter}`}>
          <p ref={T} className='directory-letter'>
            {data[19].letter}
          </p>

          {ContactRow(data[19].letter.charCodeAt(0) - 65)}
          {contactsToDisplay}
        </div>
      );
    }
  };

  const DisplayU = (data) => {
    console.log(data[20].letter.charCodeAt(0));

    if (data[20].data.length === 0) {
      return (
        <div
          className='directory-box hide-letter-row'
          id={`mdirectory-box-${data.letter}`}
        >
          <p ref={U} className='directory-letter'>
            {data[20].letter}
          </p>

          {ContactRow(data[20].letter.charCodeAt(0) - 65)}
          {contactsToDisplay}
        </div>
      );
    } else {
      return (
        <div className='directory-box ' id={`mdirectory-box-${data.letter}`}>
          <p ref={U} className='directory-letter'>
            {data[20].letter}
          </p>

          {ContactRow(data[20].letter.charCodeAt(0) - 65)}
          {contactsToDisplay}
        </div>
      );
    }
  };

  const DisplayV = (data) => {
    console.log(data[21].letter.charCodeAt(0));

    if (data[21].data.length === 0) {
      return (
        <div
          className='directory-box hide-letter-row'
          id={`mdirectory-box-${data.letter}`}
        >
          <p ref={V} className='directory-letter'>
            {data[21].letter}
          </p>

          {ContactRow(data[21].letter.charCodeAt(0) - 65)}
          {contactsToDisplay}
        </div>
      );
    } else {
      return (
        <div className='directory-box ' id={`mdirectory-box-${data.letter}`}>
          <p ref={V} className='directory-letter'>
            {data[21].letter}
          </p>

          {ContactRow(data[21].letter.charCodeAt(0) - 65)}
          {contactsToDisplay}
        </div>
      );
    }
  };

  const DisplayW = (data) => {
    console.log(data[22].letter.charCodeAt(0));

    if (data[22].data.length === 0) {
      return (
        <div
          className='directory-box hide-letter-row'
          id={`mdirectory-box-${data.letter}`}
        >
          <p ref={W} className='directory-letter'>
            {data[22].letter}
          </p>

          {ContactRow(data[22].letter.charCodeAt(0) - 65)}
          {contactsToDisplay}
        </div>
      );
    } else {
      return (
        <div className='directory-box ' id={`mdirectory-box-${data.letter}`}>
          <p ref={W} className='directory-letter'>
            {data[22].letter}
          </p>

          {ContactRow(data[22].letter.charCodeAt(0) - 65)}
          {contactsToDisplay}
        </div>
      );
    }
  };

  const DisplayX = (data) => {
    console.log(data[23].letter.charCodeAt(0));

    if (data[23].data.length === 0) {
      return (
        <div
          className='directory-box hide-letter-row'
          id={`mdirectory-box-${data.letter}`}
        >
          <p ref={X} className='directory-letter'>
            {data[23].letter}
          </p>

          {ContactRow(data[23].letter.charCodeAt(0) - 65)}
          {contactsToDisplay}
        </div>
      );
    } else {
      return (
        <div className='directory-box ' id={`mdirectory-box-${data.letter}`}>
          <p ref={X} className='directory-letter'>
            {data[23].letter}
          </p>

          {ContactRow(data[23].letter.charCodeAt(0) - 65)}
          {contactsToDisplay}
        </div>
      );
    }
  };

  const DisplayY = (data) => {
    console.log(data[24].letter.charCodeAt(0));

    if (data[24].data.length === 0) {
      return (
        <div
          className='directory-box hide-letter-row'
          id={`mdirectory-box-${data.letter}`}
        >
          <p ref={Y} className='directory-letter'>
            {data[24].letter}
          </p>

          {ContactRow(data[24].letter.charCodeAt(0) - 65)}
          {contactsToDisplay}
        </div>
      );
    } else {
      return (
        <div className='directory-box ' id={`mdirectory-box-${data.letter}`}>
          <p ref={Y} className='directory-letter'>
            {data[24].letter}
          </p>

          {ContactRow(data[24].letter.charCodeAt(0) - 65)}
          {contactsToDisplay}
        </div>
      );
    }
  };

  const DisplayZ = (data) => {
    console.log(data[25].letter.charCodeAt(0));

    if (data[25].data.length === 0) {
      return (
        <div
          className='directory-box hide-letter-row'
          id={`mdirectory-box-${data.letter}`}
        >
          <p ref={Z} className='directory-letter'>
            {data[25].letter}
          </p>

          {ContactRow(data[25].letter.charCodeAt(0) - 65)}
          {contactsToDisplay}
        </div>
      );
    } else {
      return (
        <div className='directory-box ' id={`mdirectory-box-${data.letter}`}>
          <p ref={Z} className='directory-letter'>
            {data[25].letter}
          </p>

          {ContactRow(data[25].letter.charCodeAt(0) - 65)}
          {contactsToDisplay}
        </div>
      );
    }
  };

  if (loading) return null;

  return (
    <>
      <Page
        content={
          <div className='directory-page'>
            <div className='contact-btns'>
              <button
                onClick={(letter) => executeScroll(A)}
                className='letter-btn'
              >
                {' '}
                A{' '}
              </button>
              <button
                onClick={(letter) => executeScroll(B)}
                className='letter-btn'
              >
                {' '}
                B{' '}
              </button>
              <button
                onClick={(letter) => executeScroll(C)}
                className='letter-btn'
              >
                {' '}
                C{' '}
              </button>
              <button
                onClick={(letter) => executeScroll(D)}
                className='letter-btn'
              >
                {' '}
                D{' '}
              </button>
              <button
                onClick={(letter) => executeScroll(E)}
                className='letter-btn'
              >
                {' '}
                E{' '}
              </button>
              <button
                onClick={(letter) => executeScroll(F)}
                className='letter-btn'
              >
                {' '}
                F{' '}
              </button>
              <button
                onClick={(letter) => executeScroll(G)}
                className='letter-btn'
              >
                {' '}
                G{' '}
              </button>
              <button
                onClick={(letter) => executeScroll(H)}
                className='letter-btn'
              >
                {' '}
                H{' '}
              </button>
              <button
                onClick={(letter) => executeScroll(I)}
                className='letter-btn'
              >
                {' '}
                I{' '}
              </button>
              <button
                onClick={(letter) => executeScroll(J)}
                className='letter-btn'
              >
                {' '}
                J{' '}
              </button>
              <button
                onClick={(letter) => executeScroll(K)}
                className='letter-btn'
              >
                {' '}
                K{' '}
              </button>
              <button
                onClick={(letter) => executeScroll(L)}
                className='letter-btn'
              >
                {' '}
                L{' '}
              </button>
              <button
                onClick={(letter) => executeScroll(M)}
                className='letter-btn'
              >
                {' '}
                M{' '}
              </button>
              <button
                onClick={(letter) => executeScroll(N)}
                className='letter-btn'
              >
                {' '}
                N{' '}
              </button>
              <button
                onClick={(letter) => executeScroll(O)}
                className='letter-btn'
              >
                {' '}
                O{' '}
              </button>
              <button
                onClick={(letter) => executeScroll(P)}
                className='letter-btn'
              >
                {' '}
                P{' '}
              </button>
              <button
                onClick={(letter) => executeScroll(Q)}
                className='letter-btn'
              >
                {' '}
                Q{' '}
              </button>
              <button
                onClick={(letter) => executeScroll(R)}
                className='letter-btn'
              >
                {' '}
                R{' '}
              </button>
              <button
                onClick={(letter) => executeScroll(S)}
                className='letter-btn'
              >
                {' '}
                S{' '}
              </button>
              <button
                onClick={(letter) => executeScroll(T)}
                className='letter-btn'
              >
                {' '}
                T{' '}
              </button>
              <button
                onClick={(letter) => executeScroll(U)}
                className='letter-btn'
              >
                {' '}
                U{' '}
              </button>
              <button
                onClick={(letter) => executeScroll(V)}
                className='letter-btn'
              >
                {' '}
                V{' '}
              </button>
              <button
                onClick={(letter) => executeScroll(W)}
                className='letter-btn'
              >
                {' '}
                W{' '}
              </button>
              <button
                onClick={(letter) => executeScroll(X)}
                className='letter-btn'
              >
                {' '}
                X{' '}
              </button>
              <button
                onClick={(letter) => executeScroll(Y)}
                className='letter-btn'
              >
                {' '}
                Y{' '}
              </button>
              <button
                onClick={(letter) => executeScroll(Z)}
                className='letter-btn'
              >
                {' '}
                Z{' '}
              </button>
            </div>

            <h1 className='mx-5 py-5'>Employee Directory</h1>
            {/* <div className="row search-row">
              <div className="col-1 search-icon-col">
                <FontAwesomeIcon
                  className="search-icon"
                  icon={["fal", "users"]}
                />
              </div>
              <div className="col-11">
                <div className="input-group rounded">
                  <input
                    type="search"
                    className="form-control rounded search-bar"
                    placeholder="Search Employees"
                    aria-label="Search"
                    aria-describedby="search-addon"
                  />
                  <span className="input-group-text border-0" id="search-addon">
                    <FontAwesomeIcon
                      className="search-icon-2"
                      icon={["fal", "search"]}
                    />
                  </span>
                </div>
              </div>
            </div> */}
            {DisplayA(result)}
            {DisplayB(result)}
            {DisplayC(result)}
            {DisplayD(result)}
            {DisplayE(result)}
            {DisplayF(result)}
            {DisplayG(result)}
            {DisplayH(result)}
            {DisplayI(result)}
            {DisplayJ(result)}
            {DisplayK(result)}
            {DisplayL(result)}
            {DisplayM(result)}
            {DisplayN(result)}
            {DisplayO(result)}
            {DisplayP(result)}
            {DisplayQ(result)}
            {DisplayR(result)}
            {DisplayS(result)}
            {DisplayT(result)}
            {DisplayU(result)}
            {DisplayV(result)}
            {DisplayW(result)}
            {DisplayX(result)}
            {DisplayY(result)}
            {DisplayZ(result)}
          </div>
        }
      />
    </>
  );
};

export default Directory;
