import axios from 'axios';
import React, { Component } from 'react';
import Page from '../../Components/NavBars/Page';
import EmployeeEngagement from '../../Components/Notif/EmployeeEngagement';
import EmployeeShout from '../../Components/Notif/EmployeeShout';
import IncompleteEmployee from '../../Components/Notif/IncompleteEmployee';
import Notif from '../../Components/Notif/Notif';
import RatedEmployee from '../../Components/Notif/RatedEmployee';
import ShoutOut from '../../Components/Notif/ShoutOut';
import SurveyNotif from '../../Components/Notif/SurveyNotif';
import { DEFAULT_IMAGE_URL } from '../../Constants/Constants';

class EmployeeNotif extends Component {
  constructor() {
    super();
    this.state = {
      notifs: [{}],
      shoutOuts: [{}],
      loading: true,
      anyNewSurvey: false,
      npsSurvey: [{}],
      errors: {},
    };
  }

  componentDidMount() {
    axios.get('/notifications').then((res) => {
      this.setState({ notifs: res.data });
    });
    //* * Error purposefully removed so we could notify employee there are no notifications available
    // .catch((error) => {
    //   console.error('EmployeeNotif - Error fetching notifications:', error);

    //   this.setState({
    //     error: 'An error occurred while fetching notifications.',
    //   });

    axios
      .get('/companyShoutouts')
      .then((response) => {
        const data = response.data;
        this.setState({ shoutOuts: data });
        this.setState({ loading: false });
      })
      .catch((error) => {
        console.error(
          'EmployeeNotif - Error fetching company ShoutOuts:',
          error,
        );

        this.setState({
          loading: false,
          error: 'An error occurred while fetching company ShoutOuts.',
        });
      });
  }

  Notifications = (notif, index) => {
    if (notif.type === 'Check-in response') {
      return (
        <div className='notifs-employee'>
          <Notif
            key={index}
            name={notif.name}
            text='responded to your check-in.'
            link={`/response/${notif.id}`}
            id={notif.id}
            read={notif.read}
            date=''
            img='./Images/green-checkmark.png'
          />
        </div>
      );
    } else if (notif.type === 'Check-in soon') {
      return (
        <div className='notifs-employee'>
          <Notif
            key={index}
            name='You'
            text={`have a check-in in ${notif.timeTill}.`}
            link='/check-in'
            date=''
            read={notif.read}
            img='./Images/green-clock.png'
          />
        </div>
      );
    } else if (notif.type === 'due 1 week') {
      return (
        <div className='notifs-employee'>
          <IncompleteEmployee
            key={index}
            text={`action ${notif.title} expires in 1 week.`}
            id={notif.actionId}
            type='Action Expires Soon'
            read={notif.read}
            id2={notif.notificationId}
            img='./Images/green-clock.png'
          />
        </div>
      );
    } else if (notif.type === 'due tomorrow') {
      return (
        <div className='notifs-employee'>
          <IncompleteEmployee
            key={index}
            text={`action ${notif.actionTitle} expires in 1 day.`}
            id={notif.actionId}
            id2={notif.notificationId}
            type='Action Expires Soon'
            read={notif.read}
            img='./Images/yellow-clock.png'
          />
        </div>
      );
    } else if (notif.type === 'passed due') {
      return (
        <div className='notifs-employee'>
          <IncompleteEmployee
            key={index}
            id={notif.actionId}
            id2={notif.notificationId}
            type='Action Expired'
            text={`action ${notif.actionTitle} has expired.`}
            read={notif.read}
            img='./Images/red-overdue-notif-icon.png'
          />
        </div>
      );
    } else if (notif.type === 'action rated') {
      return (
        <div className='notifs-employee'>
          <RatedEmployee
            key={index}
            name='Your Manager'
            text={`rated your action ${notif.actionTitle}.`}
            id={notif.actionId}
            id2={notif.notificationId}
            read={notif.read}
            img='./Images/green-checkmark.png'
          />
        </div>
      );
    } else if (notif.type === 'shoutout') {
      return (
        <div className='notifs-employee'>
          <EmployeeShout
            name={notif.senderName}
            id={notif.notificationId}
            shoutId={notif.shoutoutId}
            text='shouted out to'
            read={notif.read}
            to='You'
          />
        </div>
      );
    } else if (notif.type === 'Need Engagement') {
      return (
        <div className='notifs-employee'>
          <EmployeeEngagement
            key={index}
            name='You'
            text={`have not had a success engagement conversation in over ${notif.timeSince}.`}
            img='./Images/red-overdue-notif-icon.png'
          />
        </div>
      );
    } else if (notif.type === 'survey') {
      return (
        <div className='notifs-employee'>
          <SurveyNotif
            key={index}
            id={notif.notificationId}
            surveyId={notif.surveyId}
            read={notif.read}
          />
        </div>
      );
    }
  };

  isImageValid = (url) => {
    const image = new Image();
    image.src = url;
    return image.complete || image.width + image.height > 0;
  };

  render() {
    if (this.state.loading) return null;

    return (
      <>
        <Page
          content={
            <div className='notif-page-outside'>
              <div className='row'>
                {this.state.notifs.length === 0 ? (
                  <h4>
                    You have no notifications at this time. Please check back
                    later.
                  </h4>
                ) : (
                  this.state.notifs.map((notif, index) =>
                    this.Notifications(notif, index),
                  )
                )}
              </div>
              <div className='row'>
                {this.state.shoutOuts.map((notif, index) => {
                  const imageSrc = this.isImageValid(notif.creatorImg)
                    ? notif.creatorImg
                    : DEFAULT_IMAGE_URL;

                  return (
                    <div className='notifs-employee'>
                      <ShoutOut
                        key={index}
                        name={notif.creatorName}
                        id={notif.shoutoutId}
                        text='shouted out to'
                        to={notif.receiverName}
                        message={notif.message}
                        img={imageSrc}
                        created={notif.created}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          }
        />
      </>
    );
  }
}

export default EmployeeNotif;
