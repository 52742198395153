import React, { useEffect } from 'react';
import Spinner from '../../Components/Loading/Spinner';

const CompanyLoading2 = (props) => {
  useEffect(() => {
    console.log('Redirecting...');
    setTimeout(() => {
      console.log('later');
      window.location.replace('/cloading');
    }, [1000]);
  }, [props.history]);

  return <Spinner />;
};

export default CompanyLoading2;
