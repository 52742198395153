import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import Welcome from './Containers/CreateCompany/WelcomeCompany';
import CompanyInfo from './Containers/CreateCompany/CompanyInfo';
import CreateCompany from './Containers/CreateCompany/CreateCompany';
import Payment from './Containers/CreateCompany/Payment';
import AdminCompanyDash from './Containers/CreateCompany/AdminCompanyDash';
import SelectPrice from './Containers/CreateCompany/SelectPrice';
import Landing from './Containers/Landing/Landing';
import Login from './Containers/Login/Login';
import ChooseManager from './Containers/Signup/ChooseManager';
import FirstSuccess from './Containers/Signup/FirstSuccess';
import Signup from './Containers/Signup/Signup';
import UploadPhoto from './Containers/Signup/UploadPhoto';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fal } from '@fortawesome/pro-light-svg-icons';
import { fas } from '@fortawesome/pro-solid-svg-icons';
import { fad } from '@fortawesome/pro-duotone-svg-icons';
import EditProfile from './Containers/EditProfile/EditProfile';
import Profile from './Containers/Profile/Profile';
import EmployeeDash from './Containers/Employee/EmployeeDash';
import ManagerDash from './Containers/Manager/ManagerDash';
import ManagerNotif from './Containers/Manager/ManagerNotif';
import EmployeeNotif from './Containers/Employee/EmployeeNotif';
import Directory from './Containers/Directory/Directory';
import AdminCompanyDirectory from './Containers/Directory/AdminCompanyDirectory';
import ManagerCheckIn from './Containers/Manager/ManagerCheckIn';
import EmployeeCheckIn from './Containers/Employee/EmployeeCheckIn';
import ViewCheck from './Containers/Manager/ViewCheck';
import NewCheck from './Containers/Employee/NewCheck';
import Actions from './Containers/Employee/Actions';
import MActions from './Containers/Manager/MActions';
import axios from 'axios';
import jwtDecode from 'jwt-decode';
import Mdirectory from './Containers/Directory/Mdirectory';
import ViewProfile from './Containers/Profile/ViewProfile';
import ViewResponse from './Containers/Employee/ViewResponse';
import { Provider } from 'react-redux';
import store from './Redux/store';
import { SET_AUTHENTICATED } from './Redux/types';
import { logoutUser, getUserData } from './Redux/actions/userActions';
import ProtectedRoute from './Components/ProtectedRoute/ProtectedRoute';
import EditSuccess from './Containers/EditProfile/EditSuccess';
import IsManager from './Containers/Signup/IsManager';
import ImManager from './Containers/Signup/ImManager';
import MArchive from './Containers/Manager/MArchive';
import Archive from './Containers/Employee/Archive';
import NewManager from './Containers/EditProfile/NewManager';
import EditMProfile from './Containers/EditProfile/EditMProfile';
import AuthRoute from './Components/ProtectedRoute/AuthRoute';
import LoadingPage from './Containers/Signup/LoadingPage';
import EmployeeProfile from './Containers/Profile/EmployeeProfile';
import ForgotPass from './Containers/Login/ForgotPass';
import Feedback from './Containers/Feedback.jsx/Feedback';
import CompanyLogin from './Containers/Login/CompanyLogin';
import Mentor from './Containers/Mentor/Mentor';
import CompanyRedirect from './Containers/Login/CompanyRedirect';
import CompanyFocus from './Containers/CompanyFocus/CompanyFocus';
import AdminCompanyFocus from './Containers/CompanyFocus/AdminCompanyFocus';
import Survey from './Containers/Survey/Survey';
import SurveyResponses from './Containers/CreateCompany/SurveyResponses';
import ThisSurvey from './Containers/CreateCompany/ThisSurvey';
import AdminCompanyPlan from './Containers/CreateCompany/AdminCompanyPlan';
import AdminCompanySettings from './Containers/CreateCompany/AdminCompanySettings';
import CompanyLoading from './Containers/CreateCompany/CompanyLoading';
import CompanyLoading2 from './Containers/CreateCompany/CompanyLoading2';
import CompanyPaid from './Containers/CreateCompany/CompanyPaid';

axios.defaults.baseURL =
  'https://us-central1-sr-server-e8ea5.cloudfunctions.net/api';

const App = () => {
  const [loading, setLoading] = useState(true);
  const [isManager, setIsManager] = useState();
  const [auth, setAuth] = useState();
  const [company, setCompany] = useState(false);

  const getCompany = () => {
    axios
      .get('/company')
      .then((res) => {
        setLoading(false);
      })
      .catch((err) => {
        console.error('App.js - Error fetching company data:', err);
        setLoading(false);
      });
  };

  const getCredentials = () => {
    axios
      .get('/user')
      .then((res) => {
        const manager = res.data.credentials.managerCode;
        if (manager === '') {
          setIsManager(false);
        } else {
          setIsManager(true);
        }
        setAuth(true);
        setLoading(false);
      })
      .catch((err) => {
        console.error('App.js - Error fetching user data:', err);
        setAuth(true);
        setCompany(true);
        getCompany();
      });
  };

  const getData = async (data) => {
    if (data) {
      if (data === 'Bearer undefined') {
        store.dispatch(logoutUser());
        window.location.href = '/login';
        setIsManager(false);
        setAuth(false);
        setLoading(false);
      } else {
        const decodedToken = jwtDecode(data);

        if (decodedToken.exp * 1000 < Date.now()) {
          store.dispatch(logoutUser());
          window.location.href = '/login';
          setIsManager(false);
          setAuth(false);
          setLoading(false);
        } else {
          store.dispatch({ type: SET_AUTHENTICATED });
          axios.defaults.headers.common['Authorization'] = data;

          try {
            await store.dispatch(getUserData());
            getCredentials();
          } catch (error) {
            console.error('App.js - getData:', error);
          }
        }
      }
    } else {
      setIsManager(false);
      setAuth(false);
      setLoading(false);
    }
  };

  useEffect(() => {
    const IdToken = localStorage.IdToken;
    getData(IdToken);
  }, []);

  library.add(fal);
  library.add(fas);
  library.add(fad);

  if (loading) return null;

  return (
    <>
      <Provider store={store}>
        <Router>
          {/* Company Login Redirect */}
          <Route exact path='/companyRedirect' component={CompanyRedirect} />
          {/* Landing Screen */}
          <Route exact path='/' component={Landing} />
          {/* Login */}
          <Route exact path='/login' component={Login} />
          {/* Company Login */}
          <Route exact path='/companylogin' component={CompanyLogin} />
          {/* Signup-User */}
          <Route exact path='/signup' component={Signup} />
          <Route exact path='/loading' component={LoadingPage} />
          {/* Forgot pass */}
          <Route exact path='/forgot' component={ForgotPass} />
          {/* Signed in user- Creating Account */}
          {/* TODO: This is broken and needs to be fixed */}
          {/* <AuthRoute
            exact
            path='/uploadPhoto'
            component={UploadPhoto}
            auth={auth}
          /> */}
          <AuthRoute
            exact
            path='/chooseManager'
            component={ChooseManager}
            auth={auth}
          />
          <AuthRoute
            exact
            path='/newManager'
            component={NewManager}
            auth={auth}
          />
          <AuthRoute
            exact
            path='/isManager'
            component={IsManager}
            auth={auth}
          />
          <AuthRoute
            exact
            path='/createManager'
            component={ImManager}
            auth={auth}
          />
          <AuthRoute
            exact
            path='/firstSuccess'
            component={FirstSuccess}
            auth={auth}
          />
          {/* Signup-Company */}
          <Route exact path='/createCompany' component={CreateCompany} />
          <Route exact path='/cloading' component={CompanyLoading} />
          <Route exact path='/credirect' component={CompanyLoading2} />
          {/* Signed in company- Creating Account */}
          <AuthRoute
            exact
            path='/companyInfo'
            component={CompanyInfo}
            auth={auth}
          />
          <AuthRoute
            exact
            path='/selectPrice'
            component={SelectPrice}
            auth={auth}
          />
          <AuthRoute exact path='/payment' component={Payment} auth={auth} />
          <AuthRoute exact path='/welcome' component={Welcome} auth={auth} />
          <AuthRoute
            exact
            path='/company'
            component={AdminCompanyDash}
            auth={auth}
          />
          <AuthRoute
            exact
            path='/mysurveys'
            component={SurveyResponses}
            auth={auth}
          />
          <AuthRoute
            exact
            path='/company/directory'
            component={AdminCompanyDirectory}
            auth={auth}
          />
          <AuthRoute
            exact
            path='/company/focus'
            component={AdminCompanyFocus}
            auth={auth}
          />
          <AuthRoute
            exact
            path='/company/plan'
            component={AdminCompanyPlan}
            auth={auth}
          />
          <AuthRoute
            exact
            path='/company/paid/:date'
            component={CompanyPaid}
            auth={auth}
          />
          <AuthRoute
            exact
            path='/company/settings'
            component={AdminCompanySettings}
            auth={auth}
          />
          <AuthRoute
            exact
            path='/surveys/:surveyId'
            component={ThisSurvey}
            auth={auth}
          />
          {/*<AuthRoute exact path="/company/subscription" component={plan} auth={auth} />*/}

          {/* Profile
          
           *Change to /Profile/:id*
           */}

          {/* Employee */}
          <AuthRoute exact path='/newcheck' component={NewCheck} auth={auth} />
          <AuthRoute
            exact
            path='/response/:id'
            component={ViewResponse}
            auth={auth}
          />

          {/* Manager */}
          <AuthRoute
            exact
            path='/check/:id'
            component={ViewCheck}
            auth={auth}
          />

          {/* New Routes */}
          <ProtectedRoute
            exact
            path='/directory'
            component={Directory}
            mcomponent={Mdirectory}
            auth={auth}
            isManager={isManager}
          />
          <ProtectedRoute
            exact
            path='/dashboard'
            component={EmployeeDash}
            mcomponent={ManagerDash}
            auth={auth}
            isManager={isManager}
          />
          <ProtectedRoute
            exact
            path='/notif'
            component={EmployeeNotif}
            mcomponent={ManagerNotif}
            auth={auth}
            isManager={isManager}
          />
          {/* adding route for company focus */}
          <ProtectedRoute
            exact
            path='/focus'
            component={CompanyFocus}
            mcomponent={CompanyFocus}
            auth={auth}
            isManager={isManager}
          />
          <ProtectedRoute
            exact
            path='/check-in'
            component={EmployeeCheckIn}
            mcomponent={ManagerCheckIn}
            auth={auth}
            isManager={isManager}
          />
          <ProtectedRoute
            exact
            path='/actions'
            component={Actions}
            mcomponent={MActions}
            auth={auth}
            isManager={isManager}
          />
          <ProtectedRoute
            exact
            path='/archive'
            component={Archive}
            mcomponent={MArchive}
            auth={auth}
            isManager={isManager}
          />
          <ProtectedRoute
            exact
            path='/profile'
            component={EmployeeProfile}
            mcomponent={Profile}
            auth={auth}
            isManager={isManager}
          />
          <ProtectedRoute
            exact
            path='/editProfile'
            component={EditProfile}
            mcomponent={EditMProfile}
            auth={auth}
            isManager={isManager}
          />
          <ProtectedRoute
            exact
            path='/editSuccess'
            component={EditSuccess}
            mcomponent={EditSuccess}
            auth={auth}
            isManager={isManager}
          />
          <ProtectedRoute
            exact
            path='/profile/:id'
            component={ViewProfile}
            mcomponent={ViewProfile}
            auth={auth}
            isManager={isManager}
          />
          <ProtectedRoute
            exact
            path='/feedback'
            component={Feedback}
            mcomponent={Feedback}
            auth={auth}
            isManager={isManager}
          />
          <ProtectedRoute
            exact
            path='/mentor'
            component={Mentor}
            mcomponent={Mentor}
            auth={auth}
            isManager={isManager}
          />
          <ProtectedRoute
            exact
            path='/survey/:surveyId'
            component={Survey}
            mcomponent={Survey}
            auth={auth}
            isManager={isManager}
          />
        </Router>
      </Provider>
    </>
  );
};

export default App;
