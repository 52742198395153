import React, { Component } from 'react';
import Page from '../../Components/NavBars/Page';
import BackBtn from '../../Components/Back/BackBtn';
import axios from 'axios';
import { analytics } from '../../firebaseConfig.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class EditSuccess extends Component {
  constructor() {
    super();
    this.state = {
      id: '',
      q1: '',
      q2: '',
      q3: '',
      q1I: false,
      q2I: false,
      q3I: false,
      loading: true,
      errors: {},
    };
  }
  componentDidMount() {
    analytics.logEvent(`edit_sc_visited`);
    //     const data = {
    //         q1: "As a Customer Success Manager my role is to ensure that our customers are able to maximize our software so that they can optimize results for their organization. I wear multiple hats including trainer, advisor, and coach. I help organizations establish key metrics on what they aspire to gain in using our software, and then I conduct quarterly connection calls. I help organizations establish key metrics on what they aspire to gain in using our software, and then I conduct quarterly connection calls.",
    //         q2: "As a Customer Success Manager my role is to ensure that our customers are able to maximize our software so that they can optimize results for their organization. I wear multiple hats including trainer, advisor, and coach. I help organizations establish key metrics on what they aspire to gain in using our software, and then I conduct quarterly connection calls. I help organizations establish key metrics on what they aspire to gain in using our software, and then I conduct quarterly connection calls.",
    //         q3: "As a Customer Success Manager my role is to ensure that our customers are able to maximize our software so that they can optimize results for their organization. I wear multiple hats including trainer, advisor, and coach. I help organizations establish key metrics on what they aspire to gain in using our software, and then I conduct quarterly connection calls. I help organizations establish key metrics on what they aspire to gain in using our software, and then I conduct quarterly connection calls.",
    //     }
    //     this.setState({q1: data.q1})
    // this.setState({q2: data.q2})
    // this.setState({q3: data.q3})
    axios.get('/user').then((res) => {
      const data = res.data.credentials;
      this.setState({ q1: data.a1 });
      this.setState({ q2: data.a2 });
      this.setState({ q3: data.a3 });
    });
    this.setState({ loading: false });
  }

  handleSubmit = (event) => {
    event.preventDefault();
    this.setState({
      loading: true,
    });
    let editSuccess = {
      id: this.state.id,
      a1: this.state.q1,
      a2: this.state.q2,
      a3: this.state.q3,
    };
    if (editSuccess.a1 === '') {
      editSuccess.a1 = '...';
    } else if (editSuccess.a2 === '') {
      editSuccess.a2 = '...';
    } else if (editSuccess.a3 === '') {
      editSuccess.a3 = '...';
    }
    console.log(editSuccess);
    axios
      .post('/updateScAnswers', editSuccess)
      .then((res) => {
        this.setState({
          loading: false,
        });
        // this.props.history.push("/profile");
      })
      .catch((err) => {
        console.log(err.response.data);
        this.setState({
          errors: { general: 'Error: Please check information and try again.' },
          loading: false,
        });
      });
  };
  onOneChange = (event) => {
    this.setState({
      q1I: true,
    });
    this.setState({
      q1: event.target.value,
    });
  };
  onTwoChange = (event) => {
    this.setState({
      q2I: true,
    });
    this.setState({
      q2: event.target.value,
    });
  };
  onThreeChange = (event) => {
    this.setState({
      q3I: true,
    });
    this.setState({
      q3: event.target.value,
    });
  };

  render() {
    if (this.state.loading) return null;
    return (
      <>
        <Page
          content={
            <div className='first-sc'>
              <div className='row first-sc-col'>
                <BackBtn link='profile' className='space-below-2' />
                <h1 className='edit-sc-header'>Success Criteria</h1>
                <form>
                  <div className='form-group'>
                    <div className='row'>
                      <div className='col-9'>
                        <label htmlFor='q1' className='sc-label'>
                          What does success look like in my role?
                        </label>
                      </div>
                      <div className='col-3 mb-3'>
                        <button
                          onClick={this.handleSubmit}
                          className='btn btn-submit'
                          type='submit'
                        >
                          Update
                        </button>
                      </div>
                    </div>
                    <div className='clear-space'>
                      {this.state.q1I ? (
                        <FontAwesomeIcon
                          className='sc-icon-edit'
                          icon={['fad', 'bullseye-arrow']}
                        />
                      ) : null}
                    </div>
                    <textarea
                      value={this.state.q1}
                      onChange={this.onOneChange}
                      type='text'
                      className='form-control sc-input'
                      id='q1'
                    />
                  </div>
                  <div className='form-group'>
                    <div className='row'>
                      <div className='col-9'>
                        <label htmlFor='q2' className='sc-label'>
                          How does my success impact my departments success?
                        </label>
                      </div>

                      <div className='col-3 mb-3'>
                        <button
                          onClick={this.handleSubmit}
                          className='btn btn-submit'
                          type='submit'
                        >
                          Update
                        </button>
                      </div>
                    </div>
                    <div className='clear-space'>
                      {this.state.q2I ? (
                        <FontAwesomeIcon
                          className='sc-icon-edit'
                          icon={['fad', 'people-group']}
                        />
                      ) : null}
                    </div>
                    <textarea
                      value={this.state.q2}
                      onChange={this.onTwoChange}
                      type='text'
                      className='form-control sc-input'
                      id='q2'
                    />
                  </div>
                  <div className='form-group'>
                    <div className='row'>
                      <div className='col-9'>
                        <label htmlFor='q3' className='sc-label'>
                          How does my success impact my company success?
                        </label>
                      </div>

                      <div className='col-3 mb-3'>
                        <button
                          onClick={this.handleSubmit}
                          className='btn btn-submit'
                          type='submit'
                        >
                          Update
                        </button>
                      </div>
                    </div>
                    <div className='clear-space'>
                      {this.state.q3I ? (
                        <FontAwesomeIcon
                          className='sc-icon-edit'
                          icon={['fad', 'building-user']}
                        />
                      ) : null}
                    </div>
                    <textarea
                      value={this.state.q3}
                      onChange={this.onThreeChange}
                      type='text'
                      className='form-control sc-input'
                      id='q3'
                    />
                  </div>
                  <p className='errors-p p-3'>{this.state.errors.general}</p>
                  {/* <div className="row justify-content-center">
                    <button
                      onClick={this.handleSubmit}
                      className="sc-btn"
                      type="submit"
                    >
                      Update Profile
                    </button>
                  </div> */}
                </form>
              </div>
            </div>
          }
        />
      </>
    );
  }
}

export default EditSuccess;
