import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component } from 'react';
import { faCircle } from '@fortawesome/pro-solid-svg-icons';
import ModalCloseBtn from '../../Constants/ModalCloseBtn';

class ArchivedSub extends Component {
  constructor() {
    super();
    this.state = {
      id: '',
      main: '',
      name: '',
      action: '',
      dDate: '',
      comment: '',
      priority: '',
      rating: '',
      loading: true,
      errors: {},
    };
  }

  componentDidMount() {
    this.setState({ id: this.props.id });
    this.setState({ main: this.props.main });
    this.setState({ name: this.props.name });
    this.setState({ action: this.props.title });
    this.setState({ priority: this.props.priority });
    this.setState({ dDate: this.props.dDate });

    this.setState({ loading: false });
  }

  render() {
    if (this.state.loading) return null;
    return (
      <>
        <div
          className='modal '
          id={`edit${this.state.id}`}
          tabIndex='-1'
          role='dialog'
          aria-labelledby='exampleModalCenterTitle'
          aria-hidden='true'
        >
          <div className='col-xs-2'>
            <div className='modal-arrow-sub' id='top'></div>
          </div>
          <div className='modal-dialog modal-dialog-centered' role='document'>
            <div className='modal-content sub-action'>
              <div className='modal-header'>
                <h5 className='modal-edit-title' id='exampleModalLongTitle'>
                  Archived Action
                </h5>
                <ModalCloseBtn />
              </div>
              <div className='modal-body'>
                <form>
                  <div className='form-group disabled-group'>
                    <label
                      htmlFor='exampleFormControlInput5'
                      className='action-label'
                    >
                      Main Action
                    </label>
                    <div className=''>
                      <div className=' action-input-col'>
                        <select className='form-select action-input' disabled>
                          <option>{this.state.main}</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  {/* <div className="form-group disabled-group">
                    <label htmlFor="exampleFormControlInput1" className="action-label">
                      Employee Name
                    </label>
                    <div className="">
                      <div className=" action-input-col">
                        <select
                          className="form-select action-input selectPicker"
                          
                          data-live-search="true"
                          disabled
                        >
                          <option>{this.state.name}</option>
                        </select>
                      </div>
                    </div>
                  </div> */}
                  <div className='form-group disabled-group'>
                    <label
                      htmlFor='exampleFormControlInput3'
                      className='action-label'
                    >
                      Action
                    </label>
                    <input
                      type='text'
                      className='form-control action-input'
                      value={this.state.action}
                      disabled
                    ></input>
                  </div>
                  <div className='form-group disabled-group'>
                    <label
                      htmlFor='exampleFormControlInput6'
                      className='action-label'
                    >
                      Due Date
                    </label>
                    <input
                      type=''
                      className='form-control action-input'
                      value={this.state.dDate}
                      disabled
                    />
                  </div>
                  <div className='form-group disabled-group'>
                    <label
                      htmlFor='exampleFormControlInput5'
                      className='action-label'
                    >
                      Priority
                    </label>
                    <div className='row'>
                      <div className='col-11 action-input-col'>
                        <p
                          type='text'
                          className='form-control action-input disabled-group'
                        >
                          <FontAwesomeIcon
                            className={`priority-icon-${this.state.priority}`}
                            icon={faCircle}
                          />{' '}
                          {this.state.priority}
                        </p>
                      </div>
                      <div className='col-1 action-icon-col'>
                        <span className='action-icon'>
                          <FontAwesomeIcon
                            className='action-i form-control-feedback'
                            icon={['fal', 'chevron-down']}
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className='form-group disabled-group'>
                    <label
                      htmlFor='exampleFormControlInput7'
                      className='action-label'
                    >
                      Status
                    </label>
                    <select className='form-select action-select '>
                      <option>Complete</option>
                    </select>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default ArchivedSub;
