import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import React, { Component } from 'react';
import $ from 'jquery';
import Select from 'react-select';
import ModalCloseBtn from '../../Constants/ModalCloseBtn';

class NewSuccessEng extends Component {
  constructor() {
    super();
    this.state = {
      id: '',
      receiverName: '',
      receiverData: '',
      receiver: '',
      employeeNames: [],
      loading: true,
      errors: {},
    };
  }
  handleSubmit = (event) => {
    event.preventDefault();
    this.setState({
      loading: true,
    });
    const successEng = {
      employeeId: this.state.receiver,
    };
    if (successEng.employee === '') {
      this.setState({
        errors: { general: 'Error: Please check information and try again.' },
        loading: false,
      });
    } else {
      axios
        .post(`/incrementSuccessEngagement/${successEng.employeeId}`)
        .then((res) => {
          this.setState({
            loading: false,
          });

          window.location.reload();
        })
        .catch((err) => {
          console.log(err.response.data);
          this.setState({
            errors: {
              general: 'Error: Please check information and try again.',
            },
            loading: false,
          });
          $('#newSuccessEngPopover').trigger('click');
        });
    }
  };
  componentDidMount() {
    axios.get('/getManagedTeam').then((res) => {
      const data = res.data;
      let names = [];
      for (let i = 0; i < data.length; i++) {
        let fName = data[i].firstName.toUpperCase();
        let lName = data[i].lastName.toUpperCase();
        names.push({
          name: `${fName} ${lName}`,
          id: data[i].userId,
          img: data[i].profileImg,
        });
      }
      names.sort((a, b) =>
        a.name
          .split(' ')[1]
          .charAt(0)
          .localeCompare(b.name.split(' ')[1].charAt(0), 'es', {
            sensitivity: 'base',
          }),
      );
      const formOptions = [];
      for (let i = 0; i < names.length; i++) {
        let thisUser = {
          value: `${names[i].id},${names[i].name}`,
          label: (
            <div>
              {' '}
              <img
                src={names[i].img}
                alt={names[i].id}
                className='newShoutImg'
              />{' '}
              {names[i].name}{' '}
            </div>
          ),
        };
        formOptions.push(thisUser);
      }
      this.setState({ employeeNames: formOptions });
    });
    this.setState({ loading: false });
  }
  onReceiverChange = (value) => {
    this.setState({
      receiverData: value,
    });
    this.setState({
      receiver: value.value.split(',')[0],
    });
    this.setState({
      receiverName: value.value.split(',')[1],
    });
  };

  render() {
    if (this.state.loading) return null;
    return (
      <>
        <div
          className='modal '
          id='NewSuccessEng'
          tabIndex='-1'
          role='dialog'
          aria-labelledby='exampleModalCenterTitle'
          aria-hidden='true'
        >
          <div className='col-xs-2'>
            <div className='modal-arrow-sub' id='top'></div>
          </div>
          <div className='modal-dialog modal-dialog-centered' role='document'>
            <div className='modal-content sub-action'>
              <div className='modal-header'>
                <h5 id='exampleModalLongTitle'>
                  Success Engagement Conversation
                </h5>
                <ModalCloseBtn />
              </div>
              <div className='modal-body'>
                <form>
                  <div className='form-group'>
                    <label
                      htmlFor='exampleFormControlInput1'
                      className='action-label'
                    >
                      Employee Name
                    </label>
                    <div className='row'>
                      <div className='col-11 action-input-col'>
                        <Select
                          onChange={this.onReceiverChange.bind(this)}
                          className='action-selector'
                          value={this.state.receiverData}
                          options={this.state.employeeNames}
                          placeholder='Search for a teammate...'
                        />
                      </div>
                      <div className='col-1 action-icon-col'>
                        <span className='action-icon'>
                          <FontAwesomeIcon
                            className='action-i form-control-feedback'
                            icon={['fal', 'search']}
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              <p className='errors-p p-3'>{this.state.errors.general}</p>
              <div className='modal-footer'>
                <button
                  type='button'
                  onClick={this.handleSubmit}
                  className='btn btn-submit'
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default NewSuccessEng;
