import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import React, { Component } from 'react';
import './actions.css';
import $ from 'jquery';
import moment from 'moment';
import Select from 'react-select';
import { faCircle } from '@fortawesome/pro-solid-svg-icons';
import ModalCloseBtn from '../../Constants/ModalCloseBtn';

const today = moment().format('YYYY-MM-DD');

const formOptions = [
  {
    data: 'low',
    label: (
      <div>
        {' '}
        <FontAwesomeIcon
          className='priority-icon-low'
          icon={faCircle}
        /> Low{' '}
      </div>
    ),
  },
  {
    data: 'medium',
    label: (
      <div>
        {' '}
        <FontAwesomeIcon
          className='priority-icon-medium'
          icon={faCircle}
        />{' '}
        Medium
      </div>
    ),
  },
  {
    data: 'high',
    label: (
      <div>
        <FontAwesomeIcon className='priority-icon-high' icon={faCircle} /> High
      </div>
    ),
  },
  {
    data: 'critical',
    label: (
      <div>
        <FontAwesomeIcon className='priority-icon-critical' icon={faCircle} />{' '}
        Critical
      </div>
    ),
  },
];

class ResMain extends Component {
  constructor() {
    super();
    this.state = {
      id: '',
      name: '',
      action: '',
      description: '',
      priority: '',
      dDate: '',
      employeeNames: [],
      loading: true,
      errors: {},
    };
  }

  componentDidMount() {
    // axios.get('/users')
    // .then(res => {
    //   const data = res.data;
    //   let names = [];
    //   for (let i = 0; i < data.length; i++) {
    //     names.push(data[i].name);
    //   }
    //   this.setState({employeeNames: names})
    // });
    const employeeNames = [
      'Me',
      'Jane Doe',
      'John Doe',
      'Sam Smith',
      'John Smith',
      'Mike Smith',
      'Johnny Appleseed',
    ];
    this.setState({ employeeNames: employeeNames });

    this.setState({ id: this.props.id });
    this.setState({ action: this.props.action });
    this.setState({ name: this.props.name });
    this.setState({ description: this.props.description });
    this.setState({ priority: this.props.priority });

    this.setState({ loading: false });
  }
  handleSubmit = (event) => {
    event.preventDefault();
    this.setState({
      loading: true,
    });
    const newMainAction = {
      title: this.state.action,
      description: this.state.description,
      priority: this.state.priority.data,
      dueDate: this.state.dDate,
    };
    console.log(newMainAction);
    if (
      newMainAction.title === '' ||
      newMainAction.description === '' ||
      newMainAction.priority === '' ||
      newMainAction.dueDate === ''
    ) {
      this.setState({
        errors: { general: 'Error: Please check information and try again.' },
        loading: false,
      });
    } else {
      axios
        .post('/action', newMainAction)
        .then((res) => {
          this.setState({
            loading: false,
          });

          window.location.reload();
        })
        .catch((err) => {
          console.log(err.response.data);
          this.setState({
            errors: {
              general: 'Error: Please check information and try again.',
            },
            loading: false,
          });
          $(`#resMainAction${this.state.id}btn`).trigger('click');
        });
    }
  };

  onNameChange = (event) => {
    this.setState({
      name: event.target.name,
    });
  };
  onActionChange = (event) => {
    this.setState({
      action: event.target.value,
    });
  };
  onDescriptionChange = (event) => {
    this.setState({
      description: event.target.value,
    });
  };
  onPriorityChange = (value) => {
    this.setState({
      priority: value,
    });
  };
  onDateChange = (event) => {
    this.setState({
      dDate: event.target.value,
    });
  };

  render() {
    if (this.state.loading) return null;
    return (
      <>
        <div
          className='modal res-modal'
          id={`resMainAction${this.state.id}`}
          tabIndex='1'
          role='dialog'
          aria-labelledby='exampleModalCenterTitle'
          aria-hidden='true'
        >
          <div className='col-xs-2'>
            <div className='modal-arrow' id='top'></div>
          </div>
          <div className='modal-dialog modal-dialog-centered' role='document'>
            <div className='modal-content main-action res-content'>
              <div className='modal-header'>
                <h5 className='modal-title' id='exampleModalLongTitle'>
                  Main Action
                </h5>
                <ModalCloseBtn />
              </div>
              <div className='modal-body'>
                <form>
                  <div className='form-group'>
                    <label
                      htmlFor={'resAction' + this.state.id}
                      className='action-label'
                    >
                      Action
                    </label>
                    <input
                      type='text'
                      className='form-control action-input'
                      id={'resAction' + this.state.id}
                      onChange={this.onActionChange}
                      value={this.state.action}
                    />
                  </div>
                  <div className='form-group'>
                    <label
                      htmlFor='exampleFormControlInput4'
                      className='action-label'
                    >
                      Description
                    </label>
                    <input
                      type='text'
                      className='form-control action-input'
                      placeholder=''
                      onChange={this.onDescriptionChange}
                      value={this.state.description}
                    />
                  </div>
                  <div className='form-group'>
                    <label
                      htmlFor='exampleFormControlInput5'
                      className='action-label'
                    >
                      Priority
                    </label>
                    <div className='row'>
                      <div className='col-11 action-input-col'>
                        <Select
                          onChange={this.onPriorityChange.bind(this)}
                          className='action-selector'
                          value={this.state.priority}
                          options={formOptions}
                        />
                        {/* <select
                        onChange={this.onPriorityChange} value={this.state.priority}
                          className="form-select action-input"
                          
                        >
                         
                          <option value="low">🟡‎‎ ‏‏‎ ‎‏‏‎ ‎Low</option>
                          <option value="medium">🟢‏‏‎ ‎‏‏‎ ‎ Medium</option>
                          <option value="high">🔵 ‏‏‎ ‎‏‏‎ ‎High</option>
                          <option value="critical">🔴 ‏‏‎ ‎‏‏‎ ‎Critical</option>
                        </select> */}
                      </div>
                      <div className='col-1 action-icon-col'>
                        <span className='action-icon'>
                          <FontAwesomeIcon
                            className='action-i form-control-feedback'
                            icon={['fal', 'chevron-down']}
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className='form-group'>
                    <label
                      htmlFor='exampleFormControlInput6'
                      className='action-label'
                    >
                      Due Date
                    </label>
                    <input
                      onChange={this.onDateChange}
                      value={this.state.dDate}
                      type='date'
                      className='form-control action-input'
                      placeholder=''
                      min={today}
                    />
                  </div>
                </form>
              </div>
              <p className='errors-p p-3'>{this.state.errors.general}</p>
              <div className='modal-footer'>
                <button
                  type='button'
                  onClick={this.handleSubmit}
                  className='btn btn-submit'
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default ResMain;
