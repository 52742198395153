import React, { useEffect } from 'react';
import axios from 'axios';
import { useState } from 'react';
import { useParams } from 'react-router-dom';

const CompanyPaid = (props) => {
  const [loading, setLoading] = useState(true);
  const params = useParams();

  const getCompany = (date) => {
    const data = { nextPayment: date };
    axios
      .post('/company/payment', data)
      .then((res) => {
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getCompany(params.date);
  }, [params.date]);

  const returnHome = (e) => {
    e.preventDefault();
    window.location.replace('/company/plan');
  };

  return (
    <>
      <div className='container'>
        <div className='row text-center'>
          <div className='col-4'></div>
          <div className='col-4'>
            <div className='row add-r-box'>
              <h1 className='package-header'>Completed Payment</h1>

              <p className='package-billed mt-1 pb-3'>
                {`Next payment is due on or before ${new Date(
                  params.date * 1,
                )}`}
              </p>

              <div className='row'>
                <button onClick={(e) => returnHome(e)} className='btn'>
                  Return
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CompanyPaid;
