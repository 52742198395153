import axios from 'axios';
import React, { useEffect, useState } from 'react';
import Page from '../../Components/NavBars/Page';
import ActionNotif from '../../Components/Notif/ActionNotif';
import EmployeeShout from '../../Components/Notif/EmployeeShout';
import Engagement from '../../Components/Notif/Engagement';
import IncompleteEmployee from '../../Components/Notif/IncompleteEmployee';
import Notif from '../../Components/Notif/Notif';
import OverdueAction from '../../Components/Notif/OverdueAction';
import RatedEmployee from '../../Components/Notif/RatedEmployee';
import ShoutOut from '../../Components/Notif/ShoutOut';
// import SurveyNotif from '../../Components/Notif/SurveyNotif';
import { DEFAULT_IMAGE_URL } from '../../Constants/Constants';

const ManagerNotif = () => {
  const [loading, setLoading] = useState(true);
  const [newUsers, setNewUsers] = useState([]);
  const [anyNewUsers, setAnyNewUsers] = useState(false);
  const [shoutsToMe, setShoutsToMe] = useState([]);
  const [shoutOuts, setShoutOuts] = useState([]);
  const [anyNewShouts, setAnyNewShouts] = useState(false);
  const [actionsPassed, setActionsPassed] = useState([]);
  const [ratedActions, setRatedActions] = useState([]);
  const [anyNewRated, setAnyNewRated] = useState(false);
  const [actionsCompleted, setActionsCompleted] = useState([]);
  const [anyNewCompleted, setAnyNewCompleted] = useState(false);
  const [checkIns, setCheckIns] = useState([]);
  const [anyNewChecks, setAnyNewChecks] = useState(false);
  const [overdueActions, setOverdueActions] = useState([]);
  const [anyNewOverdue, setAnyNewOverdue] = useState(false);
  const [npsSurvey, setNpsSurvey] = useState([]);
  const [anyNewSurvey, setAnyNewSurvey] = useState(false);
  const [se, setSe] = useState([]);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    populateData();
    fetchNotifications();
    fetchCompanyShoutouts();
  }, []);

  const populateData = () => {
    const data = {
      checkIns: [
        { id: '001', name: 'John Doe', date: '4/11/20' },
        { id: '002', name: 'Jane Doe', date: '4/10/20' },
      ],
      actionsPassed: [
        { id: '001', name: 'John Doe', date: '4/11/20' },
        { id: '001-02', name: 'Jane Doe', date: '4/10/20' },
      ],
      actionsComplete: [
        { id: '001-04', name: 'John Doe' },
        { id: '002', name: 'Jane Doe' },
      ],
      se: [
        {
          id: '0001',
          name: 'John Doe',
          text: 'has not had a success engagement conversation in over 5 weeks',
        },
      ],
      shoutOuts: [
        {
          id: '009',
          giver: 'John Doe',
          receiver: 'Jane Doe',
        },
        {
          id: '010',
          giver: 'Jane Doe',
          receiver: 'Sam Smith',
        },
        {
          id: '011',
          giver: 'Sam Smith',
          receiver: 'Jane Doe',
        },
        {
          id: '012',
          giver: 'John Doe',
          receiver: 'Sam Smith',
        },
      ],
    };

    setActionsPassed(data.actionsPassed);
    setSe(data.se);
    setLoading(false);
  };

  const fetchCompanyShoutouts = () => {
    axios
      .get('/companyShoutouts')
      .then((res) => {
        setShoutOuts(res.data);
      })
      .catch((error) => {
        console.error(
          'ManagerNotif - Error fetching company shoutouts:',
          error,
        );
        setErrors({ ...errors, shoutouts: error.message });
        setLoading(false);
      });
  };

  const fetchNotifications = () => {
    axios
      .get('/notifications')
      .then((res) => {
        const notifs = res.data;
        let newMembers = [];
        let shouts = [];
        let completed = [];
        let rated = [];
        let checks = [];
        let overdue = [];
        let survey = [];

        for (let i = 0; i < notifs.length; i++) {
          if (notifs[i].type === 'new team member') {
            newMembers.push(notifs[i]);
            setAnyNewUsers(true);
          } else if (notifs[i].type === 'shoutout') {
            shouts.push(notifs[i]);
            setAnyNewShouts(true);
          } else if (notifs[i].type === 'action rated') {
            rated.push(notifs[i]);
            setAnyNewRated(true);
          } else if (notifs[i].type === 'action completed') {
            completed.push(notifs[i]);
            setAnyNewCompleted(true);
          } else if (notifs[i].type === 'new check-in') {
            checks.push(notifs[i]);
            setAnyNewChecks(true);
          } else if (
            ['due 1 week', 'due tomorrow', 'past due'].includes(notifs[i].type)
          ) {
            overdue.push({
              ...notifs[i],
              type:
                notifs[i].type === 'due 1 week'
                  ? 'Action Expires Soon'
                  : notifs[i].type === 'due tomorrow'
                  ? 'Action Expires Soon'
                  : 'Action Expired',
              text: `action ${notifs[i].actionTitle} ${
                notifs[i].type === 'due 1 week'
                  ? 'expires in 1 week.'
                  : notifs[i].type === 'due tomorrow'
                  ? 'expires in 1 day.'
                  : 'has expired.'
              }`,
              img:
                notifs[i].type === 'due 1 week'
                  ? './Images/green-clock.png'
                  : notifs[i].type === 'due tomorrow'
                  ? './Images/yellow-clock.png'
                  : './Images/red-overdue-notif-icon.png',
            });
            setAnyNewOverdue(true);
          } else if (notifs[i].type === 'survey') {
            survey.push({ ...notifs[i], type: 'Survey' });
            setAnyNewSurvey(true);
          }
        }

        setNpsSurvey(survey);
        setNewUsers(newMembers);
        setRatedActions(rated);
        setOverdueActions(overdue);
        setCheckIns(checks);
        setActionsCompleted(completed);
        setShoutsToMe(shouts);
      })
      .catch((error) => {
        console.error('ManagerNotif - Error fetching notifications:', error);
        setErrors({ ...errors, notifications: error.message });
      });
  };

  // Function to check if the image URL is valid
  const isImageValid = (url) => {
    const image = new Image();
    image.src = url;
    return image.complete || image.width + image.height > 0;
  };

  return (
    <>
      {loading ? null : (
        <Page
          content={
            <div className='notif-page-outside'>
              <div className='row justify-content-center'>
                <div className='col-lg-11'>
                  <h2 className='mb-4'>Manager Notifications</h2>
                  <div className='row'>
                    <div className='col-lg-5 mx-4'>
                      {/* Hardcoded NPS SURVEY */}
                      {/* <div
                    className={
                      anyNewSurvey
                        ? 'row card-box'
                        : 'row card-box hidden'
                    }
                  >
                    <h3 className='box-title'>NPS Survey</h3>
                  </div>
                  <div
                    className={anyNewSurvey ? 'row' : 'row hidden'}
                  >
                    {npsSurvey.map((user, index) => (
                      <SurveyNotif
                        key={index}
                        id={user.notificationId}
                        surveyId={user.surveyId}
                        read={user.read}
                      />
                    ))}
                  </div> */}
                      {/* end */}
                      <div
                        className={
                          anyNewShouts ? 'row card-box' : 'row card-box hidden'
                        }
                      >
                        <h3 className='box-title'>Shout-Outs to You</h3>
                        <div className={anyNewShouts ? 'row' : 'row hidden'}>
                          {shoutsToMe.map((user, index) => (
                            <EmployeeShout
                              key={index}
                              name={user.senderName}
                              id={user.notificationId}
                              shoutId={user.shoutoutId}
                              text='shouted out to'
                              read={user.read}
                              to='You'
                            />
                          ))}
                        </div>
                      </div>
                      <div
                        className={
                          anyNewRated ? 'row card-box' : 'row card-box hidden'
                        }
                      >
                        <h3 className='box-title'>Rated Actions</h3>
                        <div className={anyNewRated ? 'row' : 'row hidden'}>
                          {ratedActions.map((action, index) => (
                            <RatedEmployee
                              key={index}
                              name='Your Manager'
                              text={`rated your action ${action.actionTitle}.`}
                              id={action.actionId}
                              id2={action.notificationId}
                              read={action.read}
                              img='./Images/green-checkmark.png'
                            />
                          ))}
                        </div>
                      </div>
                      <div
                        className={
                          anyNewOverdue ? 'row card-box' : 'row card-box hidden'
                        }
                      >
                        <h3 className='box-title'>Overdue Actions</h3>
                        <div className={anyNewOverdue ? 'row' : 'row hidden'}>
                          {overdueActions.map((action, index) => (
                            <IncompleteEmployee
                              key={index}
                              read={action.read}
                              text={action.text}
                              id={action.actionId}
                              type={action.type}
                              img={action.img}
                              id2={action.notificationId}
                            />
                          ))}
                        </div>
                      </div>
                      <div
                        className={
                          anyNewUsers ? 'row card-box' : 'row card-box hidden'
                        }
                      >
                        <h3 className='box-title'>New Team Members</h3>
                        <div className={anyNewUsers ? 'row' : 'row hidden'}>
                          {newUsers.map((user, index) => (
                            <Notif
                              key={index}
                              name={user.senderName}
                              text={`joined your team!`}
                              link={`/profile/${user.sender}`}
                              id={user.notificationId}
                              read={user.read}
                              img='./Images/green-new-user.png'
                            />
                          ))}
                        </div>
                      </div>
                      <div
                        className={
                          anyNewChecks ? 'row card-box' : 'row card-box hidden'
                        }
                      >
                        <h3 className='box-title'>Check-ins</h3>
                        <div className={anyNewChecks ? 'row' : 'row hidden'}>
                          {checkIns.map((checkIn, index) => (
                            <Notif
                              key={index}
                              name={checkIn.senderName}
                              text={`checked in on ${checkIn.date}, view the check-in and respond.`}
                              link={`/check/${checkIn.id}`}
                              id={checkIn.id}
                              img='./Images/green-checkmark.png'
                            />
                          ))}
                        </div>
                      </div>

                      <div className='row card-box'>
                        <h3 className='box-title'>Overdue Actions - Team</h3>
                        <div className='row'>
                          {actionsPassed.map((action, index) => (
                            <OverdueAction
                              key={index}
                              name={action.name}
                              id={action.id}
                              img='./Images/red-overdue-notif-icon.png'
                            />
                          ))}
                        </div>
                      </div>

                      <div className='row card-box'>
                        <h3 className='box-title'>Completed Actions - Team</h3>
                        <div className={anyNewCompleted ? 'row' : 'row hidden'}>
                          {actionsCompleted.map((action, index) => (
                            <ActionNotif
                              key={index}
                              senderName={action.senderName}
                              senderId={action.sender}
                              read={action.read}
                              id={action.actionId}
                              type={action.type}
                              img='./Images/green-checkmark.png'
                              id2={action.notificationId}
                            />
                          ))}
                        </div>
                      </div>
                    </div>
                    <div className='col-lg-5 mx-3'>
                      <div className='row card-box'>
                        <div className='row box-title-row'>
                          <h3 className='box-title'>Success Engagements</h3>
                          <div className='row engagement-row'>
                            {se.map((Se, index) => (
                              <Engagement
                                key={index}
                                name={Se.name}
                                text={`${Se.text}.`}
                                id={Se.id}
                                img='./Images/red-overdue-notif-icon.png'
                              />
                            ))}
                          </div>
                        </div>
                      </div>
                      <div className='row card-box'>
                        <div className='row box-title-row'>
                          <h3 className='box-title'>Shout Outs</h3>
                          <div className='row engagement-row'>
                            {shoutOuts.map((shout, index) => {
                              const imageSrc = isImageValid(shout.creatorImg)
                                ? shout.creatorImg
                                : DEFAULT_IMAGE_URL;

                              return (
                                <ShoutOut
                                  key={index}
                                  name={shout.creatorName}
                                  id={shout.shoutoutId}
                                  text='shouted out to'
                                  to={shout.receiverName}
                                  message={shout.message}
                                  img={imageSrc}
                                  created={shout.created}
                                />
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          }
        />
      )}

      {errors && (
        <div className='error-messages'>
          {errors.shoutouts && (
            <p>Error fetching shoutouts: {errors.shoutouts}</p>
          )}
          {errors.notifications && (
            <p>Error fetching notifications: {errors.notifications}</p>
          )}
        </div>
      )}
    </>
  );
};

export default ManagerNotif;
