import axios from 'axios';
import React, { useEffect, useState } from 'react';
import RatedAction from '../Actions/RatedAction';
import $ from 'jquery';
import { analytics } from '../../firebaseConfig.js';

function RatedEmployee({ id, id2, name, text, read, img }) {
  const [loading, setLoading] = useState(true);
  const [sub, setSub] = useState();
  const [rating, setRating] = useState();
  const [thisCheck, setThisCheck] = useState({});

  const getAction = () => {
    if (id !== undefined) {
      axios.get(`/getActionByID/${id}`).then((res) => {
        setThisCheck(res.data);
        if (res.data.actionType === 'sub') {
          setSub(true);
        } else {
          setSub(false);
        }
        const rating = res.data.actionRating;
        if (rating === 0) {
          setRating('☆ ☆ ☆ ☆ ☆');
        } else if (rating === 1) {
          setRating('★ ☆ ☆ ☆ ☆');
        } else if (rating === 2) {
          setRating('★ ★ ☆ ☆ ☆');
        } else if (rating === 3) {
          setRating('★ ★ ★ ☆ ☆');
        } else if (rating === 4) {
          setRating('★ ★ ★ ★ ☆');
        } else {
          setRating('★ ★ ★ ★ ★');
        }
      });
    }
  };

  const viewNotif = () => {
    analytics.logEvent(`rating_notif_viewed`);
    $(`#rated${id}btn`).addClass('btn-notif-grey');
    axios.post(`/notifications/${id2}`).then((res) => {
      console.log(res.data);
    });
  };

  useEffect(() => {
    getAction();

    // const setBigCheck = data.filter((x) => x.id === props.id.substring(0, 3));

    // if (props.id.length > 4) {
    //   const smallCheck = setBigCheck[0].sub.filter((x) => x.id === props.id);

    //   setThisCheck({
    //     sub: true,
    //     info: {
    //       main: setBigCheck[0].main,
    //       name: setBigCheck[0].name,
    //       status: smallCheck[0].status,
    //       title: smallCheck[0].name,
    //       dDate: smallCheck[0].dDate,
    //       rating: smallCheck[0].rating,
    //       comment: smallCheck[0].comment,
    //     },
    //   });
    //   // info: setBigCheck.sub.filter(x => x.id === props.id) });
    // } else {
    //   setThisCheck({
    //     sub: false,
    //     info: {
    //       priority: setBigCheck[0].priority,
    //       main: setBigCheck[0].main,
    //       name: setBigCheck[0].name,
    //       status: setBigCheck[0].status,
    //       title: setBigCheck[0].main,
    //       dDate: setBigCheck[0].dDate,
    //       description: setBigCheck[0].description,
    //       rating: setBigCheck[0].rating,
    //       comment: setBigCheck[0].comment
    //     },
    //   });
    // }

    setLoading(false);
  }, [id]);

  if (loading) return null;

  return (
    <div className='notif-outside row'>
      <div className='col-1 mb-3 icon-col'>
        <img
          className=' notif-icon'
          src='./Images/green-checkmark.png'
          alt={name}
        />
      </div>
      <div className='col-7'>
        <p className='notif-text'>
          <strong>{name}</strong> {text}
        </p>
      </div>
      <div className='col-4 notif-btn-row'>
        <button
          id={`rated${id}btn`}
          className={read ? 'btn-notif btn-notif-grey' : ' btn-notif'}
          data-toggle='modal'
          data-target={`#rated${id}`}
          onClick={() => viewNotif()}
        >
          view
        </button>
      </div>
      <RatedAction
        title={thisCheck.title}
        status={thisCheck.completed}
        id={thisCheck.actionId}
        sub={sub}
        name={thisCheck.name}
        main={thisCheck.mainActionTitle}
        priority={thisCheck.priority}
        dDate={thisCheck.dueDate}
        description={thisCheck.description}
        rating={rating}
        comment={thisCheck.ratingComment}
      />
    </div>
  );
}

export default RatedEmployee;
