const ModalCloseBtn = (props) => {
  const handlePauseAudio = () => {
    if (props.pauseAudio) {
      props.pauseAudio();
    }
  };

  return (
    <button
      type='button'
      className='closeBtn'
      data-dismiss='modal'
      aria-label='Close'
      onClick={handlePauseAudio}
    >
      <span aria-hidden='true'>X</span>
    </button>
  );
};

export default ModalCloseBtn;
