import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Page from '../../Components/NavBars/Page';
import { faExclamationCircle } from '@fortawesome/pro-solid-svg-icons';
import HelpBtn from '../../Components/Actions/HelpBtn';

class ManagerCheckIn extends Component {
  constructor() {
    super();
    this.state = {
      overseer: '',
      myCheckIns: [],
      checkIns: [],
      next: ' ',
      loading: true,
      errors: {},
      usersId: '',
    };
  }

  componentDidMount() {
    axios
      .get('/getCheckins')
      .then((res) => {
        if (res.data.length === 0) {
          console.log('no checkins');
          this.setState({ loading: false });
        } else {
          const data = res.data;
          let check = [];
          for (let i = 0; i < data.length; i++) {
            const checkins = data[i].checkin;
            const date = data[i].checkin[checkins.length - 1].created.substring(
              0,
              10,
            );

            const status = data[i].checkin[checkins.length - 1].response;
            check.push({ date: date, id: data[i].checkinId, status: status });
          }
          this.setState({
            myCheckIns: res.data[0].checkin.sort(
              (a, b) => Date.parse(b.created) - Date.parse(a.created),
            ),
            usersId: res.data[0].creatorId,
          });
        }
      })
      .catch((error) => {
        console.error('ManagerCheckIn - Error fetching checkins:', error);

        this.setState({
          loading: false,
          error: 'An error occurred fetching checkins.',
        });
      });
    axios
      .get('/getManagedCheckins')
      .then((res) => {
        if (res.data === 0) {
          console.log('no checkins');
          this.setState({ loading: false });
        } else {
          const data = res.data;
          let check = [];
          for (let i = 0; i < data.length; i++) {
            const employeeName = data[i].creatorName;
            const checkins = data[i].checkin;
            const date = data[i].checkin[checkins.length - 1].created.substring(
              0,
              10,
            );
            const response = data[i].checkin[checkins.length - 1].response;

            check.push({
              date: date,
              id: data[i].checkinId,
              name: employeeName,
              userId: data[i].creatorId,
              response: response,
            });
          }
          this.setState({ checkIns: check });
        }
      })
      .catch((error) => {
        console.error(
          'ManagerCheckIn - Error fetching managed checkins:',
          error,
        );

        this.setState({
          loading: false,
          error: 'An error occurred while fetching managed checkins.',
        });
      });

    axios
      .get('/user')
      .then((res) => {
        const data = res.data.credentials.overseer;
        if (data === '') {
          this.setState({ overseer: false });
        } else {
          this.setState({ overseer: true });

          const next = 'Today';
          this.setState({ next: next });
        }
        this.setState({ loading: false });
      })
      .catch((error) => {
        console.error('ManagerCheckin - Error fetching user data:', error);

        this.setState({
          loading: false,
          error: 'An error occurred while fetching user data.',
        });
      });
  }

  //TODO: Add Responded functionality
  render() {
    if (this.state.loading) return null;
    return (
      <>
        <Page
          content={
            <div className='m-checkin-page'>
              <div className='row view-check'>
                <div className='row check-alert'>
                  <div className='col-9 col-md-10 '>
                    <p className='alert-text'>
                      <FontAwesomeIcon
                        className='alert-icon'
                        icon={faExclamationCircle}
                      />
                      <strong className='alert-p'>
                        {`Next Check-in: ${this.state.next}`}
                      </strong>
                    </p>
                  </div>
                </div>
                <div className='col-10'>
                  <Link to='/newcheck'>
                    <button
                      className='btn addcheck-btn'
                      type='button'
                      style={{ color: 'white', textDecoration: 'none' }}
                    >
                      + Add Check-In
                    </button>
                  </Link>
                </div>
                <div className='col-2'>
                  <HelpBtn
                    source='../Audio/checkinsaudio2.mp3'
                    section='Check-Ins'
                  />
                </div>
              </div>
              {this.state.overseer ? (
                <>
                  <h1>My Check-ins</h1>
                  <div>
                    <table className='m-check-in-table mb-5'>
                      <thead>
                        <tr>
                          <th>Date</th>
                          <th>Response</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.myCheckIns.map((checkIn, index) => (
                          <tr key={index}>
                            <td>
                              <Link
                                className='link-btn'
                                to={{
                                  pathname: `/response/${this.state.usersId}`,
                                  state: {
                                    id: this.state.usersId,
                                    created: checkIn.created,
                                  },
                                }}
                                params={{ id: { userId: this.state.usersId } }}
                              >
                                {checkIn.created.substring(0, 10)}
                              </Link>
                            </td>
                            <td>
                              {checkIn.response === false ? 'None' : 'Yes'}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </>
              ) : (
                <div className='hidden' />
              )}
              <h1>Team Check-ins</h1>
              <div>
                <table className='m-check-in-table'>
                  <thead>
                    <tr>
                      <th>Employee</th>
                      <th>Date</th>
                      {/* <th>Responded</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.checkIns.map((user, index) => (
                      <tr key={index}>
                        <td>
                          <Link
                            className='link-btn'
                            to={`/check/${user.id}`}
                            params={{ id: { userId: user.id } }}
                          >
                            {user.name}
                          </Link>
                        </td>
                        <td>{user.date}</td>
                        {/* <td>Placeholder</td> */}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          }
        />
      </>
    );
  }
}

export default ManagerCheckIn;
